import React, { FC, useEffect } from 'react';
import './FavoriteSelectGroupModal.scss';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import Image from '../../../../../components/ui/components/Image/Image';
import IconCreatablePlus from '../../../../../components/ui/icons/hotels/IconCreatablePlus';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../../../store/auth/auth-selector';
import { Redirect } from 'react-router-dom';
import { wishListSelector } from '../../../../../store/account/favorite-group-list/favorite-group-list-selectors';
import IconNoPhoto from '../../../../../components/ui/icons/account/IconNoPhoto';
import { getFavoriteGroupThunk } from '../../../../../store/account/favorite-group-list/favorite-group-list-actions';
import { setToWishListThunk } from '../../../../../store/hotels/hotels-thunk';
import { FavoritesGroupListItem } from '../../../../../types/favorites';

interface Props {
   onClose: () => void;
   onAdd: () => void;
   id: number;
   onSetFavorite?: () => void;
}

interface FavoriteProps {
   title: string;
   description?: string;
   leftComponent: React.ReactNode;
}

const FavoriteSelectItem: FC<FavoriteProps> = ({ title, description, leftComponent }) => {
   return (
      <div className="favorite-select-item">
         {leftComponent}
         <div>
            <h5 className="favorite-select-item__title">{title}</h5>
            {description && <div className="favorite-select-item__description">{description}</div>}
         </div>
      </div>
   );
};

const FavoriteSelectGroupModal: FC<Props> = ({ onClose, onSetFavorite, onAdd, id }) => {
   const auth = useSelector(authSelector);
   const wishList = useSelector(wishListSelector);
   const dispatch = useDispatch();

   useEffect(() => {
      if (!wishList.data.length) {
         dispatch(getFavoriteGroupThunk());
      }
   }, []);

   if (!auth.isAuthorized) {
      return <Redirect to="/auth/signin" />;
   }

   const toWishList = (groupId: number) => {
      dispatch(
         setToWishListThunk({
            favorites_group_id: groupId,
            hotel_id: id,
            isFavorite: true,
         }),
      );

      onSetFavorite && onSetFavorite();

      // якщо запрос успішний тоді закриваю вікно
      onClose();
   };

   return (
      <Modal className="modal-favorite-select-group" onClose={onClose}>
         <ModalLayoutPrimary title="Ваше обране">
            <ul className="modal-favorite-select-group__list">
               <li onClick={onAdd}>
                  <FavoriteSelectItem title="Створити список обраного" leftComponent={<IconCreatablePlus />} />
               </li>

               {wishList.data.map((item: FavoritesGroupListItem) => {
                  const photo =
                     item.photos && item.photos.data && item.photos.data.length && item.photos.data[0].source && item.photos.data[0].source['302x170']
                        ? item.photos.data[0].source['302x170']
                        : '';

                  return (
                     <li onClick={() => toWishList(item.id)} key={item.id}>
                        <FavoriteSelectItem
                           title={item.name}
                           description={item.description}
                           leftComponent={
                              photo ? (
                                 <Image
                                    aspectRatio="1:1"
                                    width={64}
                                    src={photo}
                                    className="favorite-select-item__image"
                                 />
                              ) : (
                                 <IconNoPhoto />
                              )
                           }
                        />
                     </li>
                  );
               })}
            </ul>
         </ModalLayoutPrimary>
      </Modal>
   );
};

export default FavoriteSelectGroupModal;
