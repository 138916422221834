import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './MobileRoomDetailPrices.scss';

import { createBemClassProp } from '../../../../../../../../helpers/common';
import { RoomItemPrepared } from '../../../../../../../../types/rooms';
import RoomPriceMealIcon from '../../../../../../../../components/ui/icons/hotels/rooms/RoomPriceMealIcon';
import PriceViewer from '../../../../../../../../components/ui/components/PriceViewer/PriceViewer';
import AppText from '../../../../../../../../components/ui/typography/AppText/AppText';
import useOrderLogic from '../../../../../../../../hooks/useOrderLogic';
import {
    getOptionsForRoomCountSelect
} from '../../../../../components/HotelRooms/components/HotelRoomItem/hotelRoomHelpers';
import FieldStyledSelect from '../../../../../../../../components/ui/forms/fields/FieldStyledSelect/FieldStyledSelect';
import MobileRoomsListFooter from '../../../MobileRoomsList/components/MobileRoomsListFooter/MobileRoomsListFooter';
import MobileRoomSelect from '../../../MobileRoomSelect/MobileRoomSelect';
import useRoomsLogic from '../../../../../useRoomsLogic';
import NoRoomsMessage
    from '../../../../../components/HotelRooms/components/HotelRoomItem/components/NoRoomsMessage/NoRoomsMessage';
import HotelDetailOrderSummarySearchInfo
    from '../../../../../components/HotelDetailOrderSummary/components/HotelDetailOrderSummarySearchInfo/HotelDetailOrderSummarySearchInfo';

import { getHotelSelector } from '../../../../../../../../store/hotel/hotel-selector';

type Props = {
    room: RoomItemPrepared
    onFocusOnSearchForm: () => void
}

const MobileRoomDetailPrices: React.FC<Props> = ({room, onFocusOnSearchForm}) => {
    const {selectedOrderBookType, bookingState} = useSelector(getHotelSelector);
    const cn = createBemClassProp('mobile-room-detail-prices');
    const [selectedPrice, setSelectedPrice] = useState<any>();
    const {showDisabledPricesWithMessage, showNoPricesMessageWithButton, focusOnEditForm} = useRoomsLogic(room);
    const {reservations, getSelectedValuesForSelect, getReservationsSum, addRoomToOrder} = useOrderLogic();
    const showPrices = !!room?.prices?.data?.length;

    let paddingBottom = 16;
    if (reservations?.length) paddingBottom += 40;

    return (
        <>
            <div {...cn('', showDisabledPricesWithMessage && 'disabled')}>
                <HotelDetailOrderSummarySearchInfo
                    onClickOnEdit={onFocusOnSearchForm}
                    fromPhonesSection
                />

                <div {...cn('prices')} style={{paddingBottom}}>
                    <span {...cn('title')}>Пакети проживання</span>
                    {(showDisabledPricesWithMessage || showNoPricesMessageWithButton) && selectedOrderBookType === 'cart' ?
                        <NoRoomsMessage text={room?.message?.data?.text}/> : null}

                    {showPrices && room.prices.data.map((price, index) => (
                        <div {...cn('price-row')} key={'mobile-room-price-' + index}>
                            <div {...cn('meal-icon-wrap')}>
                                <RoomPriceMealIcon variant={price.mealType.code}/>
                            </div>

                            <div>
                                <AppText variant={'gray'} {...cn('price-label')}>
                                    {price.mealType.name}
                                </AppText>

                                {selectedOrderBookType === 'cart' && <PriceViewer price={price.totalPrice}/>}
                            </div>

                            <div>
                                {(selectedOrderBookType === 'phone') && <PriceViewer price={price.totalPrice}/>}

                                {selectedOrderBookType === 'cart' && (
                                    <FieldStyledSelect
                                        onClick={() => setSelectedPrice(price)}
                                        options={getOptionsForRoomCountSelect(room.availableRoomsCount)}
                                        disabled={showDisabledPricesWithMessage}
                                        placeholder={'Вибрати'}
                                        values={getSelectedValuesForSelect(room.id, price.rateId)}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {selectedPrice && (
                    <MobileRoomSelect
                        maxRooms={room.availableRoomsCount}
                        selectedCount={getReservationsSum(room.id, selectedPrice?.rateId)}
                        onClose={() => setSelectedPrice(null)}
                        onSelect={(count) => {
                            addRoomToOrder(room, selectedPrice, count);
                            setSelectedPrice(null);
                        }}
                    />
                )}

                <MobileRoomsListFooter/>
            </div>
        </>
    )
};

export default MobileRoomDetailPrices;
