import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './NotFound.scss';
import Page from '../../components/common/Page/Page';
import Container from '../../components/ui/layouts/Container/Container';
import NoFoundBackground from './components/NoFoundBackground';
import AppButton from "../../components/ui/components/Buttons/AppButton/AppButton";

const NotFound = () => {
    const [t] = useTranslation('404');

    return (
        <Route
            render={({staticContext}) => {
                if (staticContext) staticContext.statusCode = 404;

                return (
                    <Page>
                        <Container>
                            <div className="not-found-back">
                                <div className="not-found-back__text">
                                    <h1>{t('Уппс!')}</h1>
                                    <h2>{t('Схоже, ми не знайшли потрібної вам сторінки')}.</h2>
                                    <h5>{t('Код помилки: 404')}</h5>
                                    <AppButton to="/" size="middle">{t('Повернутися на головну')}</AppButton>
                                </div>

                                <div className="not-found-back__background">
                                    <NoFoundBackground/>
                                </div>
                            </div>
                        </Container>
                    </Page>
                );
            }}
        />
    );
};

NotFound.getInitialProps = async () => {
    return {statusCode: 404}
};

export default NotFound;
