import React, { ClipboardEvent, FC, HTMLProps } from 'react';
import {Field, FieldProps} from 'formik';
import ControlLayout from './ControlLayout/ControlLayout';
import FieldCode from '../muiFields/FieldCode/FieldCode';
import { removeSymbolsFromString } from "../../../../helpers/regEx";

interface Props extends HTMLProps<HTMLInputElement> {
   label?: string;
   onPast?: (e: any) => void
}

const AppFormCode: FC<Props> = props => {
   const {name, disabled, label, autoFocus, onPast} = props;

   return (
      <Field name={name}>
         {(propsField: FieldProps) => {
            const {field, meta, form} = propsField;
            const isError: boolean = meta.touched && !!meta.error;

            const handlePast = (e: ClipboardEvent<HTMLInputElement>) => {
               e.preventDefault()
               let value = e.clipboardData.getData('Text');

               if(onPast){
                  onPast(e)
               }

               const unmask = removeSymbolsFromString(value);
               form.setFieldValue(name, unmask);
            }
            return (
               <ControlLayout isError={isError} error={meta.error}>
                  <FieldCode
                     length={4}
                     label={label}
                     {...field}
                     value={field.value}
                     onFocus={() => {
                        // form.setFieldTouched(name, true);
                     }}
                     onBlur={()=>{
                        form.setFieldTouched(name, true);
                     }}
                     onChange={(value: string) => {
                        form.setFieldValue(name, value);
                     }}
                     onPaste={handlePast}
                     autoFocus={autoFocus}
                     isError={isError}
                     disabled={disabled}
                  />
               </ControlLayout>
            );
         }}
      </Field>
   );
};

export default AppFormCode;
