import React from 'react';
import './ClickableBlock.scss';

type Props = {
    title?: string,
    description?: string,
    onClick: () => void

}

const ClickableBlock: React.FC<Props> = ({title, description, children, onClick}) => (
    <div className={'clickable-block'} onClick={onClick}>
        <div>
            {title && <span className={'clickable-block__title'}>{title}</span>}

            {description && <div className={'clickable-block__description'}>{description}</div>}

            {children}

        </div>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6829 12L9.24076 5.65081C8.88134 5.23148 8.9299 4.60018 9.34923 4.24076C9.76855 3.88134 10.3999 3.9299 10.7593 4.34923L16.7593 11.3492C17.0803 11.7237 17.0803 12.2763 16.7593 12.6508L10.7593 19.6508C10.3999 20.0701 9.76855 20.1187 9.34923 19.7593C8.9299 19.3999 8.88134 18.7686 9.24076 18.3492L14.6829 12Z"
                fill="#6D7188"/>
        </svg>

    </div>
);

export default ClickableBlock;
