import React, { FC } from 'react';
import Slick, { Settings } from 'react-slick';
import './Carousel.scss';
import IconRight from './components/IconRight';
import IconLeft from './components/IconLeft';
import classNames from 'classnames';
import CarouselSecondaryArrow from '../../carousel/CarouselSecondaryArrow/CarouselSecondaryArrow';

interface Props extends Settings {
   ref?: any;
   theme?: 'primary' | 'secondary';
}

const Carousel: FC<Props> = React.forwardRef((props, ref: any) => {
   const { theme = 'primary', ...rest } = props;

   const arrows =
      theme === 'secondary'
         ? {
              nextArrow: <CarouselSecondaryArrow direction={'next'} />,
              prevArrow: <CarouselSecondaryArrow direction={'prev'} />,
           }
         : {
              nextArrow: <IconRight />,
              prevArrow: <IconLeft />,
           };

   const settings = {
      touchThreshold: 50,
      speed: 100,
      infinite: true,
      focus: false,
      arrows: true,
      initialSlide: 0,
      slidesToScroll: 1,
      ...arrows,
      ...rest,
   };

   const classes = classNames('carousel', `carousel--${theme}`);
   return (
      <div className={classes}>
         <Slick {...settings} ref={ref} />
      </div>
   );
});

export default Carousel;
