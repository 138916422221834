import React from "react";
import LoaderAspectRatio from "../../../../../components/ui/loaders/LoaderAspectRatio";
import "./FavoriteContentLoader.scss";


const FavoriteLoaderItem = () => {
    return (
        <div className="favorite-content-loader-item">
            <div className="favorite-content-loader-item__body">
                <LoaderAspectRatio aspectRatio="3:4"/>
                <div>
                    <LoaderAspectRatio aspectRatio="3:4"/>
                    <LoaderAspectRatio aspectRatio="3:4"/>
                </div>
            </div>
        </div>
    )
}


const FavoriteContentLoader = () => {
    return (
        <div className="favorite-content-loader">
            {[0, 1, 2, 3, 4].map((item) => (<FavoriteLoaderItem key={item}/>))}
        </div>
    )
}

export default FavoriteContentLoader;