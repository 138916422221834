import {AppState} from "../index";


export const authSelector = (state: AppState) => {
    return state.auth
}
export const isAuthorizedSelector = (state: AppState) => {
    return state.auth.isAuthorized;
}

export const authStageSelector = (state: AppState) => {
    return state.auth.stage;
}