import { AppState } from "../index";
import { HotelListItemType } from '../../types/hotels';
import { DISABLE_STARS_SHOW } from '../../config';

export const getHotelSelector = (state: AppState) => {
    return {
        ...state.hotel,
        data: {
            ...state.hotel.data,
            stars: DISABLE_STARS_SHOW ? 0 : state.hotel.data.stars
        }
    };
}
