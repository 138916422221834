import React, { FC, memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AuthLinks.scss';

import IconUnknownUser from '../../../../../ui/icons/header/IconUnknownUser';



const AuthLinks: FC = memo(() => {
    const [t] = useTranslation();
    const {pathname, search} = useLocation();


    return (
        <div className="auth-links">
            <div className="auth-links__user">
                <IconUnknownUser/>
            </div>
            <ul>
                <li>
                    <NavLink to={`/auth/signin?redirect-url=${pathname}${encodeURIComponent(search)}`}>{t('Вхід')}</NavLink>
                </li>
                <li>
                    <NavLink to={`/auth/signup?redirect-url=${pathname}${encodeURIComponent(search)}`}>{t('Реєстрація')}</NavLink>
                </li>
            </ul>
        </div>
    );
});

export default AuthLinks;
