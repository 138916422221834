import React, { Suspense } from 'react';
import './AccountOrdersDetail.scss';
import Page from '../../../../components/common/Page/Page';
import Container from '../../../../components/ui/layouts/Container/Container';
import PageHeader from '../../common/PageHeader/PageHeader';
import AccountOrdersDetailMain from './components/AccountOrdersDetailMain/AccountOrdersDetailMain';
import { RouteNames } from '../../../../routes';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { unwrapResult } from '@reduxjs/toolkit';
import {
    getAccountOrdersDetailThunk
} from '../../../../store/account/account-orders-detail/account-orders-detail-thunks';
import { orderDetailSelector } from '../../../../store/account/account-orders-detail/account-orders-detail-selectors';

const AppMapLazy = React.lazy(() => import('../../../../components/common/AppMap/AppMap'));

const AccountOrdersDetail = () => {
    const ordersDetail = useAppSelector(orderDetailSelector)
    const {isSending} = ordersDetail;
    const {hotel, latitude, longitude} = ordersDetail.data;

    const breadcrumbs = [
        {
            url: RouteNames.PROFILE_INDEX,
            title: 'Акаунт',
        },
        {
            title: 'Мої подорожі',
            url: RouteNames.PROFILE_ORDERS_LIST,
        },
        {
            title: hotel,
        },
    ];

    return (
        <Page className="account-orders-detail-page">
            <Container>
                <div className="account-orders-detail">
                    <div className="account-orders-detail__left">
                        <PageHeader breadcrumbs={breadcrumbs}/>
                        {!ordersDetail.isFetching ? (
                            <AccountOrdersDetailMain
                                orderDetail={ordersDetail.data}
                                isSending={isSending}
                            />
                        ) : 'LOADING.....'}
                    </div>

                    <div className="account-orders-detail__right">
                        <div className="account-orders-detail-map">
                            {!ordersDetail.isFetching && latitude && longitude ? (
                                <Suspense fallback={''}>
                                    <AppMapLazy coordinates={{latitude, longitude}}/>
                                </Suspense>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Container>
        </Page>
    );
};

AccountOrdersDetail.getInitialProps = ({store, match, ...rest}) => {
    const {id} = match.params;

    return store.dispatch(getAccountOrdersDetailThunk(id))
        .then(unwrapResult)
        .catch(error => {
            // this code will redirect to 404 page after any error
            return {statusCode: 404};
        });
};

export default AccountOrdersDetail;
