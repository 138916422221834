import qs from 'qs';

// функція яка парсить url строку
export const getUrlParams = (search) => {
    let hashes = search.slice(search.indexOf('?') + 1).split('&');
    let params = {};

    hashes.map((hash) => {
        let [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
    });

    return params;
};

export const updateQueryStringParameter = (uri, key, value) => {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        return uri + separator + key + '=' + value;
    }
};

export function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (url.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

export const serializeQueryStringParameter = function (obj, withQuestionMark = true) {
    const str = qs.stringify(obj, {arrayFormat: 'brackets'});
    return withQuestionMark ? '?' + str : str;
}

export const removePlusSignsFromString = (str: string): string => {
    return str.replace(/\+/g, ' ');
}

export const parseSearchParams = (search: string) => {
    return qs.parse(search, {ignoreQueryPrefix: true})
}

// export class UrlQueryService {
//     constructor(private search: string) {
//
//     }
//
//     setSearchString(search: string) {
//         this.search = search;
//     }
//
//     addParam(key: string, value) {
//         const parsedSearch = qs.parse(this.search);
//
//         console.log(parsedSearch);
//     }
//
//
//     removeParam() {
//
//     }
//
//
// }


