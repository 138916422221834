import React from 'react';
import { scroller } from 'react-scroll';
import { useSelector } from 'react-redux';
import './HotelDetailOrderSummary.scss';

import HotelDetailOrderSummarySelectedContent
    from './components/HotelDetailOrderSummarySelectedContent/HotelDetailOrderSummarySelectedContent';
import { orderDataSelector } from '../../../../../store/order/order-selector';
import HotelDetailOrderSummarySearchInfo
    from './components/HotelDetailOrderSummarySearchInfo/HotelDetailOrderSummarySearchInfo';
import { setHotelSearchFormFocus } from '../../../../../store/rooms/rooms-reducer';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import InfoRow from '../common/InfoRow';
import { getHotelSelector } from '../../../../../store/hotel/hotel-selector';

type Props = {};

const HotelDetailOrderSummary: React.FC<Props> = () => {
    const {reservations, totalRoomsCount, totalPrice} = useSelector(orderDataSelector);
    const {focusOnHotelSearchForm} = useAppSelector(state => state.rooms);
    const hotelData = useAppSelector(getHotelSelector);

    const dispatch = useAppDispatch();

    const onClickOnEdit = () => {
        if (!focusOnHotelSearchForm) {
            scroller.scrollTo('hotelDetailSearchForm',
                {
                    offset: -100, smooth: true, duration: 300,
                    //containerId: 'layoutContainer'
                }
            );
            dispatch(setHotelSearchFormFocus(true));

            setTimeout(() => {
                dispatch(setHotelSearchFormFocus(false));
            }, 1300);
        }
    }

    return (
        <div className="hotel-detail-order-summary">
            <HotelDetailOrderSummarySearchInfo onClickOnEdit={onClickOnEdit}/>

            {reservations.length ? (
                <div>
                    {/*<HotelDetailOrderSummarySearchInfo onClickOnEdit={onClickOnEdit}/>*/}
                    <HotelDetailOrderSummarySelectedContent
                        reservations={reservations}
                        totalRoomsCount={totalRoomsCount}
                        totalPrice={totalPrice}
                    />
                </div>
            ) : (
                <div>
                    <span className={'hotel-detail-order-summary__title'}>Виберіть свій номер</span>
                    <InfoRow text={'Миттєве підтвердження'}/>
                    <InfoRow text={'Відсутність зборів за бронювання'}/>
                    <InfoRow text={'Бронювання займає лише 2 хвилини.'}/>
                </div>
            )}
        </div>
    );
};

export default HotelDetailOrderSummary;
