import React from 'react';
import './MobileRoomsInfoLineTexts.scss';
import AppText from '../../../../../../components/ui/typography/AppText/AppText';

type Props = {
    roomsText: string,
    bathroomsText: string,
    square?: number | string,
    roomPersonsData: any
    isInList?: boolean
}

const MobileRoomsInfoLineTexts: React.FC<Props> = ({roomsText, bathroomsText, square, roomPersonsData, isInList}) => {

    const roomText = (
        <AppText variant={'gray'}>
            {`${roomsText}, ${bathroomsText}`}
        </AppText>);

    return (
        <div className={'mobile-rooms-info-line-texts'}>
            <div>
                {isInList ? roomText : roomPersonsData}

                {square && <AppText variant={'gray'}>
                    {square} m2
                </AppText>}
            </div>

            <div>
                {isInList && <div></div>}
                {isInList ? roomPersonsData : roomText}
            </div>
        </div>
    )
};

export default MobileRoomsInfoLineTexts;
