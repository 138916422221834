import React, { FC } from "react";
import "./Container.scss";

interface Props {
    className?: string
}

const Container: FC<Props> = (props) => {
    const {children, className = ''} = props;

    return (
        <div className={`container ${className}`}>
            {children}
        </div>
    )
}

export default Container
