import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { completeSchema } from '../../../../helpers/validation';
import AppFormInput from '../../../../components/ui/forms/controls/AppFormInput';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import AppFormSelect from '../../../../components/ui/forms/controls/AppFormSelect';
import { FormikHelpers } from 'formik/dist/types';
import { SignUpCompleteRequest } from '../../../../types/auth';
import { genderOptions } from "../../../Account/Profile/helpers/common";

interface IProps {
   onSubmit: (data: SignUpCompleteRequest, formikHelpers: FormikHelpers<SignUpCompleteRequest>) => void;
   isSending: boolean;
}

const initialValues: SignUpCompleteRequest = {
   name: '',
   lastname: '',
   email: '',
   birthday: '',
   gender: '1',
   password: '',
   password_confirmation: '',
};

const FormComplete: FC<IProps> = ({ onSubmit, isSending }) => {
   return (
      <Formik
         initialValues={initialValues}
         validationSchema={completeSchema}
         onSubmit={onSubmit}
         // enableReinitialize
      >
         <Form>
            <div>
               <AppFormInput name="name" label="Ім'я" />
               <AppFormInput name="lastname" label="Прізвище" />
               <AppFormInput name="email" label="E-mail" />
               <AppFormInput mask="99.99.9999" label="Дата народження (день.місяць.рік)" name="birthday" />
               <AppFormSelect options={genderOptions} name="gender" label="Стать" />
               <AppFormInput type="password" name="password" label="Пароль" />
               <AppFormInput type="password" name="password_confirmation" label="Пароль" />
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
               <AppButton theme="primary" size="middle" className="sign-up__submit sign-up__full" showIndicator={isSending}>
                  Зареєструватися
               </AppButton>
            </div>
         </Form>
      </Formik>
   );
};

export default FormComplete;
