import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';

import { RoomItemPrepared } from '../../../../../types/rooms';
import HotelRoomItem from './components/HotelRoomItem/HotelRoomItem';
import { HotelPropertyItem } from '../../../../../types/hotel';
import HotelAmenitiesModal from '../HotelAmenities/HotelAmenitiesModal/HotelAmenitiesModal';
import HotelRoomNoData from './components/HotelRoomNoData/HotelRoomNoData';
import ModalGallery from '../../../../../components/hotels/ModalGallery/ModalGallery';
import { getHotelSelector } from '../../../../../store/hotel/hotel-selector';
import { RouteNames } from '../../../../../routes';

type Props = {
   hotelRoomItems: Array<RoomItemPrepared>;
};

const HotelRooms: React.FC<Props> = ({ hotelRoomItems }) => {
   const [propertiesForModal, setPropertiesForModal] = useState<HotelPropertyItem[] | null>();
   const hotel = useSelector(getHotelSelector);
   const { search } = useLocation<any>();
   const params = useParams<{ id: string }>();
   const history = useHistory();
   const [galleryPhotos, setGalleryPhotos] = useState([]);

   const { alias, name } = hotel.data;
   const backUrl = `/hotels/${alias}`;
   const backUrlWithSearch = `${backUrl}/${search};`;

   useEffect(() => {
      const { id } = params;

      if (id) {
         const room = hotelRoomItems.find(room => String(room.id) === id);

         if (room && !galleryPhotos.length) setGalleryPhotos(room.photos);
      }
   }, [params]);

   const showRoomItems = hotelRoomItems && hotelRoomItems.length;

   return (
      <>
         <Switch location={useLocation()}>
            <Route
               exact={false}
               path={RouteNames.HOTELS_ROOMS_GALLERY}
               render={() =>
                  galleryPhotos.length && params?.id ? (
                     <ModalGallery
                        images={galleryPhotos}
                        roomId={params?.id}
                        onClose={() => history.replace(backUrlWithSearch)}
                        initialPathName={backUrl}
                     />
                  ) : null
               }
            />
         </Switch>

         {showRoomItems ? (
            <div>
               {hotelRoomItems.map((hotelRoomItem, index) => (
                  <HotelRoomItem
                     key={'hotel-room-item' + hotelRoomItem.id + index}
                     roomItem={hotelRoomItem}
                     onShowRoomProperties={() => setPropertiesForModal(hotelRoomItem.properties)}
                  />
               ))}

               {propertiesForModal && (
                  <HotelAmenitiesModal
                     hotelName={name}
                     properties={propertiesForModal}
                     onClose={() => setPropertiesForModal(null)}
                  />
               )}
            </div>
         ) : (
            <HotelRoomNoData />
         )}
      </>
   );
};

export default HotelRooms;
