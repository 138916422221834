import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './AppButton.scss';

const ButtonPreloader = () => (
    <div className="button-preloader">
        <span className="button-preloader__dot"/>
        <span className="button-preloader__dot"/>
        <span className="button-preloader__dot"/>
    </div>
);

interface Props {
    to?: string; //For internal links
    href?: string; //For external links
    replace?: boolean; //History replace
    showIndicator?: boolean;
    icon?: any;
    theme?: 'outline' | 'outline-primary' | 'fillable' | 'light' | 'primary' | 'link';
    type?: 'button' | 'submit';
    size?: 'small' | 'middle' | 'large' | 'big';
    color?: 'green' | 'red';
    className?: string;
    disabled?: boolean;
    onClick?: (e: any) => void;
    isLoading?: boolean;
    fullWidth?: boolean
    style?: React.CSSProperties;
    target?: string;
}

const AppButton: FC<Props> = memo((props) => {
    const {
        to,
        children,
        showIndicator,
        isLoading,
        icon,
        theme,
        type,
        size,
        className = '',
        onClick,
        disabled,
        style,
        color,
        href,
        fullWidth,
        ...rest
    } = props;

    const classes = classNames('app-button', size, theme, {
        [`color-${color}`]: color,
        'full-width': fullWidth
    }, className);

    if (to) {
        return (
            <Link to={to} className={classes} style={style} {...rest}>
                {/*@ts-ignore*/}
                {children}
            </Link>
        );
    }

    if (href) {
        return (
            <a href={href} className={classes} style={style} onClick={onClick} {...rest}>
                {/*@ts-ignore*/}
                {children}
            </a>
        )
    }

    return (
        <>
            <button
                className={classes}
                type={type}
                role="button"
                style={style}
                disabled={disabled || showIndicator}
                onClick={!isLoading ? onClick : () => {}}
                {...rest}
            >
                {showIndicator || isLoading ? (
                    <ButtonPreloader/>
                ) : (
                    <>
                        {icon && <i className={'app-button__icon-wrap'}>{icon}</i>}
                        {children}
                    </>
                )}
            </button>
        </>
    );
});

export default AppButton;
