import { AxiosResponse, AxiosError } from 'axios';
import { Cookies, useCookies } from 'react-cookie';

const cookies = new Cookies();

import { axiosInstance } from '../api/axiosInstance';
import {
    HTTP_HEADER_AUTHORIZATION,
    HTTP_HEADER_CONTACT_TOKEN,
    HTTP_HEADER_CURRENCY,
    HTTP_HEADER_LANGUAGE
} from '../config';

type Header =
    typeof HTTP_HEADER_AUTHORIZATION
    | typeof HTTP_HEADER_LANGUAGE
    | typeof HTTP_HEADER_CURRENCY
    | typeof HTTP_HEADER_CONTACT_TOKEN

export const setAxiosInstanceHeader = (key: Header, value: string) => {
    if (process.env.RAZZLE_USE_MOCK && key === HTTP_HEADER_CURRENCY) return;

    axiosInstance.defaults.headers.common[key] = value;
};

export const setContactToken = (token: string, setCookie = true, deleteCookie = false) => {
    if (token) {
        if (setCookie) cookies.set(HTTP_HEADER_CONTACT_TOKEN, token, {path: '/'});
        setAxiosInstanceHeader(HTTP_HEADER_CONTACT_TOKEN, token);
    } else {
        if (deleteCookie) cookies.remove(HTTP_HEADER_CONTACT_TOKEN, {path: '/'});
        delete axiosInstance.defaults.headers.common[HTTP_HEADER_CONTACT_TOKEN];
    }
}

const isAuthTokenIsSet = () => axiosInstance.defaults.headers.common[HTTP_HEADER_AUTHORIZATION];

export function fetchData<T>(url: string, options?: any): Promise<T> {
    return axiosInstance
        .get(url, options)
        .then((result: AxiosResponse) => result.data)
        .catch((error: AxiosError) => {
            // signOut(error.response.status);
            throw error.response;
            // signOut(error?.response?.status);
        });
};

export const sendData = (url: string, data?: any, options?: any) => {
    return axiosInstance
        .post(url, data, options)
        .then((result: AxiosResponse) => result.data)
        .catch((error: AxiosError) => {
            // signOut(error.response.status);
            throw error;
        });
};

export const editData = (url: string, data?: any, options?: any) => {
    return axiosInstance
        .put(url, data, options)
        .then((result: AxiosResponse) => result.data)
        .catch((error: AxiosError) => {
            // signOut(error.response.status);
            throw error;
        });
};

export const patchData = (url: string, data?: any, options?: any) => {
    return axiosInstance
        .patch(url, data, options)
        .then((result: AxiosResponse) => result.data)
        .catch((error: AxiosError) => {
            // signOut(error.response.status);
            throw error;
        });
};

export const deleteData = (url: string, options?: any) => {
    return axiosInstance
        .delete(url, options)
        .then((result: AxiosResponse) => result.data)
        .catch((error: AxiosError) => {
            // signOut(error.response.status);
            throw error;
        });
};

export const getData = fetchData;
export const postData = sendData;
export const putData = editData;

// axiosInstance.interceptors.response.use((data => {
//     if (!isAuthTokenIsSet()) {
//         console.log(data.data.contactToken);
//     }
//
//     return data;
// }), error => {
//
// })
/**
 axiosInstance.interceptors.response.use((config)=>{
   return config
},async (error) =>{
   const originalRequest = error.config;

   if(error.response.status === 401 && error.config && !originalRequest._isRetry){
      originalRequest._isRetry = true

      try{
         // на сервері для refresh_token повинно вказуватись httpOnly: true для кук

         // refresh token
        const response =  await axios.get(`${process.env.RAZZLE_BASE_URL}/auth/refresh` ,{withCredentials: true}) //  by refresh token from cookies make request for access token

          // зберегти в універсальних куках і повідомити стор
          response.data.accessToken


         // і повторяємо запрос який був через 401 помилку
         axiosInstance.request(originalRequest)
      } catch (e){
         console.log("")
      }
      throw error
   }
})
 **/

function signOut(status: number) {
    if (status === 401) {

    }
}


