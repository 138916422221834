import {createAsyncThunk} from "@reduxjs/toolkit";
import hotelsAPI from "../../api/hotelsAPI";

export const getHotelThunk = createAsyncThunk(
    'hotel/getHotelThunk',
    async (alias: string, { rejectWithValue }) => {
        try {
            const res = await hotelsAPI.fetchHotelByAlias(alias);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response || error)
        }
    }
)
