import { fetchData, postData } from "../helpers/network";


interface RequestCompeted {
  phone_id?: string;
  phone?: string;
  company_name: string;
  email: string;
  admin_name: string;
  admin_lastname: string;
  password: string;
  secret: string;
}

interface ResponseHotels {
  data: Array<{
    id: string;
    name: string;
    photo: string;
  }>
}

interface RequestConfirm{
  phone: string;
  code: string;
}

interface RequestSelectHotel {
  phone?: string;
  phone_id?: string
  'g-recaptcha-response': string
}

type RequestId = string | number


const hotelRegistrationApi = {
  fetchRegistrationHotelList: (query: string) => fetchData<ResponseHotels>(`/hotels/registration${query}`),
  fetchRegistrationHotelDetail: (id: RequestId) => fetchData<any>(`/hotels/registration/${id}`),
  setSelectedHotel: (id: RequestId, data: RequestSelectHotel) => postData(`/hotels/registration/${id}/register`, data),
  registerNew: (data: {phone: string; 'g-recaptcha-response': string}) => postData(`/hotels/registration/register`, data),
  confirmCode: (data: RequestConfirm) => postData(`/hotels/registration/confirm-code`, data),
  registrationCompleted: (data: RequestCompeted) => postData(`/hotels/registration/company`, data),
}

export default hotelRegistrationApi