import React, {FC} from "react";
import "./NextTripItem.scss";
import Image from "../../../../../../components/ui/components/Image/Image";


interface Props {
   date: string;
    place: string
    image: string
}

const NextTripItem: FC<Props> = (props) => {
    const {date, image, place} = props

    return <div className='next-trip-item'>
        <Image src={image} aspectRatio={'1:1'} className='next-trip-item__image'/>
        <div className='next-trip-item__block-information'>
            <div className='next-trip-item__name'>{place}</div>
            <div className='next-trip-item__date'>Період бронювання:<br/> {date}</div>
        </div>
    </div>
}

export default NextTripItem