import { useState } from 'react';

interface UseSwitcher {
    onOpen: () => void;
    onToggle: () => void;
    onClose: () => void;
    isOpen: boolean;
}

/**
 * switch functionality
 * * */
const useSwitcher = (initialFlag = false): UseSwitcher => {
    const [isOpen, setSwitcher] = useState(initialFlag);

    const onOpen = () => {
        setSwitcher(true);
    };

    const onToggle = () => {
        setSwitcher(!isOpen);
    };

    const onClose = () => {
        setSwitcher(false);
    };

    return {
        isOpen,
        onOpen,
        onToggle,
        onClose,
    };
};

export default useSwitcher;
