import React from 'react';
import AppMedia from './Media';

export const MobileOnly: React.FC = ({children}) => {
    return (
        <AppMedia.Media lessThan={'md'}>{children}</AppMedia.Media>
    )
};

export const TabletAndUpper: React.FC = ({children}) => {
    return (
        <AppMedia.Media greaterThanOrEqual={'md'}>{children}</AppMedia.Media>
    )
};

export const DesktopOnly: React.FC = ({children}) => {
    return (
        <AppMedia.Media greaterThanOrEqual={'lg'}>{children}</AppMedia.Media>
    )
};

// export const DesktopOnly: React.FC = ({children}) => {
//     return (
//         <AppMedia.Media greaterThanOrEqual={'lg'}>{children}</AppMedia.Media>
//     )
// };


