import React from "react";
import "./Сontract.scss";
import AppButton from "../../../../../components/ui/components/Buttons/AppButton/AppButton";


const Сontract = ({onCloseContractStep}) => {
  return (
    <div className="registration-hotel-contract">
      <h1>ПУБЛІЧНИЙ ДОГОВІР ПРО НАДАННЯ ПОСЛУГ КОРИСТУВАННЯ САЙТОМ</h1>
      <p>
        Цей Публічний договір (далі за текстом - “Договір”) визначає порядок надання та отримання послуг користування
        сайтом для бронювання готелів, регулює відносини між Готелем та Оператором сайту. В розумінні ст. 633 Цивільного
        кодексу України цей Договір є публічним договором , укладається сторонами з урахуванням статей 633, 634, 641,
        642 Цивільного кодексу України шляхом приєднання Готелю до умов цього договору. Умови Договору є однаковими для
        всіх Готелів.
      </p>

      <h4>ЗАГАЛЬНІ ПОЛОЖЕННЯ ТА ТЕРМІНИ</h4>

      <div className="registration-hotel-contract__section">
      <h5>У цьому Договорі терміни вживаються в наступному значенні:</h5>
        <p>Гість (споживач) - фізична особа або юридична особа (в т.ч. фізична особа-підприємець), яка виявила бажання
        скористатися послугами бронювання, уклала Договір з Готелем та належним чином виконує умови Договору. </p>
        <p>Оператор сайту - особа, яка належним чином зареєстрована та надає послуги користування інтернет - сайтом
          (вебсайт) Готелю для реалізації останнім готельних та інших туристичних послуг Гостям .</p>
        <p>Готель - фізична особа-підприємець або юридична особа інший суб’єкт господарювання , що надає послуги по
        розміщенню та проживанні у Засобах розміщення. В значенні Готель вживаються всі засоби розміщення передбачені в
        Україні , а саме готелі, бази відпочинку, будинки відпочинку, , готельно-офісні центри, гуртожитки, дитячі
        табори, кемпінги, клуб-готелі, пансіонати, профілакторії, санаторії, туристичні бази, та інші засоби тимчасового
        проживання.</p>
        <p>Готель надає послуги у сфері туризму з готельного обслуговування (розміщення) споживача, шляхом надання номера
        (місця) для тимчасового проживання в засобах розміщення, а також інші послуги, пов'язані з розміщенням і
        тимчасовим проживанням.</p>
        <p>Платформи - веб-сайт(-и), додатки, інструменти, платформи та (або) інші пристрої bronui.com, на яких або через
          які доступна (надається) Послуга.</p>
        <p>Екстранет - онпайн-системи bronui.com, до яких Готель може мати доступ (після ідентифікації імені користувача та
        пароля) для завантаження, зміни, перевірки, оновлення та (або) уточнення Інформації про Готель (включаючи
        тарифи, наявність номерів, номери) та бронювання.</p>
      </div>


      <div className="registration-hotel-contract__section">
        <h5>1. Предмет Договору.</h5>
        <p>1.1. Оператор сайту зобов'язується надати в користування Готелю платформу (надалі-послуги) на інтернет-сайтах
        домену bronui.com , (далі іменоване - bronui.com) для укладення договорів на надання послуг з готельного
        обслуговування / розміщення споживача а також інші послуги, пов'язані з розміщенням та тимчасовим проживанням
        споживачів (далі - Гість).</p>
        <p>1.1.1.Оператор сайту надає Гостям можливість бронювання послуг Готелю по телефону, онлайн – бронювання та інші
        види бронювання послуг Готелю відповідно до обраного пакету на надання послуг.</p>
        <p>1.1.2 Готель зобов’язується прийняти надані послуги. Оплата вартості послуг Оператора сайту здійснюється на
        умовах даного Договору.</p>
        <p>1.2. Готель укладає публічні договори на надання послуг з готельного обслуговування / розміщення споживача з
        Гостями через сайт.</p>
        <p>1.3.Права та обов’язки пов’язані з укладенням договору між Гостем та Готелем на сайті bronui.com виникають у
        Готелю.</p>
      </div>


      <div  className="registration-hotel-contract__section">
        <h5>2. Права та обов'язки сторін.</h5>
        <p>2.1. Оператор сайту зобов'язаний:</p>
        <p>2.1.1. Надати Готелю доступ до сайту bronui.com згідно з технічними регламентами та алгоритмами ; </p>
        <p>2.1.2. У випадку , якщо між сторонами даного Договору виникають грошові зобов’язання Оператор сайту
        зобов’язується надати Готелю Акт наданих послуг . Акт наданих послуг містить відомості про фактично надані
        послуги та розмір плати за надані послуги. У випадку, якщо Готель протягом 5 (п'яти) робочих днів з дня
        отримання Акту наданих послуг не направив Оператору сайту заперечення, послуги за розрахунковий період
        вважаються наданими. </p>
        <p>2.1.3. Інформаційний та документарний обмін між Сторонами проводити за допомогою електронного та / або
        телефонного зв'язку, що дозволяє зафіксувати факт відправки та отримання інформації (документів) Сторонами.
        Аудіозапис телефонної розмови також є фактом відправки та отримання інформації (документів). </p>
        <p>2.1.4. Оператор сайту надає Готелю ідентифікаційний номер користувача і пароль, що дозволить Готелю одержати
        доступ до системи Екстранет. Готель повинен тримати і зберігати ідентифікаційний номер та пароль користувача в
        таємниці і безпеці, у тому числі не розкривати його іншим особам, крім тих, які потребують доступу до системи
        Екстранет. Готель зобов’язаний повідомити Оператора сайту про кожне порушення або підозру порушення безпеки чи
        зловживання. </p>

        <p>2.2. Оператор сайту має право:</p>
        <p>2.2.1. отримувати плату за надані послуги. </p>
        <p>2.2.2.Тимчасово призупинити надання послуг в разі порушення Готелем договірних зобов'язань.</p>

        <p>2.3. Готель зобов'язаний:</p>
        <p>2.3.1. Своєчасно (протягом доби з моменту як подія сталась) самостійно публікувати або надавати достовірну і
        актуальну інформацію, таку як реєстраційні дані юридичної/ фізичної особи, умови співпраці, контактну
        інформацію, опис готелю, номерів, послуг, будь-які події які можуть впливати на наявність та якість послуг, що
        надаються , опис умов бронювання / скасування, вартості додаткових послуг і ціни додаткового розміщення,
        кількість або тип доступних номерів і перевіряти статус бронювання. </p>
        <p>2.3.2. Готель зобов’язаний забезпечити якісне надання готельних послуг згідно з вимогами чинного законодавства .
        За умови недотримання Готелем належної якості обслуговування Оператор сайту має право призупинити надання
        послуг. </p>
        <p>2.3.3. Готель підтверджує і гарантує, що опублікована/надана інформація згідно даного Договору завжди актуальна
        і достовірна. </p>
        <p>2.3.4. Сплатити Оператору сайту плату за послуги згідно умов даного Договору. </p>
        <p>2.4. Готель має право:</p>
        <p>2.4.1. Розміщувати якісні додаткові фото та відеоматеріали Готелю . </p>
        <p>2.4.2. Розміщувати посилання на свою інформацію, зазначену на bronui.com, в будь-яких рекламних
        матеріалах. </p>
      </div>


      <div  className="registration-hotel-contract__section">
        <h5>3. Плата за користування сайтом та порядок розрахунків.</h5>
        <p>3.1. Тарифи на послуги Оператора сайту визначаються відповідно до обраного Готелем пакету послуг, які є
        додатками до даного Договору. </p>
        <p>3.2. У випадку збільшення вартості послуг Оператор сайту повідомляє Готель не пізніше як за 14 днів шляхом
        розміщення такого повідомлення на веб сайті bronui.com та/або направляє повідомлення на електронну пошту Готелю
        чи повідомляє іншим загальноприйнятим способом. Якщо Готель не погоджується із зміною вартості послуг , то
        Готель зобов’язаний повідомити про це Оператор сайту письмово , електронною поштою протягом 7 (семи) днів , в
        іншому випадку вважається , що Готель погодився на збільшення вартості послуг. Вартість послуг , що оплачена
        Готелем збільшенню не підлягає. </p>
        <p>3.3 Оператор сайту має право призупинити надання послуг у випадку неоплати або неповної оплати послуг Готелем. </p>
      </div>

      <div  className="registration-hotel-contract__section">
        <h5>4. Скасування та ануляції бронювання.</h5>
        <p>4.1. Скасування та анулювання бронювання  здійснюється відповідно до правил Готелю та вимог чинного законодавства.</p>
        <p>4.2 Оператор сайту не несе відповідальності за скасування та/або анулювання бронювання здійснене Гостем чи Готелем.</p>
        <p>4.3. Оператор сайту не несе відповідальності за неприбуття , пізнє анулювання , незаїзд Гостя до Готелю , а також будь-які збитки , що виникли внаслідок невиконання та /або неналежного виконання  зобов’язань Готелю перед Гостем, будь –яку заподіяну позадоговірну шкоду.</p>
        <p>4.4.У випадку одержання від Гостя претензії Оператор сайту негайно повідомляє Готель про таку претензію і надає необхідну інформацію щодо скарги.</p>
      </div>

      <div  className="registration-hotel-contract__section">
        <h5>5.Рейтинг,  відгуки, реклама</h5>
        <p>5.1.	Рейтинг є відображення результатів пошуку, релевантних для кожного конкретного Гостя, шляхом відображення персоналізованого рейтингу Готелю за замовчуванням на своїй Платформі. Гості можуть переглядати цей рейтинг за замовчуванням, використовувати фільтри та сортувати за альтернативними порядками ранжирування і, таким чином, мають можливість впливати на представлення результатів пошуку, щоб отримувати порядок ранжування на основі інших критеріїв.</p>
        <p>5.2 Порядок, у якому Готель з'являється в списку на Веб-сайті ("Рейтинг"), визначається автоматично Оператором сайту . На рейтинг впливає мінімальна кількість вільних місць, встановлених Готелем, відношення кількості бронювань до кількості відвідувань сторінки Готелю на Веб-сайті ("Конверсія"), обсяг транзакцій Готелю, коефіцієнт скасування замовлень, загальна оцінка за відгуками Гостей, історія служби підтримки Гостей, кількість і тип скарг від Гостей, а також платіжна історія Готелю. Оператор сайту має право встановлювати привілейовані програми.</p>
        <p>5.3. Оператор сайту  пропонуватиме Гостям, які зупинялися в Готелі, прокоментувати своє перебування (відгук) в Готелі  та надати оцінку .</p>
        <p>5.4.Оператор сайту має право публікувати ці коментарі та оцінки на Платформах. Готель підтверджує, що Оператор сайту є поширювачем (без будь-яких зобов'язань із перевірки), а не публікатором цих коментарів.</p>
        <p>5.5.Оператор сайту  не несе відповідальності за зміст і наслідки (публікації або поширення) будь-яких коментарів або відгуків у будь-який спосіб.</p>
        <p>5.6 Оператор сайту може здійснювати просування Готелю , рекламу Готелю та інші заходи для збільшення продажів послуг Готелю згідно рекламної програми , що встановлюється Оператором сайту за погодженням з Готелем.</p>
      </div>

      <div  className="registration-hotel-contract__section">
        <h5>6. Відповідальність сторін.</h5>
        <p>6.1. Готель несе повну відповідальність за несвоєчасну публікацію/ надання достовірної інформації такої  як реєстраційні дані юридичної/ фізичної особи, умови співпраці, контактну інформацію, опис готелю, номерів, послуг, будь-які події які можуть впливати на наявність та якість послуг, що надаються опис умов бронювання / скасування, вартості додаткових послуг і ціни додаткового розміщення,</p>
        <p>6.2. Готель несе повну відповідальність за несвоєчасне надання достовірної інформації про кількість або тип доступних номерів на сайті bronui.com, і в разі претензій Гостей  бере на себе зобов’язання вирішувати всі конфліктні ситуації самостійно.</p>
        <p>6.3. Готель звільняє від відповідальності Оператор сайту у разі пред'явлення йому претензій третьою стороною, у т. ч. Гостем, пов'язаних з будь-якою невірною інформацією про Готель, проживанням у Готелі.</p>
        <p>6.4. Збитки, завдані майну Готелю  Гостями Оператора сайту  Гості оплачують самостійно .</p>
        <p>6.5. Відповідальність за коректність дат і особистих відомостей, наданих Гостем, несе Гість.</p>
        <p>6.6. Оператор сайту не несе відповідальність у випадку пред'явлення претензій, що виникають в результаті (можливого) порушення прав на об'єкти інтелектуальної власності (наприклад, наданий Готелем фотоматеріал і т. п.). Претензії вирішуються Готелем без посередництва Оператора сайту.</p>
        <p>6.7  Жодна із Сторін не несе відповідальності перед іншою Стороною за будь-які непрямі  збитки, втрачену вигоду.</p>
        <p>6.8 Сторони не несуть відповідальність за невиконання (неналежне виконання) своїх зобов'язань за цим Договором, які є наслідком форс-мажорних обставин, за умови попередження іншої Сторони про дію таких обставин, а також підтвердження наявності форс-мажорних обставин, їх наслідків та терміну дії документами, виданими Торгово-промисловою палатою України.</p>
      </div>

      <div  className="registration-hotel-contract__section">
        <h5>7. Строк дії Договору.</h5>
        <p>7.1. Даний Договір набирає чинності з моменту його підписання Готелем шляхом подання та підписання заяви-приєднання. Договір укладається строком на 10 (десять) років. Строк дії цього Договору продовжується на кожний наступний  рік, якщо жодна із Сторін не повідомить письмово  іншу Сторону про намір розірвати цей Договір не пізніше ніж за 30 (тридцять) календарних днів до дати закінчення поточного строку дії цього Договору. Підписання заяви –приєднання здійснюється Готелем на сайті bronui.com згідно з правилами електронного документообігу. При цьому, невиконані Сторонами зобов'язання за цим Договором підлягають належному виконанню незалежно від спливу строку дії цього Договору. </p>
          <p>7.2. Сторони мають право розірвати даний Договір в односторонньому порядку. Про плановане  розірвання Договору зацікавлена сторона  надсилає іншій стороні відповідне письмове повідомлення за 14 днів до бажаної дати припинення строку дії цього Договору.</p>
            <p>7.3. Після розірвання Договору Сторони зобов’язані виконати свої зобов’язання  за замовленнями, оформленими через Оператора сайту. незалежно від того, чи передує дата заїзду/ виїзду Гостя даті припинення дії Договору або слідує за нею. В частині фінансових взаємин Сторін цей Договір зберігає свою силу до проведення повного взаєморозрахунку.</p>
      </div>

      <div  className="registration-hotel-contract__section">
        <h5>8. Вирішення спорів.</h5>
        <p>8.1. За невиконання або неналежне виконання своїх зобов'язань за Договором у випадках, не передбачених цим Договором, Сторони несуть відповідальність згідно вимог чинного законодавства України.</p>
        <p> 8.2. У разі виникнення спорів між Сторонами з питань, передбачених цим Договором або у зв'язку з ним, Сторони зобов'язуються вжити всіх заходів щодо їх вирішення шляхом переговорів.</p>
        <p>8.3. Сторони зобов'язуються всі можливі претензії розглянути протягом 10 днів з дати їх отримання та надати обґрунтовану відповідь.</p>
        <p>8.4. При недосягненні домовленостей спір вирішується в порядку, встановленому законодавством України.</p>
      </div>

      <div  className="registration-hotel-contract__section">
        <h5>9. Інші умови.</h5>
        <p>9.1. Договір складений у двох примірниках, які мають однакову юридичну силу, по одному для кожної із Сторін.</p>
        <p>9.2. Кожна Сторона заявляє, що вона має всі права на укладення Договору та представники Сторін мають всі повноваження на підписання Договору від імені відповідної Сторони.</p>
        <p>9.3.Жодна зі сторін не має права відступати, передавати, обтяжувати зобов'язаннями будь-які свої права та (або) зобов'язання за цим Договором без попередньої письмової згоди іншої сторони, за умови, що Оператор сайту має право відступати, передавати, будь-які свої права та (або) зобов'язання за цим Договором (повністю або частково або час від часу) афілійованій компанії без попередньої письмової згоди Готелю. Незважаючи на будь-які положення цього Договору про інше, будь-яке відступлення, новація або передача, здійснена Готелем, не звільнятиме особу, яка відступає/передає право, від її зобов'язань за цим Договором.</p>
        <p>9.4.Сторони підтверджують і погоджуються з тим, що під час виконання цього Договору кожна зі Сторін може мати доступ до конфіденційної інформації третіх осіб ("Конфіденційна інформація ") або отримувати її прямо чи опосередковано. Конфіденційна інформація включає Дані клієнта, обсяг операцій, маркетингові плани та бізнес плани, ділову, фінансову, технічну, операційну та іншу не призначену для широкого загалу інформацію, яку сторона, яка її розкриває, визначає як приватну або конфіденційну, або щодо якої сторона, що її отримує, має усвідомлювати, що така інформація повинна розглядатися як приватна і конфіденційна.</p>
        <p>9.5.Договір може бути змінений Оператором сайту  з обов’язковим повідомленням про це Готель через офіційний веб-сайт bronui.com бо шляхом надсилання повідомлення на електронну пошту Готелю, не пізніше ніж за 7 днів до такої зміни. У разі незгоди Готелю, зі змінам, внесеними до Договору, він зобов’язаний повідомити про це Оператора сайту протягом 7 (семи)  з моменту, коли він дізнався чи міг дізнатися про відповідні зміни, в такому випадку Договір вважатиметься розірваним.</p>
        <p>9.6. Сервіс  bronui постійно здійснює роботу з вдосконалення та оптимізації послуг для готельного сервісу та його клієнтів.</p>
      </div>

      <div  className="registration-hotel-contract__section">
        <h5>10. Адреси, реквізити Оператора сайту</h5>

        <strong>ТОВ "БРОНЮЙ"</strong>
        <br/>
        ЄДРПОУ 44137068 <br/>
        Україна, 76010, Івано-Франківська обл., <br/>
        Івано-Франківський р-н, <br/>
        Місто Івано-Франківськ, вул.Володимира Великого, <br/>
        будинок 10А <br/>
        Рахунок №: UA203077700000026005311178675 <br/>
        Валюта: UAH <br/>
        Найменування банку: АТ «А–БАНК» <br/>
        МФО: 307770 ЄДРПОУ банку: 14360080 <br/>
        <br/>
        Директор  Шевченко А.Л.
      </div>

      <AppButton onClick={onCloseContractStep} theme="primary" className="registration-hotel-contract__button">Погоджуюсь</AppButton>
    </div>
  )
}

export default Сontract;