import React from 'react';
import './OrderIndexSummaryReservations.scss';

import { createBemClass, createBemClassProp } from '../../../../../helpers/common';
import { OrderResponseReservationItem } from '../../../../../types/order';
import PriceViewer from '../../../../../components/ui/components/PriceViewer/PriceViewer';
import { pluralize } from '../../../../../helpers/translatioHelpers';

type Props = {
    reservations: OrderResponseReservationItem[],
    amount?: number,
    prepaymentAmount?: number,
    afterAmount?: number,
}

const OrderIndexSummaryReservations: React.FC<Props> = (props) => {
    const {reservations, afterAmount, prepaymentAmount, amount} = props;
    const cn = createBemClassProp('order-index-summary-reservations');
    const c = createBemClass('order-index-summary-reservations');

    return (
        <div {...cn()}>
            {reservations.map(reservation => (
                <div {...cn('item')} key={'reservation-' + reservation.id + reservation.rateId}>
                    <div {...cn('name')}>{reservation.room}</div>
                    <div {...cn('bottom-info')}>
                        <span {...cn('price-text')}>
                            Ціна за {pluralize('ніч', reservation.nights, true)}
                        </span>

                        <PriceViewer price={reservation.amount}/>
                    </div>
                </div>
            ))}

            <div className={c('payment-container')}>
                {prepaymentAmount ? (
                    <div {...cn('row', c('before-payment'))}>
                        <span>До сплати зараз</span>
                        <PriceViewer price={prepaymentAmount} totalAfterDiscount/>
                    </div>
                ) : null}

                {afterAmount ? (
                    <div {...cn('row', c('after-payment'))}>
                        <span>При поселенні</span>
                        <PriceViewer price={afterAmount}/>
                    </div>
                ) : null}
            </div>

            <div {...cn('row', c('total-row'))}>
                <span>Всього</span>
                <PriceViewer price={amount}/>
            </div>
        </div>
    )
};

export default OrderIndexSummaryReservations;
