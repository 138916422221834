import React from "react";


const IconCall = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.399902" width="43.2002" height="43.2002" rx="19.2" fill="#8BDAC2"/>
      <path d="M23.6498 15.9999C24.6266 16.1905 25.5242 16.6682 26.2279 17.3718C26.9316 18.0755 27.4093 18.9732 27.5999 19.9499M23.6498 11.9999C25.6791 12.2253 27.5715 13.1341 29.0161 14.5769C30.4608 16.0197 31.3719 17.9109 31.5999 19.9399M19.8268 23.863C18.6252 22.6614 17.6764 21.3028 16.9804 19.8532C16.9205 19.7285 16.8906 19.6661 16.8676 19.5872C16.7859 19.3069 16.8446 18.9626 17.0146 18.7252C17.0624 18.6584 17.1196 18.6012 17.2339 18.4869C17.5835 18.1373 17.7583 17.9625 17.8726 17.7867C18.3036 17.1238 18.3036 16.2692 17.8726 15.6063C17.7583 15.4306 17.5835 15.2558 17.2339 14.9061L17.039 14.7113C16.5076 14.1798 16.2419 13.9141 15.9565 13.7698C15.3889 13.4827 14.7187 13.4827 14.1511 13.7698C13.8657 13.9141 13.6 14.1798 13.0685 14.7113L12.9109 14.8689C12.3813 15.3985 12.1165 15.6633 11.9142 16.0234C11.6898 16.4229 11.5284 17.0434 11.5298 17.5016C11.531 17.9146 11.6111 18.1968 11.7713 18.7612C12.6323 21.7947 14.2568 24.657 16.6448 27.045C19.0328 29.433 21.8952 31.0575 24.9286 31.9185C25.493 32.0787 25.7753 32.1588 26.1882 32.16C26.6464 32.1614 27.2669 32 27.6664 31.7756C28.0265 31.5734 28.2913 31.3085 28.8209 30.7789L28.9786 30.6213C29.51 30.0898 29.7757 29.8241 29.9201 29.5387C30.2071 28.9712 30.2071 28.3009 29.9201 27.7333C29.7757 27.4479 29.51 27.1822 28.9786 26.6508L28.7837 26.4559C28.4341 26.1063 28.2593 25.9315 28.0835 25.8172C27.4206 25.3862 26.566 25.3862 25.9031 25.8172C25.7273 25.9315 25.5525 26.1063 25.2029 26.4559C25.0886 26.5702 25.0314 26.6274 24.9646 26.6752C24.7272 26.8452 24.3829 26.9039 24.1026 26.8222C24.0237 26.7992 23.9614 26.7693 23.8367 26.7094C22.387 26.0134 21.0284 25.0646 19.8268 23.863Z" stroke="white" stroke-width="1.60001" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default IconCall;