import React, { FC, useRef, useState } from 'react';
import { Settings } from 'react-slick';
import classNames from 'classnames';
import './CustomCarousel.scss';
import Carousel from '../Carousel/Carousel';

interface Props {
    Item: any;
    data: Array<any>;
    settings: Settings;
    space?: number;
    theme?: 'secondary' | 'primary';
    noDraggingCheck?: boolean
    onClick?: () => void
    onClickOnItem?: (item: any, index: number) => void
}

const CustomCarousel: FC<Props> = React.forwardRef((props, ref: any) => {
    const {data, Item, theme, noDraggingCheck, onClick, onClickOnItem} = props;
    const [isDragging, setIsDragging] = useState(false);

    const settings = {
        ...props.settings,
        beforeChange: (currentSlide: number, nextSlide) => {
            setIsDragging(true);

            if (props.settings.beforeChange) {
                props.settings.beforeChange(currentSlide, nextSlide);
            }
        },
        afterChange: (currentSlide: number) => {
            setIsDragging(false);

            if (props.settings.afterChange) {
                props.settings.afterChange(currentSlide);
            }
        },
    };

    const classes = classNames('custom-carousel', !noDraggingCheck ? {disabled: isDragging} : '');

    return (
        <div className="custom-carousel-out" onClick={onClick}>
            {!!isDragging}
            <Carousel {...settings} ref={ref} theme={theme}>
                {data && data.map((item, index) => {
                    return (
                        <div key={'customCarouselImage' + index}>
                            <div
                                className={classes}
                                onClickCapture={e => {
                                    if (isDragging) e.preventDefault();
                                }}
                                onClick={() => {
                                    if (onClickOnItem) onClickOnItem(item, index)
                                }}
                            >
                                <Item
                                    data={item}
                                    index={index + 1}
                                />
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
});

export default CustomCarousel;
