import React, {FC} from "react";
import "./LinksBlock.scss";
import CustomLink from "../CustomLink/CustomLink";

interface Props {
  title: string;
  links: Array<{ to: string, blank: boolean, title: string, value: string }>
}

const LinksBlock: FC<Props> = (props) => {
  const {title, links} = props;

  return (
    <div className="links-block">
      <h2 data-testid="title">{title}</h2>
      <ul className="links-block__list">
        {links.map((item,index) => {
          const {to, value, title, blank} = item

          return (
            <li className="links-block__item" key={index}>
              <CustomLink to={to} value={value} title={title} blank={blank}/>
            </li>
          )
        })
        }
      </ul>
    </div>
  )
}

export default LinksBlock