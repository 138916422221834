import React, {FC} from "react";

interface Props {

}

const IconApple: FC<Props> = (props) => {

  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12C0 6.34315 0 3.51472 1.75736 1.75736C3.51472 0 6.34315 0 12 0H22C27.6569 0 30.4853 0 32.2426 1.75736C34 3.51472 34 6.34315 34 12V22C34 27.6569 34 30.4853 32.2426 32.2426C30.4853 34 27.6569 34 22 34H12C6.34315 34 3.51472 34 1.75736 32.2426C0 30.4853 0 27.6569 0 22V12Z" fill="white"/>
      <path d="M15.4275 24C13.195 23.9871 11.3484 19.43 11.3484 17.1091C11.3484 13.318 14.1924 12.488 15.2884 12.488C15.7824 12.488 16.3098 12.682 16.775 12.8536C17.1003 12.9733 17.4368 13.0969 17.6239 13.0969C17.7359 13.0969 17.9998 12.9918 18.2329 12.8995C18.7298 12.7017 19.3482 12.4558 20.0683 12.4558C20.0696 12.4558 20.0714 12.4558 20.0726 12.4558C20.6103 12.4558 22.2407 12.5738 23.2209 14.0458L23.4505 14.3908L23.12 14.6402C22.648 14.9964 21.7867 15.6461 21.7867 16.9332C21.7867 18.4575 22.7621 19.0437 23.2308 19.3257C23.4376 19.4501 23.6518 19.5785 23.6518 19.8591C23.6518 20.0424 22.1892 23.9773 20.0653 23.9773C19.5456 23.9773 19.1783 23.8211 18.8542 23.6833C18.5264 23.5438 18.2436 23.4237 17.7762 23.4237C17.5393 23.4237 17.2398 23.5357 16.9226 23.6546C16.4892 23.8163 15.9987 24 15.4421 24H15.4275Z" fill="#2F2C37"/>
      <path d="M20.2934 9C20.3487 10.9944 18.9224 12.378 17.4977 12.2913C17.263 10.6997 18.9222 9 20.2934 9Z" fill="#2F2C37"/>
    </svg>

  )
}

export default IconApple