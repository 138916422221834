import React from 'react';

type Props = {}

const NoReviewsIcon: React.FC<Props> = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="216"
            height="167"
            fill="none"
            viewBox="0 0 216 167"
        >
            <path
                fill="#F1F2F7"
                fillRule="evenodd"
                d="M116.886 0C97.257 0 79.216 6.804 64.97 18.188H41.947c-2.992 0-5.439 2.456-5.439 5.457 0 3 2.447 5.456 5.439 5.456h15.151c2.99 0 5.438 2.456 5.438 5.457a5.442 5.442 0 01-1.599 3.852 5.407 5.407 0 01-3.84 1.604H25.994c-2.99 0-5.438 2.456-5.438 5.457 0 3 2.447 5.456 5.438 5.456h14.128c-4.265 10.056-6.627 21.12-6.627 32.74 0 4.215.312 8.359.91 12.407.25 1.692.551 3.369.903 5.028 10.26-1.576 21.145-2.842 32.508-3.765l.856.782.805-.913c13.65-1.066 27.98-1.634 42.758-1.634 31.399 0 60.782 2.561 85.905 7.014a83.884 83.884 0 001.929-12.976c.142-1.964.211-3.947.211-5.944C200.279 37.457 162.944 0 116.886 0M9.477 39.762H6.181c-3.03 0-5.51 2.505-5.51 5.566 0 3.062 2.48 5.567 5.51 5.567h3.296c3.03 0 5.51-2.505 5.51-5.567 0-3.061-2.48-5.566-5.51-5.566"
                clipRule="evenodd"
            ></path>
            <mask
                id="mask0_5112_75992"
                style={{ maskType: "luminance" }}
                width="181"
                height="72"
                x="35"
                y="95"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M215.326 95.691V167H35.187V95.691h180.139z"
                    clipRule="evenodd"
                ></path>
            </mask>
            <g mask="url(#mask0_5112_75992)">
                <path
                    fill="#E8EBF2"
                    fillRule="evenodd"
                    d="M215.326 123.261c0 2.982-2.447 5.422-5.437 5.422h-34.731a5.421 5.421 0 00-3.838 1.594 5.393 5.393 0 00-1.599 3.828c0 2.982 2.447 5.422 5.437 5.422h6.453c1.493 0 2.856.611 3.839 1.594a5.392 5.392 0 011.598 3.828c0 2.982-2.447 5.422-5.437 5.422h-14.837c-13.938 10.44-31.257 16.628-50.027 16.628-40.087 0-73.567-28.213-81.56-65.813 10.259-1.565 21.14-2.823 32.501-3.742l.855.778.805-.908c13.648-1.059 27.974-1.623 42.749-1.623 31.391 0 60.767 2.545 85.884 6.97a82.226 82.226 0 01-5.122 15.178h17.03c1.497 0 2.852.611 3.838 1.594a5.391 5.391 0 011.599 3.828"
                    clipRule="evenodd"
                ></path>
            </g>
            <path
                fill="#E0E2EE"
                fillRule="evenodd"
                d="M199.482 89.362a87.124 87.124 0 01-1.927 13.223c-25.089-4.538-54.433-7.147-85.791-7.147-14.759 0-29.069.578-42.701 1.664l9.454-10.954 14.707 9.597 19.909-5.497 34.249 5.19 14.869-5.19 3.766 5.19 8.984-6.669 8.396 7.695 13.242-10.58 2.843 3.478z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#D8DBEA"
                fillRule="evenodd"
                d="M150.67 94.156c3.765-5.475 5.923-11.967 5.867-18.914-.154-19.324-17.369-34.852-38.45-34.684-21.08.169-38.045 15.97-37.89 35.295.154 19.323 17.368 34.851 38.449 34.683 7.532-.06 14.538-2.117 20.426-5.617l15.75 3.709-4.152-14.472z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#BEC0D6"
                fillRule="evenodd"
                d="M107.234 74.72a3.977 3.977 0 11-7.954.061 3.977 3.977 0 017.954-.06M121.546 74.72a3.976 3.976 0 11-7.952.063 3.976 3.976 0 017.952-.063M135.859 74.72a3.977 3.977 0 01-7.953.063 3.976 3.976 0 017.953-.063"
                clipRule="evenodd"
            ></path>
            <path
                fill="#F1F2F7"
                fillRule="evenodd"
                d="M69.06 97.02l-.772.795-.82-.681c.528-.042 1.06-.08 1.591-.115"
                clipRule="evenodd"
            ></path>
            <path
                fill="url(#paint0_linear_5112_75992)"
                fillRule="evenodd"
                d="M75.5 100.213c0 3.019.966 5.83 2.625 8.196l-1.761 6.313 6.858-1.668c2.578 1.499 5.635 2.372 8.917 2.372 9.19 0 16.639-6.811 16.639-15.213S101.329 85 92.139 85 75.5 91.811 75.5 100.213z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M96.414 100.214a1.902 1.902 0 103.803 0 1.902 1.902 0 00-3.803 0M90.719 100.214a1.902 1.902 0 103.804 0 1.902 1.902 0 00-3.804 0M84.055 100.214a1.902 1.902 0 103.804 0 1.902 1.902 0 00-3.804 0"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E0E2EE"
                fillRule="evenodd"
                d="M67.47 97.225c-11.359.925-22.24 2.193-32.497 3.771a82.634 82.634 0 01-.903-5.036l17.583-13.255 15.817 14.52z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#D8DBEA"
                fillRule="evenodd"
                d="M146.997 124.455c0 1.537-13.885 2.784-31.014 2.784-17.129 0-31.014-1.247-31.014-2.784 0-1.537 13.885-2.783 31.014-2.783 17.129 0 31.014 1.246 31.014 2.783"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E1E3EF"
                fillRule="evenodd"
                d="M197.895 150.299c-3.062 0-5.567-2.505-5.567-5.566 0-3.062 2.505-5.567 5.567-5.567 3.061 0 5.566 2.505 5.566 5.567 0 3.061-2.505 5.566-5.566 5.566"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_5112_75992"
                    x1="92.139"
                    x2="61.835"
                    y1="69.787"
                    y2="102.932"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A7EBD6"></stop>
                    <stop offset="1" stopColor="#5ECBAA"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
};

export default NoReviewsIcon;
