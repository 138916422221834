import React from 'react';
import './ReservationRemoveModal.scss';

import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import ModalFooterWithButtons from '../../../../../components/ui/modals/components/ModalFooterWithButtons';
import { OrderResponseReservationItem } from '../../../../../types/order';

type Props = {
    onRemove: () => void,
    onCancel: () => void,
    reservationForRemove: OrderResponseReservationItem
}

const ReservationRemoveModal: React.FC<Props> = ({reservationForRemove, onRemove, onCancel}) => {
    const Footer = <ModalFooterWithButtons
       cancelButtonLabel="Скасувати"
        actionButtonLabel='Видалити'
        onActionButtonClick={onRemove}
        onCancel={onCancel}
    />

    return (
        <Modal onClose={onCancel} className={'reservation-remove-modal'} disableFullSizeOnMobile>
            <ModalLayoutPrimary FooterRendered={Footer} title='Видалити номер' disableFullSizeOnMobile>
                <div className="text-center">
                    Ви дійсно бажаєте видалити номер
                    <div style={{marginTop: 3}}><b>{reservationForRemove.room}</b></div>
                </div>
            </ModalLayoutPrimary>
        </Modal>
    )
}

export default ReservationRemoveModal;
