import React, {FC} from "react";
import {numberToArray} from "../../../../../../helpers/common";
import FieldSelect from "../../../../../../components/ui/forms/fields/FieldSelect/FieldSelect";
import MuiFieldSelect from "../../../../../../components/ui/forms/muiFields/MuiFieldSelect";

interface Props {
  kids: Array<string | number>
  onChange: (value: string, index: number) => void;
}

const HotelKidsAgeList: FC<Props> = (props) => {
  const {kids, onChange} = props;


  if (kids.length) {
    return (
      <div className="kids-age-select">
        <ul>
          {
            kids.map((item, index) => {
              // console.log("item",item);
              return (
                <li key={index}>
                  <MuiFieldSelect
                    label={`Вік дитини ${index + 1}`}
                    // @ts-ignore
                    value={item}
                    onChange={(e) => {
                      onChange(e.target.value, index)
                    }}>
                    {
                      numberToArray(10).map((age,index) => {
                        return (
                          <option key={index} value={age}>{age} років</option>
                        )
                      })
                    }
                  </MuiFieldSelect>
                </li>
              )
            })
          }
        </ul>

        <div className="kids-age-select__info">
          Щоб знайти відповідне для всієї вашої групи помешкання з коректними цінами, укажіть вік ваших дітей на момент виїзду
        </div>
      </div>
    )
  }
  return null
}

export default HotelKidsAgeList
