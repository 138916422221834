import React, { FC } from "react";

interface Props {
    className?: string;
    lettersFill?: string
}

const IconLogo: FC<Props> = ({className, lettersFill = '#2F2C37'}) => (
  <svg width="156" height="32" viewBox="0 0 156 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_9404_3287)">
      <path className="no-color" d="M74.251 25.9821H53.085C48.0059 25.9821 43.8711 21.8473 43.8711 16.7683C43.8711 11.6892 48.0059 7.55444 53.085 7.55444H74.251C79.3301 7.55444 83.4649 11.6892 83.4649 16.7683C83.4649 21.8473 79.3301 25.9821 74.251 25.9821ZM53.085 11.5799C50.2224 11.5799 47.8966 13.9057 47.8966 16.7683C47.8966 19.6308 50.2224 21.9567 53.085 21.9567H74.251C77.1136 21.9567 79.4394 19.6308 79.4394 16.7683C79.4394 13.9057 77.1136 11.5799 74.251 11.5799H53.085Z" fill="#EB5757"/>
      <path d="M14.8147 9.97911C16.0771 10.6898 17.066 11.6837 17.7767 12.956C18.4824 14.2282 18.8402 15.6843 18.8402 17.3293C18.8402 18.9743 18.4476 20.5149 17.6624 21.817C16.8772 23.124 15.7789 24.1378 14.3575 24.8584C12.9412 25.584 11.326 25.9418 9.51702 25.9418C6.48549 25.9418 4.14475 24.933 2.48486 22.9153C0.829944 20.9075 0 18.0002 0 14.2083C0 10.6798 0.750428 7.88189 2.25625 5.81946C3.76208 3.75703 6.05809 2.4003 9.15423 1.73932L17.2648 -7.72476e-05L17.8512 3.68248L10.4961 5.19328C8.38392 5.63061 6.80355 6.37607 5.75494 7.42965C4.70633 8.48323 4.10002 9.9642 3.92608 11.8676C4.7113 10.9234 5.66051 10.1978 6.76876 9.68093C7.88198 9.16408 9.09956 8.90565 10.4314 8.90565C12.0864 8.90565 13.5475 9.26347 14.8147 9.97414V9.97911ZM13.2642 21.161C14.1886 20.1869 14.6557 18.9395 14.6557 17.4287C14.6557 15.9179 14.1935 14.7003 13.2642 13.776C12.3349 12.8566 11.1222 12.3944 9.61642 12.3944C8.11059 12.3944 6.85822 12.8566 5.92391 13.776C4.98463 14.6954 4.51748 15.913 4.51748 17.4287C4.51748 18.9445 4.9896 20.2118 5.93882 21.1759C6.88804 22.14 8.11556 22.6221 9.61642 22.6221C11.1173 22.6221 12.3349 22.135 13.2642 21.161Z" fill={lettersFill}/>
      <path d="M36.4607 8.98095C37.8224 9.72641 38.881 10.78 39.6314 12.1367C40.3818 13.4984 40.7595 15.0738 40.7595 16.8729C40.7595 18.6719 40.4017 20.2175 39.6811 21.5742C38.9605 22.9359 37.9516 23.9845 36.6545 24.73C35.3574 25.4754 33.8566 25.8482 32.1569 25.8482C29.7367 25.8482 27.8432 25.0679 26.4666 23.5124V31.9957H22.3467V16.8977C22.3467 15.1235 22.7393 13.5481 23.5245 12.1765C24.3097 10.8048 25.4031 9.74132 26.8095 8.98592C28.2159 8.23052 29.846 7.85282 31.6997 7.85282C33.5534 7.85282 35.094 8.22555 36.4557 8.97101L36.4607 8.98095ZM35.268 20.8387C36.2271 19.8199 36.7092 18.498 36.7092 16.8778C36.7092 15.2577 36.2271 13.9308 35.268 12.8971C34.3088 11.8684 33.0664 11.3515 31.5407 11.3515C30.015 11.3515 28.7477 11.8534 27.8134 12.8623C26.8741 13.8711 26.4069 15.1981 26.4069 16.843C26.4069 18.488 26.8791 19.8199 27.8283 20.8387C28.7775 21.8575 30.015 22.3694 31.5407 22.3694C33.0664 22.3694 34.3088 21.8575 35.268 20.8387Z" fill={lettersFill}/>
      <path d="M87.9639 8.05645H92.049V15.193H100.845V8.05645H104.931V25.6194H100.845V18.6817H92.049V25.6194H87.9639V8.05645Z" fill={lettersFill}/>
      <path d="M130.637 8.99499C132.009 9.75039 133.092 10.8139 133.872 12.1855C134.658 13.5572 135.05 15.1077 135.05 16.8372C135.05 18.5667 134.658 20.1222 133.872 21.4889C133.087 22.8605 132.009 23.929 130.637 24.6943C129.265 25.4597 127.705 25.8473 125.961 25.8473C124.435 25.8473 123.038 25.5441 121.776 24.9428C120.514 24.3415 119.465 23.4867 118.635 22.3784C117.805 21.2702 117.274 20.0029 117.035 18.5816H114.093V25.6187H110.008V8.05571H114.093V14.8642H117.1C117.363 13.4826 117.905 12.2601 118.735 11.1966C119.565 10.1331 120.598 9.31305 121.841 8.73159C123.083 8.15014 124.455 7.86189 125.961 7.86189C127.705 7.86189 129.265 8.23959 130.637 8.99499ZM128.545 21.6876C129.31 21.2404 129.907 20.5893 130.344 19.7495C130.781 18.9046 131 17.9355 131 16.8372C131 15.1723 130.528 13.8405 129.579 12.8415C128.629 11.8426 127.427 11.3457 125.966 11.3457C124.504 11.3457 123.307 11.8426 122.368 12.8415C121.428 13.8405 120.961 15.1723 120.961 16.8372C120.961 18.5021 121.433 19.8389 122.382 20.8478C123.332 21.8566 124.524 22.3586 125.961 22.3586C126.92 22.3586 127.78 22.1349 128.545 21.6827V21.6876Z" fill={lettersFill}/>
      <path d="M155.419 8.0571V25.6201H151.528V23.3837C150.872 24.1739 150.057 24.7802 149.078 25.2076C148.099 25.635 147.04 25.8487 145.907 25.8487C143.576 25.8487 141.737 25.1976 140.396 23.8906C139.054 22.5885 138.383 20.6504 138.383 18.086V8.0571H142.468V17.5294C142.468 19.1097 142.821 20.2876 143.531 21.0628C144.237 21.8431 145.246 22.2307 146.558 22.2307C148.019 22.2307 149.177 21.7735 150.042 20.8641C150.902 19.9546 151.334 18.6426 151.334 16.933V8.05213H155.419V8.0571ZM142.816 4.75224C141.886 3.93223 141.414 2.72956 141.394 1.14919H144.108C144.128 1.93938 144.376 2.56556 144.843 3.02278C145.311 3.48496 145.972 3.71357 146.821 3.71357C147.671 3.71357 148.307 3.48496 148.799 3.02278C149.291 2.56059 149.545 1.93938 149.57 1.14919H152.348C152.328 2.72956 151.846 3.92727 150.907 4.75224C149.967 5.57224 148.615 5.98473 146.851 5.98473C145.087 5.98473 143.74 5.57224 142.811 4.75224H142.816Z" fill={lettersFill}/>
    </g>
    <defs>
      <clipPath id="clip0_9404_3287">
        <rect width="155.418" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default IconLogo
