import React from 'react';
import './ModalOptionsSelect.scss';

import ModalMobile from '../ModalMobile/ModalMobile';
import ModalMobileHeader from '../ModalMobile/components/ModalMobileHeader/ModalMobileHeader';
import IconClose from '../../icons/modal/IconClose';
import { createBemClassProp } from '../../../../helpers/common';

type Props = {
    options: React.ReactNode[],
    title: string,
    onClose: () => void
}

const ModalOptionsSelect: React.FC<Props> = ({options, title, onClose}) => {
    const cn = createBemClassProp('modal-options-select');

    const content = (
        <div {...cn('options-list')}>
            <ul>
                {options.map((option, index) => (
                    <li key={'dropdownOption' + index}>
                        {option}
                    </li>
                ))}
            </ul>
        </div>
    )

    const header = () => (
        <ModalMobileHeader
            onClickIcon={onClose}
            icon={<IconClose/>}
            title={title}
        />
    )

    return <ModalMobile Header={header} onClose={onClose}>{content}</ModalMobile>;
};

export default ModalOptionsSelect;
