import React, { FC } from 'react';

interface Props {

}

const IconExit: FC<Props> = (props) => {

   return (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M2.83176 7.2L11.3004 7.2C11.7422 7.2 12.1004 7.55817 12.1004 8C12.1004 8.44183 11.7422 8.8 11.3004 8.8L2.83176 8.8L5.46608 11.4343C5.7785 11.7467 5.7785 12.2533 5.46608 12.5657C5.15366 12.8781 4.64712 12.8781 4.33471 12.5657L0.334705 8.56569C0.0222855 8.25327 0.0222855 7.74673 0.334705 7.43431L4.33471 3.43431C4.64712 3.12189 5.15366 3.12189 5.46608 3.43431C5.7785 3.74673 5.7785 4.25327 5.46608 4.56569L2.83176 7.2ZM9.70039 1.6C9.25856 1.6 8.90039 1.24183 8.90039 0.8C8.90039 0.358172 9.25856 0 9.70039 0H14.5004C15.8259 0 16.9004 1.07452 16.9004 2.4V13.6C16.9004 14.9255 15.8259 16 14.5004 16H9.70039C9.25856 16 8.90039 15.6418 8.90039 15.2C8.90039 14.7582 9.25856 14.4 9.70039 14.4H14.5004C14.9422 14.4 15.3004 14.0418 15.3004 13.6L15.3004 2.4C15.3004 1.95817 14.9422 1.6 14.5004 1.6H9.70039Z" fill="#E05233"/>
      </svg>

   );
};

export default IconExit;