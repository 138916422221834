import { RoomItemPrepared } from '../../../types/rooms';
import { scroller } from 'react-scroll';
import { useHistory, useLocation } from 'react-router-dom';

import { setHotelSearchFormFocus } from '../../../store/rooms/rooms-reducer';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { hotelDetailRoute } from '../../../helpers/routes';
import useWindowSize from '../../../hooks/useWindowSize';

const useRoomsLogic = (roomItem?: RoomItemPrepared) => {
    const {focusOnHotelSearchForm} = useAppSelector(state => state.rooms);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {search} = useLocation();
    const {isMobile} = useWindowSize();

    const showDisabledPricesWithMessage = typeof roomItem === 'object' && (roomItem?.prices?.data?.length && roomItem.availableRoomsCount < 1);
    const showNoPricesMessageWithButton = typeof roomItem === 'object' && !roomItem?.prices?.data?.length;

    const focusOnEditForm = (onlyScroll = false, hotelAlias?: string) => {
        const doScroll = () => scroller.scrollTo('hotelDetailSearchForm', {
            offset: isMobile ? -75 : -100, smooth: true, duration: 300,
            //containerId: 'layoutContainer'
        });

        if (hotelAlias) {
            history.replace(hotelDetailRoute(hotelAlias, search));
        }

        if (onlyScroll) doScroll();
        else {
            if (!focusOnHotelSearchForm) {
                doScroll();
                dispatch(setHotelSearchFormFocus(true));

                setTimeout(() => {
                    dispatch(setHotelSearchFormFocus(false));
                }, 1300);
            }
        }
    }

    return {
        showDisabledPricesWithMessage,
        showNoPricesMessageWithButton,
        focusOnEditForm
    }

}

export default useRoomsLogic;
