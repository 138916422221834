import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { codeSchema } from '../../../../helpers/validation';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import AppFormCode from '../../../../components/ui/forms/controls/AppFormCode';
import { FormikHelpers } from 'formik/dist/types';

interface Values {
   code: string;
}

interface IProps {
   onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void;
   onResend: () => void;
   isSending: boolean;
}
const initialValues = {
   code: '',
};

const FormCode: FC<IProps> = ({ onSubmit, onResend, isSending }) => {
   return (
      <Formik initialValues={initialValues} validationSchema={codeSchema} onSubmit={onSubmit}>
         <Form>
            <div>
               <AppFormCode name="code" label={'Введіть код, який ми надіслали через SMS'} />
               <span className="sign-up__resend-link">
                  Не отримали SMS-повідомлення?
                  <br />
                  <span onClick={onResend}>Надіслати повторно</span>
               </span>
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
               <AppButton theme="primary" size="middle" className="sign-up__submit sign-up__full" showIndicator={isSending}>
                  Продовжити
               </AppButton>
            </div>
         </Form>
      </Formik>
   );
};

export default FormCode;
