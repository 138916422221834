import React from "react";

const IconSocialTwitter = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#F4F2F6"/>
      <path
        d="M32.2367 20.5938C32.9398 20.0664 33.5727 19.4336 34.0648 18.6953C33.432 18.9766 32.6938 19.1875 31.9555 19.2578C32.7289 18.8008 33.2914 18.0977 33.5727 17.2188C32.8695 17.6406 32.0609 17.957 31.2523 18.1328C30.5492 17.3945 29.6 16.9727 28.5453 16.9727C26.5063 16.9727 24.8539 18.625 24.8539 20.6641C24.8539 20.9453 24.8891 21.2266 24.9594 21.5078C21.9008 21.332 19.1586 19.8555 17.3305 17.6406C17.0141 18.168 16.8383 18.8008 16.8383 19.5039C16.8383 20.7695 17.4711 21.8945 18.4906 22.5625C17.893 22.5273 17.2953 22.3867 16.8031 22.1055V22.1406C16.8031 23.9336 18.0688 25.4102 19.7563 25.7617C19.475 25.832 19.1234 25.9023 18.807 25.9023C18.5609 25.9023 18.35 25.8672 18.1039 25.832C18.5609 27.3086 19.932 28.3633 21.5492 28.3984C20.2836 29.3828 18.7016 29.9805 16.9789 29.9805C16.6625 29.9805 16.3813 29.9453 16.1 29.9102C17.7172 30.9648 19.6508 31.5625 21.7602 31.5625C28.5453 31.5625 32.2367 25.9727 32.2367 21.0859C32.2367 20.9102 32.2367 20.7695 32.2367 20.5938Z"
        fill="#C5C6CF"/>
    </svg>

  )
};


export default IconSocialTwitter