import React, { useEffect, useState } from 'react';
import './MobileRoomDetail.scss';

import { RoomItemPrepared } from '../../../../../../types/rooms';
import CustomCarousel from '../../../../../../components/ui/components/CustomCarousel/CustomCarousel';
import HotelAmenitiesModal from '../../../components/HotelAmenities/HotelAmenitiesModal/HotelAmenitiesModal';
import ShowMoreButton from '../../../components/ShowMoreButton/ShowMoreButton';
import { createBemClassProp } from '../../../../../../helpers/common';
import MobileRoomsInfoLineTexts from '../MobileRoomsInfoLineTexts/MobileRoomsInfoLineTexts';
import HotelIconText from '../../../../../../components/ui/icons/hotels/HotelIconText/HotelIconText';
import MobileRoomDetailBookSectionTexts
    from './components/MobileRoomDetailBookSectionTexts/MobileRoomDetailBookSectionTexts';
import MobileRoomDetailPrices from './components/Prices/MobileRoomDetailPrices';
import RoomItemPersons
    from '../../../components/HotelRooms/components/HotelRoomItem/components/RoomItemPersons/RoomItemPersons';
import { useSelector } from 'react-redux';
import { getHotelSelector } from '../../../../../../store/hotel/hotel-selector';
import { useHistory, useLocation } from 'react-router-dom';

type Props = {
    room: RoomItemPrepared;
    onGoBack: () => void;
    onFocusOnSearchForm: () => void;
    hotelName: string;
};

const MobileRoomDetail: React.FC<Props> = ({room, onFocusOnSearchForm, hotelName, onGoBack}) => {
    const {bookingState} = useSelector(getHotelSelector);
    const [showPropertiesModal, setShowPropertiesModal] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const carouselSettings = {};
    const cn = createBemClassProp('mobile-room-detail');
    let paddingBottom = 16;

    useEffect(() => {
        if (location.hash === '#show-rooms') onGoBack();
    }, [location.hash]);

    //if (canBookOnCart) paddingBottom += 66;

    const PhotoItem = data => (
        <div className={'gallery-image'} style={{backgroundImage: `url(${data?.data?.source?.['302x170']})`}}/>
    );

    return room ? (
        <div {...cn()} style={{paddingBottom}}>
            {showPropertiesModal && (
                <HotelAmenitiesModal
                    hotelName={hotelName}
                    properties={room.properties}
                    onClose={() => setShowPropertiesModal(false)}
                />
            )}

            <CustomCarousel Item={PhotoItem} data={room.photos} settings={carouselSettings} theme={'secondary'}/>

            <div {...cn('info-row-wrap')}>
                <MobileRoomsInfoLineTexts
                    roomsText={room.rooms}
                    bathroomsText={room.bathrooms}
                    square={room.square}
                    roomPersonsData={<RoomItemPersons basePlaces={room.basePlaces} extraPlaces={room.extraPlaces}/>}
                />
            </div>

            {room.properties.length ? (
                <div {...cn('properties-list-wrapper')}>
                    <span {...cn('properties-title')}>Зручності в номері</span>
                    <div {...cn('properties-list')}>
                        {room.properties.slice(0, 4).map(property => (
                            <HotelIconText key={'property-' + property.id} text={property.name} icon={property.icon}/>
                        ))}
                    </div>

                    {room.properties.length > 4 ? (
                        <ShowMoreButton
                            onClick={() => setShowPropertiesModal(true)}
                            isShowingMore={false}
                            texts={['Переглянути всі зручності', '']}
                        />
                    ) : null}
                </div>
            ) : null}

            {bookingState === 'both' ||
                (bookingState === 'cart' && (
                    <div {...cn('book-section-texts')}>
                        <MobileRoomDetailBookSectionTexts/>
                    </div>
                ))}

            <MobileRoomDetailPrices room={room} onFocusOnSearchForm={onFocusOnSearchForm}/>
        </div>
    ) : (
        <div>номер не знайдено</div>
    );
};

export default React.memo(MobileRoomDetail);
