import React, { CSSProperties, FC, memo } from 'react';
import './Image.scss';
import { getAspectRatioPadding } from '../../../../helpers/styles';
import classNames from 'classnames';
import { AspectRatio } from "../../../../types/common";

interface Props {
    srcMobile?: string;
    srcTablet?: string;
    src?: string;
    alt?: string;
    aspectRatio?: AspectRatio;
    className?: string;
    withBackgroundColor?: boolean;
    height?: string | number;
    width?: string | number;
    style?: CSSProperties;
    onClick?: () => void
}

const Image: FC<Props> = memo((props) => {
    const {srcMobile, srcTablet, src, aspectRatio = '', withBackgroundColor = true, style, onClick, ...rest} = props;
    const aspect = getAspectRatioPadding(aspectRatio || '1:1');

    const classes = classNames('image', props.className, {
        'image--aspect-ratio': !!aspectRatio,
        'image--with-background-color': withBackgroundColor,
    });

    const defaultProps = {
        className: classes,
        style: aspectRatio ? {paddingTop: `${aspect}`, ...style} : {},
        onClick
    }

    return src ? (
        <picture {...defaultProps}>
            <source srcSet={srcMobile} media="(max-width: 768px)" {...rest} />
            <source srcSet={srcTablet} media="(max-width: 991px)" {...rest} />
            <source srcSet={src} {...rest} />
            <img srcSet={src} {...rest} />
        </picture>
    ) : <div  {...defaultProps}></div>;
});

export default Image;
