import React, { FC, lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Element } from 'react-scroll';
import './HotelDetail.scss';

import { getHotelSelector } from '../../../store/hotel/hotel-selector';
import HotelDescription from './components/HotelDescription/HotelDescription';
import Container from '../../../components/ui/layouts/Container/Container';
import HotelRooms from './components/HotelRooms/HotelRooms';
import Page from '../../../components/common/Page/Page';
import { getRoomsWithMealSelector } from '../../../store/rooms/rooms-selectors';
import HotelAmenities from './components/HotelAmenities/HotelAmenities';
import { getCommentsSelector } from '../../../store/comments/comments-selectors';
import HotelReviews from './components/HotelComments/HotelReviews';
import HotelRules from './components/HotelRules/HotelRules';
import HotelCancelReservation from './components/HotelCancelReservation/HotelCancelReservation';
import HotelDetailContentBlock from './components/HotelDetailContentBlock/HotelDetailContentBlock';
import HotelDetailHeader from './components/HotelDetailHeader/HotelDetailHeader';
import { getHotelDetailInitialProps } from './hotelDetailHelpers';
import useWindowSize from '../../../hooks/useWindowSize';
import { MobileOnly, TabletAndUpper } from '../../../components/common/Media/MediaHelpers';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import AppLoader from '../../../components/ui/loaders/AppLoader/AppLoader';

import NotFound from '../../NotFound/NotFound';
import { AppState } from "../../../store";
import RoomLoader from "./components/RoomLoader/RoomLoader";
// import FeedbackModal from './components/FeedbackModal/FeedbackModal';
import HotelDetailHead from './components/HotelDetailHead';
import HotelDetailGalleryModalRoute from './components/HotelDetailGallery/HotelDetailGalleryModalRoute';
import { useHotelDetailLogic } from './useHotelDetailLogic';
import HotelDetailMobile from './HotelDetailMobile/HotelDetailMobile';
import HotelBookSection from './components/HotelBookSection/HotelBookSection';
import { isClient } from '../../../helpers/common';
import HotelLocation from './components/HotelLocation/HotelLocation';
import IconInfo from "../../../components/ui/icons/hotels/IconInfo";
import IconClose from "../../../components/ui/icons/hotels/IconClose";
import HotelMessage from "./components/HotelMessage/HotelMessage";

interface IHotelDetail extends FC {
    getInitialProps: any;
}

const HotelDetail: IHotelDetail = () => {
    const reviews = useSelector(getCommentsSelector);
    const rooms = useSelector(getRoomsWithMealSelector);
    const {search} = useLocation<any>();
    const {data: reviewsData} = reviews.data;
    const hotel = useSelector(getHotelSelector);
    const roomsData = useSelector((state: AppState) => state.rooms);
    //const [HotelLocationComponent, setHotelLocationComponent] = React.useState<FC | null>(null);

    const [showMessage, setShowMessage] = useState(true);

    const closeMessage = () => {
        setShowMessage(false);
    };

    const {
        address,
        name,
        photos,
        description,
        alias,
        coordinates,
        propertyGroups,
        popularProperties,
        placeName,
    } = hotel.data;

    const {bookingState} = hotel;

    const history = useHistory();
    const dispatch = useAppDispatch();
    const {isMobile} = useWindowSize();

    useEffect(() => {
        // const HotelLocation = lazy(() => import('./components/HotelLocation/HotelLocation'));
        // setHotelLocationComponent(HotelLocation);

    }, []);

    useHotelDetailLogic(dispatch, alias);

    if (!hotel?.data?.name && !hotel.isFetching) return <NotFound/>;

    const onCloseModalWithPath = () => {
        history.replace(`/hotels/${alias}${search}`)
    }

    const title = `${name}, ${placeName} - Бронюй.com, готелі в Україні`;

    return (
        <Page className='page--hotel-detail' hideSearchHeader={isMobile}>
            {hotel.isFetching ? <AppLoader full fixed withBackground/> : ''}

            <HotelDetailHead name={title} photos={photos} description={description}/>

            <Container className='hotel-detail-page'>
                <HotelDetailGalleryModalRoute photos={photos} onClose={onCloseModalWithPath} alias={alias}/>

                {/*{hash === '#feedback-form' && <FeedbackModal onClose={onCloseModalWithPath}/>}*/}

                <TabletAndUpper>
                    <HotelDetailHeader/>
                </TabletAndUpper>

                <Element name={'photosScrollElement'}>
                    <HotelDescription hotel={hotel.data}/>
                </Element>

                <TabletAndUpper>
                    <HotelDetailContentBlock title={'Вибрати номер'}>
                        <div className={`hotel-detail-rooms-list-wrapper booking-state-${bookingState}`}>
                            <Element
                                name="hotelPhonesSection"
                                className={'hotel-detail-rooms-list-wrapper__rooms-wrapper'}
                            >
                                {!roomsData.isFetching ? <HotelRooms hotelRoomItems={rooms}/> : <RoomLoader/>}
                            </Element>

                            <HotelBookSection/>
                        </div>
                    </HotelDetailContentBlock>
                </TabletAndUpper>

                <Element name='amenitiesScrollElement'>
                    <HotelAmenities
                        hotelName={name}
                        amenities={propertyGroups}
                        //@ts-ignore
                        popularProperties={popularProperties}
                    />
                </Element>

                <Element name='mapScrollElement'>
                    <HotelLocation coordinates={coordinates} address={address}/>
                    {/*{HotelLocationComponent && (*/}
                    {/*    <Suspense fallback={() => <div></div>}>*/}
                    {/*        <HotelLocationComponent*/}
                    {/*            address={address}*/}
                    {/*            coordinates={coordinates}*/}
                    {/*            alias={alias}*/}
                    {/*        />*/}
                    {/*    </Suspense>*/}
                    {/*)}*/}

                </Element>

                <Element name='reviewsScrollElement'>
                    <HotelReviews reviews={reviewsData}/>
                </Element>

                {/*<HotelOwner/>*/}
                <HotelRules/>

                {/*<HotelCancelReservation/>*/}

                <MobileOnly>
                    <HotelDetailMobile/>
                </MobileOnly>
                <TabletAndUpper>
                    {hotel.bookingState === 'phone'
                        ? <HotelMessage portal={true} closeMessage={closeMessage} showMessage={showMessage}/>
                        : null
                    }
                </TabletAndUpper>

            </Container>
        </Page>
    );
};

HotelDetail.getInitialProps = getHotelDetailInitialProps;

export default HotelDetail;
