import React, { FC, useRef, useState } from 'react';
import './FieldUpload.scss';
import classNames from 'classnames';
import NoPhotoIcon from '../../../../../screens/Account/Profile/AccountEditModal/components/NoPhotoIcon/NoPhotoIcon';
// import Cropper from 'react-cropper';
// import "cropperjs/dist/cropper.css";

interface IProps {
   isError?: boolean;
   onChange: (base64: string) => void;
   className?: string;
   disabled?: boolean;
   value?: string;
}

const FieldUpload: FC<IProps> = props => {
   const { isError, className, onChange, value = '', disabled = false } = props;
   const fileInput = useRef(null);
   const [indicator, setIndicator] = useState<boolean>(false);
   const [image, setImage] = useState<string>(value);
   const [size, setSize] = useState<string>('');
   const [name, setName] = useState<string>('');

   const cropperRef = useRef<HTMLImageElement>(null);
   // const onCrop = () => {
   //   const imageElement: any = cropperRef?.current;
   //   const cropper: any = imageElement?.cropper;
   //   // console.log(cropper.getCroppedCanvas().toDataURL());
   // };

   const getUploadImg = (e: any) => {
      if (e.target.files && e.target.files[0]) {
         let { name, size } = e.target.files[0];
         const FR = new FileReader();

         setName(name);

         FR.onloadstart = function (e) {
            setIndicator(true);
         };

         FR.onloadend = function (e) {
            setIndicator(false);
         };

         FR.addEventListener('load', function (e: any) {
            setImage(e.target.result);
            onChange(e.target.result || '');
         });

         FR.readAsDataURL(e.target.files[0]);

         // file size
         const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
         let i = 0;

         while (size > 900) {
            size /= 1024;
            i++;
         }
         const exactSize = Math.round(size * 100) / 100 + ' ' + fSExt[i];

         setSize(exactSize);
      }
   };

   const handleClick = (e: any) => {
      e.preventDefault();
      // @ts-ignore
      fileInput.current.click();
   };

   return (
      <div className={classNames('field-upload', className, { isError })}>
         {indicator ? (
            <div>Loading...</div>
         ) : (
            <>
               <div className="field-upload-body">
                  <div className="field-upload-body__avatar">
                     {image ? (
                        <div>
                           <img src={image} className="field-upload-body__image" alt="аватар" />
                        </div>
                     ) : (
                        <NoPhotoIcon />
                     )}
                  </div>
                  <div className="field-upload-body__info">
                     <p>Фотографія профілю, на якій видно ваше обличчя, може допомогти іншим познайомитися з вами.</p>
                     {image ? (
                        <p>
                           <b>
                              {name} {size}
                           </b>
                        </p>
                     ) : (
                        <p>
                           <b>Виберіть зображення для завантаження</b>
                        </p>
                     )}
                     <button className="field-upload-body__upload" onClick={handleClick} disabled={disabled}>
                        {image ? 'Загрузити інший файл' : 'Завантажити файл'}
                     </button>
                  </div>
               </div>
            </>
         )}

         <input
            ref={fileInput}
            type="file"
            accept="image/jpg, image/jpeg, image/png"
            name="upload"
            style={{ display: 'none' }}
            id="upload"
            onChange={getUploadImg}
         />
      </div>
   );
};

export default FieldUpload;
