import React, {FC} from "react";

interface Props {
  classNames?: string
}

const IconTravel: FC<Props> = (props) => {

  return (
    <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M28 28V30H0V28H28ZM18 1.00268e-07C18.5046 -0.000159579 18.9906 0.190406 19.3605 0.533497C19.7305 0.876587 19.9572 1.34684 19.995 1.85L20 2V4H23C24.2885 4.00007 25.5272 4.49754 26.4578 5.38866C27.3884 6.27978 27.9391 7.49575 27.995 8.783L28 9V21C27.9999 22.2885 27.5025 23.5272 26.6113 24.4578C25.7202 25.3884 24.5042 25.9391 23.217 25.995L23 26H5C3.71154 25.9999 2.47284 25.5025 1.54222 24.6113C0.611607 23.7202 0.0609194 22.5042 0.00500011 21.217L0 21V9C7.44425e-05 7.71154 0.497541 6.47284 1.38866 5.54222C2.27978 4.61161 3.49575 4.06092 4.783 4.005L5 4H8V2C7.99984 1.49542 8.19041 1.00943 8.5335 0.639452C8.87659 0.269471 9.34685 0.0428433 9.85 0.0050001L10 1.00268e-07H18ZM23 6H5C4.23479 5.99996 3.49849 6.29233 2.94174 6.81728C2.38499 7.34224 2.04989 8.06011 2.005 8.824L2 9V21C1.99996 21.7652 2.29233 22.5015 2.81728 23.0583C3.34224 23.615 4.06011 23.9501 4.824 23.995L5 24H23C23.7652 24 24.5015 23.7077 25.0583 23.1827C25.615 22.6578 25.9501 21.9399 25.995 21.176L26 21V9C26 8.20435 25.6839 7.44129 25.1213 6.87868C24.5587 6.31607 23.7956 6 23 6V6ZM15 8V17.5L18.293 14.207L19.707 15.621L15.061 20.268L14.947 20.371C14.6795 20.5888 14.345 20.7077 14 20.7077C13.655 20.7077 13.3206 20.5888 13.053 20.371L12.939 20.268L8.293 15.621L9.707 14.207L13 17.5V8H15ZM18 2H10V4H18V2Z" fill="#C5C6CF"/>
    </svg>
  )
}

export default IconTravel