import { fetchData } from '../helpers/network';
import { HotelListResponse } from '../types/hotels';
import { HotelResponse, PlacesGetResponse } from '../types/hotel';
import { RoomsResponse } from '../types/rooms';
import { ReviewsResponse } from '../types/reviews';

export default {
    fetchHotelsPlaces: (search: string) => fetchData(`/places${search}`) as Promise<PlacesGetResponse>,
    fetchHotels: (search: string = '', signal = null) => fetchData(`/hotels${search}`, {signal}) as Promise<HotelListResponse>,
    fetchHotelByAlias: (alias: string) => fetchData(`/hotels/${alias}`) as Promise<HotelResponse>,
    fetchHotelRoomsByAlias: (alias: string, search: string) =>
        fetchData(`/hotels/${alias}/rooms${search}`) as Promise<RoomsResponse>,
    fetchHotelCommentsById: (id: string) => fetchData(`/hotels/${id}/reviews`) as Promise<ReviewsResponse>,
    fetchHotelsNextPage: (nextPageUrl: string) => fetchData(nextPageUrl) as Promise<HotelListResponse>,
    sendPhoneTrackData: (alias: string, phone: string) => fetchData(`/hotels/${alias}/show-contact/track?value=${phone}`),
    sendPhoneTrackDataCall: (alias: string, phone: string) => fetchData(`/hotels/${alias}/show-contact/track?value=${phone}&action=call`),
};
