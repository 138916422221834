import React, { FC } from 'react';

interface Props {

}

const IconMail: FC<Props> = (props) => {

   return (
      <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M25 1H5C2.79086 1 1 2.79086 1 5V21C1 23.2091 2.79086 25 5 25H25C27.2091 25 29 23.2091 29 21V5C29 2.79086 27.2091 1 25 1Z" stroke="#6D7188" strokeWidth="2"/>
         <path d="M2 3L12.4162 11.8137C13.9076 13.0757 16.0924 13.0757 17.5838 11.8137L28 3" stroke="#6D7188" strokeWidth="2"/>
      </svg>

   );
};

export default IconMail;