import React from 'react';

type Props = {}

const IconPerson: React.FC<Props> = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5928 18.2086V16.8011C17.5928 16.0546 17.2962 15.3386 16.7683 14.8108C16.2404 14.2829 15.5245 13.9863 14.7779 13.9863H9.14831C8.40178 13.9863 7.68582 14.2829 7.15794 14.8108C6.63006 15.3386 6.3335 16.0546 6.3335 16.8011V18.2086"
                stroke="#5ECBAA" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.9633 11.1716C13.5178 11.1716 14.7781 9.91139 14.7781 8.35681C14.7781 6.80223 13.5178 5.54199 11.9633 5.54199C10.4087 5.54199 9.14844 6.80223 9.14844 8.35681C9.14844 9.91139 10.4087 11.1716 11.9633 11.1716Z"
                stroke="#5ECBAA" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default IconPerson;
