import React from 'react';

const RoomPriceMealIconFB: React.FC = () => (
    <svg className='RoomPriceMealIconFB' width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 6.18164C14.3869 6.18164 16.6761 7.17773 18.364 8.95078C20.0518 10.7238 21 13.1286 21 15.6361V15.6361C21 16.0219 20.8541 16.3918 20.5945 16.6646C20.3348 16.9374 19.9826 17.0906 19.6154 17.0906H4.38462C4.01739 17.0906 3.66521 16.9374 3.40554 16.6646C3.14588 16.3918 3 16.0219 3 15.6361C3 13.1286 3.94821 10.7238 5.63604 8.95078C7.32387 7.17773 9.61305 6.18164 12 6.18164V6.18164Z"
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 6.1818V4" strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M3 20H21" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
);

export default RoomPriceMealIconFB;
