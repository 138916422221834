import React, { FC } from 'react';
import './StatusReservation.scss';
import classNames from 'classnames';

interface Props {
  status: 'new' | 'guaranteed' | 'checked_in' | 'checked_out' | 'no_show' | 'cancelled';
}

const StatusReservation: FC<Props> = (props) => {
  const {status = '', children} = props;
  const classes = classNames('status-reservation', `status-reservation--${status}`)

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default StatusReservation;