import React, { HTMLProps } from 'react';
import classNames from 'classnames';
import './FieldInput.scss';

interface Props extends HTMLProps<HTMLInputElement> {
   size?: any;
   isError?: boolean;
}

const FieldInput = React.forwardRef<any, Props>(({ size, isError, value, ...rest }, ref) => {
   const classes = classNames('field-input', { isError }, size);

   return (
      <div className={classes}>
         <input value={value} ref={ref} {...rest} autoComplete="off" />
      </div>
   );
});

export default FieldInput;
