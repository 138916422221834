import React, { FC, useState } from 'react';
import './FavoriteItem.scss';
import IconUpload from '../../../../components/ui/icons/icons/IconUpload';
import IconMore from '../../../../components/ui/icons/account/IconMore';
import Image from '../../../../components/ui/components/Image/Image';
import { NavLink } from 'react-router-dom';
import { FavoritesGroupListItem } from '../../../../types/favorites';
import IconNoPhotoOutline from '../../../../components/ui/icons/account/IconNoPhotoOutline';
import ModalShareRoute from '../components/ModalShareRoute/ModalShareRoute';
import ModalEditGroupName from '../components/ModalEditGroupName/ModalEditGroupName';
import favoriteApi from '../../../../api/favoriteApi';
import { useSelector } from 'react-redux';
import { initialSelector } from '../../../../store/initial/initial-selectors';

interface Props {
   favoriteItem: FavoritesGroupListItem;
}

type ModalStateType = '' | 'edit' | 'share';

const FavoriteItem: FC<Props> = props => {
   const [modal, setModal] = useState<ModalStateType>('');
   const { id, name = '', description = '', photos = {}, itemsCount  } = props.favoriteItem;
   //@ts-ignore
   const { data = [] } = photos;
   const [firstPhoto = {}] = data;
   // const shareLink = `/profile/favorite/share/${id}`;
   // const editLink = `/profile/favorite/edit/${id}`;

   const [shareLink, setShareLink] = useState('');
   const { language } = useSelector(initialSelector);

   const onInit = () => {
      favoriteApi.createShareGroup({ id, name }).then(data => {
         const prefix = `/${language}`;
         const shareUrl = `${location.origin}${prefix}/hotels/wishlist/${data.data.id}`;
         setShareLink(shareUrl);
      });
   };

   return (
      <div className="favorite-item">
         <div className="favorite-item__block-setting">
            <div className="favorite-item__places-amount">{itemsCount} збережені місця</div>

            <div onClick={() => setModal('share')} className="favorite-item__upload-icon">
               <IconUpload color="#6D7188"/>
            </div>

            <div onClick={() => setModal('edit')} className="favorite-item__more-icon">
               <IconMore />
            </div>
         </div>
         <NavLink to={`/profile/favorite/${id}/list`}>
            <div className="favorite-item__images-list">
               {data.length ? (
                  <>
                     {data.map((el, index) => {
                        return (
                           <Image key={index} aspectRatio="3:4" className="favorite-item__images-item" src={el.source['302x170']} />
                        );
                     })}
                  </>
               ) : (
                  <div className="favorite-item__no-photo">
                     <IconNoPhotoOutline />
                  </div>
               )}
            </div>
         </NavLink>
         <div className="favorite-item__place">{name}</div>
         <div className="favorite-item__date">{description}</div>

         {modal === 'share' ? (
            <ModalShareRoute
               title="Надішліть посилання на обране"
               shareLink={shareLink}
               onInit={onInit}
               onClose={() => setModal('')}
               image={firstPhoto?.source['302x170']}
               name={`Перегляньте мій список обраного «${name}»`}
            />
         ) : null}
         {modal === 'edit' ? <ModalEditGroupName onClose={() => setModal('')} id={id} /> : null}
      </div>
   );
};
export default FavoriteItem;
