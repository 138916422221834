import React, { CSSProperties } from 'react';
import ContentLoader  from 'react-content-loader';
import {getAspectRatioPadding} from "../../../helpers/styles";
import {AspectRatio} from "../../../types/common";

type Props = {
  style?: CSSProperties;
  borderRadius?: number;
  aspectRatio?: AspectRatio;
  children?: React.ReactNode;
  backgroundColor?: any;
  foregroundColor?: any
  width?: any;
  height?: any
};
const LoaderAspectRatio: React.FC<Props> = (props) => {
  const { style = {}, children, borderRadius, aspectRatio, width, height, ...rest } = props;
  const content = children || <rect x="0" y="0" rx={borderRadius} ry={borderRadius} width={width || "100%"} height={height || "100%"} />;
  //@ts-ignore
  const ratio = getAspectRatioPadding(aspectRatio || '');

  const styleLoader = {
    width: width || '100%',
    height: height || '100%',
    position: 'absolute',
    top: '0',
    left: '0',

  };

  return (
    <div style={{ paddingTop: ratio, position: 'relative', ...style, }}>
      {/*@ts-ignore*/}
      <ContentLoader style={styleLoader} {...rest}>{content}</ContentLoader>
    </div>
  );
};

export default LoaderAspectRatio;
