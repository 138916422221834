import React from 'react';
import './AppLoader.scss';
import classnames from 'classnames';

type Props = {
    size?: number,
    full?: boolean,
    fixed?: boolean,
    withBackground?: boolean
    style?: any;
}

const AppLoader: React.FC<Props> = ({size = 54, full, fixed, withBackground, style}) => {
    const cn = classnames({'spinner-container': full}, {
        'spinner-container--fixed': fixed,
        'spinner-container--with-background': withBackground
    });

    return (
        <div className={cn} style={style}>
            <div className={`spinner`} style={{
                width: size,
                height: size
            }}/>
        </div>
    )
}

export default AppLoader;
