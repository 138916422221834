import React, { FC } from "react";
import "./ContactsSocial.scss";
import IconSocialFacebook from "../icons/social/IconSocialFacebook";
import IconSocialInstagram from "../icons/social/IconSocialInstagram";
import IconSocialTwitter from "../icons/social/IconSocialTwitter";
import IconSocialYouTube from "../icons/social/IconSocialYouTube";


interface Props {
  facebookLink?: string;
  instagramLink?: string;
  twitterLink?: string;
  youtubeLink?: string;
}

const ContactsSocial: FC<Props> = ({
                                     facebookLink,
                                     instagramLink,
                                     twitterLink,
                                     youtubeLink,
                                   }) => {

  if (!facebookLink &&
    !instagramLink &&
    !twitterLink &&
    !youtubeLink) {
    return null
  }

  return (
    <div className="contacts-social">
      <h6 className="contacts-social__title">Слідкуйте за нами</h6>
      <ul className="contacts-social__list">
        {facebookLink &&
          <li className="contacts-social__item"><a target="_blank" href={facebookLink}><IconSocialFacebook/></a></li>}
        {instagramLink &&
          <li className="contacts-social__item"><a target="_blank" href={instagramLink}><IconSocialInstagram/></a></li>}
        {twitterLink &&
          <li className="contacts-social__item"><a target="_blank" href={twitterLink}><IconSocialTwitter/></a></li>}
        {youtubeLink &&
          <li className="contacts-social__item"><a target="_blank" href={youtubeLink}><IconSocialYouTube/></a></li>}
      </ul>
    </div>
  )
}

export default ContactsSocial;