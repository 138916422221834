import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getHotelsForMapThunk,
    getHotelsNextPageThunk,
    getHotelsThunk,
    removeFromWishListThunk,
    setToWishListThunk
} from './hotels-thunk';
import { HotelList, HotelListItemType, HotelListMeta } from '../../types/hotels';
import { SaveToWishListParams } from '../../types/favorites';
import { isClient } from '../../helpers/common';

interface InitialState {
    data: HotelList;
    mapHotelsData: HotelList,
    lastFetchedPlace: any,
    error: any;
    isFetching: boolean;
    isNextPageFetching: boolean,
    isMapHotelsFetching: boolean,
    showFiltersModal: boolean,
    isFetchedOnServer: boolean,
    lastSavedPlaceId: string | number,
}

const hotelsDefaultMeta: HotelListMeta = {
    filters: {},
    pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 0,
        total_pages: 0,
        links: {},
    },
    parameters: {
        adults: '',
        kids: [],
        order_by: '',
        arrival_date: '',
        departure_date: '',
    },
    sortable: [],
    properties: {
        data: [],
    },
    mealTypes: {
        data: []
    },
}

const initialState: InitialState = {
    lastFetchedPlace: null,
    data: {
        data: [],
        meta: hotelsDefaultMeta
    },
    mapHotelsData: {
        data: [],
        meta: hotelsDefaultMeta

    },

    error: {},
    isFetching: false,
    isMapHotelsFetching: false,
    isNextPageFetching: false,
    showFiltersModal: false,
    isFetchedOnServer: true,
    lastSavedPlaceId: ''
};

const hotelsSlice = createSlice({
    name: 'hotels',
    initialState,
    reducers: {
        // this code duplicated in wishlist and favorite
        setToWishList(state, action: PayloadAction<SaveToWishListParams>) {
            const {isFavorite, hotel_id} = action.payload;

            // TODO REFACTOR
            state.mapHotelsData = {
                ...state.mapHotelsData,
                data: state.mapHotelsData.data.map(item => {
                    if (item.id === hotel_id) {
                        item.isFavorite = isFavorite;
                    }

                    return item;
                }),
            }

            state.data = {
                ...state.data,
                data: state.data.data.map(item => {
                    if (item.id === hotel_id) {
                        item.isFavorite = isFavorite;
                    }

                    return item;
                }),
            };
        },
        setHotelsData(state, action: PayloadAction<HotelList>) {
            state.data = action.payload;
        },
        setMapHotelsData(state, action: PayloadAction<HotelList>) {
            state.mapHotelsData = action.payload;
        },
        copyMapHotelsDataFromHotelsData(state) {
            state.mapHotelsData = state.data;
        },
        setShowFiltersModal(state, action: PayloadAction<boolean>) {
            state.showFiltersModal = action.payload;
        },
        setIsFetchedOnServer(state, action: PayloadAction<boolean>) {
            state.isFetchedOnServer = action.payload;
        },
        setLastFetchedPlace(state, action: PayloadAction<any>) {
            state.lastFetchedPlace = action.payload;
        },
        setLastSavedPlaceId(state, action: PayloadAction<any>) {
            state.lastSavedPlaceId = action.payload;
        }
    },
    extraReducers: builder => {
        /**
         * Get hotels
         */
        builder.addCase(getHotelsThunk.pending, (state, action) => {
            state.isFetching = true;
        });

        builder.addCase(getHotelsThunk.fulfilled, (state, action) => {
            state.isFetching = false;
            state.data = action.payload.hotelData;
            state.lastSavedPlaceId = action.payload.lastSavedPlaceId;

            state.isFetchedOnServer = !isClient;

            // state.data = {
            //     data: [],
            //     meta: state.data.meta
            // }
        });

        builder.addCase(getHotelsThunk.rejected, (state, action) => {
            state.isFetching = false;

            state.data = {
                data: [],
                meta: state.data.meta
            }

            state.lastSavedPlaceId = '';

            // TODO якщо повернути action payload тут тоді помилку отримаємо в server.js catch
            state.error = action.error;
        });

        builder.addCase(setToWishListThunk.fulfilled, (state, action) => {
            hotelsSlice.caseReducers.setToWishList(state, action)
        });

        builder.addCase(removeFromWishListThunk.fulfilled, (state, action) => {
            hotelsSlice.caseReducers.setToWishList(state, action)
        })

        /**
         * Get next page
         */
        builder.addCase(getHotelsNextPageThunk.pending, state => {
            state.isNextPageFetching = true;
        })

        builder.addCase(getHotelsNextPageThunk.rejected, state => {
            state.isNextPageFetching = false;
        })

        builder.addCase(getHotelsNextPageThunk.fulfilled, (state, action) => {
            state.isNextPageFetching = false;
            state.data.data = [...state.data.data, ...action.payload.data];
            state.data.meta.pagination = action.payload.meta.pagination;
        })

        /**
         * Working with map
         */
        builder.addCase(getHotelsForMapThunk.pending, state => {
            state.isMapHotelsFetching = true;
        })

        builder.addCase(getHotelsForMapThunk.rejected, state => {
                state.isMapHotelsFetching = false;
            }
        )

        builder.addCase(getHotelsForMapThunk.fulfilled, (state, action: PayloadAction<HotelList>) => {
            state.isMapHotelsFetching = false;
            state.mapHotelsData = action.payload;
        })
    },
});

export const {
    setHotelsData,
    setToWishList,
    setMapHotelsData,
    setShowFiltersModal,
    copyMapHotelsDataFromHotelsData,
    setLastFetchedPlace
} = hotelsSlice.actions;

export default hotelsSlice.reducer;

