import i18next from '../i18n';

export const translate = key => {
   return i18next.t(key);
};

export const pluralize = (key: string, count: number, withCount: boolean = true) => {
   const result = i18next.t(key, { count: count });

   return withCount ? `${count} ${result}` : result;
};


