// import { useState, useCallback, useMemo, RefObject } from 'react';
// import { throttle } from 'lodash';
// import { getRefElement, isSSR } from '../helpers/common';
// import { useEventListener } from './useEventListener';
//
// interface Scroll {
//     y?: number;
//     x?: number;
//     direction?: 'up' | 'right' | 'down' | 'left';
// }
//
// interface ScrollResult {
//     scrollY?: number;
//     scrollX?: number;
//     direction?: 'up' | 'right' | 'down' | 'left';
// }
//
// interface UseScroll {
//     wait?: number;
//     element?: RefObject<Element> | Window | null;
// }
//
// const useScroll = (options?: UseScroll): ScrollResult => {
//     const {wait, element} = useMemo<UseScroll>(
//         () => ({
//             wait: 250,
//             element: isSSR ? undefined : window,
//             ...options
//         }),
//         [options]
//     );
//
//     const getScrollOffset = useCallback(
//         (direction: 'y' | 'x') => {
//             const target = getRefElement(element);
//
//             if (isSSR || !target) {
//                 return undefined;
//             }
//
//             if ('window' in target) {
//                 return direction === 'y' ? target.pageYOffset : target.pageXOffset;
//             }
//
//             if ('nodeType' in target) {
//                 return direction === 'y' ? target.scrollTop : target.scrollLeft;
//             }
//         },
//         [element]
//     );
//
//     const [scroll, setScroll] = useState<Scroll>({
//         y: getScrollOffset('y'),
//         x: getScrollOffset('x'),
//         direction: undefined
//     });
//
//     const setDirection = useCallback(
//         ({y, x}: Scroll) => {
//             const yOffset = getScrollOffset('y');
//             const xOffset = getScrollOffset('x');
//
//             if (
//                 y !== undefined &&
//                 x !== undefined &&
//                 yOffset !== undefined &&
//                 xOffset !== undefined
//             ) {
//                 if (y > yOffset) return 'up';
//                 if (y < yOffset) return 'down';
//                 if (x > xOffset) return 'left';
//                 if (x < xOffset) return 'right';
//             }
//         },
//         [getScrollOffset]
//     );
//
//     const scrollFunc = useCallback(() => {
//         const yOffset = getScrollOffset('y');
//         const xOffset = getScrollOffset('x');
//
//         setScroll((prev) => ({
//             y: yOffset,
//             x: xOffset,
//             direction: setDirection(prev)
//         }));
//     }, [getScrollOffset, setDirection]);
//
//     const handleScroll = useMemo(
//         () =>
//             wait !== 0 ? throttle(() => scrollFunc(), wait) : () => scrollFunc(),
//         [wait, scrollFunc]
//     );
//
//     useEventListener({
//         type: 'scroll',
//         listener: handleScroll,
//         element,
//         options: {passive: true}
//     });
//
//     return {
//         scrollY: scroll.y,
//         scrollX: scroll.x,
//         direction: scroll.direction
//     };
// };
//
// export default useScroll;

import { useState, useEffect, useCallback } from 'react';

type ReturnType = {
    scrollY: number,
    scrollX: number,
    scrollDirection: 'up' | 'down'
}

const defaultVal: ReturnType = {
    scrollY: 0,
    scrollX: 0,
    scrollDirection: 'down',
};

export const useScroll = (elementId = ''): ReturnType => {
    if (typeof window !== 'undefined') {
        const scrollElement = elementId ? document.getElementById(elementId) : window;

        const [state, setState] = useState<any>({
            lastScrollTop: 0,
            //scrollY: scrollElement ? scrollElement.getBoundingClientRect().top : 0,
            scrollY: scrollElement?.scrollY || scrollElement?.scrollTop || 0,
            scrollDirection: 'down',
        });

        const handleScrollEvent = useCallback(e => {
            //Todo: do refactoring
            const scrollY = elementId ? e.target.scrollTop : scrollElement.scrollY;

            setState((prevState: any) => {
                const prevLastScrollTop = prevState.lastScrollTop;

                return {
                    scrollY,
                    scrollDirection: prevLastScrollTop > scrollY ? 'up' : 'down',
                    lastScrollTop: scrollY,
                };
            });
        }, [scrollElement]);

        useEffect(() => {
            if (scrollElement) {
                const scrollListener = (e: Event) => {
                    handleScrollEvent(e);
                };

                scrollElement.addEventListener('scroll', scrollListener);

                return () => {
                    scrollElement.removeEventListener('scroll', scrollListener);
                };
            }
        }, [scrollElement, handleScrollEvent]);

        return {
            scrollY: state.scrollY,
            scrollX: 0,//state.scrollX,
            scrollDirection: state.scrollDirection,
        };
    } else {
        return defaultVal;
    }
};

export default useScroll;
