import React, { FC } from 'react';

const NoFoundBackground: FC = () => (
    <svg width="526" height="432" viewBox="0 0 526 432" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.2627 198.531V202.986" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M8.25684 200.945L11.8972 204.693" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M4 210.92H9.14737" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M7.8418 221.072L11.4821 217.324" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M18.2627 222.291V217.836" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M25.6877 219.322L22.7178 216.352" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M31.6278 211.896H27.1729" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M28.6575 201.504L25.6875 204.474" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M257.345 4V6.96996" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M248.435 12.9121H251.405" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M257.345 21.8196V18.8496" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M264.771 12.9121H261.801" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M85.0869 128.74V133.195" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M71.7217 142.104H76.1766" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M85.0869 153.984V149.529" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path d="M96.9676 142.104H92.5127" stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path
            d="M404.735 290.66C408.283 290.66 411.162 293.539 411.162 297.089C411.162 300.64 408.283 303.517 404.735 303.517C401.186 303.517 398.308 300.64 398.308 297.089"
            stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M434.148 232.338C437.965 231.147 443.814 226.48 445.168 221.459C446.396 225.898 451.543 231.147 456.188 231.545C450.96 233.396 445.961 238.895 445.168 243.215C444.629 238.812 437.715 232.98 434.148 232.338Z"
              fill="#EBECEE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M42.4277 288.681C44.3364 288.086 47.2599 285.753 47.9369 283.242C48.5509 285.46 51.124 288.086 53.4461 288.285C50.8327 289.209 48.3341 291.96 47.9369 294.12C47.6671 291.918 44.2103 289.001 42.4277 288.681Z"
              fill="#EBECEE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M77.8478 82.1078C77.8478 84.8619 75.6164 87.0926 72.8644 87.0926C70.111 87.0926 67.8809 84.8619 67.8809 82.1078C67.8809 79.355 70.111 77.123 72.8644 77.123C75.6164 77.123 77.8478 79.355 77.8478 82.1078Z"
              fill="#D1D8DF"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M410.624 95.4897C410.624 100.091 406.893 103.821 402.294 103.821C397.693 103.821 393.964 100.091 393.964 95.4897C393.964 90.8883 397.693 87.1582 402.294 87.1582C406.893 87.1582 410.624 90.8883 410.624 95.4897Z"
              fill="#EBECEE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M526 72.9195C526 76.5702 523.043 79.5285 519.394 79.5285C515.745 79.5285 512.787 76.5702 512.787 72.9195C512.787 69.2689 515.745 66.3105 519.394 66.3105C523.043 66.3105 526 69.2689 526 72.9195Z"
              fill="#EBECEE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M170.984 381.586C170.984 383.888 169.119 385.753 166.818 385.753C164.519 385.753 162.654 383.888 162.654 381.586C162.654 379.285 164.519 377.42 166.818 377.42C169.119 377.42 170.984 379.285 170.984 381.586Z"
              stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M168.506 50.0844C168.506 52.8385 166.275 55.0692 163.523 55.0692C160.77 55.0692 158.539 52.8385 158.539 50.0844C158.539 47.3316 160.77 45.0996 163.523 45.0996C166.275 45.0996 168.506 47.3316 168.506 50.0844Z"
              stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M295.626 427.721C292.441 428.933 288.877 427.332 287.666 424.148C286.454 420.963 288.053 417.397 291.238 416.186C294.422 414.974 297.986 416.573 299.197 419.759C300.41 422.944 298.81 426.509 295.626 427.721Z"
              stroke="#D2D8DF" strokeWidth="6.06476" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M293.729 309.908H171.953C163.382 309.908 156.366 302.871 156.366 294.271V120.614C156.366 112.014 163.382 104.98 171.953 104.98H293.729C302.303 104.98 309.319 112.014 309.319 120.614V294.271C309.319 302.871 302.303 309.908 293.729 309.908Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M283.333 317.334H161.558C152.987 317.334 145.971 310.297 145.971 301.697V128.039C145.971 119.439 152.987 112.406 161.558 112.406H283.333C291.908 112.406 298.924 119.439 298.924 128.039V301.697C298.924 310.297 291.908 317.334 283.333 317.334Z"
              fill="#E8EBF2"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M273.222 323.196H151.194C142.606 323.196 135.575 316.188 135.575 307.624V134.686C135.575 126.121 142.606 119.117 151.194 119.117H273.222C281.814 119.117 288.844 126.121 288.844 134.686V307.624C288.844 316.188 281.814 323.196 273.222 323.196Z"
              fill="#D8DBEA"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M273.222 323.196H151.194C142.606 323.196 135.575 316.188 135.575 307.624V134.686C135.575 126.121 142.606 119.117 151.194 119.117H273.222C281.814 119.117 288.844 126.121 288.844 134.686V307.624C288.844 316.188 281.814 323.196 273.222 323.196Z"
              fill="#F1F2F7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M255.633 171.806H165.501C162.927 171.806 160.82 169.802 160.82 167.353C160.82 164.901 162.927 162.896 165.501 162.896H255.633C258.207 162.896 260.314 164.901 260.314 167.353C260.314 169.802 258.207 171.806 255.633 171.806Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M255.633 198.533H165.501C162.927 198.533 160.82 196.528 160.82 194.08C160.82 191.628 162.927 189.623 165.501 189.623H255.633C258.207 189.623 260.314 191.628 260.314 194.08C260.314 196.528 258.207 198.533 255.633 198.533Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M255.633 225.263H165.501C162.927 225.263 160.82 223.259 160.82 220.81C160.82 218.361 162.927 216.354 165.501 216.354H255.633C258.207 216.354 260.314 218.361 260.314 220.81C260.314 223.259 258.207 225.263 255.633 225.263Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M255.633 251.996H165.501C162.927 251.996 160.82 249.991 160.82 247.543C160.82 245.09 162.927 243.086 165.501 243.086H255.633C258.207 243.086 260.314 245.09 260.314 247.543C260.314 249.991 258.207 251.996 255.633 251.996Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M212.549 278.722H165.521C162.936 278.722 160.82 276.718 160.82 274.269C160.82 271.817 162.936 269.812 165.521 269.812H212.549C215.134 269.812 217.25 271.817 217.25 274.269C217.25 276.718 215.134 278.722 212.549 278.722Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M307.469 244.179C289.447 262.198 261.545 264.231 241.289 250.319C238.7 248.509 236.236 246.476 233.938 244.179C232.756 243 231.641 241.783 230.59 240.502C228.489 237.944 226.648 235.216 225.141 232.397C222.484 227.636 220.677 222.584 219.691 217.363C216.509 200.987 221.267 183.36 233.938 170.694C246.64 157.991 264.27 153.264 280.649 156.415C285.868 157.432 290.924 159.238 295.686 161.864C298.509 163.406 301.2 165.246 303.762 167.347C305.039 168.393 306.256 169.512 307.435 170.694C309.737 172.992 311.8 175.451 313.576 178.043C327.495 198.292 325.458 226.193 307.469 244.179Z"
              fill="white" fillOpacity="0.1"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M302.427 240.629C283.676 259.382 253.29 259.379 234.536 240.629C215.807 221.899 215.807 191.509 234.561 172.759C253.29 154.03 283.676 154.03 302.406 172.759C321.156 191.509 321.156 221.899 302.427 240.629ZM312.049 163.125C287.981 139.057 248.981 139.057 224.913 163.125C200.849 187.189 200.828 226.214 224.892 250.282C246.796 272.179 281.129 274.154 305.277 256.175C307.635 254.416 309.907 252.445 312.073 250.282C314.237 248.115 316.208 245.844 317.963 243.485C335.942 219.334 333.95 185.026 312.049 163.125Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M406.765 341.005L405.754 342.016C399.069 348.704 388.123 348.704 381.435 342.016L319.712 280.293L345.042 254.963L406.765 316.686C413.453 323.374 413.453 334.317 406.765 341.005Z"
              fill="#EB5757"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M319.294 241.598L337.533 259.837L324.584 272.782L306.349 254.543C308.755 252.748 311.07 250.737 313.284 248.53C315.491 246.322 317.503 244.004 319.294 241.598Z"
              fill="#E0E2EE"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M405.842 315.927L380.674 341.092L376.143 336.558L401.308 311.393L405.842 315.927Z" fill="#EF545F"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M346.444 255.834L320.586 281.692L316.744 277.85L342.602 251.992L346.444 255.834Z" fill="#B61616"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M290.013 166.307L226.599 229.717C224.211 225.437 222.587 220.895 221.704 216.201L276.496 161.408C281.188 162.325 285.733 163.946 290.013 166.307Z"
              fill="white" fillOpacity="0.5"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M310.804 181.38L242.246 246.054C239.791 244.437 237.454 242.618 235.275 240.563C234.153 239.504 233.092 238.419 232.1 237.273L301.492 171.805C302.707 172.745 303.861 173.746 304.979 174.804C307.159 176.86 309.119 179.061 310.804 181.38Z"
              fill="white" fillOpacity="0.5"/>
    </svg>
);

export default NoFoundBackground;
