import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderPostData, OrderReservationEditPutData } from '../../types/order';
import orderApi from '../../api/orderApi';
import { AppState } from '../index';

export const addToOrderThunk: any = createAsyncThunk(
    'order/addToOrder',
    async (withRemoval: boolean = false, thunkAPI) => {
        const {order} = thunkAPI.getState() as AppState;

        //if(order.isLoading) return;

        const {reservations} = order;

        const adults = reservations[0].searchData.adults;
        const kids = reservations[0].searchData.kids || [];

        const data: OrderPostData = {
            with_removal: withRemoval,
            adults,
            kids,
            reservations: reservations.map((reservation => {
                const {arrival_date, departure_date} = reservation.searchData;

                return {
                    arrival_date,
                    departure_date,
                    rate_id: reservation.price.rateId,
                    quantity: reservation.count,
                    room_type_id: reservation.roomTypeId
                }
            }))
        };

        try {
            return await orderApi.addToOrder(data);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
)

export const getOrderThunk = createAsyncThunk('order/getOrderThunk',
    async (_, thunkAPI) => {
        try {
            return await orderApi.getOrder();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
);

export const removeReservationThunk = createAsyncThunk(
    'order/removeReservationThunk',
    async (reservationId: string, thunkAPI) => {
        try {
            return orderApi.removeReservation(reservationId);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
)

export const editReservationThunk = createAsyncThunk(
    'order/editReservationThunk',
    async (sendData: { reservationId: string | number, data: OrderReservationEditPutData }, thunkAPI) => {
        try {
            return await orderApi.editReservation(sendData.reservationId, sendData.data);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response);
        }
    }
)

export const goToPaymentThunk = createAsyncThunk(
    'order/goToPayment',
    async (sendData: { payment_method: string }, thunkAPI) => {
        try {
            return await orderApi.goToPayment(sendData.payment_method);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.data);
        }
    }
)
