import React, {FC} from "react";

interface Props {

}

const IconSearch: FC<Props> = (props) => {

  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8058 10.8565L9.53986 8.59723C10.2709 7.66583 10.6676 6.51567 10.6662 5.33162C10.6662 4.27712 10.3535 3.24631 9.76762 2.36953C9.18178 1.49275 8.34909 0.809385 7.37487 0.405847C6.40064 0.00231009 5.32863 -0.103274 4.2944 0.102448C3.26017 0.308169 2.31017 0.815957 1.56453 1.5616C0.818886 2.30724 0.311099 3.25724 0.105378 4.29147C-0.100344 5.3257 0.00523978 6.39771 0.408777 7.37194C0.812314 8.34616 1.49568 9.17885 2.37246 9.76469C3.24924 10.3505 4.28005 10.6632 5.33455 10.6632C6.5186 10.6647 7.66876 10.268 8.60016 9.53693L10.8594 11.8029C10.9214 11.8653 10.9951 11.9149 11.0763 11.9487C11.1575 11.9826 11.2446 12 11.3326 12C11.4206 12 11.5077 11.9826 11.5889 11.9487C11.6701 11.9149 11.7438 11.8653 11.8058 11.8029C11.8683 11.7409 11.9178 11.6672 11.9517 11.586C11.9855 11.5048 12.0029 11.4177 12.0029 11.3297C12.0029 11.2417 11.9855 11.1546 11.9517 11.0734C11.9178 10.9922 11.8683 10.9185 11.8058 10.8565V10.8565ZM1.33584 5.33162C1.33584 4.54075 1.57036 3.76764 2.00974 3.11005C2.44912 2.45247 3.07364 1.93994 3.80431 1.63729C4.53498 1.33464 5.33898 1.25545 6.11466 1.40974C6.89033 1.56403 7.60283 1.94487 8.16206 2.5041C8.72129 3.06333 9.10213 3.77583 9.25642 4.55151C9.41071 5.32718 9.33153 6.13119 9.02887 6.86186C8.72622 7.59253 8.2137 8.21704 7.55611 8.65642C6.89853 9.09581 6.12542 9.33033 5.33455 9.33033C4.27402 9.33033 3.25693 8.90904 2.50703 8.15913C1.75713 7.40923 1.33584 6.39214 1.33584 5.33162Z" fill="#C5C6CF" fillOpacity="0.5"/>
    </svg>


  )
}

export default IconSearch