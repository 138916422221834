import React, { FC } from 'react';
import './ProfilePasswords.scss';
import AppFormInput from '../../../../../../components/ui/forms/controls/AppFormInput';
import { Form, Formik } from 'formik';
import FormButtons from '../../FormButtons/FormButtons';
import AppFormCode from '../../../../../../components/ui/forms/controls/AppFormCode';
import FormatPhone from '../../../../../../components/ui/components/FormatPhone/FormatPhone';

interface Props {
   isSending: boolean;
   cancelEdit: (s) => void;
   submitData: (props: any, propsMethods: any) => void;
   initialValues: any;
   validationSchema: any;
   sendCode: () => void;
}

const ProfilePasswords: FC<Props> = props => {
   const { initialValues, validationSchema, submitData, isSending, cancelEdit, sendCode } = props;

   return (
      <div>
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitData}
            enableReinitialize
         >
            <Form>

               {/*на телефон {initialValues.phone}*/}
               <div className="profile-send-code">
                  <div className="profile-password-message">
                     На ваш мобільний <span className="profile-password-message__phone"><FormatPhone>{initialValues.login}</FormatPhone></span> телефон було надіслано
                     СМС підтвердження для скидання пароля
                  </div>

                  <div className="profile-send-code__form">
                     <AppFormCode name="code" autoFocus={false} label="Введіть код, який ми надіслали через SMS" />
                  </div>
                  <p className="profile-send-code__send-code">
                     <span>Не отримали SMS-повідомлення? </span><span onClick={sendCode}>Надіслати повторно</span>
                  </p>
               </div>
               <AppFormInput type="password" name="password" label="Пароль" />
               <AppFormInput type="password" name="password_confirmation" label="Введіть пароль ще раз" />

               <FormButtons onCancel={cancelEdit} isSending={isSending} onSave={() => {}} />
            </Form>
         </Formik>
      </div>
   );
};

export default ProfilePasswords;
