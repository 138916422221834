import React, { ChangeEvent, FC, useRef, useState } from 'react';
import './MessagesEditable.scss';
import IconSend from '../IconSend/IconSend';

interface Props {
   onSubmit: any
}

const MessagesEditable: FC<Props> = ({onSubmit}) => {
   const [value, setValue] = useState('');
   const ref = useRef<HTMLTextAreaElement>();
   const submitMessage = () => {
      // send to store
      onSubmit(value)
      // clear value

      setValue('');

      ref.current.focus()
   };

   return (
      <div className="messages-editable">
         <button onClick={submitMessage}>
            <IconSend />
         </button>

         <textarea
            ref={ref}
            value={value}
            placeholder="Напишіть повідомлення ..."
            // rows={rows}
            rows={1}
            onKeyPress={(e) => {
               if (e.key === "Enter") {
                  submitMessage();
               }
            }}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setValue(event.target.value)}
            // onFocus={() => setRows(5)}
            // onBlur={() => setRows(1)}
         />
      </div>
   );
};

export default MessagesEditable;
