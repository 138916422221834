import React from 'react';
import './TotalPriceBlock.scss';
import PriceViewer from '../../../../../../../components/ui/components/PriceViewer/PriceViewer';

type Props = {
    totalPrice: number
}

const TotalPriceBlock: React.FC<Props> = ({totalPrice}) => {
    return (
        <div className={'order-success-payment-data__total-price-block'}>
            <span className={'order-success-payment-data__total-price-block__label-text'}>Cума оплати</span>
            {typeof totalPrice !== 'undefined' && <PriceViewer price={totalPrice} className={'order-success-payment-data__total-price-block__price-text'}/>}
        </div>
    )
};

export default TotalPriceBlock;
