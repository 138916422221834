import React, { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './ModalLayoutPrimary.scss';

import classnames from 'classnames';
import AppText from '../../../../typography/AppText/AppText';
import useScroll from '../../../../../../hooks/useScroll';

interface Props {
    title?: string | any;
    subTitle?: string;
    headerScrollContent?: any;
    noPaddingTop?: boolean;
    noPadding?: boolean;
    Footer?: FC;
    FooterRendered?: any,
    noFooterPadding?: boolean,
    disableFullSizeOnMobile?: boolean,
    rightContent?: any
    footerVariant?: 1 | 2 | 3,
    style?: any,
    onScrollUpdate?: (y: number) => void
}

const scrollTopForHideScrollContent = 40;

const ModalLayoutPrimary: FC<Props> = forwardRef((props, ref: any) => {
    const {
        title,
        subTitle,
        children,
        Footer,
        FooterRendered,
        disableFullSizeOnMobile,
        rightContent,
        noPadding,
        noPaddingTop,
        noFooterPadding,
        footerVariant,
        style,
        headerScrollContent,
        onScrollUpdate
    } = props;

    const {scrollDirection, scrollY} = useScroll('modalScroll');
    const headerScrollContentRef = useRef<HTMLDivElement>();
    const bodyRef = useRef<HTMLDivElement>();

    const [paddingTop, setPaddingTop] = useState(null);
    const [hideScrolledContent, setHideScrolledContent] = useState(false);
    const [scrolledContentHasTransition, setScrolledContentHasTransition] = useState(true);

    useImperativeHandle(ref, () => ({
        setScrollPosition: (y: number) => {
            if (y > scrollTopForHideScrollContent) {
                setHideScrolledContent(true);
                setScrolledContentHasTransition(false);
                setTimeout(() => setScrolledContentHasTransition(true), 100)
            }
            if (bodyRef.current) bodyRef.current.scrollTop = y;
        },
        getCurrentScrollPosition: () => scrollY
    }));

    useEffect(() => {
        if (headerScrollContent) {
            setPaddingTop(headerScrollContentRef.current ? headerScrollContentRef.current.getBoundingClientRect().height || 0 : 0);
        } else {
            setPaddingTop(null);
        }
    }, [headerScrollContent, headerScrollContentRef.current]);

    useEffect(() => {
        setHideScrolledContent(scrollDirection === 'down' && scrollY > scrollTopForHideScrollContent);
        if (onScrollUpdate) onScrollUpdate(scrollY);
    }, [scrollY, scrollDirection]);

    const classNames = classnames(
        'modal-layout-primary',
        {
            'modal-layout-primary--disable-full-size-styles-on-mobile': disableFullSizeOnMobile,
            'modal-layout-primary--with-footer': Footer || FooterRendered,
            'modal-layout-primary--no-padding': noPadding,
            'modal-layout-primary--no-padding-top': noPaddingTop,
            'modal-layout-primary--no-footer-padding': noFooterPadding,
            [`modal-layout-primary--footer-variant-${footerVariant}`]: footerVariant
        },
    );

    const headerScrollContentClassNames = classnames(
        'modal-layout-primary__header-scroll-content',
        {
            'modal-layout-primary__header-scroll-content-hidden': hideScrolledContent,
            'modal-layout-primary__header-scroll-content--with-transition': scrolledContentHasTransition
        }
    );

    const titleContent = title ? (
        <div className={'modal-layout-primary__title'}>
            <span>{title}</span>

            {subTitle && (
                <AppText variant={'gray'} className={'modal-layout-primary__sub-title'}>
                    {subTitle}
                </AppText>
            )}
        </div>
    ) : null;

    return (
        <div className={classNames} style={style}>
            {headerScrollContent ? (
                <div className={'modal-layout-primary__header'}>
                    {titleContent}

                    {headerScrollContent && (
                        <div ref={headerScrollContentRef} className={headerScrollContentClassNames}>
                            {headerScrollContent}
                        </div>
                    )}
                </div>
            ) : titleContent}

            {rightContent && (
                <div className={'modal-layout-primary__title-right-content'}>
                    {rightContent}
                </div>
            )}

            <div className="modal-layout-primary__body" id={'modalScroll'} style={{paddingTop}} ref={bodyRef}>
                {children}
            </div>

            {Footer || FooterRendered ? (
                <div className="modal-layout-primary__footer">
                    {FooterRendered || <Footer/>}
                </div>
            ) : null}
        </div>
    );
});

export default ModalLayoutPrimary;
