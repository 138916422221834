import React from 'react';

const IconInfo = () => {
   return (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M11 0C4.9 0 0 4.9 0 11C0 17.1 4.9 22 11 22C17.1 22 22 17.1 22 11C22 4.9 17.1 0 11 0ZM12.5 18H9.5V10H12.5V18ZM11 8C9.9 8 9 7.1 9 6C9 4.9 9.9 4 11 4C12.1 4 13 4.9 13 6C13 7.1 12.1 8 11 8Z"
            fill="white"
         />
      </svg>
   );
};

export default IconInfo;
