import React, {FC} from "react";

interface Props {

}

const IconFacebook: FC<Props> = (props) => {

  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12C0 6.34315 0 3.51472 1.75736 1.75736C3.51472 0 6.34315 0 12 0H22C27.6569 0 30.4853 0 32.2426 1.75736C34 3.51472 34 6.34315 34 12V22C34 27.6569 34 30.4853 32.2426 32.2426C30.4853 34 27.6569 34 22 34H12C6.34315 34 3.51472 34 1.75736 32.2426C0 30.4853 0 27.6569 0 22V12Z" fill="white"/>
      <path d="M21.1226 9.00328L19.0981 9C16.8237 9 15.3538 10.5244 15.3538 12.8839V14.6747H13.3183C13.1424 14.6747 13 14.8188 13 14.9966V17.5912C13 17.7691 13.1426 17.913 13.3183 17.913H15.3538V24.46C15.3538 24.6378 15.4963 24.7818 15.6722 24.7818H18.3279C18.5038 24.7818 18.6463 24.6377 18.6463 24.46V17.913H21.0262C21.2021 17.913 21.3446 17.7691 21.3446 17.5912L21.3455 14.9966C21.3455 14.9113 21.3119 14.8295 21.2523 14.7691C21.1927 14.7087 21.1115 14.6747 21.0271 14.6747H18.6463V13.1566C18.6463 12.427 18.8183 12.0566 19.7585 12.0566L21.1222 12.0561C21.298 12.0561 21.4404 11.912 21.4404 11.7343V9.32508C21.4404 9.1476 21.2981 9.00361 21.1226 9.00328Z" fill="#6D5ECB"/>
    </svg>

  )
}

export default IconFacebook