import React, { FC, forwardRef, memo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import './HotelSearchForm.scss';
import HotelPeople from './components/HotelPeople/HotelPeople';
import FieldDatepicker from '../../../../components/ui/forms/fields/FieldDatepicker/FieldDatepicker';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import Container from '../../../../components/ui/layouts/Container/Container';
import FieldReactSelect from '../../../../components/ui/forms/fields/FieldReactSelect/FieldReactSelect';
import FieldInput from '../../../../components/ui/forms/fields/FieldInput/FieldInput';
import useSearchForm from './hooks/useSearchForm';
import { formatDate } from '../../../../helpers/date';
import SearchOption from '../SearchOption/SearchOption';
import QueryNoFound from '../QueryNoFound/QueryNoFound';

import useEvent from '../../../../hooks/useEvent';
import { RouteNames } from '../../../../routes';

interface Props {
    title?: string;
}

interface IPlace {
    value: string;
    label: string;
}

const HotelSearchForm: FC<Props> = memo((props) => {
    const {
        place,
        endDate,
        loadOptions,
        placeObject,
        searchHotel,
        startDate,
        changeDates,
        query,
        guestsParams,
        changeKidsAge,
        changeGuestForm,
        selectChildren,
        defaultOptions,
        openSearchDropdown,
    } = useSearchForm();

    const [t] = useTranslation();
    const getDate = date => (date ? `${formatDate(new Date(date), 'dd MMMM')}` : '');
    const isMapOpen = useRouteMatch(RouteNames.HOTELS_LIST_MAP);

    const history = useHistory();
    const calendarRef = useRef<ReactDatePicker>(null);
    const selectRef = useRef(null);

    const handleChangePlace = (value: IPlace) => {
        if (!endDate && !startDate) {
            calendarRef.current.setOpen(true);
        }

        searchHotel(value);
    };

    const handleDateChange = dates => {
        changeDates(dates);

        if (dates[0] && dates[1]) {
            calendarRef.current.setOpen(false);
        }
    };

    const CustomInput = forwardRef(
        // @ts-ignore
        ({value, onClick, ...rest}, ref) => {
            const rangeEndString = getDate(endDate);
            const rangeStartString = getDate(startDate);

            return (
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                    <div className="hotel-search-block">
                        <div className="hotel-search-block__label">{t('Заїзд')}</div>
                        <div className="hotel-search-block__input">
                            {/*  @ts-ignore */}
                            <FieldInput
                                size="large"
                                value={rangeStartString}
                                ref={ref}
                                readOnly
                                onClick={onClick}
                                placeholder={t("День заїзду")}
                            />
                        </div>
                    </div>
                    <div className="hotel-search-block">
                        <div className="hotel-search-block__label">{t('Виїзд')}</div>
                        <div className="hotel-search-block__input">
                            {/*  @ts-ignore */}
                            <FieldInput
                                size="large"
                                value={rangeEndString}
                                ref={ref}
                                readOnly
                                onClick={onClick}
                                placeholder={t("День виїзду")}
                            />
                        </div>
                    </div>
                </div>
            );
        },
    );

    const searchSubmit = useEvent(() => {
        if (!place.value) {
            selectRef.current.focus();
        } else if (!startDate) {
            calendarRef.current.setOpen(true);
        } else if (!endDate) {
            calendarRef.current.setOpen(true);
        } else {
            history.push((isMapOpen ? RouteNames.HOTELS_LIST_MAP : RouteNames.HOTELS_LIST) + query);
        }

        // коли спрацьовує пуш справьовує getInitialProps
        // тому не потрібно робити запрос
        // dispatch(getHotelsThunk(query))
    });

    return (
        <div className="hotel-search-form">
            <Container>
                <div className="hotel-search-form__form">
                    <div className="hotel-search-form__inputs">
                        <div className="hotel-search-block hotel-search-block--round-left">
                            <div className="hotel-search-block__label">{t('Куди плануєте?')}</div>
                            <div className="hotel-search-block__input">
                                <FieldReactSelect
                                    // @ts-ignore
                                    ref={selectRef}
                                    classNamePrefix={'custom-select'}
                                    className={'custom-select'}
                                    OptionTemplate={SearchOption}
                                    TemplateNoFound={QueryNoFound}
                                    openMenuOnFocus={true}
                                    // @ts-ignore
                                    loadOptions={loadOptions}
                                    // menuIsOpen={true}
                                    onChange={handleChangePlace}
                                    value={placeObject}
                                    onMenuOpen={openSearchDropdown}
                                    // inputValue={{label: place.label, value: place.value}}
                                    // value={this.options.filter(({value}) => value === this.state.selectedValue)}
                                    // defaultValue={{label: place.label, value: place.value}}
                                    async
                                    placeholder={t("Місто або назва готелю")}
                                    defaultOptions={defaultOptions}
                                    size="large"
                                    menuShouldBlockScroll
                                    //menuIsOpen
                                />
                            </div>
                        </div>

                        <FieldDatepicker
                            //@ts-ignore
                            ref={calendarRef}
                            name="start"
                            size="large"
                            // inline
                            selectsRange
                            minDate={new Date()}
                            monthsShown={2}
                            shouldCloseOnSelect={false}
                            dateFormat="dd.MM.yyyy"
                            selected={null}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            customInput={<CustomInput/>}
                        />

                        <div className="hotel-search-block">
                            <div className="hotel-search-block__label">{t('Гості')}</div>
                            <div className="hotel-search-block__input">
                                <HotelPeople
                                    guestsParams={guestsParams}
                                    guestsMethods={{
                                        changeGuestForm,
                                        changeKidsAge,
                                        selectChildren,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`hotel-search-form__button`}>
                        <AppButton onClick={searchSubmit} size="large">
                            {t('Шукати')}
                        </AppButton>
                    </div>
                </div>
            </Container>
        </div>
    );
});

export default HotelSearchForm;
