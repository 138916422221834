import React, { FC } from 'react';
import './HotelIconText.scss';

interface Props {
    text: string;
    icon: any;
}

const HotelIconText: FC<Props> = props => {
    const {text, icon} = props;
    let iconForRender;
    const textAlignClass = typeof icon === 'string' ? 'align-self-center' : '';

    if (icon) {
        if (typeof icon === 'string')
            iconForRender = <img className="amenities-icon" src={icon} alt=""/>
        else
            iconForRender = <div className="amenities-icon">{icon}</div>;
    }

    return (
        <div className="amenities">
            {iconForRender}

            <div className={`amenities-text ${textAlignClass}`}>{text}</div>
        </div>
    );
};

export default HotelIconText;
