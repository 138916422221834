import React, { FC } from 'react';
import './NoTrips.scss';
import IconCar from '../../../../../../components/ui/icons/account/IconCar';
import AppButton from '../../../../../../components/ui/components/Buttons/AppButton/AppButton';

interface Props {}

const NoTrips: FC<Props> = props => {
   return (
      <div className="no-trips">
         <div className="no-trips__block-information">
            <div className="no-trips__title">У вас немає заброньованих подорожей... поки що!</div>
            <div className="no-trips__info">Час пакувати валізи й планувати нову пригоду</div>
            <AppButton to="/" theme={'fillable'} size={'middle'} className="no-trips__button">
               Почніть пошук
            </AppButton>
         </div>
         <IconCar />
         <AppButton to="/" theme={'fillable'} className="no-trips__button-mobile" size={'middle'}>
            Почніть пошук
         </AppButton>
      </div>
   );
};

export default NoTrips;
