import React, { FC } from 'react';

interface Props {

}

const IconSend: FC<Props> = (props) => {

   return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect width="32" height="32" rx="16" fill="#5ECBAA"/>
         <path d="M18.5122 16L14.4306 11.2381C14.161 10.9236 14.1974 10.4501 14.5119 10.1806C14.8264 9.911 15.2999 9.94743 15.5695 10.2619L20.0694 15.5119C20.3102 15.7928 20.3102 16.2072 20.0694 16.4881L15.5695 21.7381C15.2999 22.0526 14.8264 22.089 14.5119 21.8194C14.1974 21.5499 14.161 21.0764 14.4306 20.7619L18.5122 16Z" fill="white"/>
      </svg>
   );
};

export default IconSend;