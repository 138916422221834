import { combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import auth from './auth/auth-reducer';
import profile from './profile/profile-reducer';
import hotels from './hotels/hotels-reducer';
import hotel from './hotel/hotel-reducer';
import rooms from './rooms/rooms-reducer';
import order from './order/order-reducer';
import comments from './comments/comments-reducer';
import initial from './initial/initial-reducer';
import wishlist from './account/favorite-group-list/favorite-group-list-reducer';
import wishlistItems from './account/favorite-group-items/favorite-group-items-reducer';
import messages from './messages/messages-reducer';
import wishListPublic from "./wishListPublic/wishListPublic-reducer";
import reservations from "./account/orders/orders-reducer";
import accountOrdersDetail from "./account/account-orders-detail/account-orders-detail-reducer";

const rootReducer = combineReducers({
   profile,
   auth,
   hotels,
   order,
   hotel,
   initial,
   rooms,
   comments,
   wishlist,
   wishlistItems,
   messages,
   wishListPublic,
   reservations,
   accountOrdersDetail
});

//type RootReducerType = typeof rootReducer;
export type AppState = ReturnType<typeof rootReducer>; // (globalState: AppStateType) => AppStateType;


export type BaseThunkCreator<A extends Action, R = any> = ThunkAction<R, AppState, unknown, A>;

export default rootReducer;
