import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import { parse } from 'qs';
import './HotelItems.scss';

import { HotelList } from '../../../../types/hotels';
import HotelListItem from '../HotelListItem/HotelListItem';
import HotelsListHeader from '../HotelsListHeader/HotelsListHeader';
import useSearchForm from '../../common/HotelSearchForm/hooks/useSearchForm';
import HotelItemNoData from './components/HotelItemsNoData/HotelItemsNoData';
import Container from '../../../../components/ui/layouts/Container/Container';
import HotelsListLoader from '../components/HotelsListLoader/HotelsListLoader';
import SelectedHotelInList from './components/SelectedHotelInList/SelectedHotelInList';
import { getFilteredSearchForHotelLink } from './hotelItemsHelpers';

interface Props {
    hotels: HotelList;
    isFetching: boolean;
    onClickOnMapButton: () => void;
    search: string;
}

const HotelItems: FC<Props> = ({onClickOnMapButton, isFetching, hotels, search}) => {
    const {data, meta} = hotels;
    const {place} = useSearchForm();
    const isNoData = !data.length;

    const classes = classnames(
        'hotels-list-outer',
        {
            'hotels-list-outer--is-no-data': isNoData,
            'hotels-list-outer--is-loading': isFetching
        }
    );

    const filteredSearch = getFilteredSearchForHotelLink(parse(search, {ignoreQueryPrefix: true}));

    const renderView = React.useMemo(() => {
        if (isFetching) return <HotelsListLoader/>;
        if (isNoData) return <HotelItemNoData/>;

        const selectedItem = data[0].selected ? data[0] : null;
        const sliceIndex = selectedItem ? 1 : 0;

        return (
            <div>
                {selectedItem && (
                    <>
                        <SelectedHotelInList
                            hotel={selectedItem}
                            generatedLink={`/hotels/${selectedItem.alias}?${filteredSearch}`}
                        />

                        {data.length > 1 && (
                            <div className={'hotels-list-more-hotels-texts-section'}>
                                <span>Готелі що знаходяться поруч</span>
                                <span>Подивіться, що користується популярністю</span>
                            </div>
                        )}
                    </>
                )}

                <ul className="hotels-list">
                    {data.slice(sliceIndex).map((hotelItem) => (
                        <li
                            className="hotels-list__item"
                            key={'hotel-list-item-' + hotelItem.id}
                        >
                            <HotelListItem
                                hotelItem={hotelItem}
                                fromList
                                generatedLink={`/hotels/${hotelItem.alias}?${filteredSearch}`}/>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }, [isFetching, data, search]);

    return (
        <div className={classes}>
            {/*{(data.length && !isFetching) ?*/}
            {(!isFetching) ? (
                <HotelsListHeader
                    city={place?.label || ''}
                    numberOfHotels={meta.pagination.total}
                    isNoHotels={!data?.length}
                    onClickOnMapButton={onClickOnMapButton}
                />
            ) : null}

            <Container>
                {renderView}
            </Container>
        </div>
    );
}

export default HotelItems;

// export default React.memo(HotelItems, function (prevProps, nextProps) {
//     //console.log(prevProps.search.length, nextProps.search.length);
//     //console.log(prevProps.search == nextProps.search, prevProps.search === nextProps.search);
//     return prevProps.search == nextProps.search;
// });
