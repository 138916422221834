import React from 'react';
// import {asyncComponent} from '@jaredpalmer/after';
import AccountProfilePreview from './screens/Account/Profile/AccountProfilePreview/AccountProfilePreview';
import NotFound from './screens/NotFound/NotFound';
import { allowedLanguagesArray } from './config';
import SignIn from './screens/Auth/SignIn/SignIn';
// import NoSSR from "./components/common/NoSSR/NoSSR";
import SignUp from './screens/Auth/SignUp/SignUp';
import withPrivat from './hoc/withPrivate';
import AccountProfileEdit from './screens/Account/Profile/AccountProfileEdit/AccountProfileEdit';
import HotelList from './screens/Hotels/HotelList/HotelList';
import HotelDetail from './screens/Hotels/HotelDetail/HotelDetail';
import HotelIndex from './screens/Hotels/HotelIndex/HotelIndex';
import HotelBookingSummary from './screens/Hotels/HotelBookingSummary/HotelBookingSummary';
import AccountFavorite from './screens/Account/AccountFavorite/AccountFavorite';
import AccountFavoriteDetail from './screens/Account/AccountFavoriteDetail/AccountFavoriteDetail';
import ResetPassword from './screens/Auth/ResetPassword/ResetPassword';
import AccountSettings from './screens/Account/AccountSettings/AccountSettings';
import AccountPayment from './screens/Account/AccountPayment/AccountPayment';
import AccountOrdersList from './screens/Account/account-orders/AccountOrdersList/AccountOrdersList';
import TestScreen from './screens/test/TestScreen';
import IconsScreen from './screens/UI/IconsScreen';
import AccountRules from './screens/Account/AccountRules/AccountRules';
import AccountMessages from './screens/Account/AccountMessages/AccountMessages';
import HotelWishList from './screens/Hotels/HotelWishList/HotelWishList';
import OrderPageBase from "./screens/Order/OrderIndex/OrderPageBase";
import OrderSuccess from './screens/Order/OrderSuccess/OrderSuccess';
import AccountOrdersDetail from './screens/Account/account-orders/AccountOrdersDetail/AccountOrdersDetail';
import AccountOrdersPrint from './screens/Account/account-orders/AccountOrdersPrint/AccountOrdersPrint';
import Contacts from "./screens/Contacts/Contacts";
import HotelRegistrationInit from "./screens/HotelRegistration/HotelRegistrationInit/HotelRegistrationInit";

export enum RouteNames {
    PROFILE_ORDERS_LIST = '/profile/orders',
    PROFILE_ORDERS_DETAIL = '/profile/orders/:id',
    PROFILE_ORDERS_PRINT = '/profile/orders/:id/print',
    PROFILE_PAYMENT = '/profile/payment',
    PROFILE_SETTINGS = '/profile/settings',
    PROFILE_FAVORITE = '/profile/favorite',
    PROFILE_FAVORITE_DETAIL = '/profile/favorite/:id/list',
    PROFILE_MESSAGE = '/profile/messages',
    PROFILE_EDIT = '/profile/edit',
    PROFILE_RULES = '/profile/rules',
    PROFILE_INDEX = '/profile',

    AUTH_SIGNIN = '/auth/signin',
    AUTH_SIGNUP = '/auth/signup',
    AUTH_RESET = '/auth/reset',

    HOTELS_LIST = '/hotels',
    HOTELS_LIST_MAP = '/hotels/map',
    HOTELS_DETAIL = '/hotels/:alias',
    HOTELS_DETAIL_MAP = '/hotels/:alias/map',

    HOTELS_GALLERY = '/hotels/:alias/gallery/:index',
    HOTELS_FEEDBACK = '/hotels/:alias/feedback',
    HOTELS_ROOMS_GALLERY = '/hotels/:alias/room-gallery/:id/:index',

    HOTELS_WISH_LIST = '/hotels/wishlist/:id',
    ORDER_SUCCESS = '/order/success',

    CONTACTS = '/contacts',

    HOTEL_REGISTRATION = '/hotel-registration',

    // TODO
    //https://betterprogramming.pub/the-best-way-to-manage-routes-in-a-react-project-with-typescript-c4e8d4422d64
}

const path = `/:lng(${allowedLanguagesArray.join('|')})?`; // '/:lng(uk|en|de|ru)?';

const routes = [
    {
        path: path,
        exact: true,
        component: HotelIndex,
    },
    {
        path: RouteNames.CONTACTS,
        exact: true,
        component: Contacts,
    },
    {
        path: RouteNames.HOTEL_REGISTRATION,
        exact: true,
        component: HotelRegistrationInit,
    },

    // profile pages
    {
        path: RouteNames.PROFILE_ORDERS_PRINT,
        component: withPrivat(AccountOrdersPrint),
    },
    {
        path: RouteNames.PROFILE_ORDERS_DETAIL,
        component: withPrivat(AccountOrdersDetail),
    },
    {
        path: RouteNames.PROFILE_ORDERS_LIST,
        component: withPrivat(AccountOrdersList),
    },
    {
        path: RouteNames.PROFILE_PAYMENT,
        component: withPrivat(AccountPayment),
    },
    {
        path: RouteNames.PROFILE_SETTINGS,
        component: withPrivat(AccountSettings),
    },
    {
        path: RouteNames.PROFILE_FAVORITE_DETAIL,
        component: withPrivat(AccountFavoriteDetail),
    },
    {
        path: RouteNames.PROFILE_FAVORITE,
        component: withPrivat(AccountFavorite),
    },
    {
        path: RouteNames.PROFILE_MESSAGE,
        component: withPrivat(AccountMessages),
    },
    {
        path: RouteNames.PROFILE_EDIT,
        component: withPrivat(AccountProfileEdit),
    },
    {
        path: RouteNames.PROFILE_RULES,
        component: withPrivat(AccountRules),
    },
    {
        path: RouteNames.PROFILE_INDEX,
        component: withPrivat(AccountProfilePreview),
    },

    {
        path: RouteNames.AUTH_SIGNIN,
        component: SignIn,
    },
    {
        path: RouteNames.AUTH_SIGNUP,
        component: SignUp,
    },

    {
        path: RouteNames.AUTH_RESET,
        component: ResetPassword,
    },
    {
        path: `/hotels/booking-summary/:alias`,
        component: HotelBookingSummary,
    },
    {
        path: RouteNames.ORDER_SUCCESS,
        component: OrderSuccess,
    },
    {
        path: RouteNames.HOTELS_WISH_LIST,
        component: HotelWishList,
    },
    {
        path: RouteNames.HOTELS_LIST,
        exact: true,
        component: HotelList,
    },
    {
        path: RouteNames.HOTELS_LIST_MAP,
        exact: true,
        component: HotelList
    },
    {
      path: RouteNames.HOTELS_FEEDBACK,
        exact: false,
        component: HotelDetail,
    },
    {
        path: RouteNames.HOTELS_GALLERY,
        exact: false, //Not exact for gallery
        component: HotelDetail,
    },
    {
        path: RouteNames.HOTELS_ROOMS_GALLERY,
        exact: false, //Not exact for gallery
        component: HotelDetail,
    },
    {
        path: RouteNames.HOTELS_DETAIL,
        exact: false, //Not exact for gallery
        component: HotelDetail,
    },
    {
        path: RouteNames.HOTELS_DETAIL_MAP,
        exact: false,
        component: HotelDetail
    },
    // {
    //    path: `/hotels/:alias/choose-room`,
    //    component: HotelDetail,
    // },
    // {
    //    path: `/hotels/:alias/choose-room/:roomId`,
    //    component: HotelDetail,
    // },
    {
        path: `/order`,
        component: OrderPageBase,
    },
    {
        path: '/test',
        component: TestScreen,
    },
    {
        path: '/debug/icons',
        component: IconsScreen,
    },
    {
        component: NotFound,
    },
];

export default routes.map((route) => {

    if (route.path) {
        return {
            ...route,
            path: `${path}${route.path}`
        }
    } else {
        return route
    }
});
