import React from "react";
import "./AuthLogo.scss";
import Logo from "../icons/AuthLogo";
import {Link} from "react-router-dom";
import AuthBack from "../icons/AuthBack";


const AuthLogo = (props: any) => {
    const {className, ...rest} = props;


    return (
        <div {...props} className={`auth-stepper-with-logo ${className}`}>

            <Link to="/" className="auth-stepper-with-logo__logo">
                <Logo/>
            </Link>
        </div>
    )
}

export default AuthLogo