import React, { FC } from 'react';
import './Conversations.scss';
import MessageUserItem from '../MessageUserItem/MessageUserItem';
import { Link, useLocation } from 'react-router-dom';
import { getUrlParams, } from '../../../../../helpers/url';

interface Props {
   conversations: any;
}

const Conversations: FC<Props> = ({ conversations }) => {
   const {search} = useLocation()
   //@ts-ignore
   const { conversation_id } = getUrlParams(search);
   return (
      <ul className="conversations">
         {conversations.map(item => {
            const { hotelName, name, status, lastConversation, id } = item;
            //className="active"
            return (
               <li className={id === Number(conversation_id) ? 'active' : ''}>
                  <Link to={`/profile/messages?conversation_id=${id}`}>
                     <MessageUserItem text={lastConversation} status={status} name={name} hotelName={hotelName} />
                  </Link>
               </li>
            );
         })}
      </ul>
   );
};

export default Conversations;
