import React, { FC, useEffect, useState } from 'react';
import './AccountOrdersPrint.scss';
import Page from '../../../../components/common/Page/Page';
import Container from '../../../../components/ui/layouts/Container/Container';
import PageHeader from '../../common/PageHeader/PageHeader';
import { RouteNames } from '../../../../routes';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
// import OrderPrintInfo from './components/OrderPrintInfo/OrderPrintInfo';
// import OrderPrintSummaryList from './components/OrderPrintSummaryList/OrderPrintSummaryList';
// import AccountPrintGuestsPayment from './components/AccountPrintGuestsPayment/AccountPrintGuestsPayment';
// import AccountOrdersPrintHelp from './components/AccountOrdersPrintHelp/AccountOrdersPrintHelp';
// import AccountOrdersPaymentWay from './components/AccountOrdersPaymentWay/AccountOrdersPaymentWay';
// import OrdersCancellationRules from './components/OrdersCancellationRules/OrdersCancellationRules';
import { useParams } from 'react-router-dom';
import accountOrdersDetailApi from '../../../../api/account-orders-detail-api';
import { extractBody } from '../../../../helpers/regEx';

interface Props {}

const AccountOrdersPrint: FC<Props> = props => {
   const [html, setHtml] = useState('');
   const { id } = useParams<{ id: string }>();

   const handlePrint = () => {
      window.print();
   };

   useEffect(() => {
      accountOrdersDetailApi.fetchOrderConfirmHtml(Number(id)).then(data => {
         setHtml(  extractBody(data));
      });
   }, []);

   const breadcrumbs = [
      {
         url: RouteNames.PROFILE_INDEX,
         title: 'Акаунт',
      },
      {
         title: 'Design&Comfort discovering old Kyiv',
         url: RouteNames.PROFILE_ORDERS_DETAIL.replace(':id', String(id)),
      },
      {
         title: 'Підтвердження бронювання',
      },
   ];

   return (
      <Page>
         <Container>
            <PageHeader
               breadcrumbs={breadcrumbs}
               breadcrumbsRightComponent={
                  <div className="account-orders-print-button">
                     <AppButton theme="outline" onClick={handlePrint}>
                        Надрукувати
                     </AppButton>
                  </div>
               }
            />
            <div dangerouslySetInnerHTML={{ __html: html }} />
         </Container>
      </Page>
   );
};

export default AccountOrdersPrint;
