import {createAsyncThunk} from "@reduxjs/toolkit";
import initialApi from '../../api/initialApi';

export const getInitialThunk = createAsyncThunk(
   'initial/getInitialThunk',
   async (data: void, { rejectWithValue }) => {
      try {
         const res = await initialApi.fetchInitialData();
         console.log("res",res);

         return res.data;
      } catch (error) {
         throw rejectWithValue(error)
      }
   }
)
