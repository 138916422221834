import React, { FC } from 'react';
import './LastTrips.scss';
import LastTripItem from './LastTripItem/LastTripItem';
import { OrdersList } from '../../../../../types/orders';
import { durationFormat, formatDate } from '../../../../../helpers/date';
// import { Link } from 'react-router-dom';
import { RouteNames } from '../../../../../routes';

interface Props {
   orders: {
      [key: string]: OrdersList;
   };
}

const LastTrips: FC<Props> = props => {
   const { orders } = props;

   return (
      <div className="last-trips">
         {Object.keys(orders).map(key => {
            return (
               <div key={key}>
                  <h3>{formatDate(new Date(key), 'dd MMMM yyyy')}</h3>
                  <ul className="last-trips__list">
                     {orders[key].map(item => {
                        const route = RouteNames.PROFILE_ORDERS_DETAIL.replace(':id', String(item.id));

                        return (
                           <li key={item.id}>
                              <LastTripItem
                                 id={item.id}
                                 route={route}
                                 date={durationFormat(item.arrivalDate, item.departureDate)}
                                 place={item.hotel}
                                 totalPrice={item.amount}
                                 image={item.photo?.source['302x170']}
                              />
                           </li>
                        );
                     })}
                  </ul>
               </div>
            );
         })}
      </div>
   );
};

export default LastTrips;
