import React, { FC, forwardRef } from 'react';
import AsyncSelect from 'react-select/async';
import ReactSelect, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import AppLoader from "../../../loaders/AppLoader/AppLoader";

import "./FieldReactSelect.scss"

interface Option {
  label: string,
  value: string;
}

interface PropsType {
  size?: string;
  defaultOptions?: any;
  loadOptions?: (inputValue: string) => Promise<Array<Option>>;
  cacheOptions?: boolean;
  onChange: any;
  async?: boolean;
  isError?: boolean;
  options?: any;
  placeholder?: string;
  OptionTemplate: any;
  TemplateNoFound: any;
  classNamePrefix?: string;
  className?: string;
  components: object;
}

const FieldReactSelect: FC<PropsType> = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const {
    classNamePrefix,
    className,
    size,
    defaultOptions,
    loadOptions,
    cacheOptions,
    async,
    isError,
    placeholder,
    OptionTemplate,
    TemplateNoFound,
    ...rest
  } = props;


  const onInputChange = () => {

  }

  const LoadingIndicator = () => {
    return (
      <div className="field-react-select-loader">
        <AppLoader size={24}/>
      </div>
    )
  }

  const DropdownIndicator = (parameters: any) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...parameters}>
        &nbsp;
      </components.DropdownIndicator>
    );

  const Option = (parameters: any) => (
    <components.Option {...parameters}>
      <OptionTemplate parameters={parameters}/>
    </components.Option>
  );

  const customStyles = {
    indicatorSeparator: () => {
      return { display: 'none' };
    },
    clearIndicator: () => {
      return {
        display: 'none',
      };
    },
  };

  const noOptionsMessage = (data: any): any => {
    if (data.inputValue === '') {
      return null;
    }

    return t('Результатів не знайдено');
  };

  if (async) {
    return (
      <>
        <AsyncSelect
          onInputChange={onInputChange}
          classNamePrefix={classNamePrefix}
          className={className}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          noOptionsMessage={TemplateNoFound || noOptionsMessage}
          styles={customStyles}
          placeholder={placeholder}
          components={{DropdownIndicator, Option, LoadingIndicator, LoadingMessage: () => null, ...props.components }}
          ref={ref}
          {...rest}
        />
      </>
    );
  }
  return (
    <>
      <ReactSelect
        styles={customStyles}
        classNamePrefix={classNamePrefix}
        className={className}
        placeholder={placeholder}
        ref={ref}
        {...rest} />
    </>
  );
});

export default FieldReactSelect;
