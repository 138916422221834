import React, { FC, HTMLProps } from 'react';
import { Field, FieldProps } from 'formik';
import FieldUpload from '../fields/FieldUpload/FieldUpload';
import ControlLayout from './ControlLayout/ControlLayout';

const AppFormUpload: FC<HTMLProps<HTMLInputElement>> = props => {
   const { name, className, disabled } = props;

   return (
      <Field name={name}>
         {(propsField: FieldProps) => {
            const { field, form, meta } = propsField;
            const isError = meta.touched && !!meta.error;
            const { setFieldValue } = form;

            return (
               <ControlLayout isError={isError} error={meta.error}>
                  <FieldUpload
                     className={className}
                     isError={isError}
                     disabled={disabled}
                     {...field}
                     onChange={(value: any) => {
                        setFieldValue(field.name, value);
                     }}
                  />
               </ControlLayout>
            );
         }}
      </Field>
   );
};

export default AppFormUpload;
