import React from 'react';
import './HotelsIcons.scss';

const IconMap = () => (
   <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M11.3744 1.95153C10.1159 0.693063 8.44269 0 6.66297 0C4.88325 0 3.21 0.693063 1.95153 1.95153C0.693031 3.21 0 4.88322 0 6.66297C0 7.83972 0.342875 9.06494 1.01903 10.3046C1.54481 11.2685 2.27372 12.2453 3.18547 13.2077C4.72203 14.8296 6.24056 15.8489 6.30447 15.8914C6.413 15.9638 6.53797 16 6.66297 16C6.78797 16 6.91294 15.9638 7.0215 15.8914C7.08541 15.8488 8.60394 14.8296 10.1405 13.2077C11.0522 12.2453 11.7812 11.2686 12.3069 10.3046C12.9831 9.06494 13.326 7.83972 13.326 6.66297C13.326 4.88322 12.6329 3.21 11.3744 1.95153ZM6.663 14.5595C5.40753 13.6299 1.29266 10.3043 1.29266 6.66297C1.29266 3.70175 3.70175 1.29262 6.66297 1.29262C9.62419 1.29262 12.0333 3.70175 12.0333 6.66297C12.0333 10.3046 7.91794 13.6302 6.663 14.5595ZM6.66297 4.01109C5.20075 4.01109 4.01109 5.20072 4.01109 6.66297C4.01109 8.12519 5.20072 9.31484 6.66297 9.31484C8.12522 9.31484 9.31484 8.12522 9.31484 6.66297C9.31484 5.20072 8.12519 4.01109 6.66297 4.01109ZM6.66297 8.02219C5.9135 8.02219 5.30375 7.41244 5.30375 6.66297C5.30375 5.9135 5.9135 5.30375 6.66297 5.30375C7.41247 5.30375 8.02219 5.9135 8.02219 6.66297C8.02219 7.41244 7.41247 8.02219 6.66297 8.02219Z"
         fill="#C5C6CF"
      />
   </svg>
);

export default IconMap;
