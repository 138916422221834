import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { HotelListItemType, HotelListMeta } from '../../../../../../types/hotels';
import './HotelsMapListItem.scss';
import Image from '../../../../../../components/ui/components/Image/Image';
import PriceViewer from '../../../../../../components/ui/components/PriceViewer/PriceViewer';
import HotelListItemTopInfo from '../../../HotelListItem/components/HotelListItemTopInfo/HotelListItemTopInfo';
import FavoriteFunctionality from '../../../components/FavoriteFunctionality/FavoriteFunctionality';
import IconFavorites from '../../../../../../components/ui/icons/hotels/IconFavorites';
import AppText from '../../../../../../components/ui/typography/AppText/AppText';
import { getOnePhotoLinkFromHotelData } from '../../../../../../helpers/hotels';

interface Props {
    hotel: HotelListItemType;
    meta: HotelListMeta;
    nights?: number,
    link: string
}

const HotelMapListItem: FC<Props> = props => {
    const {
        name,
        stars,
        minPrice,
        reviewsCount,
        distanceToPlaceFormatted,
        id,
        isFavorite,
        mealType
    } = props.hotel;

    const link = props.link;

    const properties = props.hotel.preparedProperties.slice(0, 10).map(p => p.name).join(', ');
    const photoLink = getOnePhotoLinkFromHotelData(props.hotel);

    return (
        <div className="map-list-item">
            <div className="map-list-item__carousel">
                <NavLink to={link} target={'_blank'}>
                    <Image
                        className="hotel-list-item__image"
                        aspectRatio="3:2"
                        src={photoLink}
                        alt={name}
                    />
                </NavLink>
            </div>

            <div className="map-list-item__block-right">
                <div className="map-list-item__block-name-favorite">
                    <div>
                        <NavLink to={link} target={'_blank'} className="map-list-item__name">
                            {name}
                        </NavLink>

                        {distanceToPlaceFormatted && (
                            <div className="map-list-item__location">{distanceToPlaceFormatted}</div>
                        )}

                        {/*<div className="map-list-item__location">{address || distanceToPlaceFormatted}</div>*/}
                    </div>

                    <FavoriteFunctionality
                        id={id}
                        isFavorite={isFavorite}
                        FavoriteButton={() => <IconFavorites size={'big'} isSelected={isFavorite}/>}
                    />
                </div>

                {properties && (
                    <div className="map-list-item__properties">
                        <AppText variant="C400">
                            {properties}
                        </AppText>
                    </div>
                )}

                <div className="map-list-item__block-footer">
                    {(!!stars || !!reviewsCount) && (
                        <div className="map-list-item__star">
                            <HotelListItemTopInfo stars={stars} reviewsCount={reviewsCount}/>
                            {/*<StarReviewDistance stars={stars} reviews={reviewsCount} />*/}
                        </div>
                    )}

                    {minPrice ? (
                        <div className="map-list-item__block-price">
                            {/*{minPrice < fullPrice && (*/}
                            {/*    <div className="map-list-item__block-price-container">*/}
                            {/*        <PriceViewer*/}
                            {/*            price={fullPrice} className="map-list-item__price" oldPrice*/}
                            {/*            nights={props.nights}*/}
                            {/*            totalAfterDiscount*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            <div className="map-list-item__block-price-container">
                                <PriceViewer price={minPrice} className="map-list-item__price" nights={props.nights}/>
                            </div>

                            {mealType?.name && (
                                <div className="map-list-item__meals">
                                    <AppText variant={'C400'}>{mealType?.name}</AppText>
                                </div>
                            )}
                        </div>
                    ) : <div/>}

                    {/*<div className="map-list-item__button-submit">*/}
                    {/*    <HotelListItemSubmitButton link={link}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default React.memo(HotelMapListItem, (pp, np) => {
    if (pp.hotel.isFavorite !== np.hotel.isFavorite) return false;
    if (pp.hotel.alias !== np.hotel.alias) return false;
    if (pp.hotel.fullPrice !== np.hotel.fullPrice) return false;

    return true;
});
