import React from 'react';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import './OrderCollisionModal.scss';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { clearStoringOnServerData } from '../../../../../store/order/order-reducer';
import { addToOrderThunk } from '../../../../../store/order/order-thunk';
import useWindowSize from '../../../../../hooks/useWindowSize';

type Props = {
    storingOnServerData: {
        success: boolean,
        reason: string
    },
}

const OrderCollisionModal: React.FC<Props> = ({storingOnServerData}) => {
    const dispatch = useAppDispatch();
    const onCancel = () => dispatch(clearStoringOnServerData());
    const onReplace = () => {
        //@ts-ignore
        dispatch(addToOrderThunk(true));
    }

    const {isMobile} = useWindowSize();

    const Footer = () => (
        <div className={'order-collision-modal__footer'}>
            <AppButton onClick={onCancel} theme={isMobile ? 'link' : null}>Залишити</AppButton>
            <AppButton onClick={onReplace}>Створити нове</AppButton>
        </div>
    )

    return (
        <Modal className={'order-collision-modal'} disableFullSizeOnMobile>
            <ModalLayoutPrimary title={'Ваш кошик буде очищено'} Footer={Footer} disableFullSizeOnMobile>
                {storingOnServerData.reason}
            </ModalLayoutPrimary>
        </Modal>
    )
};

export default OrderCollisionModal;
