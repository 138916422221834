import React, { FC } from 'react';
import './ControlLayout.scss';
import classNames from 'classnames';
import IconInfo from "../../../icons/form/IconInfo";

interface IProps {
   isError?: boolean;
   error?: string;
}

const ControlLayout: FC<IProps> = props => {
   const { children, isError, error } = props;
   const classes = classNames('field-control', { isError });

   return (
      <div className={classes}>
         {children}
         <span className="field-control__error">{isError && <span><IconInfo/>{error}</span>}</span>
      </div>
   );
};

export default ControlLayout;
