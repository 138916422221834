import React, { FC } from 'react';

interface Props {

}

const IconCopy: FC<Props> = (props) => {

   return (
      <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M22 4C23.0609 4 24.0783 4.42143 24.8284 5.17157C25.5786 5.92172 26 6.93913 26 8V25C26 26.3261 25.4732 27.5979 24.5355 28.5355C23.5979 29.4732 22.3261 30 21 30H9C7.67392 30 6.40215 29.4732 5.46447 28.5355C4.52678 27.5979 4 26.3261 4 25V9C4 7.67392 4.52678 6.40215 5.46447 5.46447C6.40215 4.52678 7.67392 4 9 4H22ZM22 6H9C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9V25C6 25.7956 6.31607 26.5587 6.87868 27.1213C7.44129 27.6839 8.20435 28 9 28H21C21.7956 28 22.5587 27.6839 23.1213 27.1213C23.6839 26.5587 24 25.7956 24 25V8C24 7.46957 23.7893 6.96086 23.4142 6.58579C23.0391 6.21071 22.5304 6 22 6ZM19 0V2H8C6.44762 1.99994 4.95564 2.60157 3.83753 3.67847C2.71942 4.75536 2.06221 6.22371 2.004 7.775L2 8V21H0V8C2.41087e-05 5.92157 0.808937 3.92474 2.25547 2.4323C3.702 0.939847 5.67259 0.0689508 7.75 0.00399995L8 0H19Z" fill="#6D7188"/>
      </svg>

   );
};

export default IconCopy;