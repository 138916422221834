import React, { FC, Suspense } from 'react';
import { useHistory, useLocation, generatePath, useParams } from 'react-router-dom';

import './HotelLocation.scss';
import HotelDetailContentBlock from '../HotelDetailContentBlock/HotelDetailContentBlock';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import ModalCloseButton from '../../../../../components/ui/modals/Modal/components/ModalCloseButton/ModalCloseButton';
import { RouteNames } from '../../../../../routes';
import { isClient } from '../../../../../helpers/common';

const AppMapLazy = React.lazy(() => import('../../../../../components/common/AppMap/AppMap'));

interface Props {
    coordinates: {
        latitude: number;
        longitude: number;
    };
    address: string;
}

const HotelLocation: FC<Props> = props => {
    const {address} = props;
    const {longitude, latitude} = props.coordinates;
    const history = useHistory();
    const {search, pathname, hash} = useLocation<any>();
    const {alias} = useParams<{ alias: string }>();

    const onCloseModal = () => {
        history.replace(
            generatePath(
                RouteNames.HOTELS_DETAIL,
                {alias}
            ) + search
        )
    }

    return (latitude && longitude && isClient) ? (
        <>
            {hash === '#map' && (
                <Modal
                    wrapperClassName={'hotel-location__map-popup'}
                    closeButtonElement={<ModalCloseButton onClick={onCloseModal}/>}
                    onClose={onCloseModal}
                >
                    <ModalLayoutPrimary>
                        <Suspense fallback={''}>
                            <AppMapLazy
                                coordinates={props.coordinates}
                                className="hotel-location__map-modal-container"
                                mapOptions={{dragging: true}}
                            />
                        </Suspense>

                    </ModalLayoutPrimary>
                </Modal>
            )}

            <HotelDetailContentBlock title={'Розташування'} noBorder className={'hotel-location-container'}>
                <div className="hotel-location">
                    <div className="hotel-location__address">{address}</div>

                    <div
                        className={'hotel-location__map-outer'}
                        onClick={() => history.push(pathname + search + '#map', {shallow: true})}
                    >
                        <Suspense fallback={''}>
                            <AppMapLazy
                                coordinates={props.coordinates}
                                isStatic
                                className="hotel-location__map-container"
                            />
                        </Suspense>

                    </div>
                </div>
            </HotelDetailContentBlock>
        </>
    ) : null;
};

export default HotelLocation;
