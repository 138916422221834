import { fetchData, patchData, putData, sendData } from '../helpers/network';
import {
   ProfileDataResponse,
   ProfileSetPhoneResponse,
   RequestSetPasswordParams,
   RequestSetPhoneParams, ResponseSetPasswordParams,
} from '../types/profile';

export default {
   fetchProfile: () => fetchData<ProfileDataResponse>('/auth/account'),
   editProfile: data => patchData('/auth/account', data) as Promise<ProfileDataResponse>,
   setPhone: (data:RequestSetPhoneParams) => putData('/auth/set-phone', data) as Promise<ProfileSetPhoneResponse>,
   setPassword: (data: RequestSetPasswordParams) => sendData('/auth/set-password', data) as Promise<ResponseSetPasswordParams>,
};
