import React, { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { useAppTranslate } from '../../../../../hooks/useAppTranslate';
import { generateStaticLinkToHotelsSearch } from '../../../../../helpers/hotels';

type Props = {
    isMobile?: boolean
}

const FooterPlacesList: React.FC<Props> = ({isMobile}) => {
    const {regions} = useAppSelector(state => state.initial.data);
    const [showMoreOnMobile, setShowMoreOnMobile] = useState(false);
    const {t} = useAppTranslate();

    const placesForShow = isMobile ? regions.data.slice(0, (showMoreOnMobile ? -1 : 4)) : regions.data;

    return (
        <ul>
            {placesForShow.map(region => (
                <li key={'region-' + region.id}>
                    <a href={generateStaticLinkToHotelsSearch(region)}>
                        {region.name}
                    </a>
                </li>
            ))}

            {isMobile && !showMoreOnMobile && (
                <li
                    onClick={() => setShowMoreOnMobile(true)}
                    style={{color: '#5ECBAA', opacity: '0.7'}}
                >
                    {t('Показати всі')}
                </li>
            )}
        </ul>
    )
};

export default FooterPlacesList;
