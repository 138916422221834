import React from 'react';

const RoomPriceMealIconAI: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.965 6.70492L16.555 5.29492L10.215 11.6349L11.625 13.0449L17.965 6.70492ZM22.205 5.29492L11.625 15.8749L7.445 11.7049L6.035 13.1149L11.625 18.7049L23.625 6.70492L22.205 5.29492ZM0.375 13.1149L5.965 18.7049L7.375 17.2949L1.795 11.7049L0.375 13.1149Z"/>
    </svg>
);

export default RoomPriceMealIconAI;
