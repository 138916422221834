import { useEffect } from 'react';
//import { useScrollbarWidth } from 'react-use';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { allowRootScroll } from '../../../../../helpers/common';

function preventScroll(e) {
    e.preventDefault();
    e.stopPropagation();

    return false;
}

const useModal = (goBack: () => void) => {
    const escapeByKeyboard = (e: any) => {
        if (e.key === 'Escape') goBack();
    };
    const {isMobile} = useWindowSize();
    //const scrollBarWidth = useScrollbarWidth();

    useEffect(() => {
        const scrollElement = document.body; //document.getElementById('layoutContainer');
        const fixedHeader = document.querySelector('.fixed-header') as HTMLElement;
        document.addEventListener('keyup', escapeByKeyboard);
        //allowRootScroll(false);

        // if (!isMobile) {
        //     ['wheel', 'touchmove'].forEach(eventType => {
        //         scrollElement.addEventListener(eventType, preventScroll, {passive: false});
        //     });
        // }

        return () => {
            if (fixedHeader) fixedHeader.style.right = '0px';
            document.removeEventListener('keyup', escapeByKeyboard);
            //allowRootScroll(true);
            // ['wheel', 'touchmove'].forEach(eventType => {
            //     scrollElement.removeEventListener(eventType, preventScroll);
            // });
        };
    }, [, isMobile]);

    return {};
};

export default useModal;
