import React from 'react';
import IconMap from '../../components/ui/icons/hotels/IconMap';
import IconSort from '../../components/ui/icons/hotels/IconSort';
import './IconsScreen.scss';
import IconClose from '../../components/ui/icons/modal/IconClose';
import IconBar from '../../components/ui/icons/hotels/IconBar';
//import IconCar from '../../components/ui/icons/account/IconCar';
import IconApple from '../../components/ui/icons/social/IconApple';
import IconArrowBottom from '../../components/ui/icons/icons/IconArrowBottom';
import IconArrowLeft from '../../components/ui/icons/account/IconArrowLeft';
import IconFacebook from '../../components/ui/icons/social/IconFacebook';
import IconFavorites from '../../components/ui/icons/hotels/IconFavorites';
import IconUpload from '../../components/ui/icons/icons/IconUpload';
import IconFavoriteSmall from '../../components/ui/icons/hotels/IconFavoriteSmall';
import IconTravel from '../../components/ui/icons/account/IconTravel';
import IconWithMeals from '../../components/ui/icons/hotels/IconWithMeals';

type Props = {};

const IconsScreen: React.FC<Props> = () => {
   return (
      <ul className={'icons-debug-screen'}>
         <li title={'Icon map'}>
            <IconMap />
         </li>

         <li title={'Icon sort'}>
            <IconSort />
         </li>

         <li title={'Icon Close'}>
            <IconClose />
         </li>

         <li>
            <IconBar />
         </li>

         <li>
            <IconApple />
         </li>

         <li>
            <IconArrowBottom />
         </li>

         <li>
            <IconArrowLeft />
         </li>

         <li>
            <IconFacebook />
         </li>

         <li>
            <IconFavorites isSelected />
            <IconFavorites isSelected={false} />
            <IconFavoriteSmall />
         </li>

         <li>
            <IconUpload />
         </li>

         <li>
            <IconTravel />
         </li>

         <li>
            <IconWithMeals />
         </li>
      </ul>
   );
};

export default IconsScreen;
