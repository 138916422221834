// unique id
import { RefObject } from 'react';
import { PhotoType, PhotoTypeSource } from '../types/common';
import * as CryptoJS from 'crypto-js';

export {
    getDeepDataObject,
    getNested,
    uid,
    snakeToCamel,
    convertKeysToCamelCase,
    capitalize
} from '@3ddie/react-utils';

export const numberToArray = (num: number): Array<number> => {
    if (typeof num !== 'number' || num < 0) {
        return [];
    }

    return Array.from(Array(num).keys());
};

export const isClient = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const getNumbersFromString = (inputString: string) => {
    const regex = /\d+/g;

    const matches = inputString.match(regex);

    return matches ? matches[0] : 0;
};

export const toggleValueInArray = (values: Array<any>, value: any): Array<any> => {
    let result;

    if (values.includes(value)) {
        result = values.filter(_number => _number !== value);
    } else {
        result = [...values, value];
    }

    return result;
};

export const isSSR: boolean = !(typeof window !== 'undefined' && window.document?.createElement);

export const getRefElement = <T>(element?: RefObject<Element> | T): Element | T | undefined | null => {
    if (element && 'current' in element) {
        return element.current;
    }
    //@ts-ignore
    return element;
};

export const createBemClass = (baseClass: string) => {
    return (variant?: string) => `${baseClass}${variant ? '__' + variant : ''}`;
};

export const getPercentFromValue = (value, valueFrom): number => {
    return (value / valueFrom) * 100;
};

interface WithId {
    id: number;
}

export function mergeArrayItemById<I extends WithId>(array: I[], newItem: I): I[] {
    if (!newItem) return array;

    return array.map(item => {
        if (item && 'id' in item) {
            if (newItem.id === item.id) {
                return {
                    ...item,
                    ...newItem,
                };
            } else {
                return item;
            }
        }

        return item;
    });
}

interface ReturnDataGroupBy<T> {
    [key: string]: Array<T>
}

// (groupKey: keyof I) instead of groupKey: string
export const groupBy = <I>(array: Array<I>, groupKey: string): ReturnDataGroupBy<I> => {
    if (!groupKey) {
        throw new Error('groupKey is Required')
    }

    if (!Array.isArray(array)) {
        throw new Error('param should be an array')
    }

    return array.reduce((ac, item, index) => {
        ac[item[groupKey]]
            ? ac[item[groupKey]].push(item)
            : ac[item[groupKey]] = [item];

        return ac
    }, {})
}

interface ObjOfArray {
    [key: string]: Array<any>
}

export const hasAnyPropLength = (obj: ObjOfArray): boolean => {
    const keys: Array<string> = Object.keys(obj);

    return keys.some((item: string) => obj[item].length)
}

type CreateBemClassReturnType = {
    className: string
}

//@ts-ignore
export const createBemClassProp = (baseClass: string): (variant?: string, additionalClass?: string) => CreateBemClassReturnType => {
    return (variant?: string, additionalClass?: string) => ({className: `${baseClass}${variant ? '__' + variant : ''}${additionalClass ? ' ' + additionalClass : ''}`});
}

// зафрізити код на n секунд
export const freezeStream = (duration: number): void => {
    const start = new Date().getTime();
    let end = start;

    while (end < start + duration) {
        end = new Date().getTime();
    }
}

export const getValueFromPercent = (percent, value) => {
    return (value / 100) * percent;
}

export function formatString(value, pattern) {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, _ => v[i++]);
}

export const formatCreditCard = (ccText: string) => {
    return (ccText.includes("*"))
        ? formatString(ccText.replaceAll('*', '•'), '#### ####')
        : ccText;
}

export const removePlusSymbolsFromString = (string) => string.replace(new RegExp("\\+", "g"), ' ');

export const generateSrcSet = (source: PhotoTypeSource, size: "302x170" | "560x560" | string) => {
    if (!source) return '';
    let srcSet = '';

    if (typeof source[size] === 'string') srcSet = `${source[size]}`;
    if (typeof source[`${size}@2x`] === 'string') srcSet += `, ${source[`${size}@2x`]} 2x`;

    return srcSet;
}

export const formatPhone = (phone, withMask: boolean = false): string => {
    const prefixes = ['+38', '38'];
    let result = '';

    if (phone.startsWith(prefixes[0])) {
        result = phone.slice(prefixes[0].length)
    }

    if (phone.startsWith(prefixes[1])) {
        result = phone.slice(prefixes[1].length)
    }

    return withMask ? `(${result.slice(0, 3)}) XXX-XX-XX` :
        `(${result.slice(0, 3)}) ${result.slice(3, 6)} ${result.slice(6, 8)} ${result.slice(8, 10)}`
}

export const scrollToTop = (scrollEl?, timeout = 0) => {
    ///console.log();
    //const el = scrollEl || document.getElementById('layoutContainer');
    const el = scrollEl || window;

    setTimeout(() => {
        el.scrollTo({top: 0});
    }, timeout);
}

export const allowRootScroll = (allowScroll = true) => {
    if (isClient && window) {
        //const el = document.getElementById('layoutContainer');
        const body = document.body;
        //
        body.style.overflow = allowScroll ? 'auto' : 'hidden';

        // body.style.overflow = allowScroll ? 'auto' : 'hidden';
        // document.getElementsByTagName('html')[0].style.overflow = allowScroll ? 'auto' : 'hidden';
    }
}

// const secret = 'BrDkGP@8UA';
//
// export const cryptJson = (obj: object): string => {
//     const json = JSON.stringify(obj);
//     return CryptoJS.AES.encrypt(json, secret).toString();
// }

export const formatCreditCardNumber = (value: string): string => {
    return value.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
}


