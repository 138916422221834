import React, { FC, useState } from 'react';
import './HotelReviewsModal.scss';
import Modal from '../../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import Stars from '../../../../../../components/ui/icons/hotels/Stars';
import IconSortButton from '../../../../../../components/ui/icons/icons/IconSortButton';
import HotelCommentItem from '../HotelReviewItem/HotelReviewItem';
import { Review } from "../../../../../../types/reviews";
import HotelsSortingDropdown from '../../../../HotelList/components/HotelsSortingDropdown/HotelsSortingDropdown';
import FieldRadio from '../../../../../../components/ui/forms/fields/FieldRadio/FieldRadio';
import OutsideClick from '../../../../../../components/common/OutsideClick/OutsideClick';
import AppMedia from '../../../../../../components/common/Media/Media';

interface Props {
    comments: Array<Review>;
    onClose: () => void;
}

//Todo: change mock data to server data when it will be available
const sortingOptions = [
    {
        id: 1,
        name: 'Рекомендовані'
    },
    {
        id: 2,
        name: 'Спочатку нові'
    },
    {
        id: 3,
        name: 'Спочатку старі'
    },
    {
        id: 4,
        name: 'Спочатку (найвища оцінка)'
    },
    {
        id: 5,
        name: 'Спочатку (найшижча оцінка)'
    },
];

const HotelReviewsModal: FC<Props> = ({onClose, comments}) => {
    const [showSorting, setShowSorting] = useState(false);

    const sortingContent = sortingOptions.map(sortingOption => (
        <FieldRadio
            key={'sortingOption' + sortingOption.id}
            name={'reviewSortingOption'}
            label={sortingOption.name}
            onChange={() => {}}
            //checked={orderBy === String(sortingOption.id)}
        />
    ))

    return (
        <>
            <Modal className="hotel-comments-modal" onClose={onClose}>
                <ModalLayoutPrimary title="Відгуки">
                    <div className="hotel-comments-modal__header">
                        <div className={'hotel-comments-modal__rating-container'}>
                            <Stars stars={4} color={'#fff'}/>
                        </div>

                        <div className="hotel-comments-modal__title">
                            {comments.length} відгуків
                        </div>

                        <div className="hotel-comments-modal__sorting-button-wrap">
                            <button
                                className="hotel-comments-modal__sorting-button"
                                onClick={() => setShowSorting(true)}
                            >
                                <IconSortButton/>
                            </button>
                            {/*@ts-ignore*/}
                            <AppMedia.Media greaterThanOrEqual={'md'}>
                                {showSorting && <div className={'hotel-comments-modal__sorting-list'}>
                                   <OutsideClick onOutsideClick={() => setShowSorting(false)}>
                                       {sortingContent}
                                   </OutsideClick>
                                </div>}
                            </AppMedia.Media>
                        </div>

                    </div>

                    <div className="hotel-comments-modal__list">
                        {comments.map((el: Review, index) => {
                            return <HotelCommentItem
                                key={'commentItem' + index}
                                review={el}
                                isModal={true}
                            />;
                        })}
                    </div>


                </ModalLayoutPrimary>
            </Modal>
            {/*@ts-ignore*/}
            <AppMedia.Media lessThan={'md'}>
                {showSorting && <Modal className="hotel-comments-modal" onClose={() => setShowSorting(false)}>
                   <ModalLayoutPrimary title="Сортувати за">
                       {sortingContent}
                   </ModalLayoutPrimary>
                </Modal>}
            </AppMedia.Media>


        </>
    );
};

export default HotelReviewsModal;
