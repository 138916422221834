import React, { FC } from 'react';
import './HotelDetailPhoneOrder.scss';
import HotelCallbackBlock from "../HotelCallbackBlock/HotelCallbackBlock";

interface IProps {
  isMobile?: boolean
}

const HotelDetailPhoneOrder:FC<IProps> = ({isMobile}) => {

  return (
    <HotelCallbackBlock isMobile={isMobile} />
  );
};

export default HotelDetailPhoneOrder;
