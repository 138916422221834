import React, { FC } from 'react';
import './ButtonSecondary.scss';

interface Props {
    icon?: any;
    text?: string;
    onClick?: (e: any) => void;
    badgeContent?: any
    disabled?: boolean
    className?: string
}

const ButtonSecondary: FC<Props> = props => {
    const {icon, text, badgeContent, onClick, disabled, className, ...rest} = props;

    return (
        <button
            className={`button-secondary ${className}`}
            onClick={(e) => {
                if (onClick && !disabled) onClick(e);
            }}
            disabled={disabled}
            {...rest}
        >
            <div className="button-secondary__block">
                <div className="button-secondary__icon">{icon}</div>
                {text && <div className="button-secondary__text">{text}</div>}

                {badgeContent ? <div className={'button-secondary__badge-content'}>{badgeContent}</div> : null}
            </div>
        </button>
    );
};
export default ButtonSecondary;
