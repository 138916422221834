import React, { FC } from "react";

interface Props {
    size?: number
   color?: string
}

const IconUpload: FC<Props> = ({size = 15, color = '#C5C6CF'}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 15 15"  xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.2293 2.91925V9.6293C8.2293 10.0159 7.9159 10.3293 7.5293 10.3293C7.1427 10.3293 6.8293 10.0159 6.8293 9.6293V2.91925L4.52427 5.22427C4.2509 5.49764 3.80769 5.49764 3.53432 5.22427C3.26096 4.9509 3.26096 4.50769 3.53432 4.23432L7.03432 0.734322C7.30769 0.460955 7.7509 0.460955 8.02427 0.734322L11.5243 4.23432C11.7976 4.50769 11.7976 4.9509 11.5243 5.22427C11.2509 5.49764 10.8077 5.49764 10.5343 5.22427L8.2293 2.91925ZM13.1293 9.6293C13.1293 9.2427 13.4427 8.9293 13.8293 8.9293C14.2159 8.9293 14.5293 9.2427 14.5293 9.6293V12.4293C14.5293 13.5891 13.5891 14.5293 12.4293 14.5293H2.6293C1.4695 14.5293 0.529297 13.5891 0.529297 12.4293V9.6293C0.529297 9.2427 0.842698 8.9293 1.2293 8.9293C1.6159 8.9293 1.9293 9.2427 1.9293 9.6293V12.4293C1.9293 12.8159 2.2427 13.1293 2.6293 13.1293H12.4293C12.8159 13.1293 13.1293 12.8159 13.1293 12.4293V9.6293Z"
                fill={color}
            />
        </svg>
    )
}
export default IconUpload


