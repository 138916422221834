import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import classnames from 'classnames';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import './HotelsList.scss';

import { getHotelsSelector } from '../../../store/hotels/hotels-selector';
import { getHotelsNextPageThunk, getHotelsThunk } from '../../../store/hotels/hotels-thunk';
import HotelItems from './HotelsItems/HotelItems';
import { isClient, scrollToTop } from '../../../helpers/common';
import { serializeQueryStringParameter } from '../../../helpers/url';
import Container from '../../../components/ui/layouts/Container/Container';
import Page from '../../../components/common/Page/Page';

import useScroll from '../../../hooks/useScroll';
import HotelsListLoadMoreSection from './components/HotelsListLoadMoreSection/HotelsListLoadMoreSection';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useSearchForm from '../common/HotelSearchForm/hooks/useSearchForm';
import HotelsFilter from './components/HotelsFilter/HotelsFilter';
import { useHotelsFiltersVisibility } from './useHotelsFiltersVisibility';
import { RouteNames } from '../../../routes';
import Head from '../../../components/layout/components/Head/Head';
import { setLastFetchedPlace } from '../../../store/hotels/hotels-reducer';

const HotelsMapLazy = React.lazy(() => import('./HotelsMap/HotelsMap'));

const HotelList = () => {
    const hotels = useSelector(getHotelsSelector);
    const {lastSavedPlaceId} = useAppSelector(state => state.hotels);
    const {query, place} = useSearchForm();

    const {isFetching, data, isNextPageFetching} = hotels;

    const {scrollDirection, scrollY} = useScroll();
    const [scrolledDown, setScrolledDown] = useState(false);
    const history = useHistory();
    const {search} = useLocation();
    const showMap = useRouteMatch(RouteNames.HOTELS_LIST_MAP);
    const [lastSearchSaved, setLastSearchSaved] = useState(search);

    const closeMap = () => history.replace(RouteNames.HOTELS_LIST + search);
    const openMap = () => history.push(RouteNames.HOTELS_LIST_MAP + search);

    const getHotels = (search?) => {
        if (isClient && !showMap) dispatch(getHotelsThunk(search || query));
        dispatch(setLastFetchedPlace(place));
    }

    useEffect(() => {
        if (isClient) scrollToTop(null, 100);

        if (place.value !== lastSavedPlaceId) getHotels();

        // if ((!data?.data?.length && hotels.isFetchedOnServer) || (lastFetchedPlace && lastFetchedPlace?.value !== place?.value)) {
        //     getHotels();
        // }
    }, []);

    useEffect(() => {
        if (search !== lastSearchSaved && !showMap) {
            getHotels(search);
            setLastSearchSaved(search);
        }
    }, [search])

    useEffect(() => {
        setScrolledDown(scrollDirection === 'down');
    }, [scrollDirection]);

    const dispatch = useAppDispatch();
    const nextLink = hotels?.data?.meta?.pagination?.links?.next;

    const {filtersInitData, isOpenFilters, closeFilters} = useHotelsFiltersVisibility();

    const classNames = classnames({
            'page--map': showMap,
            'hotels-list-page': true,//!showMap,
            'scrolled-down': scrolledDown,
            'header-with-shadow': scrollY > 74,
        }
    );

    const title = `Готелі, ${place.label} - Бронюй.com, готелі в Україні`
    const description = "Перевірені готелі в Україні. Бронюй напряму без комісій та посередників."

    return (
        <Page className={classNames}>
            <Head
                ogImage="/images/index/kyiv.png"
                ogTitle={title}
                metaKeywords='готель, номер, житло, подорож'
                title={title}
                metaDescription={description}
                ogDescription={description}
            />

            {isClient && (
                <Suspense fallback={''}>
                    {showMap && <HotelsMapLazy onCloseMap={closeMap}/>}
                </Suspense>
            )}

            {!showMap && (
                <div className='hotels-list-page__list-container'>
                    <HotelItems
                        hotels={data}
                        isFetching={isFetching}
                        search={search}
                        onClickOnMapButton={openMap}
                    />

                    {(!showMap && !isFetching && nextLink) && (
                        <Container>
                            <HotelsListLoadMoreSection
                                isLoading={isNextPageFetching}
                                onClick={() => {
                                    dispatch(getHotelsNextPageThunk(nextLink))
                                }}
                            />
                        </Container>
                    )}
                </div>
            )}

            {(isOpenFilters && filtersInitData) && (
                <HotelsFilter
                    onClose={closeFilters}
                    filtersInitData={filtersInitData}
                />
            )}
        </Page>
    );
};

HotelList.getInitialProps = ({store, req, location}) => {
    if (!isClient) {
        const query = serializeQueryStringParameter(req.query);

        return store
            .dispatch(getHotelsThunk(query))
            .then(unwrapResult)
            .catch(error => {
                console.log(error.status);
                //return {statusCode: 404};
            });
    }
};

export default React.memo(HotelList);
