
import React from "react";

const IconSocialYouTube = () => {
return(
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="25" fill="#F4F2F6"/>
    <path d="M34.2758 19.6445C34.0648 18.8008 33.3969 18.1328 32.5883 17.9219C31.0766 17.5 25.1 17.5 25.1 17.5C25.1 17.5 19.0883 17.5 17.5766 17.9219C16.768 18.1328 16.1 18.8008 15.8891 19.6445C15.4672 21.1211 15.4672 24.2852 15.4672 24.2852C15.4672 24.2852 15.4672 27.4141 15.8891 28.9258C16.1 29.7695 16.768 30.4023 17.5766 30.6133C19.0883 31 25.1 31 25.1 31C25.1 31 31.0766 31 32.5883 30.6133C33.3969 30.4023 34.0648 29.7695 34.2758 28.9258C34.6977 27.4141 34.6977 24.2852 34.6977 24.2852C34.6977 24.2852 34.6977 21.1211 34.2758 19.6445ZM23.1313 27.1328V21.4375L28.1234 24.2852L23.1313 27.1328Z" fill="#C5C6CF"/>
  </svg>
)
};


export default IconSocialYouTube