import React, { FC } from 'react';
// import './IconNoPhoto.scss';

interface Props {

}

const IconNoPhoto: FC<Props> = (props) => {

   return (
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect width="64" height="64" rx="8" fill="#F4F2F6"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M44.2801 26.5313V37.3993C44.2801 41.426 41.7601 44.266 37.7334 44.266H26.2001C22.1734 44.266 19.6667 41.426 19.6667 37.3993V26.5313C19.6667 22.5047 22.1867 19.666 26.2001 19.666H37.7334C41.7601 19.666 44.2801 22.5047 44.2801 26.5313Z" stroke="#C5C6CF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
         <path d="M23.0417 37.9071L25.0791 35.7564C25.7871 35.0058 26.9671 34.9698 27.7191 35.6764C27.7417 35.6991 28.9684 36.9458 28.9684 36.9458C29.7084 37.6991 30.9177 37.7111 31.6711 36.9724C31.7204 36.9244 34.7831 33.2098 34.7831 33.2098C35.5724 32.2511 36.9897 32.1138 37.9497 32.9044C38.0137 32.9578 40.9071 35.9271 40.9071 35.9271" stroke="#C5C6CF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M29.7503 28.1766C29.7503 29.4686 28.7037 30.5152 27.4117 30.5152C26.1197 30.5152 25.073 29.4686 25.073 28.1766C25.073 26.8846 26.1197 25.8379 27.4117 25.8379C28.7037 25.8392 29.7503 26.8846 29.7503 28.1766Z" stroke="#C5C6CF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

   );
};

export default IconNoPhoto;