import React from "react";
import {Link} from "react-router-dom";
import "./Breadcrumbs.scss";


interface Props {
  breadcrumbs: Array<{
    title: string;
    url?: string
  }>
}

const Breadcrumbs = ({breadcrumbs}: Props) => {
  return (
    <ul className="breadcrumbs">
      {breadcrumbs.map((item, index) => {
        const {title, url} = item;
        return (
          <li className="breadcrumbs__item" key={index}>
            {url ? <Link to={url} className={'breadcrumbs__item-text'}>{title}</Link> :
              <span className={'breadcrumbs__item-text'}>{title}</span>}
          </li>
        )
      })}
    </ul>
  )
}

export default Breadcrumbs
