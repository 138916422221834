import React from "react";
import "./Contacts.scss";
import ContactForm from "./components/ContactForm/ContactForm";
import ContactsHeader from "./components/ContactsHeader/ContactsHeader";
import ContactsBlock from "./components/ContactsBlock/ContactsBlock";
// import PageHeader from "../Account/common/PageHeader/PageHeader";
import Page from "../../components/common/Page/Page";
import Container from "../../components/ui/layouts/Container/Container";



const Contacts = () => {
  return (
    <Page>
        <div className="contacts">
          <div className="contacts__header">
            <ContactsHeader/>
          </div>
          <Container>
          <div className="contacts-body">
            <div className="contacts-body__form">
              <ContactForm/>
            </div>
            <div className="contacts-body__block">
              <ContactsBlock/>
            </div>
          </div>
          </Container>
        </div>
    </Page>

  )
}

export default Contacts;