import React from 'react';

const IconHotelBackground = () => {
   return (
      <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
         <g clipPath="url(#clip0_8198_162776)">
            <path d="M41.6313 36.7957H51.9548V51.3054H41.6347V36.7957H41.6313Z" fill="#E0E2EE" />
            <path d="M119.045 36.7957H129.366V51.3054H119.045V36.7957Z" fill="#E0E2EE" />
            <path
               d="M132.211 36.7957H38.7889C33.0878 36.7957 28.4688 32.2004 28.4688 26.5359V10.2631C28.4688 4.59531 33.0911 0 38.7889 0H132.211C137.912 0 142.531 4.59531 142.531 10.2631V26.5359C142.531 32.2037 137.909 36.799 132.211 36.799V36.7957Z"
               fill="#F1F2F7"
            />
            <path
               d="M132.211 0H121.891C127.592 0 132.211 4.59531 132.211 10.2631V26.5359C132.211 32.2037 127.589 36.7957 121.891 36.7957H132.211C137.912 36.7957 142.531 32.2004 142.531 26.5359V10.2631C142.535 4.59531 137.912 0 132.211 0Z"
               fill="#E8EBF2"
            />
            <path
               d="M121.841 23.1556H121.83C120.92 23.1613 119.965 23.1641 119.147 23.167V11.4552C119.147 10.2449 118.161 9.26416 116.943 9.26416C115.726 9.26416 114.739 10.2449 114.739 11.4552V25.3466C114.739 26.4152 115.512 27.3251 116.573 27.5065C116.729 27.532 116.832 27.549 118.423 27.549C119.178 27.549 120.268 27.5461 121.856 27.5376C123.073 27.532 124.054 26.5456 124.045 25.3353C124.037 24.1307 123.053 23.1585 121.841 23.1585V23.1556Z"
               fill="#E0E2EE"
            />
            <path
               d="M54.9576 9.26416C53.7401 9.26416 52.7537 10.2449 52.7537 11.4552V15.9789H47.482V11.4552C47.482 10.2449 46.4955 9.26416 45.2781 9.26416C44.0607 9.26416 43.0742 10.2449 43.0742 11.4552V25.3608C43.0742 26.5711 44.0607 27.5518 45.2781 27.5518C46.4955 27.5518 47.482 26.5711 47.482 25.3608V20.3609H52.7537V25.3608C52.7537 26.5711 53.7401 27.5518 54.9576 27.5518C56.175 27.5518 57.1614 26.5711 57.1614 25.3608V11.4552C57.1614 10.2449 56.175 9.26416 54.9576 9.26416Z"
               fill="#E0E2EE"
            />
            <path
               d="M108.133 13.7709C109.351 13.7709 110.337 12.7902 110.337 11.5799C110.337 10.3696 109.351 9.38892 108.133 9.38892H102.294C101.077 9.38892 100.09 10.3696 100.09 11.5799V25.358C100.09 26.5683 101.077 27.549 102.294 27.549H108.133C109.351 27.549 110.337 26.5683 110.337 25.358C110.337 24.1477 109.351 23.167 108.133 23.167H104.495V20.6586H107.7C108.917 20.6586 109.904 19.6778 109.904 18.4675C109.904 17.2573 108.917 16.2765 107.7 16.2765H104.495V13.7681H108.133V13.7709Z"
               fill="#E0E2EE"
            />
            <path
               d="M93.4817 9.26416H85.7581C84.5407 9.26416 83.5542 10.2449 83.5542 11.4552C83.5542 12.6655 84.5407 13.6462 85.7581 13.6462H87.4032V25.3608C87.4032 26.5711 88.3896 27.5518 89.6071 27.5518C90.8245 27.5518 91.811 26.5711 91.811 25.3608V13.6462H93.4845C94.702 13.6462 95.6884 12.6655 95.6884 11.4552C95.6884 10.2449 94.702 9.26416 93.4845 9.26416H93.4817Z"
               fill="#E0E2EE"
            />
            <path
               d="M70.7616 9.26416C65.6895 9.26416 61.564 13.3656 61.564 18.4051C61.564 23.4447 65.6895 27.5461 70.7616 27.5461C75.8337 27.5461 79.9563 23.4447 79.9563 18.4051C79.9563 13.3656 75.8308 9.26416 70.7616 9.26416ZM70.7616 23.1698C68.1215 23.1698 65.9717 21.0327 65.9717 18.408C65.9717 15.7833 68.1215 13.6462 70.7616 13.6462C73.4017 13.6462 75.5514 15.7833 75.5514 18.408C75.5514 21.0327 73.4017 23.1698 70.7616 23.1698Z"
               fill="#E0E2EE"
            />
            <path
               d="M163.258 154.58H7.7417V57.3218C7.7417 51.654 12.364 47.062 18.0618 47.062H152.935C158.636 47.062 163.255 51.6573 163.255 57.3218V154.58H163.258Z"
               fill="#F1F2F7"
            />
            <path
               d="M152.938 47.0588H142.618C148.319 47.0588 152.938 51.6542 152.938 57.3186V154.581H163.258V57.3219C163.258 51.6542 158.636 47.0588 152.938 47.0588Z"
               fill="#E8EBF2"
            />
            <path
               d="M105.516 154.58H65.4844V109.371C65.4844 107.482 67.024 105.951 68.9244 105.951H102.076C103.976 105.951 105.516 107.482 105.516 109.371V154.58Z"
               fill="#E8EBF2"
            />
            <path
               d="M102.075 105.951H91.7554C93.6557 105.951 95.1954 107.482 95.1954 109.371V154.58H105.516V109.371C105.516 107.482 103.976 105.951 102.075 105.951Z"
               fill="#E0E2EE"
            />
            <path
               d="M88.0814 154.581V106.323C88.0814 106.197 88.0681 106.071 88.0514 105.948H82.9514C82.9347 106.071 82.9214 106.194 82.9214 106.323V154.581H88.0814Z"
               fill="white"
            />
            <path
               d="M64.723 95.6881H106.314C107.643 95.6881 108.722 94.6157 108.722 93.2942V59.7125C108.722 58.3911 107.643 57.3186 106.314 57.3186H64.723C63.3937 57.3186 62.3149 58.3911 62.3149 59.7125V93.2942C62.3149 94.6157 63.3937 95.6881 64.723 95.6881Z"
               fill="#E8EBF2"
            />
            <path
               d="M88.1015 73.9399V57.3218H82.9414V73.9399H62.3179V79.0698H82.9414V95.688H88.1015V79.0698H108.725V73.9399H88.1015Z"
               fill="white"
            />
            <path
               d="M106.317 57.3218H95.9971C97.3263 57.3218 98.4051 58.3942 98.4051 59.7157V93.294C98.4051 94.6155 97.3263 95.688 95.9971 95.688H106.317C107.646 95.688 108.725 94.6155 108.725 93.294V59.7157C108.725 58.3942 107.646 57.3218 106.317 57.3218Z"
               fill="#E0E2EE"
            />
            <path d="M98.4019 73.9399H108.722V79.0698H98.4019V73.9399Z" fill="#F1F2F7" />
            <path
               d="M121.453 95.6881H150.527C151.856 95.6881 152.935 94.6157 152.935 93.2942V59.7125C152.935 58.3911 151.856 57.3186 150.527 57.3186H121.453C120.124 57.3186 119.045 58.3911 119.045 59.7125V93.2942C119.045 94.6157 120.124 95.6881 121.453 95.6881Z"
               fill="#E8EBF2"
            />
            <path
               d="M150.527 57.3218H140.207C141.536 57.3218 142.615 58.3942 142.615 59.7157V93.294C142.615 94.6155 141.536 95.688 140.207 95.688H150.527C151.856 95.688 152.935 94.6155 152.935 93.294V59.7157C152.935 58.3942 151.856 57.3218 150.527 57.3218Z"
               fill="#E0E2EE"
            />
            <path
               d="M138.57 73.9399V57.3218H133.41V73.9399H119.045V79.0698H133.41V95.688H138.57V79.0698H152.935V73.9399H138.57Z"
               fill="white"
            />
            <path d="M142.615 73.9399H152.935V79.0698H142.615V73.9399Z" fill="#F1F2F7" />
            <path
               d="M121.453 144.321H150.527C151.856 144.321 152.935 143.248 152.935 141.927V108.348C152.935 107.027 151.856 105.954 150.527 105.954H121.453C120.124 105.954 119.045 107.027 119.045 108.348V141.93C119.045 143.251 120.124 144.324 121.453 144.324V144.321Z"
               fill="#E8EBF2"
            />
            <path
               d="M150.527 105.951H140.207C141.536 105.951 142.615 107.023 142.615 108.345V141.923C142.615 143.245 141.536 144.317 140.207 144.317H150.527C151.856 144.317 152.935 143.245 152.935 141.923V108.345C152.935 107.023 151.856 105.951 150.527 105.951Z"
               fill="#E0E2EE"
            />
            <path
               d="M138.57 122.569V105.951H133.41V122.569H119.045V127.699H133.41V144.317H138.57V127.699H152.935V122.569H138.57Z"
               fill="white"
            />
            <path d="M142.615 122.569H152.935V127.699H142.615V122.569Z" fill="#ECF9F5" />
            <path
               d="M20.473 95.6881H49.5463C50.8756 95.6881 51.9543 94.6157 51.9543 93.2942V59.7125C51.9543 58.3911 50.8756 57.3186 49.5463 57.3186H20.473C19.1437 57.3186 18.0649 58.3911 18.0649 59.7125V93.2942C18.0649 94.6157 19.1437 95.6881 20.473 95.6881Z"
               fill="#E8EBF2"
            />
            <path
               d="M49.5462 57.3218H39.2261C40.5553 57.3218 41.6341 58.3942 41.6341 59.7157V93.294C41.6341 94.6155 40.5553 95.688 39.2261 95.688H49.5462C50.8755 95.688 51.9542 94.6155 51.9542 93.294V59.7157C51.9542 58.3942 50.8755 57.3218 49.5462 57.3218Z"
               fill="#E0E2EE"
            />
            <path
               d="M37.5897 73.9399V57.3218H32.4296V73.9399H18.0649V79.0698H32.4296V95.688H37.5897V79.0698H51.9543V73.9399H37.5897Z"
               fill="white"
            />
            <path d="M41.6309 73.9399H51.9543V79.0698H41.6342V73.9399H41.6309Z" fill="#F1F2F7" />
            <path
               d="M20.473 144.321H49.5463C50.8756 144.321 51.9543 143.248 51.9543 141.927V108.348C51.9543 107.027 50.8756 105.954 49.5463 105.954H20.473C19.1437 105.954 18.0649 107.027 18.0649 108.348V141.93C18.0649 143.251 19.1437 144.324 20.473 144.324V144.321Z"
               fill="#E8EBF2"
            />
            <path
               d="M49.5462 105.951H39.2261C40.5553 105.951 41.6341 107.023 41.6341 108.345V141.923C41.6341 143.245 40.5553 144.317 39.2261 144.317H49.5462C50.8755 144.317 51.9542 143.245 51.9542 141.923V108.345C51.9542 107.023 50.8755 105.951 49.5462 105.951Z"
               fill="#E0E2EE"
            />
            <path
               d="M37.5897 122.569V105.951H32.4296V122.569H18.0649V127.699H32.4296V144.317H37.5897V127.699H51.9543V122.569H37.5897Z"
               fill="white"
            />
            <path d="M41.6309 122.569H51.9543V127.699H41.6342V122.569H41.6309Z" fill="#F1F2F7" />
            <path
               d="M167.56 170H3.44004C1.53967 170 0 168.469 0 166.58V158.004C0 156.114 1.53967 154.584 3.44004 154.584H167.56C169.46 154.584 171 156.114 171 158.004V166.58C171 168.469 169.46 170 167.56 170Z"
               fill="#E8EBF2"
            />
            <path
               d="M167.56 154.581H157.24C159.14 154.581 160.68 156.111 160.68 158V166.58C160.68 168.469 159.14 170 157.24 170H167.56C169.46 170 171 168.469 171 166.58V158C171 156.111 169.46 154.581 167.56 154.581Z"
               fill="#E0E2EE"
            />
         </g>
         <defs>
            <clipPath id="clip0_8198_162776">
               <rect width="171" height="170" fill="white" />
            </clipPath>
         </defs>
      </svg>
   );
};
export default IconHotelBackground;
