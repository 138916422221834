import React from 'react';
import './HotelsListLoader.scss';
import ContentLoader from 'react-content-loader';
import useWindowSize from '../../../../../hooks/useWindowSize';

const HotelsListLoader = () => {
    const {isMobile} = useWindowSize();
    const width = isMobile ? window?.innerWidth : 302;

    //console.log(window?.innerWidth);

    return (
        <div className={'hotels-list-loader hotels-list'}>
            {[1, 2, 3, 4, 5, 6, 7, 8].slice(0, isMobile ? 3 : 8).map(item => (
                    <div
                        key={'contentLoader' + item}
                        className={'hotels-list-loader__item hotels-list__item'}
                    >
                        <ContentLoader
                            speed={2}
                            width={width}
                            height={273}
                            viewBox={`0 0 ${width} 273`}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="0" y="0" rx="8" ry="8" width={width} height="204"/>
                            <rect x="0" y="219" rx="7" ry="7" width={width / 2} height="15"/>
                            <rect x="0" y="242" rx="6" ry="6" width="78" height="11"/>

                            <rect x="0" y="260" rx="9" ry="9" width="118" height="11"/>
                            <rect x={width - 80} y="260" rx="6" ry="6" width="80" height="11"/>

                        </ContentLoader>
                    </div>
                )
            )}
        </div>
    );
}

export default React.memo(HotelsListLoader);
