import React, { FC } from 'react';
import './HotelWishList.scss';
import Page from '../../../components/common/Page/Page';
import { getWishListPublicThunk } from '../../../store/wishListPublic/wishListPublic-thunks';
import { useSelector } from 'react-redux';
import { getWishListPublicSelector } from '../../../store/wishListPublic/wishListPublic-selectors';
import HotelListItem from '../HotelList/HotelListItem/HotelListItem';
import AccountListIsEmpty from '../../Account/common/AccountListIsEmpty/AccountListIsEmpty';
import Container from '../../../components/ui/layouts/Container/Container';
import PageHeader from '../../Account/common/PageHeader/PageHeader';

interface IHotelDetail extends FC {
   getInitialProps: any;
}

const HotelWishList: IHotelDetail = () => {
   const { data, isFetching } = useSelector(getWishListPublicSelector);
   const { hotels, id, name } = data;
   const breadcrumbs = [
      {
         url: '/',
         title: 'Головна',
      },
      {
         title: name,
      },
   ];

   const renderView = () => {
      if (!isFetching && hotels.data.length) {
         return (
            <div className="hotel-wish-list__list">
               {hotels.data.map(item => {
                  return <HotelListItem hotelItem={item} key={item.id} />;
               })}
            </div>
         );
      } else if (!isFetching && !hotels.data.length) {
         return (
            <AccountListIsEmpty
               title=""
               description="Створіть свій перший список обраного"
               additional="Під час пошуку натискайте значок серця, щоб зберігати улюблені готелі до обраного."
            />
         );
      } else {
         return <h1>LOADING..............</h1>;
      }
   };
   return (
      <Page>
         <Container>
            <PageHeader breadcrumbs={breadcrumbs} title={`${name}`} />
            <div className="hotel-wish-list">{renderView()}</div>
         </Container>
      </Page>
   );
};

HotelWishList.getInitialProps = ({ store, match }) => {
   const { id } = match.params;
   return store
      .dispatch(getWishListPublicThunk(id))
      .then(data => {})
      .catch(error => {});
};

export default HotelWishList;
