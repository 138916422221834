import React, { FC, useState } from 'react';
import './HotelsListHeader.scss';

import HotelsListActions from '../components/HotelsListActions/HotelsListActions';
import HotelsSortingDropdown from '../components/HotelsSortingDropdown/HotelsSortingDropdown';
import Container from '../../../../components/ui/layouts/Container/Container';
import { removePlusSymbolsFromString } from '../../../../helpers/common';
import useWindowSize from '../../../../hooks/useWindowSize';

interface Props {
    city: string;
    numberOfHotels: number;
    onClickOnMapButton: () => void;
    isNoHotels?: boolean
}

const HotelsListHeader: FC<Props> = props => {
    const {city, numberOfHotels, onClickOnMapButton} = props;
    const [isOpenSorting, setIsOpenSorting] = useState(false);

    const {isMobile} = useWindowSize();

    return (
        <div className='hotels-list-header'>
            <Container>
                <div className="hotels-list-header__text">
                    {removePlusSymbolsFromString(city)}
                    {/*: понад {numberOfHotels} готелів*/}
                </div>

                <div className="hotels-list-header__buttons">
                    {/*{!props.isNoHotels && (*/}
                    <HotelsListActions
                        onClickOnSort={() => setIsOpenSorting(true)}
                        onClickOnMapButton={onClickOnMapButton}
                        isNoHotels={props.isNoHotels}
                    />
                    {/*)}*/}

                    {isOpenSorting && (
                        <HotelsSortingDropdown
                            onClose={() => setIsOpenSorting(false)}
                            isModalMode={isMobile}
                        />
                    )}
                </div>
            </Container>
        </div>
    );
}

export default HotelsListHeader;
