import React, { FC } from 'react';
import './StarsRating.scss';
// @ts-ignore
import rateImg from '../../../../assets/images/icons/rate-star.svg';

interface Props {
   stars: number;
   text?: string;
   color?: 'white' | 'violet';
}

const StarsRating: FC<Props> = props => {
   const { stars, text, color } = props;
   return (
      <div className={'stars-rating'}>
         <img src={rateImg} alt={''} />

         <div className="stars-rating__stars-text">{stars}</div>
         <span className="stars-rating__text">{text}</span>
      </div>
   );
};

export default StarsRating;
