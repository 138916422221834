import React, { FC } from "react"
import ModalLayoutPrimary
  from "../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary";
import AppButton from "../../../../components/ui/components/Buttons/AppButton/AppButton";
import Modal from "../../../../components/ui/modals/Modal/Modal";
import "./ShouldAuthModal.scss";

interface IProps {
  onClose: () => void;
  title: string;
}


const ShouldAuthModal: FC<IProps> = ({ onClose,title }) => {
  return (
    <Modal onClose={onClose} className="should-auth-modal" disableFullSizeOnMobile>
      <ModalLayoutPrimary title="Увійдіть або зареєструйтеся" noPadding>
        <div className="should-auth-modal__wrapper">
          <h2>{title}</h2>
          <div className="should-auth-modal__navigation">
            <AppButton theme="outline" to="/auth/signup">Зареєструватися</AppButton>
            <AppButton to="/auth/signin">Увійти </AppButton>
          </div>
        </div>

      </ModalLayoutPrimary>
    </Modal>
  )
}

export default ShouldAuthModal;
