import React from 'react';
import { RoomMeal } from '../../../types/rooms';
import PriceViewer from '../../ui/components/PriceViewer/PriceViewer';
import { pluralize } from '../../../helpers/translatioHelpers';
import './HotelPriceWithMeal.scss';

type Props = {
    mealType?: RoomMeal,
    price: number,
    oldPrice?: number,
    fromMap?: boolean
}

const HotelPriceWithMeal: React.FC<Props> = ({price, fromMap, oldPrice, mealType}) => {
    return (
        <>
            {!!price && (
                <div className='price-container-prices'>
                    {(oldPrice && oldPrice > price) ? (
                        <div>
                            <PriceViewer price={oldPrice} className="price-container-prices__discount"/>

                            <span className="price-container-prices__discount">
                                &nbsp;/ {pluralize('ніч', 1, true)}
                            </span>
                        </div>
                    ) : null}

                    <div>
                        <PriceViewer price={price} className="price-container-prices__price"/>
                        <span className="price-container-prices__days">&nbsp;/ {pluralize('ніч', 1, true)} </span>
                    </div>
                </div>
            )}

            {mealType && (
                <div className="price-container-prices__meals">
                    {mealType.name}
                </div>
            )}
        </>
    )
};

export default HotelPriceWithMeal;
