import React, {FC} from "react";

interface Props {

}

const IconInfo: FC<Props> = (props) => {

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z" stroke="#E05233" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 3.75V7.75" stroke="#E05233" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.99805 9.7998H7.00805" stroke="#E05233" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default IconInfo