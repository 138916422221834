import React, {FC} from "react";
import "./ProfilePreviewForm.scss";

interface Props {
  list: Array<any>
}

const ProfilePreviewForm: FC<Props> = (props) => {
  const {list} = props;

  return (
    <ul className="profile-preview-form">
      {
        list.map((item, index) => {
          return (
            <li key={index}>{item}</li>
          )
        })
      }
    </ul>
  )
}

export default ProfilePreviewForm