import React, { FC } from 'react';
import './AccountOrdersDetailPrime.scss';
import StatusOrder from '../../ui/status/StatusOrder/StatusOrder';
import CustomCarousel from '../../../../../../../components/ui/components/CustomCarousel/CustomCarousel';
import Image from '../../../../../../../components/ui/components/Image/Image';
import ClickableArrowItem from '../../../../../../../components/common/ClickableArrowItem/ClickableArrowItem';
import TextWithIcon from '../../TextWithIcon/TextWithIcon';
import IconTimeTable from '../../ui/icons/IconTimeTable';
import IconHome from '../../ui/icons/IconHome';
import AppButton from '../../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import { useHistory } from 'react-router-dom';

interface Props {
   title: string;
   alias: string;
   arrival: string;
   departure: string;
   guestsString: string;
   photos: Array<{ th: string }>;
   canCancel: boolean
   status: {
      name: string;
      alias: 'cart' | 'confirmed' | 'partial_paid' | 'paid' | 'cancelled';
   }
   onCancel: () => void
   isSending: boolean;
}

const settings = {
   dots: true,
   infinite: false,
   arrows: true,
   slidesToShow: 1,
   slidesToScroll: 1,
};

const HotelGalleryTemplate = (props: any) => {
   const { data } = props;
   const { source } = data;

   return <Image aspectRatio="16:9" src={source['560x560@2x']} alt="photo" />;
};

const AccountOrdersDetailPrime: FC<Props> = props => {
   const { title, arrival, departure, guestsString, photos, status, canCancel, onCancel, isSending } = props;
   const {name, alias} = status;
   const history = useHistory();
   const cut = photos.length < 5 ? photos : photos.slice(0, 5);

   return (
      <div className="account-orders-detail-prime">
         <div className="account-orders-detail-prime-header">
            <h1>{title}</h1>
            <div>
               <StatusOrder status={alias}>{name}</StatusOrder>
            </div>
         </div>
         <CustomCarousel Item={HotelGalleryTemplate} data={cut} settings={settings} theme="secondary" />

         <div className="account-orders-detail-prime__info">
            {/*<p>*/}
            {/*   Ми повернули вам кошти в розмірі 23 345 ₴ (UAH) 4 серп.. Якщо ви їх досі не отримали, зв’яжіться зі своїм банком, щоб дізнатися дату їх надходження.*/}
            {/*</p>*/}
            {canCancel && status.alias !== 'cancelled' && <AppButton showIndicator={isSending} theme="outline" onClick={onCancel}>Скасувати бронювання</AppButton>}
         </div>


         <div className="account-orders-detail-main-dates">
            <div className="account-orders-detail-main-dates__arrival">
               <div className="account-orders-detail-main-label">Заїзд</div>
               <div className="account-orders-detail-main-data">{arrival}</div>
            </div>
            <div className="account-orders-detail-main-dates__departure">
               <div className="account-orders-detail-main-label">Виїзд</div>
               <div className="account-orders-detail-main-data">{departure}</div>
            </div>
         </div>

         <div className="account-orders-detail-main-guest">
            <div className="account-orders-detail-main-label">Гості</div>
            <div className="account-orders-detail-main-data">{guestsString}</div>
         </div>
         <ul className="account-orders-detail-info-list">
            {/*<li>*/}
            {/*   <ClickableArrowItem*/}
            {/*      content={<TextWithIcon text={"Зв'язатися з готелем"} Icon={IconTimeTable} />}*/}
            {/*      onClick={() => {*/}

            {/*      }}*/}
            {/*   />*/}
            {/*</li>*/}
            <li>
               <ClickableArrowItem
                  content={<TextWithIcon text={'Перейти на сторінку готелю'} Icon={IconHome} />}
                  onClick={() => {
                     history.push(`/hotels/${props.alias}`)
                  }}
               />
            </li>
         </ul>
      </div>
   );
};

export default AccountOrdersDetailPrime;
