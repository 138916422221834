import React, { FC } from 'react';
import './HotelAmenitiesModal.scss';
import { HotelPropertyGroup, HotelPropertyItem } from '../../../../../../types/hotel';
import Modal from '../../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import HotelIconText from '../../../../../../components/ui/icons/hotels/HotelIconText/HotelIconText';
import { RoomItemPrepared } from '../../../../../../types/rooms';
import HotelAmenitiesCheckIcon from '../components/HotelAmenitiesCheckIcon';

interface Props {
    amenities?: HotelPropertyGroup[];  //TODO  change to correct type
    properties?: HotelPropertyItem[];
    onClose: () => void;
    hotelName: string;
}

const HotelAmenitiesModal: FC<Props> = ({amenities, properties,hotelName, onClose}) => {

    return (
        <Modal wrapperClassName="hotel-amenities-modal" onClose={onClose} showClose>
            <ModalLayoutPrimary title={`Зручності в готелі ${hotelName}`} noPaddingTop>
                <div className="hotel-amenities-modal">
                    {amenities && amenities.map(el => (
                        <div className="hotel-amenities-modal__list">
                            <div className="hotel-amenities-modal__list-header">{el.name}</div>

                            {el.properties.data.map(el => (
                                <div className="hotel-amenities-modal__list-item" key={'amenities-item-' + el.id}>
                                    <HotelIconText text={el.name} icon={el.icon || <HotelAmenitiesCheckIcon/>}/>
                                </div>
                            ))}
                        </div>
                    ))}

                    {properties ? (
                        <div className="hotel-amenities-modal__list">
                            {properties.map(property => (
                                <div className="hotel-amenities-modal__list-item" key={'property' + property.id}>
                                    <HotelIconText text={property.name} icon={property.icon}/>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </ModalLayoutPrimary>
        </Modal>
    );
};

export default HotelAmenitiesModal;
