import React, { FC } from 'react';
import './ProfilePreviewList.scss';
import ProfileNavigationItem from '../ProfileNavigationItem/ProfileNavigationItem';
import IconProfile from '../../../../../components/ui/icons/account/IconProfile';
import IconSaved from '../../../../../components/ui/icons/account/IconSaved';
import IconPayment from '../../../../../components/ui/icons/account/IconPayment';
import IconTravel from '../../../../../components/ui/icons/account/IconTravel';
import IconSettings from '../../../../../components/ui/icons/account/IconSettings';
import Container from '../../../../../components/ui/layouts/Container/Container';
import IconExit from '../../../../../components/ui/icons/account/IconExit';
import useLogout from '../../../../../hooks/useLogout';
import IconMessages from '../../../../../components/ui/icons/account/IconMessages';
import { RouteNames } from '../../../../../routes';

interface Props {}

const ProfilePreviewList: FC<Props> = props => {
  const [setLogout] = useLogout();

   return (
      <nav className="profile-navigation">
         <ul>
            <li>
               <ProfileNavigationItem title="Персональна інформація" icon={<IconProfile />} link={RouteNames.PROFILE_EDIT} />
            </li>
            <li>
               <ProfileNavigationItem title="Обране" icon={<IconSaved />} link={RouteNames.PROFILE_FAVORITE} />
            </li>
            {/*<li>*/}
            {/*   <ProfileNavigationItem title="Оплата" icon={<IconPayment />} link={RouteNames.PROFILE_PAYMENT} />*/}
            {/*</li>*/}
            <li>
               <ProfileNavigationItem title="Мої подорожі" icon={<IconTravel />} link={RouteNames.PROFILE_ORDERS_LIST} />
            </li>
            {/*<li>*/}
            {/*   <ProfileNavigationItem title="Налаштування" icon={<IconSettings />} link={RouteNames.PROFILE_SETTINGS} />*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*   <ProfileNavigationItem title="Повідомлення" icon={<IconMessages />} link={RouteNames.PROFILE_MESSAGE} />*/}
            {/*</li>*/}

            <li>
               <ProfileNavigationItem icon={<IconExit />} title="Вийти" onClick={setLogout}  className="profile-navigation__logout"/>
            </li>
         </ul>
      </nav>
   );
};

export default ProfilePreviewList;
