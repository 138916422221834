import React, {FC} from "react";

interface Props {

}

const IconToolbarFavorite: FC<Props> = (props) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0001 21C11.8685 21.0008 11.738 20.9756 11.6162 20.9258C11.4944 20.876 11.3836 20.8027 11.2901 20.71L3.52012 12.93C2.54548 11.9452 1.99878 10.6156 1.99878 9.23002C1.99878 7.84445 2.54548 6.51484 3.52012 5.53002C4.50238 4.55053 5.83295 4.00049 7.22012 4.00049C8.60729 4.00049 9.93785 4.55053 10.9201 5.53002L12.0001 6.61002L13.0801 5.53002C14.0624 4.55053 15.3929 4.00049 16.7801 4.00049C18.1673 4.00049 19.4979 4.55053 20.4801 5.53002C21.4548 6.51484 22.0015 7.84445 22.0015 9.23002C22.0015 10.6156 21.4548 11.9452 20.4801 12.93L12.7101 20.71C12.6167 20.8027 12.5059 20.876 12.384 20.9258C12.2622 20.9756 12.1317 21.0008 12.0001 21ZM7.22012 6.00002C6.79679 5.9981 6.3773 6.0802 5.98592 6.24155C5.59455 6.40291 5.23909 6.64031 4.94012 6.94002C4.33618 7.54714 3.99715 8.36866 3.99715 9.22502C3.99715 10.0814 4.33618 10.9029 4.94012 11.51L12.0001 18.58L19.0601 11.51C19.6641 10.9029 20.0031 10.0814 20.0031 9.22502C20.0031 8.36866 19.6641 7.54714 19.0601 6.94002C18.4438 6.35773 17.628 6.03331 16.7801 6.03331C15.9322 6.03331 15.1165 6.35773 14.5001 6.94002L12.7101 8.74002C12.6172 8.83375 12.5066 8.90814 12.3847 8.95891C12.2628 9.00968 12.1321 9.03581 12.0001 9.03581C11.8681 9.03581 11.7374 9.00968 11.6155 8.95891C11.4937 8.90814 11.3831 8.83375 11.2901 8.74002L9.50012 6.94002C9.20115 6.64031 8.84568 6.40291 8.45431 6.24155C8.06294 6.0802 7.64344 5.9981 7.22012 6.00002Z" className="toolbar-svg-fill" fill="#C5C6CF"/>
    </svg>

  )
}

export default IconToolbarFavorite