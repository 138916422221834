import {createSlice} from "@reduxjs/toolkit";
import {signInThunk} from "./auth-actions";
import {REGISTRATION_INITIAL, REGISTRATION_CONFIRM, REGISTRATION_COMPLETE, REGISTRATION_RESTORE} from "./auth-types";

export type Stage = typeof REGISTRATION_INITIAL | typeof REGISTRATION_CONFIRM | typeof REGISTRATION_COMPLETE | typeof REGISTRATION_RESTORE ;

const initialState = {
    data: {},
    error: {},
    isAuthorized: false,
    isSending: false,
    stage: REGISTRATION_INITIAL
};


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authorizedIn: (state) => {
            state.isAuthorized = true;
        },
        authorizedOut: (state) => {
            state.isAuthorized = false;
        },
        // registration stage
        registrationStage: (state, action) => {
            state.stage = action.payload
        },
        setIsSending: (state, action) => {
            state.isSending = action.payload
        }
    },
    extraReducers: (builder) => {
        /** SIGN IN **/
        // request
        builder.addCase(signInThunk.pending, (state, action) => {
            state.isSending = true;
        })

        // response
        builder.addCase(signInThunk.fulfilled, (state, action) => {
            state.isSending = false;
            state.isAuthorized = true;
        })

        // errors
        builder.addCase(signInThunk.rejected, (state, action) => {
            state.isSending = false;
            state.isAuthorized = false;
            state.error = action.payload
        })

        /** SIGN IN SOCIAL**/


    }
})
//signInSocialThunk

// signInThunk
// signUpInitialThunkCreator
// signUpConfirmCodeThunkCreator
// actionSignUpComplete

export const {authorizedIn, authorizedOut, registrationStage, setIsSending} = authSlice.actions;
export default authSlice.reducer;





