import appToastr from '../services/appToastr';
import { isClient } from './common';

interface Position {
   latitude: number;
   longitude: number;
}

export const generateNavigationOnGoogleMap = (from: Position, to: Position): string => {
   return `http://maps.google.com/maps?saddr=${from.latitude},${from.longitude}&daddr=${to.latitude},${to.longitude}&mode=transit`;
};

export const getMyPosition = () => {
   return new Promise((resolve, reject) => {
      if (isClient) {

         if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
               position => {
                  resolve(position);
               },
               error => {

                  reject(error);
               },
            );
         } else {
            reject({ error: 'Не підтримується браузером' });
         }
      }
   });
};
