import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './HotelIndexPlaces.scss';

import Image from '../../../../../components/ui/components/Image/Image';
import Container from '../../../../../components/ui/layouts/Container/Container';
import { generateStaticLinkToHotelsSearch } from '../../../../../helpers/hotels';

const regions = {
    kyiv: {
        id: 1218,
        name: 'Київ',
        coordinates: {
            latitude: '50.4501000',
            longitude: '30.5234000'
        }
    },
    bukovel: {
        id: 13,
        name: 'Буковель Івано-Франківська область',
        coordinates: {
            latitude: '48.3623404',
            longitude: '24.4054980'
        }
    },
    yaremche: {
        id: 302,
        name: 'Яремче, Івано-Франківська область',
        coordinates: {
            latitude: '48.4815570',
            longitude: '24.5819174'
        }
    },
    lviv: {
        id: 54,
        name: 'Львів, Львівська область',
        coordinates: {
            latitude: '49.8396830',
            longitude: '24.0297170'
        }
    }
}

const HotelIndexPlaces: FC = () => {
    const [t] = useTranslation();

    return (
        <div className="hotel-index-places">
            <Container>
                <h3 className="hotel-index-places__title">{t('Популярні напрямки')}</h3>
                <div className="hotel-index-places__image-list">
                    <NavLink
                        className="hotel-index-places__image-block"
                        to={generateStaticLinkToHotelsSearch(regions.kyiv)}
                    >
                        <Image
                            className="hotel-index-places__image"
                            src="/images/index/places/summer/kyiv.png"
                        />
                        <div className="hotel-index-places__image-text">Київ</div>
                    </NavLink>

                    <NavLink
                        className="hotel-index-places__image-block"
                        to={generateStaticLinkToHotelsSearch(regions.bukovel)}
                    >
                        <Image
                            className="hotel-index-places__image"
                            src="/images/index/places/summer/bukovel.png"
                        />
                        <div className="hotel-index-places__image-text">Буковель</div>
                    </NavLink>

                    <NavLink
                        className="hotel-index-places__image-block "
                        to={generateStaticLinkToHotelsSearch(regions.yaremche)}
                    >
                        <Image
                            className="hotel-index-places__image"
                            src="/images/index/places/summer/yaremche.png"
                        />
                        <div className="hotel-index-places__image-text">Яремча</div>
                    </NavLink>

                    <NavLink
                        className="hotel-index-places__image-block"
                        to={generateStaticLinkToHotelsSearch(regions.lviv)}
                    >
                        <Image
                            className="hotel-index-places__image"
                            src="/images/index/places/summer/lviv.png"
                        />

                        <div className="hotel-index-places__image-text">Львів</div>
                    </NavLink>
                </div>
            </Container>
        </div>
    );
};

export default HotelIndexPlaces;
