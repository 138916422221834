import React, { FC } from 'react';
import './HotelsIcons.scss';

interface Props {
    isSelected: boolean;
    color?: string
    size?: 'normal' | 'big',
    variant?: 'default' | '2'

}

const IconFavorites: FC<Props> = ({isSelected, color, variant = 'default', size = 'normal'}) => {
    const renderContent = () => {
        switch (size) {
            case 'big':
                return (
                    <>
                        {isSelected ? (
                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.2496 5.46006C19.2496 6.91006 18.6796 8.30006 17.6496 9.32006L10.2196 16.7501C9.89957 17.0801 9.35957 17.0801 9.03957 16.7501L1.60957 9.32006C-0.54043 7.19006 -0.54043 3.74006 1.60957 1.61006C3.73957 -0.539941 7.19957 -0.539941 9.32957 1.61006L9.62957 1.90006L9.92957 1.61006C10.9496 0.570059 12.3296 5.8651e-05 13.7896 5.8651e-05C15.2496 5.8651e-05 16.6296 0.570059 17.6496 1.60006C18.6796 2.62006 19.2496 4.01006 19.2496 5.46006Z"
                                    fill="#E05233"/>
                            </svg>
                        ) : (
                            <svg width="22" height="19" viewBox="0 0 22 19" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M22 6.10596C22 7.7254 21.3418 9.27847 20.1704 10.4232L11.679 18.7249C11.3038 19.0917 10.6956 19.0917 10.3205 18.7249L1.8291 10.4232C-0.609699 8.03884 -0.609699 4.17308 1.8291 1.78875C4.2679 -0.59558 8.22197 -0.59558 10.6608 1.78875L10.9997 2.12016L11.3386 1.7889C12.5096 0.643517 14.0981 0 15.7546 0C17.4109 0 18.9994 0.643459 20.1704 1.78875C21.3418 2.93358 22 4.48659 22 6.10596ZM17.7937 10.0905L18.8121 9.0949C19.6232 8.30231 20.0789 7.22711 20.0789 6.10596C20.0789 4.98481 19.6232 3.9096 18.8121 3.11701C18.0011 2.32374 16.9013 1.87823 15.7546 1.87823C14.6078 1.87823 13.508 2.32374 12.6972 3.11686L11.679 4.11232C11.3038 4.47907 10.6956 4.47907 10.3205 4.11232L9.30232 3.11686C7.61377 1.46602 4.87609 1.46602 3.18755 3.11686C1.499 4.76769 1.499 7.44422 3.18755 9.09506L10.9997 16.7328L17.7937 10.0905Z"
                                    fill={'#C5C6CF'}
                                />
                            </svg>
                        )}
                    </>
                );
            case 'normal':
                return (
                    <svg width={25} height={28} viewBox="0 0 25 28" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.2523 12.4398C18.2523 13.302 17.9097 14.1289 17.3 14.7383L12.88 19.1583C12.6847 19.3536 12.3681 19.3536 12.1729 19.1583L7.75287 14.7383C6.48342 13.4689 6.48342 11.4107 7.75287 10.1412C9.02233 8.87175 11.0805 8.87175 12.35 10.1412L12.5264 10.3176L12.7028 10.1413C13.3123 9.53146 14.1392 9.18884 15.0014 9.18884C15.8636 9.18884 16.6905 9.53143 17.3 10.1412C17.9098 10.7507 18.2523 11.5776 18.2523 12.4398Z"
                            fill={isSelected ? "#E47B79" : 'none'}
                            stroke={isSelected ? "none" : (color || '#C5C6CF')}
                        />
                    </svg>
                )
        }
    }

    return (
        <div>
            <span className={`favorites-button variant-${variant}`}>
                {renderContent()}
            </span>
        </div>
    );
}

export default IconFavorites;
