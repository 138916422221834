import React, { FC } from 'react';
import './AuthLayout.scss';
import classNames from "classnames";
import IconArrowLeft from '../../../../components/ui/icons/account/IconArrowLeft';
import { Link, useHistory } from "react-router-dom";
import AuthStepper from "../AuthStepper/AuthStepper";
import AuthLogo from "../AuthLogo/AuthLogo";
import AuthBack from "../icons/AuthBack";
import useQuery from '../../../../hooks/useQuery';

interface Props {
  activeStep: number;
  className?: string;
  AuthHeader: FC;
  title: string;
  image: string;
  steps?: Array<number>;
  goBack?: () => void;
}

const AuthLayout: FC<Props> = (props) => {
  const { className = '', activeStep, steps = [1, 2, 3], children, AuthHeader, title, image, goBack } = props;
  const classes = classNames('auth-layout__form', className);
  const history = useHistory();
  let query = useQuery();
  const backPath = query.get('redirect-url') || '/';

  return (
    <div className="auth-layout" style={{ backgroundImage: `url(${image})` }}>
      <div className="auth-layout__wrapper">
        <div className="auth-layout__info">
          <div className="auth-layout__header">
            {
              goBack
                ?
                <button className="auth-layout__back" onClick={goBack}>
                  <AuthBack/>
                </button>

                : <Link to={backPath}>
                  <button className="auth-layout__back">
                    <AuthBack/>
                  </button>
                </Link>
            }

            <AuthLogo/>
          </div>
          <div style={{ alignSelf: 'center' }}>
            {steps.length ? <AuthStepper active={activeStep} steps={steps}/> : null}
          </div>
        </div>
        <div className={classes}>
          <div className="auth-layout-header-mobile">
            <div className="auth-layout-header-mobile__back">
              <IconArrowLeft onClick={() => history.goBack()}/>
            </div>
            <div className="">
              <AuthLogo className="auth-layout-header-mobile__logo"/>
            </div>
          </div>
          <h3 className="auth-layout__title">{title}</h3>
          <div className="auth-layout__description">
            <AuthHeader/>
          </div>

          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthLayout;
