import React from 'react';
import { Hotel } from '../../../../../types/hotel';
import { createBemClass } from '../../../../../helpers/common';
import './OrderIndexHotelInfo.scss';
import StarsRating from '../../../../../components/ui/components/StarsRating/StarsRating';
import { MobileOnly, TabletAndUpper } from '../../../../../components/common/Media/MediaHelpers';
import { DISABLE_STARS_SHOW } from '../../../../../config';

type Props = {
    hotel: Hotel
}

const OrderIndexHotelInfo: React.FC<Props> = ({hotel}) => {
    const bemClass = createBemClass('order-index-hotel-info');
    const image = hotel.photos.length ? hotel.photos[0].source?.['302x170'] : '';

    const distance = (
        <div className={bemClass('distance-text')}>
            {hotel.distanceToPlaceFormatted}
        </div>
    );

    return (
        <div className={bemClass()}>
            <div className={bemClass('image')} style={{backgroundImage: `url(${image})`}}/>

            <div className={bemClass('texts')}>
                <span className={bemClass('hotel-name')}>{hotel.name}</span>

                {hotel.address && <span className={bemClass('address')}>
                    {hotel.address}
                </span>}

                <MobileOnly>
                    {distance}
                </MobileOnly>

                <div className={bemClass('texts-bottom-block')}>
                    <div>
                        {!DISABLE_STARS_SHOW && <div><StarsRating stars={hotel.stars}/></div>}
                        {/*<div className={bemClass('reviews-text')}>Відгуки • {hotel.reviewsCount}</div>*/}
                    </div>

                    <TabletAndUpper>
                        {distance}
                    </TabletAndUpper>
                </div>
            </div>
        </div>
    )
};

export default OrderIndexHotelInfo;
