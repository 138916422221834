import { createSlice } from '@reduxjs/toolkit';
import { getWishListPublicThunk } from './wishListPublic-thunks';

interface InitialState {
   data: any;
   error: any;
   isFetching: boolean;
   focusOnHotelSearchForm: boolean;
}

const initialState: InitialState = {
   data: {},
   error: {},
   isFetching: false,
   focusOnHotelSearchForm: false,
};

const wishListPublicSlice = createSlice({
   name: 'wishListPublic',
   initialState,
   reducers: {},
   extraReducers: builder => {

      builder.addCase(getWishListPublicThunk.pending, (state) => {
         state.isFetching = true;
      });

      builder.addCase(getWishListPublicThunk.fulfilled, (state, action) => {
         state.isFetching = false;
         state.data = action.payload;
      });

      builder.addCase(getWishListPublicThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.payload;
      });
   },
});

export default wishListPublicSlice.reducer;