import React, { FC, HTMLProps } from 'react';
import { Field, FieldProps } from 'formik';
import ControlLayout from './ControlLayout/ControlLayout';
import { FormOptionType } from '../formTypes';
import MuiFieldSelect from '../muiFields/MuiFieldSelect';

interface Props extends HTMLProps<HTMLSelectElement> {
   options: Array<FormOptionType>;
}

const AppFormSelect: FC<Props> = props => {
   const { name, options = [], disabled, label, id } = props;

   return (
      <Field name={name}>
         {(propsField: FieldProps) => {
            const { field, meta } = propsField;
            const isError = meta.touched && !!meta.error;

            return (
               <ControlLayout isError={isError} error={meta.error}>
                  <MuiFieldSelect id={id} label={label} {...field} isError={isError} disabled={disabled}>
                     {options.map((option: FormOptionType) => {
                        return (
                           <option key={option.value} value={option.value}>
                              {option.key}
                           </option>
                        );
                     })}
                  </MuiFieldSelect>
               </ControlLayout>
            );
         }}
      </Field>
   );
};

export default AppFormSelect;
