import React from "react";




const IconTwitter = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 11.2333C0 7.43673 0 5.53843 0.750577 4.0935C1.38308 2.87588 2.37588 1.88308 3.5935 1.25058C5.03843 0.5 6.93673 0.5 10.7333 0.5H21.2667C25.0633 0.5 26.9616 0.5 28.4065 1.25058C29.6241 1.88308 30.6169 2.87588 31.2494 4.0935C32 5.53843 32 7.43673 32 11.2333V21.7667C32 25.5633 32 27.4616 31.2494 28.9065C30.6169 30.1241 29.6241 31.1169 28.4065 31.7494C26.9616 32.5 25.0633 32.5 21.2667 32.5H10.7333C6.93673 32.5 5.03843 32.5 3.5935 31.7494C2.37588 31.1169 1.38308 30.1241 0.750577 28.9065C0 27.4616 0 25.5633 0 21.7667V11.2333Z" fill="#1EA1F2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.8819 12.6014C24.8896 12.8013 24.8922 13.0011 24.8922 13.201C24.8922 19.2606 20.6295 26.2561 12.8346 26.2561C10.4401 26.2561 8.2136 25.493 6.33765 24.1938C6.66926 24.2302 7.00604 24.2575 7.34798 24.2575C9.33333 24.2575 11.1619 23.5215 12.6124 22.2859C10.7579 22.2586 9.19208 20.9232 8.65203 19.1062C8.91128 19.1607 9.17828 19.188 9.45131 19.188C9.83632 19.188 10.2101 19.1336 10.5684 19.0246C8.62789 18.6066 7.16624 16.7534 7.16624 14.5275C7.16624 14.5003 7.16624 14.4912 7.16624 14.473C7.73816 14.8091 8.39275 15.018 9.08784 15.0452C7.94917 14.2185 7.20068 12.8104 7.20068 11.2205C7.20068 10.3847 7.40912 9.59426 7.77604 8.91289C9.8656 11.6929 12.9896 13.519 16.5116 13.7098C16.4392 13.3736 16.4022 13.0194 16.4022 12.6651C16.4022 10.1304 18.2997 8.07715 20.6407 8.07715C21.8595 8.07715 22.9603 8.63141 23.7329 9.52173C24.7001 9.32187 25.6063 8.94032 26.4262 8.4134C26.1084 9.48542 25.4374 10.3846 24.5606 10.9479C25.4185 10.8389 26.2368 10.5938 26.9956 10.2304C26.4262 11.148 25.7096 11.9564 24.8819 12.6014Z" fill="white"/>
    </svg>
  )
}

export default IconTwitter