import React from 'react';
import FieldCounter from '../../../../../../../components/ui/forms/fields/FieldCounter/FieldCounter';
import HotelFiltersRow from '../HotelFiltersRow/HotelFiltersRow';
import { FiltersInitDataPrepared } from '../../../../../../../types/hotel-filters';

interface Props {
    values: any,
    setFieldValue: any,
    initialFiltersData: FiltersInitDataPrepared
}

const BedsAndRoomsFilter: React.FC<Props> = ({values, initialFiltersData, setFieldValue}) => {
    const {rooms, beds, bathrooms} = values.rooms;

    const initialRooms = initialFiltersData?.rooms;

    return (
        <>
            <HotelFiltersRow label={initialRooms?.rooms?.label}>
                <FieldCounter
                    value={parseInt(rooms)}
                    theme="primary"
                    min={initialRooms.rooms.min}
                    max={initialRooms.rooms.max}
                    onChange={(value) => setFieldValue('rooms.rooms', value)}/>
            </HotelFiltersRow>

            <HotelFiltersRow label={initialRooms?.beds?.label}>
                <FieldCounter
                    value={parseInt(beds)}
                    theme="primary"
                    min={initialRooms.bathrooms.min}
                    max={initialRooms.bathrooms.max}
                    onChange={(value) => setFieldValue('rooms.beds', value)}/>
            </HotelFiltersRow>

            <HotelFiltersRow label={initialRooms?.bathrooms?.label}>
                <FieldCounter
                    value={parseInt(bathrooms)}
                    theme="primary"
                    min={initialRooms.beds.min}
                    max={initialRooms.beds.max}
                    onChange={(value) => setFieldValue('rooms.bathrooms', value)}/>
            </HotelFiltersRow>
        </>
    )
};

export default BedsAndRoomsFilter;
