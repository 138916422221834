import React from 'react';
import Head from '../../../../components/layout/components/Head/Head';
import { PhotoType } from '../../../../types/common';

type Props = {
    name: string,
    description: string
    photos: PhotoType[]
}

const HotelDetailHead: React.FC<Props> = ({name, description, photos}) => {
    return (
        <Head
            ogTitle={name}
            ogDescription={description}
            ogImage={photos.length && photos[0] && photos[0].source['560x560'] ? photos[0].source['560x560'] : ''}
            metaDescription={description}
            metaKeywords={'готель, номер, житло, подорож'}
            title={name}
        />
    )
};

export default HotelDetailHead;
