import React, { FC, HTMLProps } from 'react';
import classNames from 'classnames';
import './FieldRadio.scss';
import { uid } from '../../../../../helpers/common';

interface IProps extends HTMLProps<HTMLInputElement> {
    isError?: boolean;
    label?: string;
    markerPosition?: 'left' | 'right'
}

const FieldRadio: FC<IProps> = props => {
    const {id, className, isError, label, onChange, children, markerPosition = 'left', ...rest} = props;
    const classes = classNames('field-radio', className, {['marker-position-' + markerPosition]: markerPosition});

    return (
        <div className={classes}>
            <label className="field-radio__label" htmlFor={id}>
                <input id={id || uid()} onChange={onChange} {...rest} type="radio"/>

                <div>{children || label}</div>
            </label>
        </div>
    );
};

export default FieldRadio;
