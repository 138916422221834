import React from "react";

const IconMessage = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.399902" width="43.2002" height="43.2002" rx="19.2" fill="#8BDAC2"/>
      <path d="M11.6001 16.9999L19.7651 22.7154C20.4262 23.1782 20.7568 23.4096 21.1164 23.4993C21.4341 23.5784 21.7663 23.5784 22.0839 23.4993C22.4435 23.4096 22.7741 23.1782 23.4353 22.7154L31.6002 16.9999M16.4001 30H26.8002C28.4804 30 29.3205 30 29.9622 29.673C30.5267 29.3854 30.9856 28.9264 31.2733 28.3619C31.6002 27.7202 31.6002 26.8801 31.6002 25.2V18.7999C31.6002 17.1197 31.6002 16.2797 31.2733 15.6379C30.9856 15.0734 30.5267 14.6145 29.9622 14.3269C29.3205 13.9999 28.4804 13.9999 26.8002 13.9999H16.4001C14.72 13.9999 13.8799 13.9999 13.2381 14.3269C12.6736 14.6145 12.2147 15.0734 11.9271 15.6379C11.6001 16.2797 11.6001 17.1197 11.6001 18.7999V25.2C11.6001 26.8801 11.6001 27.7202 11.9271 28.3619C12.2147 28.9264 12.6736 29.3854 13.2381 29.673C13.8799 30 14.72 30 16.4001 30Z" stroke="white" stroke-width="1.60001" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default IconMessage