import React from 'react';

type Props = {}

const HotelAmenitiesCheckIcon: React.FC<Props> = () => {
    return (
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5.36364L5.70588 10.6L17 1" stroke="#C5C6CF" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
};

export default HotelAmenitiesCheckIcon;
