import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { setShowFiltersModal } from '../../../store/hotels/hotels-reducer';
import { getHotelInitialFiltersSelector } from '../../../store/hotels/hotels-selector';
import { getActiveFiltersCount } from '../../../helpers/hotels';

export const useHotelsFiltersVisibility = () => {
    const dispatch = useAppDispatch();
    const filtersInitData = useAppSelector(getHotelInitialFiltersSelector);
    const {isOpenFilters, hotelsMeta, isLoading} = useAppSelector(state => {
        return {
            isOpenFilters: state.hotels.showFiltersModal,
            hotelsMeta: state.hotels.data.meta,
            isLoading: state.hotels.isFetching
        }
    });

    const activeFiltersCount = !isLoading && getActiveFiltersCount(hotelsMeta?.filters, hotelsMeta.filtersInitData);

    const openFilters = () => {
        dispatch(setShowFiltersModal(true));
    }

    const closeFilters = () => {
        dispatch(setShowFiltersModal(false));
    }

    return {
        openFilters,
        closeFilters,
        isOpenFilters,
        filtersInitData,
        activeFiltersCount
    }
}
