import { createAsyncThunk } from '@reduxjs/toolkit';
import favoriteApi from '../../api/favoriteApi';

export const getWishListPublicThunk = createAsyncThunk(
   'wishListPublic/getWishListPublicThunk',
   async (id: number, { rejectWithValue }) => {
      try {
         const res =  await favoriteApi.fetchPublicShareGroup(id)

         return res.data;
      } catch (error) {
         throw rejectWithValue(error);
      }
   },
);
