import React, { useState } from 'react';
import { scroller } from 'react-scroll';
import { useHistory, useLocation } from 'react-router-dom';
import './MobileRoomsListFooter.scss';

import useSearchForm from '../../../../../../common/HotelSearchForm/hooks/useSearchForm';
import { createBemClassProp } from '../../../../../../../../helpers/common';
import AppButton from '../../../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import PriceViewer from '../../../../../../../../components/ui/components/PriceViewer/PriceViewer';
import useOrderLogic from '../../../../../../../../hooks/useOrderLogic';
import { DATE_FORMAT_CLIENT_SHORT } from '../../../../../../../../config';
import { _formatDate } from '../../../../../../../../helpers/date';
import HotelDetailOrderSummarySearchInfo
    from '../../../../../components/HotelDetailOrderSummary/components/HotelDetailOrderSummarySearchInfo/HotelDetailOrderSummarySearchInfo';
import HotelDetailOrderSummarySelectedContent
    from '../../../../../components/HotelDetailOrderSummary/components/HotelDetailOrderSummarySelectedContent/HotelDetailOrderSummarySelectedContent';
import IconArrowLeft from '../../../../../../../../components/ui/icons/account/IconArrowLeft';
import { useAppSelector } from '../../../../../../../../hooks/reduxHooks';
import { hotelDetailRoute } from '../../../../../../../../helpers/routes';
import { getHotelSelector } from '../../../../../../../../store/hotel/hotel-selector';
import Modal from '../../../../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';

const MobileRoomsListFooter: React.FC = () => {
    const {nights, startDate, endDate} = useSearchForm();
    const [showSummary, setShowSummary] = useState(false);

    const history = useHistory();
    const {search} = useLocation();
    const {data: hotel, selectedOrderBookType} = useAppSelector(getHotelSelector);

    const {reservations, getReservationsTotalPrice, getReservationsSum} = useOrderLogic();
    const cn = createBemClassProp('mobile-rooms-list-footer');

    const onClickOnEdit = () => {
        setShowSummary(false);
        history.replace(hotelDetailRoute(hotel.alias, search));
        scroller.scrollTo('hotelDetailSearchForm', {offset: -100, smooth: true, duration: 300});
    }

    return (
        <div className={'mobile-rooms-list-footer-wrapper'}>
            {/*{(canBookOnCart && selectedOrderBookType === 'cart') && <AdditionalPhoneSection/>}*/}

            {selectedOrderBookType === 'cart' && reservations.length ? (
                <div {...cn()}>
                    <div>
                        <PriceViewer price={getReservationsTotalPrice()} nights={nights} totalAfterDiscount/>
                        <div>{_formatDate(startDate, DATE_FORMAT_CLIENT_SHORT)} - {_formatDate(endDate, DATE_FORMAT_CLIENT_SHORT)}</div>
                    </div>

                    <div>
                        <AppButton onClick={() => setShowSummary(true)} theme={'primary'}>Бронювати</AppButton>
                    </div>

                    {showSummary && (
                        <Modal
                            {...cn('summary-modal')}
                            onClose={() => setShowSummary(false)}
                        >
                            <ModalLayoutPrimary title={'Підсумок замовлення'}>
                                <HotelDetailOrderSummarySearchInfo onClickOnEdit={onClickOnEdit}/>
                                <HotelDetailOrderSummarySelectedContent
                                    reservations={reservations}
                                    totalRoomsCount={getReservationsSum()}
                                    totalPrice={getReservationsTotalPrice()}
                                    onClickOnAddRoom={() => setShowSummary(false)}
                                />
                            </ModalLayoutPrimary>
                        </Modal>
                    )}
                </div>
            ) : null}
        </div>
    )
};

export default MobileRoomsListFooter;
