import { deleteData, editData, fetchData, sendData } from '../helpers/network';
import {
   CreateFavoritePublicGroupResponse,
   CreateWishGroupParams,
   FavoritesGroupResponse,
   SaveToWishListParams,
} from '../types/favorites';
import { FavoritesItemsResponse } from '../types/favoritesItems';


const favoriteApi = {
   fetchFavoriteGroupList: () => fetchData<FavoritesGroupResponse>('/favorites/groups'), // as Promise<FavoritesGroupResponse>,
   saveToWishList: (params: SaveToWishListParams) => sendData('/favorites', params) as Promise<any>,
   removeFromWishList: (hotelId: number) => deleteData(`/favorites/${hotelId}`) as Promise<any>,
   fetchGroupById: (id: number) => fetchData<FavoritesItemsResponse>(`/favorites/groups/${id}`), // as Promise<FavoritesItemsResponse>,
   createGroup: (params: CreateWishGroupParams) => sendData('/favorites/groups', params) as Promise<any>,
   updateGroup: (data: CreateWishGroupParams, id: number) => editData(`/favorites/groups/${id}`, data) as Promise<any>,
   deleteGroup: (id: number) => deleteData(`/favorites/groups/${id}`) as Promise<any>,

   createShareGroup: (data: {name: string, id: number}) => sendData(`/favorites/groups/${data.id}/share`, {name: data.name}) as Promise<CreateFavoritePublicGroupResponse>,
   fetchPublicShareGroup: (id: number) => fetchData<any>(`/favorites/shared-groups/${id}`),

}

export default favoriteApi;