import { setAxiosInstanceHeader, setContactToken } from './network';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();


export const saveAuthTokenAndHeader = (token: string, expires_in: number): void => {
    cookies.set('auth', token, {path: '/', maxAge: expires_in });
    setAxiosInstanceHeader('Authorization', token);
    setContactToken(null);
};

export const removeAuthTokenAndHeader = (): void => {
    cookies.remove('auth', {path: '/'});
    setAxiosInstanceHeader('Authorization', '');
    setContactToken(null);
};
