import React, { FC, memo } from 'react';
import './MessageItem.scss';
import Image from '../../../../../components/ui/components/Image/Image';

interface Props {
   photo: string;
   time: string;
   fullName: string;
   message: string;
}

const MessageItem: FC<Props> = memo(({ fullName, photo, message, time }) => {

   return (
      <div className="message-item">
         <Image aspectRatio="1:1" className="message-item__image" src={photo} />
         <div className="message-item__text">
            <div className="message-item__full-name">
               {fullName} <span className="message-item__time">{time}</span>
            </div>
            <div className="message-item__message">{message}</div>
         </div>
      </div>
   );
});

export default MessageItem;
