import React, { FC } from "react";

interface Props {

}

const IconArrowBottom: FC<Props> = (props) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12 14.6829L18.3492 9.24076C18.7685 8.88134 19.3998 8.9299 19.7592 9.34923C20.1187 9.76855 20.0701 10.3999 19.6508 10.7593L12.6508 16.7593C12.2763 17.0803 11.7237 17.0803 11.3492 16.7593L4.34919 10.7593C3.92986 10.3999 3.8813 9.76855 4.24073 9.34923C4.60015 8.9299 5.23145 8.88134 5.65077 9.24076L12 14.6829Z"
                fill="#6D7188"/>
        </svg>
    )
}

export default IconArrowBottom
