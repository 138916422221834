import {AspectRatio} from "../types/common";

export const cssVar = (name: string) => {
  return getComputedStyle(document.body).getPropertyValue(name)
}

export const getAspectRatioPadding = (aspectRatio: AspectRatio) => {
  switch (aspectRatio) {
    case '16:9':
      return '56.25%'
    case '3:4':
      return '75%'
    case '3:2':
      return '66.66%'
    case '1:1':
      return '100%'
    case '1:2':
      return '200%'
    case "2:1":
      return '50%'
    case "4:3":
      return '125%'
    default:
      return '0'

  }
}