import React from "react";


const IconViber = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.5685 0.52297C23.7237 0.556585 26.9364 2.89045 27.7336 3.62038C30.37 5.91102 31.8346 10.9917 30.8502 19.0162C29.9841 26.1062 25.0388 27.364 23.3797 27.786C23.2173 27.8273 23.0863 27.8606 22.9938 27.8906C22.5472 28.0347 18.595 29.0239 13.4615 28.5917C12.8948 29.2496 12.1409 30.114 11.5982 30.6999C11.4343 30.8765 11.2808 31.0509 11.1341 31.2176C10.3914 32.0614 9.82321 32.707 8.95702 32.4383C8.09743 32.1742 8.14545 30.868 8.14545 30.868L8.15025 27.6457H8.14545C0.634833 25.5327 0.745282 17.7484 0.831722 13.5897C0.913359 9.43102 1.72013 6.0791 4.01077 3.78846C8.12624 0.013938 16.5685 0.52297 16.5685 0.52297Z" fill="#665CAC"/>
      <path d="M22.5277 25.5414C23.9377 25.2141 27.8755 24.3003 28.6027 18.4398C29.4335 11.7359 28.2954 7.15946 26.0671 5.24339C25.3948 4.63351 22.4943 2.81348 16.4388 2.78467C16.4388 2.78467 9.26911 2.31406 5.78752 5.46429C3.85224 7.37556 3.19914 10.2185 3.12711 13.6904L3.11926 14.0441C3.03799 17.5947 2.89476 23.8524 9.0338 25.5566C9.0338 25.5566 9.00979 30.4789 9.00498 30.9111C9.00498 31.2136 9.05301 31.4201 9.22589 31.4633C9.35074 31.4969 9.53803 31.4297 9.6965 31.2712C10.7098 30.2484 13.9608 26.3298 13.9608 26.3298C18.3212 26.6179 21.7884 25.7535 22.163 25.6335C22.2492 25.606 22.3733 25.5772 22.5277 25.5414Z" fill="white"/>
      <path d="M19.9164 13.3925C19.9272 13.6158 19.755 13.8055 19.5317 13.8163C19.3085 13.8271 19.1187 13.6549 19.1079 13.4316C19.0411 12.0484 18.3985 11.3848 17.0704 11.3143C16.8472 11.3024 16.6759 11.1119 16.6877 10.8887C16.6996 10.6654 16.8901 10.4941 17.1134 10.506C18.8611 10.5988 19.8298 11.5992 19.9164 13.3925Z" fill="#665CAC"/>
      <path d="M21.5775 14.513C21.801 14.5181 21.9863 14.341 21.9914 14.1176C22.0273 12.5356 21.5535 11.1999 20.5691 10.1274C19.5829 9.05298 18.2227 8.45807 16.5011 8.33582C16.2781 8.31999 16.0845 8.4879 16.0687 8.71087C16.0529 8.93384 16.2208 9.12742 16.4437 9.14325C17.9688 9.25154 19.1344 9.76134 19.9728 10.6748C20.8119 11.589 21.2134 12.721 21.1821 14.0992C21.177 14.3227 21.3541 14.5079 21.5775 14.513Z" fill="#665CAC"/>
      <path d="M24.1188 14.9361C24.1205 15.1596 23.9406 15.3422 23.7171 15.3439C23.4936 15.3455 23.311 15.1657 23.3094 14.9422C23.291 12.4792 22.5641 10.5647 21.1314 9.16384C19.6937 7.76266 17.9065 7.05464 15.7591 7.0409C15.5356 7.03947 15.3555 6.85711 15.357 6.63359C15.3584 6.41007 15.5407 6.23002 15.7643 6.23145C18.1176 6.24651 20.1061 7.03434 21.6968 8.58461C23.2931 10.1454 24.0989 12.2677 24.1188 14.9361Z" fill="#665CAC"/>
      <path d="M18.0475 18.5024C17.745 18.8817 17.1735 18.8337 17.1735 18.8337C13.0148 17.7724 11.9007 13.5609 11.9007 13.5609C11.9007 13.5609 11.8527 12.9943 12.2321 12.6869L12.9812 12.0915C13.3558 11.8033 13.5911 11.107 13.2117 10.4251C13.0004 10.0457 12.5778 9.36862 12.2657 8.94123C11.9343 8.48982 11.166 7.5678 11.1612 7.563C10.7914 7.1212 10.244 7.02035 9.66771 7.31809C9.66771 7.31809 9.66291 7.32289 9.65811 7.32289C9.11066 7.63023 8.60163 8.04322 8.12621 8.56666L8.11661 8.57626C7.76124 9.00365 7.55475 9.42625 7.50673 9.83923C7.49712 9.88726 7.49232 9.94969 7.49712 10.0217C7.49232 10.2042 7.52113 10.3819 7.57876 10.5596L7.59797 10.574C7.77565 11.2127 8.22705 12.2739 9.2019 14.0459C9.83579 15.1937 10.4841 16.1541 11.1276 16.9609C11.4637 17.3883 11.8479 17.8253 12.2897 18.2719L12.4626 18.4447C12.9092 18.8865 13.3462 19.2707 13.7736 19.6069C14.5803 20.2504 15.536 20.8987 16.6885 21.5326C18.4605 22.5074 19.5218 22.9588 20.1605 23.1365L20.1749 23.1557C20.3526 23.2133 20.5303 23.2373 20.7127 23.2373C20.7848 23.2421 20.8472 23.2373 20.8952 23.2277C21.3082 23.1797 21.7308 22.9732 22.1582 22.6178L22.1678 22.6082C22.6912 22.1328 23.1042 21.619 23.4116 21.0763C23.4116 21.0763 23.4164 21.0715 23.4164 21.0667C23.7141 20.4905 23.6133 19.943 23.1715 19.5733C23.1715 19.5733 22.2446 18.8001 21.7932 18.4688C21.3706 18.1566 20.6887 17.7388 20.3094 17.5227C19.6274 17.1434 18.9311 17.3787 18.643 17.7532L18.0475 18.5024Z" fill="#665CAC"/>
    </svg>
  )
}

export default IconViber