import React from 'react';
import AppButton from '../../components/Buttons/AppButton/AppButton';
import './ModalFooterWithButtons.scss';

type Props = {
   actionButtonLabel: string;
   cancelButtonLabel: string;
   onActionButtonClick: () => void;
   onCancel?: () => void;
};

const ModalFooterWithButtons: React.FC<Props> = ({
   actionButtonLabel,
   onActionButtonClick,
   cancelButtonLabel,
   onCancel,
}) => {
   return (
      <div className={'modal-footer-with-buttons'}>
         {onCancel && (
            <AppButton onClick={onCancel} theme={'link'}>
               {cancelButtonLabel}
            </AppButton>
         )}

         <AppButton onClick={onActionButtonClick}>{actionButtonLabel}</AppButton>
      </div>
   );
};

export default ModalFooterWithButtons;
