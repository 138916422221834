import React, {FC} from "react";

interface Props {

}

const IconSecondaryDecrement: FC<Props> = (props) => {

    return (
        <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0.142822H12V1.85711H0V0.142822Z" fill="#C5C6CF"/>
        </svg>
    )
}

export default IconSecondaryDecrement
