import React, { FC, memo } from 'react';
import './PageHeader.scss';
import Breadcrumbs from '../../../../components/ui/components/Breadcrumbs/Breadcrumbs';
import { Media } from '../../../../components/common/Media/Media';
import BreadcrumbsMobile from '../../../../components/ui/components/BreadcrumbsMobile/BreadcrumbsMobile';

interface Props {
   breadcrumbs?: Array<{
      title: string;
      url?: string;
   }>;
   title?: string;
   rightComponent?: any
   breadcrumbsRightComponent?: any
}

const PageHeader: FC<Props> = memo((props) => {
   const { breadcrumbs, title, rightComponent, breadcrumbsRightComponent } = props;

   const prev = breadcrumbs[breadcrumbs.length - 2];
   const thatPath = breadcrumbs[breadcrumbs.length - 1];

   return (
      <>
         <Media at="sm">
            <BreadcrumbsMobile backPath={prev?.url} title={thatPath.title}/>
            {rightComponent}
            {breadcrumbsRightComponent}
         </Media>

         <Media greaterThan="sm">
            <div className="page-header">
               {!!breadcrumbs.length && (
                  <div className="page-header__breadcrumbs">
                     <Breadcrumbs breadcrumbs={breadcrumbs} />
                     {breadcrumbsRightComponent}
                  </div>
               )}

               <div className="page-header__header">
                  <h1 className="page-header__title">{title}</h1>
                  {rightComponent}
               </div>
            </div>
         </Media>

      </>

   );
});

export default PageHeader;
