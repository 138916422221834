import axios, { AxiosInstance } from 'axios';

export const axiosInstance: AxiosInstance = axios.create({
   baseURL: process.env.RAZZLE_BASE_URL,
   headers: {
      common: {
         // withCredentials: true,
         'Content-Type': 'application/json',
      },
   },
});
