import React from 'react';

type Props = {}

const IconTrash: React.FC<Props> = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.3248 7.4682C16.3248 7.4682 15.7818 14.2032 15.4668 17.0402C15.3168 18.3952 14.4798 19.1892 13.1088 19.2142C10.4998 19.2612 7.88779 19.2642 5.27979 19.2092C3.96079 19.1822 3.13779 18.3782 2.99079 17.0472C2.67379 14.1852 2.13379 7.4682 2.13379 7.4682"
                stroke="#E05233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.708 4.23969H0.75" stroke="#E05233" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M14.4406 4.23967C13.6556 4.23967 12.9796 3.68467 12.8256 2.91567L12.5826 1.69967C12.4326 1.13867 11.9246 0.750671 11.3456 0.750671H7.11258C6.53358 0.750671 6.02558 1.13867 5.87558 1.69967L5.63258 2.91567C5.47858 3.68467 4.80258 4.23967 4.01758 4.23967"
                stroke="#E05233" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
};

export default IconTrash;
