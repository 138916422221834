import React from "react";




const IconTelegram = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7426_23350)">
        <path d="M0 11.2333C0 7.43673 0 5.53843 0.750577 4.0935C1.38308 2.87588 2.37588 1.88308 3.5935 1.25058C5.03843 0.5 6.93673 0.5 10.7333 0.5H21.2667C25.0633 0.5 26.9616 0.5 28.4065 1.25058C29.6241 1.88308 30.6169 2.87588 31.2494 4.0935C32 5.53843 32 7.43673 32 11.2333V21.7667C32 25.5633 32 27.4616 31.2494 28.9065C30.6169 30.1241 29.6241 31.1169 28.4065 31.7494C26.9616 32.5 25.0633 32.5 21.2667 32.5H10.7333C6.93673 32.5 5.03843 32.5 3.5935 31.7494C2.37588 31.1169 1.38308 30.1241 0.750577 28.9065C0 27.4616 0 25.5633 0 21.7667V11.2333Z" fill="url(#paint0_linear_7426_23350)"/>
        <path d="M12.3333 24.9998C11.6854 24.9998 11.7955 24.7552 11.572 24.1383L9.66663 17.8675L24.3333 9.1665" fill="#C8DAEA"/>
        <path d="M12.3334 25.0002C12.8334 25.0002 13.0543 24.7715 13.3334 24.5002L16 21.9072L12.6737 19.9014" fill="#A9C9DD"/>
        <path d="M12.6733 19.9016L20.7333 25.8564C21.653 26.3639 22.3169 26.1011 22.5459 25.0025L25.8268 9.54199C26.1627 8.19529 25.3134 7.58449 24.4335 7.98396L5.16855 15.4125C3.85353 15.9399 3.8612 16.6736 4.92885 17.0005L9.87268 18.5435L21.3182 11.3227C21.8585 10.995 22.3544 11.1712 21.9474 11.5324" fill="url(#paint1_linear_7426_23350)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_7426_23350" x1="21.3344" y1="5.8344" x2="13.3344" y2="24.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#37AEE2"/>
          <stop offset="1" stopColor="#1E96C8"/>
        </linearGradient>
        <linearGradient id="paint1_linear_7426_23350" x1="18.5008" y1="15.833" x2="21.6306" y2="22.9552" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFF7FC"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
        <clipPath id="clip0_7426_23350">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconTelegram