import { useCallback, useEffect, useRef } from 'react';

export default function useEvent(handler) {
   const handlerRef = useRef(null);

   // In a real implementation, this would run before layout effects
   useEffect(() => {
      handlerRef.current = handler;
   });

   return useCallback((...args) => {
      // In a real implementation, this would throw if called during render
      const fn = handlerRef.current;
      return fn(...args);
   }, []);
}