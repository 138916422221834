import React, { FC } from 'react';
import './SearchModalMain.scss';
import ModalMobile from '../../../../../components/ui/modals/ModalMobile/ModalMobile';
import GuestsForm from '../../HotelSearchForm/components/GuestsForm/GuestsForm';
import ModalMobileHeader from '../../../../../components/ui/modals/ModalMobile/components/ModalMobileHeader/ModalMobileHeader';
import IconArrowLeft from '../../../../../components/ui/icons/account/IconArrowLeft';
import ModalMobileButtons from '../../../../../components/ui/modals/ModalMobile/components/ModalMobileButtons/ModalMobileButtons';
import { formatDate } from '../../../../../helpers/date';
import { useTranslation } from "react-i18next";

interface Props {
   // query: string;
   onSubmit: () => void;
   onClose: () => void;
   guestsParams: any;

   start: any;
   end: any;
   place: string;

   guestsMethods: {
      selectChildren: (value: number) => void
      changeKidsAge: (value: string, index: number) => void
      changeGuestForm: (name: string, value: number) => void
   }
}

const getDate = date => (date ? `${formatDate(new Date(date), 'dd MMM')}` : '');

const SearchModalMain: FC<Props> = props => {
   const { onSubmit, onClose, place, start, end, guestsParams, guestsMethods } = props;
   const {changeGuestForm, changeKidsAge, selectChildren} = guestsMethods;
   const [t] = useTranslation()
   const {rooms, adults, kids}  = guestsParams;

   // const { data, handleChange, selectChildren, changeKidsAge, kidsNum } = guestsParams;

   const formString = `${getDate(start)} - ${getDate(end)} - ${rooms} ном., ${adults + kids.length} гостей`;

   const submit = () => {
      onSubmit();
   };

   return (
      <ModalMobile
         Footer={() => {
            return <ModalMobileButtons cancel={() => {}} submit={submit} buttonText={t('Шукати')} />;
         }}
         Header={() => {
            return (
               <div className="search-modal-main__header">
                  <ModalMobileHeader
                     onClickIcon={onClose}
                     icon={<IconArrowLeft />}
                     description={formString}
                     title={place}
                  />
               </div>
            );
         }}
         className="search-modal-main"
      >
         <div className="search-modal-main__wrapper">
            <h3>Гості</h3>
            <GuestsForm
               adults={adults}
               kids={kids}
               rooms={rooms}
               handleChange={changeGuestForm}
               selectChildren={selectChildren}
               changeKidsAge={changeKidsAge}
            />
         </div>
      </ModalMobile>
   );
};

export default SearchModalMain;
