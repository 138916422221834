import React, { FC } from 'react';
import './QueryNoFound.scss';

interface Props {
   inputValue: string;
}

const QueryNoFound: FC<Props> = props => {
   const { inputValue } = props;
   if (inputValue === '') {
      return null;
   }

   return (
      <div className="query-not-found">
         <h3>За запитом “{inputValue}” нічого не знайдено</h3>
         <p>Переконайтесь, що ви правильно ввели назву населеного пункту, і спробуйте ще раз.</p>
      </div>
   );
};

export default QueryNoFound;
