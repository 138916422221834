import React, { FC } from 'react';
import './AccountOrdersDetailHotelItem.scss';
import Image from '../../../../../../components/ui/components/Image/Image';
import PriceViewer from '../../../../../../components/ui/components/PriceViewer/PriceViewer';
import { AccountOrdersReservationItem } from '../../../../../../types/account-orders/account-orders-detail';
import { formatDate } from '../../../../../../helpers/date';
import { Media } from '../../../../../../components/common/Media/Media';
import Container from '../../../../../../components/ui/layouts/Container/Container';
import StatusOrder from "../ui/status/StatusOrder/StatusOrder";
import StatusReservation from "../ui/status/StatusReservation/StatusReservation";

interface Props {
   reservation: AccountOrdersReservationItem;
   onCancel: (reservationId) => void;
   canCancel: boolean;
}

const AccountOrdersDetailHotelItem: FC<Props> = props => {
   const { onCancel, canCancel } = props;
   const {
      amount,
      contactData,
      mealType,
      nights,
      room,
      square,
      arrivalDate,
      departureDate,
      id,
      photo,
      status,
      rateId,
      roomTypeId,
   } = props.reservation;
   const picture = !Array.isArray(photo) && photo && photo?.source['302x170@2x'] ? photo?.source['302x170@2x'] : '';

   return (
      <div className="account-orders-detail-hotel-item">
         <Media at="sm">
            <Image
               className="account-orders-detail-hotel-item__image"
               aspectRatio="3:2"
               src={picture}
            />
         </Media>
         <Media greaterThan="sm">
            <Image
               className="account-orders-detail-hotel-item__image"
               width={106}
               aspectRatio="1:1"
               src={picture}
            />
         </Media>
         <div className="account-orders-detail-hotel-item__content">
            <h3 className="account-orders-detail-hotel-item__title">
               <span>
                  {room} { square && `${square} m²`}
               </span>
               <span><StatusReservation status={status.alias}>{status.name}</StatusReservation></span>
            </h3>
            <div className="account-orders-detail-hotel-item__description">
               {formatDate(new Date(arrivalDate), 'dd MMMM')} - {formatDate(new Date(departureDate), 'dd MMMM')},{' '}
               {mealType}
            </div>
            <div className="account-orders-detail-hotel-item__name">
               {contactData} <span></span>
               {/*{canCancel && status.alias !== 'cancelled' && <button onClick={() => onCancel(id)}>Скасувати</button>}*/}
            </div>
            <div className="account-orders-detail-hotel-item__description">
               Ціна за {nights} ночі{' '}
               <span>
                  <PriceViewer price={amount} />
               </span>
            </div>
         </div>
      </div>
   );
};

export default AccountOrdersDetailHotelItem;
