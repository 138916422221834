import React, { FC } from 'react';
import './MessageUserItem.scss';
import Image from '../../../../../components/ui/components/Image/Image';

interface Props {
   text:string
   status: string
   name: string
   hotelName: string
}

const MessageUserItem: FC<Props> = props => {
   const {
      text,
      status,
      name,
      hotelName,
   } = props;

   return (
      <div className="message-user-item">
         <div className="message-user-item__photos">
            <div className="message-user-item__photo message-user-item-photo-owner">
               <Image
                  aspectRatio="1:1"
                  src="https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193"
               />
            </div>
            <div className="message-user-item__photo message-user-item-photo-client">
               <Image aspectRatio="1:1" src="https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg" />
            </div>
         </div>
         <div className="message-user-item__text">
            <div className="message-user-item__header">
               <div className="message-user-item__hotel-name">
                  {hotelName}
                  <span className="message-user-item__owner">•{name}</span>
               </div>
            </div>
            <div className="message-user-item__message">{text}</div>
            <div className="message-user-item__info">{status}</div>
         </div>
      </div>
   );
};

export default MessageUserItem;
