import React, { FC } from 'react';
import './OrderDetail.scss';
import { OrderType } from '../HotelBookingSummary';
import Image from '../../../../components/ui/components/Image/Image';
import StarReviewDistance from '../../../../components/hotels/StarReviewDistance';
import OrderRoomItem from './OrderRoomItem/OrderRoomItem';
import PriceViewer from '../../../../components/ui/components/PriceViewer/PriceViewer';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';

interface Props {
   data: OrderType;
}

const OrderDetail: FC<Props> = props => {
   const { nameOfHotel, address, reviews, stars, distance, countPrice, rooms, image } = props.data;

   return (
      <div className="order-detail">
         <div className="order-detail__title">Ваше бронювання</div>
         <div className="order-detail__block-hotel-information">
            <Image className="order-detail__image" aspectRatio={'1:1'} src={image} />
            <div className="order-detail__block-description">
               <div className="order-detail__hotel-name">{nameOfHotel}</div>
               <div className="order-detail__address">{address}</div>
               <StarReviewDistance stars={stars} reviews={reviews} distance={distance} />
            </div>
         </div>
         <div>
            {rooms.map((el, index) => {
               return <OrderRoomItem data={el} />;
            })}
         </div>
         <div className="order-detail__block-count-price">
            <div className="order-detail__price-text">Сума</div>
            <div className="order-detail__price">
               <PriceViewer price={countPrice} />
            </div>
         </div>

         <div className="order-detail__button-wrapper">
            <AppButton className="order-detail__button" size="middle" to={'/hotels/success'}>
               Бронювати і оплатити
            </AppButton>
         </div>
      </div>
   );
};

export default OrderDetail;
