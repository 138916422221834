import React, { FC, useState } from 'react';
import './HotelReviewItem.scss';
import Stars from '../../../../../../components/ui/icons/hotels/Stars';
import { Review } from '../../../../../../types/reviews';
import ShowMoreButton from '../../ShowMoreButton/ShowMoreButton';
import AppMedia from '../../../../../../components/common/Media/Media';
import { _formatDate } from '../../../../../../helpers/date';
import { DATE_FORMAT_CLIENT, DATE_FORMAT_CLIENT_SHORT, DATE_FORMAT_SERVER } from '../../../../../../config';

interface Props {
    review: Review;
    isModal: boolean;
}

const textSize = 150;

const HotelReviewItem: FC<Props> = ({isModal, review}) => {
    const [showMore, setShowMore] = useState(false);
    const {createdAt, id, mark, user, text} = review;

    const ReviewDate = () => <div className="review-item__date">{_formatDate(createdAt, DATE_FORMAT_CLIENT)}</div>

    return (
        <div className="review-item">
            <div className="review-item__header">
                <div className="review-item__personal-info">
                    <img className="review-item__image" src={user.photo}/>

                    <div className="review-item__block-name">
                        <div className="review-item__name">
                            {user.name} {user.lastname}
                        </div>

                        <ReviewDate/>
                    </div>
                </div>

                <div className={'review-item__stars-container'}>
                    <Stars stars={mark}/>
                </div>
            </div>

            <div className="review-item__text">{isModal ? text : showMore ? text : text.substring(0, textSize)}</div>

            <AppMedia.Media greaterThanOrEqual={'md'}>
                {!isModal && text.length > textSize && (
                    <ShowMoreButton onClick={() => setShowMore(!showMore)} isShowingMore={showMore}/>
                )}
            </AppMedia.Media>
        </div>
    );
};

export default HotelReviewItem;
