import React from 'react';

const FooterSiteLogo: React.FC = () => (
  <svg width="132" height="28" viewBox="0 0 132 28" fill="none" xmlns="http://www.w3.org/2000/svg"  className={'footer-site-logo'}>
      <g clipPath="url(#clip0_9404_3351)">
          <path d="M63.2194 21.9473H45.3405C41.0503 21.9473 37.5576 18.4546 37.5576 14.1644C37.5576 9.87413 41.0503 6.38147 45.3405 6.38147H63.2194C67.5097 6.38147 71.0024 9.87413 71.0024 14.1644C71.0024 18.4546 67.5097 21.9473 63.2194 21.9473ZM45.3405 9.78177C42.9225 9.78177 40.9579 11.7464 40.9579 14.1644C40.9579 16.5824 42.9225 18.547 45.3405 18.547H63.2194C65.6374 18.547 67.6021 16.5824 67.6021 14.1644C67.6021 11.7464 65.6374 9.78177 63.2194 9.78177H45.3405Z" fill="white" fillOpacity="0.33"/>
          <path d="M13.014 8.42927C14.0802 9.02957 14.9156 9.86915 15.5159 10.9438C16.112 12.0185 16.4143 13.2485 16.4143 14.638C16.4143 16.0275 16.0826 17.3288 15.4194 18.4287C14.7561 19.5327 13.8284 20.3891 12.6278 20.9978C11.4314 21.6107 10.067 21.9129 8.539 21.9129C5.97827 21.9129 4.00106 21.0608 2.59896 19.3564C1.20105 17.6605 0.5 15.2047 0.5 12.0017C0.5 9.02117 1.13388 6.65775 2.40585 4.91562C3.67782 3.17349 5.61725 2.02747 8.23255 1.46914L15.0835 -0.00012207L15.5789 3.11052L9.36599 4.38669C7.58187 4.7561 6.24694 5.38579 5.36118 6.27574C4.47542 7.1657 3.96328 8.41667 3.81635 10.0245C4.47962 9.22687 5.28142 8.61398 6.21755 8.17739C7.15789 7.74081 8.18637 7.52252 9.31141 7.52252C10.7093 7.52252 11.9435 7.82477 13.014 8.42507V8.42927ZM11.7042 17.8746C12.485 17.0518 12.8796 15.9981 12.8796 14.7219C12.8796 13.4458 12.4892 12.4173 11.7042 11.6365C10.9192 10.8599 9.89492 10.4694 8.62296 10.4694C7.35099 10.4694 6.29312 10.8599 5.50391 11.6365C4.7105 12.4131 4.3159 13.4416 4.3159 14.7219C4.3159 16.0023 4.7147 17.0728 5.5165 17.8871C6.3183 18.7015 7.35519 19.1087 8.62296 19.1087C9.89073 19.1087 10.9192 18.6973 11.7042 17.8746Z" fill="white" fillOpacity="0.6"/>
          <path d="M31.299 7.58598C32.4492 8.21567 33.3434 9.10562 33.9773 10.2516C34.6112 11.4019 34.9302 12.7326 34.9302 14.2523C34.9302 15.7719 34.628 17.0774 34.0193 18.2235C33.4106 19.3737 32.5584 20.2595 31.4627 20.8891C30.3671 21.5188 29.0993 21.8337 27.6636 21.8337C25.6192 21.8337 24.0198 21.1746 22.857 19.8607V27.0265H19.377V14.2732C19.377 12.7746 19.7086 11.4439 20.3719 10.2852C21.0351 9.12661 21.9587 8.22826 23.1467 7.59018C24.3347 6.9521 25.7116 6.63306 27.2774 6.63306C28.8432 6.63306 30.1446 6.9479 31.2948 7.57758L31.299 7.58598ZM30.2915 17.6022C31.1017 16.7416 31.5089 15.625 31.5089 14.2564C31.5089 12.8879 31.1017 11.7671 30.2915 10.8939C29.4813 10.025 28.4318 9.58838 27.1431 9.58838C25.8543 9.58838 24.7839 10.0124 23.9947 10.8645C23.2012 11.7167 22.8066 12.8376 22.8066 14.2271C22.8066 15.6166 23.2054 16.7416 24.0072 17.6022C24.8091 18.4627 25.8543 18.8951 27.1431 18.8951C28.4318 18.8951 29.4813 18.4627 30.2915 17.6022Z" fill="white" fillOpacity="0.6"/>
          <path d="M74.8037 6.80542H78.2544V12.8336H85.6847V6.80542H89.1354V21.6408H85.6847V15.7805H78.2544V21.6408H74.8037V6.80542Z" fill="white" fillOpacity="0.6"/>
          <path d="M110.848 7.59836C112.007 8.23644 112.922 9.13479 113.581 10.2934C114.244 11.452 114.576 12.7618 114.576 14.2227C114.576 15.6835 114.244 16.9975 113.581 18.1519C112.918 19.3105 112.007 20.2131 110.848 20.8595C109.69 21.506 108.372 21.8334 106.898 21.8334C105.609 21.8334 104.43 21.5774 103.364 21.0694C102.297 20.5615 101.411 19.8394 100.71 18.9033C100.009 17.9672 99.5602 16.8967 99.3587 15.6961H96.8735V21.6403H93.4229V6.80495H96.8735V12.5561H99.4133C99.6358 11.3891 100.093 10.3564 100.794 9.45803C101.495 8.55968 102.369 7.86702 103.418 7.37587C104.468 6.88471 105.626 6.64124 106.898 6.64124C108.372 6.64124 109.69 6.96028 110.848 7.59836ZM109.081 18.3198C109.728 17.942 110.231 17.3921 110.601 16.6826C110.97 15.969 111.155 15.1504 111.155 14.2227C111.155 12.8164 110.756 11.6913 109.954 10.8475C109.152 10.0038 108.137 9.58397 106.902 9.58397C105.668 9.58397 104.656 10.0038 103.863 10.8475C103.07 11.6913 102.675 12.8164 102.675 14.2227C102.675 15.6289 103.074 16.7582 103.876 17.6104C104.677 18.4625 105.685 18.8865 106.898 18.8865C107.708 18.8865 108.435 18.6976 109.081 18.3156V18.3198Z" fill="white" fillOpacity="0.6"/>
          <path d="M131.782 6.80603V21.6414H128.495V19.7524C127.941 20.4198 127.252 20.932 126.425 21.293C125.599 21.654 124.704 21.8345 123.747 21.8345C121.778 21.8345 120.225 21.2846 119.092 20.1806C117.958 19.0807 117.392 17.4435 117.392 15.2774V6.80603H120.842V14.8072C120.842 16.1422 121.14 17.1371 121.741 17.7919C122.337 18.451 123.189 18.7785 124.297 18.7785C125.531 18.7785 126.509 18.3922 127.24 17.624C127.966 16.8558 128.331 15.7476 128.331 14.3035V6.80184H131.782V6.80603ZM121.136 4.01443C120.351 3.32177 119.952 2.30588 119.936 0.970947H122.228C122.244 1.63841 122.454 2.16735 122.849 2.55356C123.243 2.94396 123.802 3.13707 124.52 3.13707C125.237 3.13707 125.775 2.94396 126.19 2.55356C126.606 2.16315 126.82 1.63841 126.841 0.970947H129.188C129.171 2.30588 128.764 3.31758 127.97 4.01443C127.177 4.70708 126.035 5.05551 124.545 5.05551C123.055 5.05551 121.917 4.70708 121.132 4.01443H121.136Z" fill="white" fillOpacity="0.6"/>
      </g>
      <defs>
          <clipPath id="clip0_9404_3351">
              <rect width="131.281" height="27.0303" fill="white" transform="translate(0.5)"/>
          </clipPath>
      </defs>
  </svg>
);

export default FooterSiteLogo;
