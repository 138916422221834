import React, {FC} from "react";
import "./Icon.scss";

interface Props {

}

const IconRight: FC<Props> = (props) => {

  return (
    <div {...props}>
      <svg width="24" height="42" viewBox="0 0 24 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.6812 2.00221L22.0001 21.1641L1.6812 40.326" stroke="#00B1B2" strokeWidth="3" strokeLinecap="round"
              strokeLinejoin="round"/>
      </svg>
    </div>


  )
}

export default IconRight