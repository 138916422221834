import { pluralize } from '../../../../../../../helpers/translatioHelpers';

export const getOptionsForRoomCountSelect = (roomsMaxCount: number, removeOptionInEnd: boolean = false, isSelected = false): Array<{ value: any; label: string }> => {
    const removeOption = {value: 0, label: 'Видалити'};
    const options = [];

    for (let i = 1; i <= roomsMaxCount; i++) {
        options.push({
            value: i,
            label: pluralize('номер', i, true),
        })
    }

    if (isSelected) {
        if (removeOptionInEnd) options.push(removeOption);
        else options.unshift(removeOption);
    }

    return options;
};
