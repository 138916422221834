import React from 'react';
import { getPercentFromValue, getValueFromPercent } from '../../../../../../../helpers/common';
import { FiltersInitPriceData } from '../../../../../../../types/hotel-filters';

//const ratioV = 1.0957549999999998;

export const getPriceForShow = (min, max, value, ratio = 2, columnsCount, prices) => {
    const step = max / columnsCount;
    const currentStep = value > step ? Math.floor(value / step) : 0;
    const stepPositionInPercents = ((value / step) - currentStep) * 100;
    const x = getPercentFromValue(value, max);

    if (prices[currentStep]) {
        const price = (stepPositionInPercents / 100) * (prices[currentStep].end - prices[currentStep].start) + prices[currentStep].start;
        return Math.floor(price);
    }

    return (((x / 100)) ** (ratio / (currentStep + 1)) * (max - min)) + min;
}

export const getPosition = (value, min, max) => {
    return ((max / (max - min)) ** 0.5) * (((value - min) / max) ** 0.5) * 100;
}

export const convertPositionForShow = (min, max, value) => {
    const x = getPercentFromValue(value, max);
    return (value * x) - ((max - min) / x) - value / 2;
}

const getStepDataFromPriceDataByPrice = (value, priceData: FiltersInitPriceData) => {
    return priceData.data.findIndex((priceItem) => (value >= priceItem.start && value <= priceItem.end));
}

const getPercentDataByStep = (step: number, columnsCount: number) => (step / columnsCount) * 100;

export const getInitialPricePositions = (priceData: FiltersInitPriceData, values) => {
    const minPrice = Math.ceil(Number(priceData.min));
    const maxPrice = Math.ceil(Number(priceData.max));
    const columnsCount = priceData.data.length;

    let priceFrom = priceData.min;
    let priceTo = priceData.max;
    let percentFrom = 0;
    let percentTo = 100;

    if (values && typeof values.min !== 'undefined' && typeof values.max !== 'undefined') {
        percentFrom = getPercentDataByStep(getStepDataFromPriceDataByPrice(values.min, priceData), columnsCount);
        percentTo = values.max !== priceTo ? getPercentDataByStep(getStepDataFromPriceDataByPrice(values.max, priceData), columnsCount) : percentTo;

        priceFrom = (maxPrice * percentFrom) / 100;
        priceTo = values.max !== priceTo ? (maxPrice * percentTo) / 100 : priceTo;
    }

    return {
        minPrice,
        maxPrice,
        priceFrom,
        priceTo,
        percentFrom,
        percentTo
    }
}

