import React from 'react';
import './MobileRoomsList.scss';

import MobileRoomListItem from './components/MobileRoomListItem/MobileRoomListItem';
import { RoomItemPrepared } from '../../../../../../types/rooms';
import MobileRoomsListFooter from './components/MobileRoomsListFooter/MobileRoomsListFooter';
import useOrderLogic from '../../../../../../hooks/useOrderLogic';
import HotelRoomNoData from '../../../components/HotelRooms/components/HotelRoomNoData/HotelRoomNoData';
import { useAppSelector } from '../../../../../../hooks/reduxHooks';
import { getHotelSelector } from '../../../../../../store/hotel/hotel-selector';

type Props = {
    onSelectRoom: (room: RoomItemPrepared) => void
    rooms: RoomItemPrepared[],
    onFocusOnSearchForm: () => void
}

const MobileRoomsList: React.FC<Props> = ({onSelectRoom, rooms, onFocusOnSearchForm}) => {
    const {reservations} = useOrderLogic();
    const {bookingState, selectedOrderBookType} = useAppSelector(getHotelSelector);

    let paddingBottom = 16;
    //if (canBookOnCart) paddingBottom += 50;
    if (reservations?.length) paddingBottom += 70;
    const showRooms = rooms && !!rooms.length;

    return (
        <div style={{paddingBottom}}>
            {!showRooms && <HotelRoomNoData/>}

            <div>
                {showRooms && rooms.map((room, index) => (
                    <div
                        className='mobile-rooms-list-item-container'
                        key={'mobileRoomListItem' + room.id + index}
                    >
                        <MobileRoomListItem
                            room={room}
                            bookingState={selectedOrderBookType}
                            onSelectRoom={() => onSelectRoom(room)}
                            onFocusOnEditForm={onFocusOnSearchForm}
                        />
                    </div>
                ))}
            </div>

            <MobileRoomsListFooter/>
        </div>
    );
}

export default MobileRoomsList;
