import React, {FC} from "react";
// import "./NoPhotoIcon.scss";

interface Props {

}

const NoPhotoIcon: FC<Props> = (props) => {

  return (
    <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="62" cy="62" r="62" fill="#F4F2F6"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M49.7746 43.0345C57.9096 42.3274 66.0907 42.3274 74.2257 43.0345L78.1272 43.3737C81.8602 43.6982 84.8845 46.5389 85.4417 50.2444C86.6136 58.0376 86.6136 65.9622 85.4417 73.7554C85.3392 74.4371 85.1532 75.0896 84.8946 75.7025C84.7231 76.1089 84.1942 76.1706 83.8975 75.8442L72.4754 63.28C71.9594 62.7123 71.1569 62.5026 70.4291 62.7452L63.8911 64.9245L54.4066 54.2544C54.0521 53.8556 53.5488 53.6206 53.0154 53.6049C52.482 53.5893 51.9658 53.7943 51.5884 54.1716L38.8936 66.8664C38.5029 67.2571 37.8366 67.0084 37.8054 66.4568C37.5 61.0476 37.751 55.6148 38.5586 50.2444C39.1158 46.5389 42.1401 43.6982 45.8732 43.3737L49.7746 43.0345ZM67.1668 54.2499C67.1668 52.1098 68.9017 50.3749 71.0418 50.3749C73.1819 50.3749 74.9168 52.1098 74.9168 54.2499C74.9168 56.39 73.1819 58.1249 71.0418 58.1249C68.9017 58.1249 67.1668 56.39 67.1668 54.2499Z" fill="#C5C6CF"/>
      <path d="M38.6503 72.5898C38.5073 72.7328 38.4393 72.9346 38.4678 73.1348C38.4973 73.3418 38.5275 73.5486 38.5586 73.7554C39.1158 77.4609 42.1401 80.3017 45.8732 80.6262L49.7746 80.9653C57.9096 81.6725 66.0907 81.6725 74.2257 80.9653L78.1272 80.6262C79.2241 80.5308 80.2599 80.2182 81.1898 79.7302C81.5422 79.5453 81.6016 79.0793 81.3339 78.7848L70.7401 67.1318C70.5681 66.9425 70.3006 66.8726 70.058 66.9535L63.9045 69.0047C63.1692 69.2498 62.3586 69.0331 61.8437 68.4538L53.3306 58.8765C53.0837 58.5988 52.6539 58.5862 52.3912 58.8489L38.6503 72.5898Z" fill="#C5C6CF"/>
    </svg>

  )
}

export default NoPhotoIcon