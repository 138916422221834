import { useRef } from 'react';

export default function useDebounce(callback, delay) {
   const ref = useRef<any>();

   const debounce = (...args) => {
      clearTimeout(ref.current);

      const promise = new Promise((resolve, reject) => {
         ref.current = setTimeout(() => {
            callback(...args)
               .then(data => {
                  resolve(data);
               })
               .catch(error => {
                  reject(error);
               });
         }, delay);
      });

      return promise;
   };

   return debounce;
}
