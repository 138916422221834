import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import "./ModalMobile.scss";
import useModal from "../Modal/hooks/useModal";
import { allowRootScroll } from '../../../../helpers/common';

interface Props {
    backPath?: string;
    className?: string;
    onClose?: () => void;
    Footer?: FC;
    Header?: FC;
}

const ModalMobile: FC<Props> = (props) => {
    const history = useHistory();
    const {children, className = '', backPath, onClose, Footer, Header} = props;
    const classes = classNames(className, 'modal-mobile__inner');

    const FooterComponent = Footer;

    useModal(goBack);

    useEffect(() => {
        allowRootScroll(false);

        return () => {
            allowRootScroll(true);
        }
    }, [])

    function goBack() {
        if (onClose) {
            onClose()
        } else if (!onClose && backPath) {
            history.push(backPath)
        } else {
            history.goBack()
        }
    }

    return (
        <div className="modal-mobile">
            <div className="modal-mobile__background" onClick={() => onClose ? onClose() : {}}/>

            <div className={classes}>
                {Header && (
                    <div className="modal-mobile__header">
                        <Header/>
                    </div>
                )}

                <div className="modal-mobile__body">
                    {children}
                </div>

                {FooterComponent && (
                    <div className="modal-mobile__footer">
                        <FooterComponent/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ModalMobile
