import React, { ChangeEvent, FC, FocusEvent } from 'react';
import './Mui.scss';
import classNames from 'classnames';
import IconDropDown from '../../icons/form/IconDropDown';

interface Props {
   isError: boolean;
   label: string;
   disabled?: boolean;
   id?: string;
   name?: string;
   readOnly?: boolean;
   onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
   onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
   onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
   onClick?: (event: any) => void;
}

const MuiFieldSelect: FC<Props> = props => {
   const { isError, label, children, ...rest } = props;
   const classes = classNames('mui-form-group', { isError });

   return (
      <div>
         <div className={classes}>
            {/*@ts-ignore*/}
            <select required={true} autoComplete="off" {...rest}>
               {children}
            </select>
            <label htmlFor="input" className="control-label">
               {label}
            </label>
            <i className="bar"></i>
            <IconDropDown className="mui-select-icon" />
         </div>
      </div>
   );
};

export default MuiFieldSelect;
