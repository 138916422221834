import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  title?: string;
  metaDescription?: string;
  metaKeywords?: string;
}

const Head: FC<Props> = props => {
  const { ogTitle, ogDescription, ogImage, title, metaDescription, metaKeywords, children } = props;

  return (
    <Helmet>
        {title && <title>{title}</title>}
        {ogTitle && <meta property="og:title" content={ogTitle}/>}
        {ogDescription && <meta property="og:description" content={ogDescription}/>}
        {ogImage && <meta property="og:image" content={ogImage}/>}
        {metaDescription && <meta name="description" content={metaDescription}/>}
        {metaKeywords && <meta name="keywords" content={metaKeywords}/>}
        {children}
    </Helmet>
  );
};

export default Head;
