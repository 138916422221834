import React, { FC } from 'react';

interface Props {}

const IconSort: FC<Props> = props => {
   return (
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
            d="M7.45933 12.8307C7.18984 12.5612 6.75291 12.5612 6.48341 12.8307L3.83071 15.4834L1.17805 12.8307C0.908548 12.5612 0.471587 12.5612 0.202123 12.8307C-0.0673743 13.1002 -0.0673743 13.5371 0.202123 13.8066L3.34277 16.9472C3.47219 17.0767 3.64773 17.1494 3.83075 17.1494C4.01379 17.1494 4.1893 17.0767 4.31872 16.9472L7.45937 13.8066C7.7288 13.5371 7.7288 13.1002 7.45933 12.8307ZM3.83071 5.84467C3.44957 5.84467 3.14064 6.15364 3.14064 6.53474V16.4593C3.14064 16.8404 3.44964 17.1494 3.83075 17.1494C4.21189 17.1494 4.52079 16.8404 4.52079 16.4593V6.53474C4.52079 6.15364 4.21185 5.84467 3.83071 5.84467Z"
            fill="#C5C6CF"
         />
         <path
            d="M6.54067 4.75711C6.81016 5.02661 7.24709 5.02661 7.51659 4.75711L10.1693 2.10441L12.822 4.75711C13.0915 5.02657 13.5284 5.02661 13.7979 4.75711C14.0674 4.48761 14.0674 4.05068 13.7979 3.78119L10.6572 0.640543C10.5278 0.511116 10.3523 0.438413 10.1693 0.438413C9.98621 0.438413 9.8107 0.511116 9.68128 0.640543L6.54063 3.78119C6.2712 4.05065 6.2712 4.48761 6.54067 4.75711ZM10.1693 11.7431C10.5504 11.7431 10.8594 11.4341 10.8594 11.053L10.8594 1.12849C10.8594 0.747348 10.5504 0.438413 10.1693 0.438413C9.78811 0.438413 9.47921 0.747348 9.47921 1.12849V11.053C9.47921 11.4341 9.78815 11.7431 10.1693 11.7431Z"
            fill="#C5C6CF"
         />
      </svg>
   );
};
export default IconSort;
