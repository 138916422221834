import { AppState } from "../index";
import { FilterGroup, FiltersInitDataPrepared } from '../../types/hotel-filters';
import { HotelListItemType } from '../../types/hotels';
import { rheostatData } from '../../mocks/rheostat';
import { DISABLE_STARS_SHOW } from '../../config';

const getHotels = (state: AppState, isForMap) => {
    const searchKey = isForMap ? 'mapHotelsData' : 'data';
    let mealTypes = state?.hotels?.[searchKey]?.meta?.mealTypes?.data;
    let properties = state?.hotels?.[searchKey]?.meta?.properties?.data

    if (!Array.isArray(mealTypes))
        mealTypes = [];

    if (!Array.isArray(properties))
        properties = [];

    const hotelsData = state.hotels[searchKey];

    const hotelsDataFormatted = hotelsData.data.map((hotelListItem: HotelListItemType) => {
        const mealType = mealTypes.find(mt => mt.id === hotelListItem.mealTypeId);
        const hotelProperties = properties.filter(p => hotelListItem.properties.includes(p.id));

        let preparedProperties = [];

        hotelListItem.properties.forEach(propertyId => {
            const propertyToFind = hotelProperties.find(p => p.id === propertyId);
            if (propertyToFind) preparedProperties.push(propertyToFind);
        });

        return {
            ...hotelListItem,
            stars: DISABLE_STARS_SHOW ? 0 : hotelListItem.stars,
            mealType: mealType,
            preparedProperties
        };
    })

    return {
        ...state.hotels,
        [searchKey]: {
            ...hotelsData,
            data: hotelsDataFormatted
        }
    }
}

export const getHotelsSelector = (state: AppState) => {
    return getHotels(state, false);
}

export const getHotelsForMapSelector = (state: AppState) => {
    return getHotels(state, true);
}

export const getSortingOptions = (state: AppState) => {
    return state?.hotels?.data?.meta?.sortable || [];
}

export const getHotelInitialFiltersSelector = (state: AppState): FiltersInitDataPrepared => {
    const result = state?.hotels?.data?.meta?.filtersInitData;

    return {
        ...result,
        // price: rheostatData,
        filterGroups: Array.isArray(result?.filterGroups) ? result.filterGroups
            .map((filterGroup: FilterGroup) => {
                if (filterGroup.meta) {
                    const properties = state?.hotels?.data?.meta?.[filterGroup.meta]?.data || [];

                    return {
                        ...filterGroup,
                        data: filterGroup.data.map(filterGroupDataItem => {
                            const property = properties.find(property => property.id === filterGroupDataItem);

                            if (property) return {...property, label: property.name};
                        })
                    }
                } else {
                    return {
                        ...filterGroup,
                        data: filterGroup.data.map(filterGroupDataItem => {
                            if (typeof filterGroupDataItem === 'object') return {
                                ...filterGroupDataItem,
                                id: filterGroupDataItem.alias
                            }
                        })
                    }
                }
            }) : []
    };
}
