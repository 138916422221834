import React from 'react';

type Props = {}

const SearchOptionHotelIcon: React.FC<Props> = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="6" fill="#F4F2F6"/>
            <path
                d="M27 9C27.3784 8.99988 27.7429 9.1428 28.0204 9.40012C28.2979 9.65744 28.4679 10.0101 28.4963 10.3875L28.5 10.5V16.3792L29.8845 20.532C29.9226 20.6465 29.9515 20.7639 29.9708 20.883L29.9932 21.063L30 21.243V28.5H28.5V27H7.5V28.5H6V21.2438C6.00001 21.0625 6.02192 20.882 6.06525 20.706L6.1155 20.532L7.5 16.377V10.5C7.49998 10.1415 7.62833 9.79494 7.86181 9.52294C8.09528 9.25095 8.41843 9.07156 8.77275 9.01725L8.88825 9.00375L9 9H27ZM28.5 22.5H7.5V25.5H28.5V22.5ZM27.459 18H8.54025L7.5405 21H28.4587L27.459 18ZM27 10.5H9V16.5H10.5V13.5C10.4999 13.1216 10.6428 12.7571 10.9001 12.4796C11.1574 12.2021 11.5101 12.0321 11.8875 12.0038L12 12H24C24.3784 11.9999 24.7429 12.1428 25.0204 12.4001C25.2979 12.6574 25.4679 13.0101 25.4963 13.3875L25.5 13.5V16.5H27V10.5ZM17.25 13.5H12V16.5H17.25V13.5ZM24 13.5H18.75V16.5H24V13.5Z"
                fill="#C5C6CF"
            />
        </svg>
    )
};

export default SearchOptionHotelIcon;
