import React, {FC} from "react";

interface Props {

}

const IconToolbarAccount: FC<Props> = (props) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 14C12.7911 14 13.5645 13.7654 14.2223 13.3259C14.8801 12.8864 15.3928 12.2616 15.6955 11.5307C15.9983 10.7998 16.0775 9.99556 15.9231 9.21964C15.7688 8.44372 15.3878 7.73098 14.8284 7.17157C14.269 6.61216 13.5563 6.2312 12.7804 6.07686C12.0044 5.92252 11.2002 6.00173 10.4693 6.30448C9.73836 6.60723 9.11365 7.11992 8.67412 7.77772C8.2346 8.43552 8 9.20888 8 10C8 11.0609 8.42143 12.0783 9.17157 12.8284C9.92172 13.5786 10.9391 14 12 14ZM12 8C12.3956 8 12.7822 8.1173 13.1111 8.33706C13.44 8.55683 13.6964 8.86918 13.8478 9.23463C13.9991 9.60009 14.0387 10.0022 13.9616 10.3902C13.8844 10.7781 13.6939 11.1345 13.4142 11.4142C13.1345 11.6939 12.7781 11.8844 12.3902 11.9616C12.0022 12.0387 11.6001 11.9991 11.2346 11.8478C10.8692 11.6964 10.5568 11.44 10.3371 11.1111C10.1173 10.7822 10 10.3956 10 10C10 9.46957 10.2107 8.96086 10.5858 8.58579C10.9609 8.21072 11.4696 8 12 8Z" className="toolbar-svg-fill" fill="#C5C6CF"/>
      <path d="M11.9999 13C10.1434 13 8.36294 13.7375 7.05019 15.0503C5.96559 16.1348 5.27367 17.5387 5.06592 19.0409L6.99994 20C6.99994 18.6739 7.52672 17.4021 8.4644 16.4645C9.40208 15.5268 10.6739 15 11.9999 15C13.326 15 14.5978 15.5268 15.5355 16.4645C16.4732 17.4021 16.9999 18.6739 16.9999 20L18.9124 18.8962C18.6813 17.4487 17.9994 16.0999 16.9497 15.0503C15.6369 13.7375 13.8565 13 11.9999 13Z" fill="#C5C6CF" className="toolbar-svg-fill"/>
      <circle cx="12" cy="12" r="9" stroke="#C5C6CF"  strokeWidth="2" className="toolbar-svg-stroke"/>
    </svg>

  )
}

export default IconToolbarAccount