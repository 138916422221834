import { AppState } from '../index';

export const orderDataSelector = (state: AppState) => {
    const reservations = state.order.reservations;

    const totalPrice = reservations.reduce((acc, reservation) => {
        return acc + reservation.price.totalPrice * reservation.count;
    }, 0);

    const totalRoomsCount = reservations.reduce((acc, reservation) => {
        return acc + reservation.count;
    }, 0);

    return {
        totalPrice,
        totalRoomsCount,
        reservations,
    };
};

export const getPaymentTypeSelector = (state: AppState) => {
    const paymentType = state?.order?.selectedPaymentType;
    const paymentTypes = state?.order?.serverOrderData?.data?.paymentTypes;

    if (!paymentType && Array.isArray(paymentTypes) && paymentTypes.length) {
        return paymentTypes[0];
    }

    return paymentType;

}
