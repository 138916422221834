import React from 'react';
import "./TextWithIcon.scss"

const TextWithIcon = ({ text, Icon }) => {
   return (
      <div className="text-with-icon">
         <Icon />
         {text}
      </div>
   );
};

export default TextWithIcon;