import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './SelectedHotelInList.scss';

import { HotelListItemType } from '../../../../../../types/hotels';
import SelectedHotelInListGallery from './componentns/SelectedHotelInListGallery';
import FavoriteFunctionality from '../../../components/FavoriteFunctionality/FavoriteFunctionality';
import StarsRating from '../../../../../../components/ui/components/StarsRating/StarsRating';
import IconFavorites from '../../../../../../components/ui/icons/hotels/IconFavorites';
import HotelPriceWithMeal from '../../../../../../components/hotels/HotelPriceWithMeal/HotelPriceWithMeal';
import { MobileOnly, TabletAndUpper } from '../../../../../../components/common/Media/MediaHelpers';
import HotelListItem from '../../../HotelListItem/HotelListItem';
import useWindowSize from '../../../../../../hooks/useWindowSize';

type Props = {
    hotel: HotelListItemType,
    generatedLink: string;
}

const SelectedHotelInList: React.FC<Props> = ({hotel, generatedLink}) => {
    const properties = hotel.preparedProperties.slice(0, 10).map(p => p.name).join(', ');
    const {search} = useLocation();

    const link = generatedLink || `/hotels/${hotel.alias}${search}`;
    const {isMobile} = useWindowSize();
    const target = process.env.RAZZLE_IS_DEV || isMobile ? '_self' : '_blank';

    return (
        <div className={'selected-list-hotel-item-container'}>
            <MobileOnly>
                <HotelListItem
                    hotelItem={hotel}
                    generatedLink={link}
                    fromList
                    selected
                />
            </MobileOnly>

            <TabletAndUpper>
                <NavLink
                    to={link}
                    className={'selected-list-hotel-item'}
                    target={target}
                >
                    {hotel.photos.length && (
                        <div>
                            <SelectedHotelInListGallery
                                photos={hotel.photos.slice(0, 5)}
                            />
                        </div>
                    )}

                    <div className={'selected-list-hotel-item__content'}>
                        <div className={'selected-list-hotel-item__name-container'}>
                            <span className={'selected-list-hotel-item__name'}>{hotel.name}</span>
                        </div>

                        <span className={'selected-list-hotel-item__distance'}>
                            {hotel.distanceToPlaceFormatted}

                            {hotel.placeName && (
                                <span className={'selected-list-hotel-item__place-name'}>
                                   {hotel.placeName}
                               </span>
                            )}
                        </span>

                        <div className={'selected-list-hotel-item__properties'}>
                            {properties}
                        </div>

                        <div className={'selected-list-hotel-item__bottom-section'}>
                            <div className={'selected-list-hotel-item__bottom-section__rating-reviews-texts'}>
                                {(hotel.stars && hotel.stars > 0) ? <StarsRating stars={hotel.stars}/> : null}

                                {hotel.reviewsCount ? <><span>Відгуки&nbsp;•&nbsp;</span>{hotel.reviewsCount}</> : null}
                            </div>

                            <HotelPriceWithMeal
                                price={hotel.minPrice}
                                oldPrice={hotel.fullPrice}
                                mealType={hotel.mealType}
                            />
                        </div>
                    </div>
                </NavLink>

                <div className={'selected-list-hotel-item-container__favorite-container'}>
                    <FavoriteFunctionality
                        id={hotel.id}
                        isFavorite={hotel.isFavorite}
                        FavoriteButton={() => <IconFavorites size={'big'} isSelected={hotel.isFavorite}/>}
                    />
                </div>
            </TabletAndUpper>


        </div>
    )
};

export default SelectedHotelInList;
