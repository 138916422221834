import React, { FC } from 'react';
import './HotelsIcons.scss';

interface Props {}

const IconFilter: FC<Props> = props => {
   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M16 19V5" stroke="#C5C6CF" strokeWidth="1.6" strokeLinecap="round" />
         <path d="M8 19L8 5" stroke="#C5C6CF" strokeWidth="1.6" strokeLinecap="round" />
         <circle cx="16" cy="15" r="1.8" fill="white" stroke="#C5C6CF" strokeWidth="1.6" />
         <circle cx="8" cy="9" r="1.8" fill="white" stroke="#C5C6CF" strokeWidth="1.6" />
      </svg>
   );
};
export default IconFilter;
