import React, { FC, useRef } from 'react';

/**
 * Component that alerts if you click outside of it
 */
import useOutsideAlerter from '../../../hooks/useOutside';

interface IProps {
  onOutsideClick: (e: any) => void;
}

const OutsideAlerter: FC<IProps> = (props) => {
  const { children, onOutsideClick } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onOutsideClick);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
