import React, {FC} from "react";

interface Props {

}

const IconEdit: FC<Props> = (props) => {

  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="17" fill="#5ECBAA" stroke="white" strokeWidth="2"/>
      <path d="M18.0405 11.5181H13.0309C12.6513 11.5181 12.2873 11.6689 12.0188 11.9373C11.7504 12.2057 11.5996 12.5698 11.5996 12.9494V22.9686C11.5996 23.3482 11.7504 23.7123 12.0188 23.9807C12.2873 24.2491 12.6513 24.3999 13.0309 24.3999H23.0502C23.4298 24.3999 23.7938 24.2491 24.0622 23.9807C24.3307 23.7123 24.4815 23.3482 24.4815 22.9686V17.959" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.408 10.4447C23.6927 10.1599 24.0788 10 24.4815 10C24.8841 10 25.2702 10.1599 25.5549 10.4447C25.8397 10.7294 25.9996 11.1155 25.9996 11.5181C25.9996 11.9208 25.8397 12.3069 25.5549 12.5916L18.7562 19.3904L15.8936 20.106L16.6092 17.2434L23.408 10.4447Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IconEdit