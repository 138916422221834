import React, { FC } from 'react';

interface Props {

}

const IconWhatsapp: FC<Props> = (props) => {

   return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M20.8435 0C24.056 0 25.6619 0 27.3912 0.546667C29.2792 1.23387 30.7661 2.7208 31.4533 4.6088C32 6.33787 32 7.94427 32 11.1565V20.8435C32 24.056 32 25.6619 31.4533 27.3912C30.7661 29.2792 29.2792 30.7661 27.3912 31.4531C25.6619 32 24.056 32 20.8435 32H11.1565C7.944 32 6.33787 32 4.6088 31.4531C2.7208 30.7661 1.23387 29.2792 0.546667 27.3912C0 25.6619 0 24.056 0 20.8435V11.1565C0 7.94427 0 6.33787 0.546667 4.6088C1.23387 2.7208 2.7208 1.23387 4.6088 0.546667C6.33787 0 7.944 0 11.1565 0H20.8435Z" fill="#25D366"/>
         <path d="M23.0865 8.87192C21.206 6.98942 18.7058 5.95242 16.0418 5.95117C10.5533 5.95117 6.08525 10.4182 6.0835 15.9089C6.0825 17.6644 6.54125 19.3777 7.4125 20.8872L6 26.0477L11.2788 24.6632C12.7335 25.4559 14.371 25.8742 16.0378 25.8749H16.042C21.5305 25.8749 25.9978 21.4072 26 15.9167C26.0013 13.2562 24.966 10.7542 23.0865 8.87192ZM16.042 24.1929H16.0388C14.553 24.1924 13.0965 23.7934 11.8255 23.0394L11.5233 22.8599L8.39075 23.6817L9.2265 20.6277L9.02975 20.3137C8.201 18.9962 7.76325 17.4734 7.76425 15.9089C7.76575 11.3457 11.479 7.63292 16.0445 7.63292C18.2555 7.63342 20.3333 8.49567 21.8963 10.0604C23.459 11.6249 24.319 13.7042 24.3185 15.9159C24.317 20.4799 20.6038 24.1929 16.042 24.1929ZM20.582 17.9942C20.333 17.8694 19.1098 17.2682 18.8815 17.1844C18.6533 17.1014 18.488 17.0602 18.322 17.3092C18.156 17.5582 17.6793 18.1189 17.5338 18.2849C17.3888 18.4509 17.2438 18.4719 16.9948 18.3472C16.7458 18.2224 15.9443 17.9599 14.9945 17.1119C14.2545 16.4527 13.755 15.6372 13.61 15.3882C13.465 15.1392 13.5945 15.0049 13.7193 14.8807C13.8313 14.7692 13.9683 14.5902 14.0925 14.4447C14.2168 14.2992 14.2583 14.1954 14.3413 14.0297C14.4243 13.8637 14.383 13.7184 14.3205 13.5939C14.258 13.4692 13.7605 12.2444 13.5535 11.7462C13.3513 11.2609 13.1463 11.3267 12.9938 11.3192C12.8483 11.3122 12.6825 11.3102 12.5168 11.3102C12.351 11.3102 12.0813 11.3724 11.8533 11.6217C11.6255 11.8707 10.9825 12.4724 10.9825 13.6977C10.9825 14.9222 11.874 16.1057 11.9985 16.2714C12.123 16.4372 13.753 18.9509 16.249 20.0287C16.8425 20.2847 17.3063 20.4379 17.6675 20.5529C18.2635 20.7427 18.806 20.7154 19.235 20.6514C19.713 20.5802 20.707 20.0497 20.9148 19.4684C21.122 18.8874 21.122 18.3894 21.0593 18.2854C20.9965 18.1812 20.8305 18.1189 20.582 17.9942Z" fill="white"/>
      </svg>

   );
};

export default IconWhatsapp;