import React, { FC } from 'react';
import './HotelsFilter.scss';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';

import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import PriceRange from './components/PriceRange/PriceRange';
import HotelFilterContentBlock from './components/HotelFilterContentBlock/HotelFilterContentBlock';
import BedsAndRoomsFilter from './components/BedsAndRoomsFilter/BedsAndRoomsFilter';
import { FilterGroupPrepared, FiltersInitDataPrepared } from '../../../../../types/hotel-filters';
import useSearchForm from '../../../common/HotelSearchForm/hooks/useSearchForm';
import { TabletAndUpper } from '../../../../../components/common/Media/MediaHelpers';
import useHotelsFilterLogic from './useHotelsFilterLogic';
import FiltersCheckboxesSection from './components/FiltersCheckboxesSection/FiltersCheckboxesSection';

interface Props {
    onClose: () => void;
    filtersInitData: FiltersInitDataPrepared
}

const HotelsFilter: FC<Props> = ({onClose, filtersInitData}) => {
    const history = useHistory();
    const location = useLocation();

    const {getUrlSearchParamsWithoutFilters} = useSearchForm();

    const {
        isLoading,
        searchOnServer,
        totalHotels,
        filterChanged,
        formikInitialValues,
        //applyFiltersAndGetHotels,
        filtersQuery,
        isTouched
    } = useHotelsFilterLogic(filtersInitData, getUrlSearchParamsWithoutFilters);

    const dispatch = useDispatch();

    const onSubmitFilters = async () => {
        // const data = await applyFiltersAndGetHotels();
        //
        // if (data) {
        //     dispatch(setHotelsData(data));
        //     dispatch(setMapHotelsData(data));
        // }

        if (filterChanged) history.replace(location.pathname + filtersQuery);
        onClose();
    }

    const onClear = () => {
        history.replace(location.pathname + '?' + getUrlSearchParamsWithoutFilters().toString());
        onClose();
    }

    const Footer = () => (
        <div className="hotels-filter__footer">
            <TabletAndUpper>
                <button className="hotels-filter__reset-button" onClick={onClear}>Скинути</button>
            </TabletAndUpper>

          <AppButton
                className="hotels-filter__button-submit"
                isLoading={isLoading}
                onClick={() => {
                    if (!isTouched) onClose()
                    else onSubmitFilters().then();
                }}
                disabled={isTouched && totalHotels === 0}
            >
                {!isTouched ? 'Відфільтрувати готелі' : (
                    <>
                        {totalHotels === 0 ? (
                            <>Готелі відсутні</>
                        ) : (
                            <>Показати {totalHotels} готелів</>
                        )}
                    </>
                )}
            </AppButton>
        </div>
    )

    const onChangePrices = (min, max, setFieldValue) => {
        setFieldValue('price.min', min);
        setFieldValue('price.max', max);
    }

    return (
        <Modal className="hotels-filter-modal" onClose={onClose} >
            <Formik
                initialValues={formikInitialValues}
                validate={searchOnServer}
                onSubmit={() => {}}
            >
                {({values, setFieldValue}) => (
                    <ModalLayoutPrimary
                        title="Фільтрувати"
                        Footer={Footer}
                        rightContent={<AppButton
                            theme='link'
                            onClick={onClear}
                            className={'hotels-filter-modal__mobile-clear-button'}>Скинути фільтри</AppButton>
                        }
                    >
                        <div className="hotels-filter">
                            <HotelFilterContentBlock
                                title={filtersInitData.price.title}
                                className={'hotels-filter__price'}
                            >
                                <div className="hotels-filter__price-text">
                                    <PriceRange
                                        priceData={filtersInitData.price}
                                        values={values.price}
                                        onChangePrices={(min, max) => onChangePrices(min, max, setFieldValue)}
                                    />
                                </div>
                            </HotelFilterContentBlock>

                            {/*<HotelFilterContentBlock*/}
                            {/*    title={filtersInitData.rating.title}*/}
                            {/*    className={'hotels-filter__stars'}*/}
                            {/*>*/}
                            {/*    <div className="hotels-filter__block-stars-button">*/}
                            {/*        <HotelsFilterRatingButtons*/}
                            {/*            onSelect={(star) => setFieldValue('rating', star)}*/}
                            {/*            stars={filtersInitData.rating.data}*/}
                            {/*            selected={values.rating}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</HotelFilterContentBlock>*/}

                            <HotelFilterContentBlock title={filtersInitData.rooms.title}>
                                <div className={'hotels-filter__content'}>
                                    <BedsAndRoomsFilter
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        initialFiltersData={filtersInitData}
                                    />
                                </div>
                            </HotelFilterContentBlock>

                            {filtersInitData.filterGroups.map((filterGroup: FilterGroupPrepared) => (
                                <FiltersCheckboxesSection
                                    key={filterGroup.alias}
                                    title={filterGroup.title}
                                    arrayName={filterGroup.alias}
                                    values={values[filterGroup.alias]}
                                    items={filterGroup.data}
                                />
                            ))}
                        </div>
                    </ModalLayoutPrimary>
                )}
            </Formik>
        </Modal>
    );
}

export default HotelsFilter;
