import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './index';

const configure = (preloadedState) => {
    function loggerMiddleware(store) {
        return function (next) {
            return function (action) {
                // console.log("action:::",action)
                next(action)
            }
        }
    }

    // create store
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(loggerMiddleware),
       // devTools: process.env.NODE_ENV !== 'production',
        preloadedState,
    });


    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../store', () => {
            const nextRootReducer = require('./index').default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
};

export default configure;
