import React, { FC, useState } from 'react';
import './LastTripItem.scss';
import Image from '../../../../../../components/ui/components/Image/Image';
import PriceViewer from '../../../../../../components/ui/components/PriceViewer/PriceViewer';
import IconDots from '../../../../../../components/ui/icons/icons/IconDots';
import OutsideClick from '../../../../../../components/common/OutsideClick/OutsideClick';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import { hideAccountOrdersThunk } from '../../../../../../store/account/orders/orders-thunks';
import ModalConfirm from '../../../../../../components/ui/modals/ModalConfirm/ModalConfirm';

interface Props {
   date: string;
   place: string;
   totalPrice: number;
   id: number;
   image: string;
   route: string;
}

const NavButton = ({ onClick }) => {
   return (
      <button onClick={onClick}>
         <IconDots />
      </button>
   );
};

const ContextMenu = ({ onClose, handleBook, leaveComment, onRemove }) => {
   return (
      <div className="order-item-menu">
         <OutsideClick onOutsideClick={onClose}>
            <ul>
               <li onClick={handleBook}>Забронювати знову</li>
               <li onClick={leaveComment}>Оцінити та залишити відгук</li>
               <li onClick={onRemove}>Видалити бронювання</li>
            </ul>
         </OutsideClick>
      </div>
   );
};

const LastTripItem: FC<Props> = props => {
   const { image, date, place, totalPrice, route, id } = props;
   // const [isContextMenuOpen, setContextMenuOpen] = useState(false);
   // const dispatch = useAppDispatch();
   // const [showConfirm, setShowConfirm] = useState(false);

   // const handleClick = e => {
   //    e.stopPropagation();
   //    setContextMenuOpen(true);
   // };


   // remove order
   // const handleConfirmRemove = () => {
   //    setShowConfirm(true)
   // }

   // const handleRemoveOrder = () => {
   //    dispatch(hideAccountOrdersThunk(id)).then(()=>{
   //       setShowConfirm(false)
   //    })
   // };

   return (
      <div className="last-trip-item">
         <Link to={route}>
            <div className="last-trip-item__wrapper">
               <Image src={image} aspectRatio={'1:1'} className="last-trip-item__image" />
               <div className="last-trip-item__block-information">
                  <div className="last-trip-item__block-date">
                     <div className="last-trip-item__date">{date}</div>
                     <div className="last-trip-item__setting">{/*<IconMore/>*/}</div>
                  </div>
                  <div className="last-trip-item__name">{place}</div>
                  <div className="last-trip-item__price">
                     <PriceViewer price={totalPrice} />
                  </div>
               </div>
            </div>
         </Link>

         {/*<div className="last-trip-item__button">*/}
         {/*   <NavButton onClick={handleClick} />*/}
         {/*   <div style={{ position: 'relative' }}>*/}
         {/*      {isContextMenuOpen && (*/}
         {/*         <ContextMenu*/}
         {/*            onRemove={handleConfirmRemove}*/}
         {/*            handleBook={() => {}}*/}
         {/*            leaveComment={() => {}}*/}
         {/*            onClose={() => setContextMenuOpen(false)}*/}
         {/*         />*/}
         {/*      )}*/}
         {/*   </div>*/}
         {/*</div>*/}

         {/*{showConfirm && (*/}
         {/*   <ModalConfirm*/}
         {/*      className="orders-detail-confirm"*/}
         {/*      title="Безкоштовне скасування броні"*/}
         {/*      onCancel={() => {*/}
         {/*         setShowConfirm(false);*/}
         {/*      }}*/}
         {/*      onConfirm={handleRemoveOrder}*/}
         {/*      confirmButtonLabel="Видалити"*/}
         {/*      cancelButtonLabel="Відмінити"*/}
         {/*   >*/}
         {/*      Ви дійсно бажаєте видалити бронювання бронювання <b>{place}</b>*/}
         {/*   </ModalConfirm>*/}
         {/*)}*/}
      </div>
   );
};

export default LastTripItem;
