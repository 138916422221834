import React from 'react';
import { RoomMealCode } from '../../../../../types/rooms';
import RoomPriceMealIconRO from './RoomPriceMealIconRO';
import RoomPriceMealIconBB from './RoomPriceMealIconBB';
import RoomPriceMealIconHB from './RoomPriceMealIconHB';
import RoomPriceMealIconFB from './RoomPriceMealIconFB';
import RoomPriceMealIconAI from './RoomPriceMealIconAI';
import RoomPriceMealIconUAI from './RoomPriceMealIconUAI';

type Props = {
    variant: RoomMealCode
}

const RoomPriceMealIcon: React.FC<Props> = ({variant}) => {
    switch (variant) {
        case 'RO':
            return <RoomPriceMealIconRO/>;
        case 'BB':
            return <RoomPriceMealIconBB/>
        case 'HB':
            return <RoomPriceMealIconHB/>
        case 'FB':
            return <RoomPriceMealIconFB/>
        case 'AI':
            return <RoomPriceMealIconAI/>
        case 'UAI':
            return <RoomPriceMealIconUAI/>
        default:
            return <span/>
    }
};

export default RoomPriceMealIcon;
