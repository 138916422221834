import React from 'react';
import FieldRadio from '../../../../../components/ui/forms/fields/FieldRadio/FieldRadio';
import OrderPageSectionTitle from '../OrderPageSectionTitle/OrderPageSectionTitle';
import { OrderResponsePaymentType } from '../../../../../types/order';
import './OrderPaymentTypeSelect.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { setSelectedPaymentType } from '../../../../../store/order/order-reducer';
import { getPaymentTypeSelector } from '../../../../../store/order/order-selector';
import { Log } from '@3ddie/react-utils';

type Props = {
    options: OrderResponsePaymentType[],
}

const OrderPaymentTypeSelect: React.FC<Props> = ({options}) => {
    const dispatch = useAppDispatch();
    const selectedPaymentType = useAppSelector(getPaymentTypeSelector);

    return (options && options.length) ? (
        <div className={'order-payment-type-select'}>
            <OrderPageSectionTitle>Тип оплати</OrderPageSectionTitle>

            {options.map((option, index) => (
                <div className={'order-payment-type-select__row'} key={option.id + option?.percent + index}>
                    <FieldRadio
                        name='paymentType'
                        markerPosition={'right'}
                        checked={option.method === selectedPaymentType?.method}
                        id={String(option.method)}
                        value={String(option.method)}
                        onChange={() => {
                            dispatch(setSelectedPaymentType(option))
                        }}
                    >
                        <div>
                            <div className={'order-payment-type-select__title'}>{option.title}</div>
                            <div className={'order-payment-type-select__description'}>{option.description}</div>
                        </div>
                    </FieldRadio>
                </div>
            ))}
        </div>
    ) : null
}

export default OrderPaymentTypeSelect;
