import React from 'react';

type Props = {
    text: string
}

const InfoRow: React.FC<Props> = ({text}) => {
    return (
        <div className={'hotel-detail-order-summary__info-row'}>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.98318 8.99303C2.70327 8.65714 2.20407 8.61176 1.86819 8.89166C1.5323 9.17157 1.48692 9.67076 1.76682 10.0067L2.98318 8.99303ZM6.33333 14.2498L5.72516 14.7567C5.86372 14.9229 6.06433 15.0252 6.28029 15.0397C6.49625 15.0542 6.70873 14.9797 6.86828 14.8334L6.33333 14.2498ZM16.3683 6.12508C16.6906 5.82964 16.7124 5.32886 16.4169 5.00656C16.1215 4.68425 15.6207 4.66248 15.2984 4.95792L16.3683 6.12508ZM1.76682 10.0067L5.72516 14.7567L6.94151 13.743L2.98318 8.99303L1.76682 10.0067ZM6.86828 14.8334L16.3683 6.12508L15.2984 4.95792L5.79838 13.6663L6.86828 14.8334Z"
                    fill="#5ECBAA"
                />
            </svg>

            <span>{text}</span>
        </div>
    )
};

export default InfoRow;
