import React, { FC, useEffect, useState } from 'react';
import './ModalShareRoute.scss';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
   EmailShareButton,
   FacebookShareButton,
   InstapaperShareButton,
   TelegramShareButton,
   TwitterShareButton,
   ViberShareButton,
   WhatsappShareButton,
   FacebookMessengerShareButton,
} from 'react-share';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import IconFacebook from '../icons/IconFacebook';
import IconWhatsapp from '../icons/IconWhatsapp';
import IconMessenger from '../icons/IconMessenger';
import IconMail from '../icons/IconMail';
import ModalLayoutPrimary from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import Image from '../../../../../components/ui/components/Image/Image';
import IconCopy from '../icons/IconCopy';
import appToastr from '../../../../../services/appToastr';
import IconTelegram from "../icons/IconTelegram";
import IconTwitter from "../icons/IconTwitter";
import IconViber from "../icons/IconViber";

interface Props {
   onClose: () => void;
   image: string;
   name: string;
   onInit?: () => void;
   shareLink: string;
   title: string;
}

const ModalShareRoute: FC<Props> = ({ onInit, shareLink = '', onClose, image, name, title }) => {
   useEffect(() => {
      if (onInit) onInit();
   }, []);

   return (
      <Modal className="modal-favorite-share" onClose={onClose}>
         <ModalLayoutPrimary title={title}>
            <a  href={shareLink} className="share-object" target="_blank">
               <Image aspectRatio="1:1" className="share-object__image" src={image} />
               <p className="share-object__text">{name}</p>
            </a>
            <ul className="share-list">
               <li>
                  <CopyToClipboard
                     text={shareLink}
                     onCopy={() => {
                        appToastr.info('Скопійовано в буфер');
                     }}
                  >
                     <button>
                        <IconCopy />
                        Копіювати посилання
                     </button>
                  </CopyToClipboard>
               </li>

               <li>
                  <EmailShareButton url={shareLink} subject="title" body="description ">
                     <IconMail />
                     mail
                  </EmailShareButton>
               </li>
               <li>
                  <FacebookShareButton url={shareLink} quote="Готель" hashtag="#hotel">
                     <IconFacebook /> Facebook
                  </FacebookShareButton>
               </li>
               <li>
                  <TelegramShareButton url={shareLink}>
                    <IconTelegram /> Telegram
                  </TelegramShareButton>
               </li>
               <li>
                  <TwitterShareButton url={shareLink}>
                     <IconTwitter/> Twitter
                  </TwitterShareButton>
               </li>
               <li>
                  <ViberShareButton url={shareLink}>
                     <IconViber/> Viber
                  </ViberShareButton>
               </li>
               <li>
                  <WhatsappShareButton url={shareLink}>
                     <IconWhatsapp /> Whatsapp
                  </WhatsappShareButton>
               </li>
               {/*<li>*/}
               {/*   <InstapaperShareButton url={shareLink}>*/}
               {/*      <div></div>Instagram*/}
               {/*   </InstapaperShareButton>*/}
               {/*</li>*/}
               <li>
                  <FacebookMessengerShareButton
                     url={shareLink}
                     appId={process.env.RAZZLE_SOCIAL_FACEBOOK_APP_ID}
                     redirectUri={process.env.HOST}
                  >
                     <IconMessenger />
                     Messanger
                  </FacebookMessengerShareButton>
               </li>
            </ul>
         </ModalLayoutPrimary>
      </Modal>
   );
};

export default ModalShareRoute;
