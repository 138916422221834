import React from 'react';

type Props = {
    size?: number
}

const IconClose: React.FC<Props> = ({size = 12}) => (
    <svg width={size + 1}
         height={size}
         viewBox="0 0 13 12"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.25526 5.99984L0.666693 1.41127C0.381936 1.12651 0.381936 0.664829 0.666693 0.380072C0.95145 0.0953146 1.41313 0.0953146 1.69789 0.380072L6.28646 4.96864L10.875 0.380072C11.1598 0.0953146 11.6215 0.0953146 11.9062 0.380072C12.191 0.664829 12.191 1.12651 11.9062 1.41127L7.31766 5.99984L11.9062 10.5884C12.191 10.8732 12.191 11.3348 11.9062 11.6196C11.6215 11.9044 11.1598 11.9044 10.875 11.6196L6.28646 7.03103L1.69789 11.6196C1.41313 11.9044 0.95145 11.9044 0.666693 11.6196C0.381936 11.3348 0.381936 10.8732 0.666693 10.5884L5.25526 5.99984Z"/>
    </svg>
);

export default IconClose;
