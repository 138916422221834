import React from "react";
import LoaderAspectRatio from "../../../../../components/ui/loaders/LoaderAspectRatio";
import "./OrdersListLoader.scss";


const OrdersListLoader = () => {
    return (
        <div className="order-list-loader">
            {[0,1,2,3,4].map((item)=> <div><LoaderAspectRatio key={item} style={{height: '120px', padding: '0', width: '100%'}}/></div>)}

        </div>
    )
}

export default OrdersListLoader;