import React, { useEffect, useRef, useState } from 'react';
import './RoomTypesSelect.scss';

import { RoomTypeInOrderEdit } from '../../../../../../../types/rooms';
import { createBemClassProp } from '../../../../../../../helpers/common';
import ReservationEditModalTitle from '../ReservationEditModalTitle/ReservationEditModalTitle';
import RoomItemPersons
    from '../../../../../../Hotels/HotelDetail/components/HotelRooms/components/HotelRoomItem/components/RoomItemPersons/RoomItemPersons';
import useSwitcher from '../../../../../../../hooks/useSwitcher';
import IconArrowBottom from '../../../../../../../components/ui/icons/icons/IconArrowBottom';
import useWindowSize from '../../../../../../../hooks/useWindowSize';
import ModalMobile from '../../../../../../../components/ui/modals/ModalMobile/ModalMobile';
import IconClose from '../../../../../../../components/ui/icons/modal/IconClose';
import ModalMobileHeader
    from '../../../../../../../components/ui/modals/ModalMobile/components/ModalMobileHeader/ModalMobileHeader';
import ModalOptionsSelect from '../../../../../../../components/ui/modals/ModalOptionsSelect/ModalOptionsSelect';

type Props = {
    roomTypes: Array<RoomTypeInOrderEdit>,
    onSelect: (roomType: RoomTypeInOrderEdit) => void
    selectedRoomType: RoomTypeInOrderEdit
}

const RoomTypesSelect: React.FC<Props> = ({roomTypes, selectedRoomType, onSelect}) => {
    const cn = createBemClassProp('reservation-edit-modal-room-types-list');
    const {onClose, onToggle, isOpen} = useSwitcher();
    const {isMobile} = useWindowSize();

    const [selectTop, setSelectTop] = useState<number | string>('auto');
    const selectButtonRef = useRef<HTMLButtonElement>();

    useEffect(() => {
        if (selectButtonRef.current) {
            setSelectTop(selectButtonRef.current.offsetTop + selectButtonRef.current.offsetHeight);
        }
    }, [selectButtonRef.current]);

    const onSelectRoomType = (roomType) => {
        onSelect(roomType);
        onClose();
    }

    const renderListItem = (roomType: RoomTypeInOrderEdit, isSelected = false) => (
        <div {...cn('item', isSelected ? 'selected' : '')}>
            <div {...cn('item-content')}>
                <div {...cn('item-image-wrap')}>
                    <img src={roomType?.photo?.source?.['302x170']} alt={''}/>
                </div>

                <div {...cn('item-texts')}>
                    <span {...cn('item-texts-category')}>{roomType.name}</span>

                    <RoomItemPersons
                        basePlaces={roomType.basePlaces}
                        extraPlaces={roomType.extraPlaces}
                    />

                    <span {...cn('item-texts-name')}>{roomType.category}</span>
                </div>
            </div>
        </div>
    )

    const selectListContent = roomTypes.map((roomType, index) => (
        <button
            {...cn('select-list-button')}
            onClick={() => onSelectRoomType(roomType)}
            key={'roomType' + roomType.id + index}
        >
            {renderListItem(roomType)}
        </button>
    ));

    return (
        <div {...cn()}>
            <ReservationEditModalTitle>Типи номерів</ReservationEditModalTitle>

            <div {...cn('content')}>
                {selectedRoomType && (
                    <button onClick={onToggle} {...cn('select-button')} ref={selectButtonRef}>
                        <div>
                            {renderListItem(selectedRoomType, true)}
                        </div>

                        <IconArrowBottom/>
                    </button>
                )}

                {isOpen && (
                    <>
                        {isMobile
                            ? <ModalOptionsSelect options={selectListContent} title={'Типи номерів'} onClose={onClose}/>
                            : <div {...cn('select-list')} style={{top: selectTop}}>{selectListContent}</div>
                        }
                    </>
                )}
            </div>
        </div>
    )
}

export default RoomTypesSelect;
