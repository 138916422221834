import React, {FC} from "react";

interface Props {
  className?: string;
}

const IconDropDown: FC<Props> = (props) => {

  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5303 1.53033C13.8232 1.23744 13.8232 0.762563 13.5303 0.46967C13.2374 0.176777 12.7626 0.176777 12.4697 0.46967L13.5303 1.53033ZM7 7L6.46967 7.53033C6.61032 7.67098 6.80109 7.75 7 7.75C7.19891 7.75 7.38968 7.67098 7.53033 7.53033L7 7ZM1.53033 0.469669C1.23744 0.176776 0.762563 0.176776 0.469669 0.469669C0.176776 0.762563 0.176776 1.23744 0.469669 1.53033L1.53033 0.469669ZM12.4697 0.46967L6.46967 6.46967L7.53033 7.53033L13.5303 1.53033L12.4697 0.46967ZM7.53033 6.46967L1.53033 0.469669L0.469669 1.53033L6.46967 7.53033L7.53033 6.46967Z"
        fill="#8C8896"/>
    </svg>
  )
}

export default IconDropDown