import React, { FC } from 'react';
import './HeaderMenuItem.scss';

interface Props {
   onClick: () => any;
   title: string;
   icon: any;
   rightIcon?: any;
}

const HeaderMenuItem: FC<Props> = (props) => {
   const {onClick, title, icon, rightIcon} = props;
   return (
      <div onClick={onClick} className="header-menu-item">
         <div className="header-menu-item__icon">
            {icon}
         </div>

         <span>{title}</span>
         {rightIcon && rightIcon}
      </div>
   );
};

export default HeaderMenuItem;