import React from 'react';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import ModalLayoutPrimary
    from '../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import Modal from '../../../../components/ui/modals/Modal/Modal';

type Props = {
    onClose: () => void
}

const ReservationConditionsModal: React.FC<Props> = ({onClose}) => {
    const order = useAppSelector(state => state.order);
    const conditionsText = order.serverOrderData.data.reservationConditions;

    return (
        <Modal onClose={onClose} showClose>
            <ModalLayoutPrimary title="Умови бронювання" style={{maxWidth: 750}}>
                <div className='text' dangerouslySetInnerHTML={{__html: conditionsText}}/>
            </ModalLayoutPrimary>
        </Modal>
    )
};

export default ReservationConditionsModal;
