import React, {FC} from "react";

interface Props {

}

const IconToolbarHome: FC<Props> = (props) => {

  return (
     <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="toolbar-svg-stroke" fillRule="evenodd" clipRule="evenodd" d="M7.31579 18.4202C7.31579 18.4202 11.6578 18.4017 12.4552 18.4202H14.0754C15.6906 18.4202 17 17.124 17 15.5249V8.17841C16.9914 7.54935 16.693 6.95863 16.1899 6.57436L10.6486 2.15522C9.67784 1.38576 8.29786 1.38576 7.32709 2.15522L1.81013 6.58238C1.30506 6.96509 1.00622 7.55679 1 8.18643V15.5249C1 17.124 2.30937 18.4202 3.92456 18.4202H5.54481C6.12198 18.4202 7.31579 18.4202 7.31579 18.4202Z" stroke="#C5C6CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
     </svg>
  )
}

export default IconToolbarHome