import React from "react";
import "./RoomLoader.scss";
import LoaderAspectRatio from "../../../../../components/ui/loaders/LoaderAspectRatio";


const RoomLoader = () => {
  return (
    <div className="room-loader">
      {[0, 1, 2, 3, 4].map((item) => <div key={item}>

        <div className="room-loader__item" >
          <LoaderAspectRatio  aspectRatio={'1:1'}/>
          <div>
            <LoaderAspectRatio width={"90%"} height="10px" />
            <br/>
            <LoaderAspectRatio width={"70%"} height="10px" />
            <br/>
            <LoaderAspectRatio width={"40%"} height="10px" />
          </div>
        </div>
      </div>)}
    </div>
  )
}

export default RoomLoader;