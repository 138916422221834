import React, { FC, useState } from 'react';
import './AccountProfileEdit.scss';
import Page from '../../../../components/common/Page/Page';
import Container from '../../../../components/ui/layouts/Container/Container';
import PageHeader from '../../common/PageHeader/PageHeader';
import { useSelector } from 'react-redux';
import { profileCountriesSelector, profileSelector } from '../../../../store/profile/profile-selector';
import { editProfileThunk, setPhoneThunk, setResetPasswordThunk } from '../../../../store/profile/profile-thunks';
import ProfilePersonal from './form/ProfilePersonal/ProfilePersonal';
import ProfilePlace from './form/ProfilePlace/ProfilePlace';
import ProfileContacts from './form/ProfileContacts/ProfileContacts';
import ProfileFormLayout from './form/ProfileFormLayout/ProfileFormLayout';
import {
   editProfileChangePasswordSchema,
   editProfileContactsSchema,
   editProfilePersonalSchema,
   editProfilePlaceSchema,
} from '../../../../helpers/validation';
import ProfilePreviewForm from './ProfilePreviewForm/ProfilePreviewForm';
import FormatPhone from '../../../../components/ui/components/FormatPhone/FormatPhone';
import { unwrapResult } from '@reduxjs/toolkit';
import { genderOptions } from '../helpers/common';
import ProfilePasswords from './form/ProfilePasswords/ProfilePasswords';
import authApi from '../../../../api/authApi';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { RouteNames } from '../../../../routes';
import appToastr from '../../../../services/appToastr';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';

interface IProps {}

const AccountProfileEdit: FC<IProps> = props => {
   const dispatch = useAppDispatch();
   const { isSending, data, error,  isFetching } = useSelector(profileSelector);
   const [view, setView] = useState({ personal: false, contacts: false, places: false, password: false });
   const { name, lastname, email, photo, phone, birthday, gender, countryId, city, countryName } = data.data;
   const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
   const countries = useSelector(profileCountriesSelector);

   function openView(key, value) {
      setView({
         personal: false,
         contacts: false,
         places: false,
         password: false,
         [key]: value,
      });
   }

   const breadcrumbs = [{
         url: RouteNames.PROFILE_INDEX,
         title: 'Акаунт',
      },
      {
         title: 'Персональна інформація',
      }];

   const initialPersonal = {
      name,
      lastname,
      gender,
      birthday,
   };

   const initialPasswordForm = {
      login: phone,
      password: '',
      code: '',
      password_confirmation: '',
   };

   const initialPlace = {
      countryId: countryId,
      city: city,
   };

   const initialContacts = {
      //@ts-ignore
      phone,
      code: '',
      email,
      password: '',
      showPassword: false,
      showCode: false,
   };

   const closeAll = () => {
      setView({ personal: false, contacts: false, places: false, password: false });
   };

   const submitData = (data, { setErrors }) => {
      const res = { ...data };
      const { phone, showCode, showPassword, code, password, ...rest } = res;


      //@ts-ignore
      dispatch(editProfileThunk(rest))
         .then(unwrapResult)
         .then(data => {
            closeAll();
         })
         .catch(error => {
            setErrors(error?.data?.errors);
         });
   };

   const setPhone = data => {
      return dispatch(setPhoneThunk(data))
         .then(unwrapResult)
         .then(unwrapResult)
         .then(data => {
            closeAll();
            return data;
         });
   };

   const submitPhoneWithPassword = data => {
      return dispatch(editProfileThunk(data)).then(unwrapResult);
   };

   const submitPassword = (data, { setErrors }) => {
      dispatch(setResetPasswordThunk(data))
         .then(unwrapResult)
         .then(() => {

            appToastr.success('Пароль було успішно змінено')

            // повертаю форму в попередній вигляд
            setShowChangePasswordForm(false);
            closeAll();
         })
         .catch(error => {
            if (error?.status === 406) {
               setErrors({
                  code: 'Неправельний код підтвердження',
               });
            } else {
               if (error?.data?.errors) {
                  setErrors(error.data.errors);
               } else {
               }
            }
         });
   };

   const sendCodeForChangePassword = () => {
      authApi
         .restoreRequest({ login: phone })
         .then(() => {
            setShowChangePasswordForm(true);
            appToastr.info('На ваш телефон було відправлено код підтвердження');
         })
         .catch(error => {
            if (error?.response?.status === 408) {
               appToastr.error(error.response?.data?.message, 'Помилка');
            }
         });
   };
   const findGenderObject = genderOptions.find(item => item.value == gender);
   const genderText = findGenderObject ? findGenderObject.key : '';

   return (
      <Page>
         <Container>
            <div className="profile-page">
               <PageHeader breadcrumbs={breadcrumbs} title="Персональна інформація" />
               <ProfileFormLayout
                  title="Особиста інформація"
                  onChangeView={openView.bind(this, 'personal')}
                  viewForm={view.personal}
               >
                  {view.personal ? (
                     <ProfilePersonal
                        cancelEdit={openView.bind(this, 'personal')}
                        submitData={submitData}
                        isSending={isSending}
                        initialValues={initialPersonal}
                        validationSchema={editProfilePersonalSchema}
                     />
                  ) : (
                     <ProfilePreviewForm list={[`${lastname} ${name}`, genderText, birthday]} />
                  )}

               </ProfileFormLayout>

               <ProfileFormLayout title="Адреса" onChangeView={openView.bind(this, 'places')} viewForm={view.places}>
                  {view.places ? (
                     <ProfilePlace
                        cancelEdit={openView.bind(this, 'places')}
                        submitData={submitData}
                        isSending={isSending}
                        countries={countries}
                        initialValues={initialPlace}
                        validationSchema={editProfilePlaceSchema}
                     />
                  ) : (
                     <ProfilePreviewForm list={[<address>{countryName}</address>, <address>{city}</address>]} />
                  )}
               </ProfileFormLayout>

               <ProfileFormLayout
                  title="Контактні дані"
                  onChangeView={openView.bind(this, 'contacts')}
                  viewForm={view.contacts}
               >
                  {view.contacts ? (
                     <ProfileContacts
                        cancelEdit={openView.bind(this, 'contacts')}
                        submitData={submitData}
                        setPhone={setPhone}
                        submitPhoneWithPassword={submitPhoneWithPassword}
                        isSending={isSending}
                        initialValues={initialContacts}
                        validationSchema={editProfileContactsSchema}
                        // resendCode={resendCode}
                     />
                  ) : (
                     <ProfilePreviewForm list={[<FormatPhone>{phone}</FormatPhone>, email]} />
                  )}
               </ProfileFormLayout>

               {phone && <ProfileFormLayout
                  title="Змінити пароль"
                  onChangeView={openView.bind(this, 'password')}
                  viewForm={view.password}
               >
                  {view.password ? (
                     <>
                        {!showChangePasswordForm ? (
                           <div>
                              <div className="profile-password-message">
                                 Щоб змінити пароль, ми маємо надіслати СМС підтвердження для скидання пароля на ваш
                                 мобільний <span className="profile-password-message__phone"><FormatPhone>{phone}</FormatPhone></span> телефон
                              </div>
                              <div className="profile-password-message-form">
                                 {/*<AppButton theme="light" size="middle" onClick={()=>{   openView('password', false) }}>*/}
                                 {/*   Скасувати*/}
                                 {/*</AppButton>*/}
                                 <AppButton onClick={sendCodeForChangePassword} size="middle">Надіслати SMS-код</AppButton>

                              </div>
                           </div>
                        ) : (
                           <ProfilePasswords
                              cancelEdit={()=>{
                                 openView('password', false)

                                 // повертаю форму в попередній вигляд
                                 setShowChangePasswordForm(false);
                              }}
                              submitData={submitPassword}
                              sendCode={sendCodeForChangePassword}
                              isSending={isSending}
                              initialValues={initialPasswordForm}
                              validationSchema={editProfileChangePasswordSchema}
                           />
                        )}
                     </>
                  ) : (
                     <ProfilePreviewForm list={['********']} />
                  )}
               </ProfileFormLayout>}
            </div>
         </Container>
      </Page>
   );
};

export default AccountProfileEdit;
