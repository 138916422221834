import React, { useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useHotkeys } from 'react-hotkeys-hook';
import './ModalGallery.scss';

import Modal from '../../ui/modals/Modal/Modal';
import ModalGalleryArrow from './components/ModalGalleryArrow/ModalGalleryArrow'
import AppMedia from '../../common/Media/Media';
import useWindowSize from '../../../hooks/useWindowSize';
import CustomCarousel from '../../ui/components/CustomCarousel/CustomCarousel';
import ModalGalleryCloseButton from './components/ModalGalleryCloseButton/ModalGalleryCloseButton';
import { PhotoType } from '../../../types/common';

type Props = {
    images: PhotoType[];
    onClose: () => void;
    roomId?: string
    initialPathName: string
};

const ModalGallery: React.FC<Props> = ({images, roomId, onClose, initialPathName}) => {
    const {isMobile} = useWindowSize();

    const params = useParams<{ index: string }>();
    const [currentSlide, setCurrentSlide] = useState(parseInt(params?.index) ? parseInt(params?.index) - 1 : 0);
    const {search} = useLocation();
    const history = useHistory();
    const slider = useRef();

    useHotkeys('right', () => {
        // @ts-ignore
        slider?.current?.slickNext && slider.current.slickNext();
    });

    useHotkeys('left', () => {
        // @ts-ignore
        slider?.current?.slickPrev && slider.current.slickPrev();
    });

    const settings: Settings = {
        initialSlide: currentSlide,
        //dots: isMobile,
        dots: false,
        infinite: false,
        speed: 300,
        fade: !isMobile,
        accessibility: true,
        arrows: !isMobile,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: isMobile,
        nextArrow: !isMobile && <ModalGalleryArrow direction={'next'}/>,
        prevArrow: !isMobile && <ModalGalleryArrow direction={'prev'}/>,
        afterChange: (slideIndex) => {
            setCurrentSlide(slideIndex);

            if (!isMobile) {
                const url = roomId
                    ? `${initialPathName}/room-gallery/${roomId}/${slideIndex + 1}${search}`
                    : `${initialPathName}/gallery/${slideIndex + 1}${search}`;

                history.replace(url);
            }
        },
    };

    const MobileSliderCarousel = () => (
        <CustomCarousel
            Item={({data}: { data: PhotoType }) => (
                <div>
                    {/*<img srcSet={generateSrcSet(data.source, 'original')} alt=""/>*/}
                    <img src={data?.source?.original} alt=""/>
                </div>
            )}
            data={images}
            settings={settings}
            theme='secondary'
        />
    );

    const MemoMobileSliderCarousel = React.useMemo(() => <MobileSliderCarousel/>, [images, settings]);

    return (
        <Modal
            wrapperClassName={'modal-gallery-wrapper'}
            className='modal-gallery'
            closeButtonElement={<ModalGalleryCloseButton onClose={onClose}/>}
        >
            <div className='modal-gallery-container'>
                <div className={'modal-gallery__content'}>
                    <div className={'modal-gallery__counter'}>
                        {currentSlide + 1}/{images.length}
                    </div>

                    <AppMedia.Media greaterThanOrEqual={'md'}>
                        <Slider
                            {...settings}
                            ref={slider}
                        >
                            {images.map(img => (
                                <div>
                                    <div className={'modal-gallery__desktop-slide'}>
                                        <img src={img.source?.original} alt=""/>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </AppMedia.Media>

                    <AppMedia.Media lessThan={'md'}>
                        <MobileSliderCarousel/>
                        {/*{MemoMobileSliderCarousel}*/}
                    </AppMedia.Media>
                </div>
            </div>
        </Modal>
    );
};

export default ModalGallery;
