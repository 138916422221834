import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import ControlLayout from './ControlLayout/ControlLayout';
import MuiFieldTextArea from "../muiFields/MuiFieldTextArea";

type Props = {
    id?: string;
    name: string;
    label?: string;
    rows?: number | string;
    cols?: number | string;
};

const AppFormTextArea: FC<Props> = props => {
    const {id, label, ...rest} = props;

    return (
        <Field name={props.name}>
            {(propsField: FieldProps) => {
                const {field, form, meta} = propsField;
                const {value, name, ...restField} = field;
                const isError: boolean = meta.touched && !!meta.error;

                return (
                    <ControlLayout isError={isError} error={meta.error}>
                        {/*@ts-ignore*/}
                        <MuiFieldTextArea
                            label={label}
                            {...restField}
                            {...rest}
                            isError={isError}

                        />
                    </ControlLayout>
                );
            }}
        </Field>
    );
};

export default AppFormTextArea;
