import React from 'react';

type Props = {
    size?: number
    fill?: string
}

const IconEdit: React.FC<Props> = ({size = 14, fill = '#E8856F'}) => (
    <svg width={size} height={size + 1} viewBox={`0 0 14 15`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.36744 2.76465H2.19276C1.87642 2.76465 1.57304 2.89031 1.34935 3.114C1.12567 3.33769 1 3.64107 1 3.95741V12.3068C1 12.6231 1.12567 12.9265 1.34935 13.1502C1.57304 13.3739 1.87642 13.4995 2.19276 13.4995H10.5421C10.8585 13.4995 11.1618 13.3739 11.3855 13.1502C11.6092 12.9265 11.7349 12.6231 11.7349 12.3068V8.13209"
            stroke={fill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M10.8401 1.87054C11.0774 1.63329 11.3992 1.5 11.7347 1.5C12.0702 1.5 12.392 1.63329 12.6293 1.87054C12.8665 2.1078 12.9998 2.42959 12.9998 2.76512C12.9998 3.10065 12.8665 3.42244 12.6293 3.65969L6.96365 9.32532L4.57812 9.92171L5.17451 7.53618L10.8401 1.87054Z"
            stroke={fill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default IconEdit;
