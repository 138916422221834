import React, {FC} from "react";
import "./HotelsIcons.scss";

interface Props {

}

const IconBar: FC<Props> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4H6V6H4V4Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 18H6V20H4V18Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 4H20V6H18V4Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 11H20V13H18V11Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 11H13V13H11V11Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 11H6V13H4V11Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 4H13V6H11V4Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 18H13V20H11V18Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 18H20V20H18V18Z" stroke="#D1CFD5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
export default IconBar


