import React, { useState } from 'react';
import './OrderIndexReservations.scss';

import { OrderResponseReservationItem } from '../../../../../types/order';
import ReservationEditModal from '../ReservationEditModal/ReservationEditModal';
import OrderIndexReservationItem from './components/OrderIndexReservationItem/OrderIndexReservationItem';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { removeReservationThunk } from '../../../../../store/order/order-thunk';
import ReservationRemoveModal from '../ReservationRemoveModal/ReservationRemoveModal';

type Props = {
    reservations: OrderResponseReservationItem[],
    hotelAlias: string
}

const OrderIndexReservations: React.FC<Props> = ({reservations, hotelAlias}) => {
    const [reservationForEdit, setReservationForEdit] = useState<any>();

    const [reservationForRemove, setReservationForRemove] = useState<OrderResponseReservationItem>();
    const dispatch = useAppDispatch();

    const onRemoveReservation = (reservationId) => {
        setReservationForRemove(null);
        dispatch(removeReservationThunk(reservationId));
    }

    return (
        <div className='order-index-reservations'>
            {reservations.map((reservation, index) => (
                <OrderIndexReservationItem
                    key={'reservation' + reservation.id}
                    index={index}
                    reservation={reservation}
                    onRemoveReservation={() => setReservationForRemove(reservation)}
                    onSelectReservationForEdit={() => setReservationForEdit(reservation)}
                />
            ))}

            {reservationForEdit && (
                <ReservationEditModal
                    reservation={reservationForEdit}
                    hotelAlias={hotelAlias}
                    onClose={() => setReservationForEdit(null)}
                />
            )}

            {reservationForRemove && (
                <ReservationRemoveModal
                    reservationForRemove={reservationForRemove}
                    onRemove={() => onRemoveReservation(reservationForRemove.id)}
                    onCancel={() => setReservationForRemove(null)}
                />
            )}
        </div>
    )
}

export default OrderIndexReservations;

