import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './HotelDetailOrderSummarySelectedContent.scss';

import { ReservationItem } from '../../../../../../../types/order';
import { removeRoomFromOrder } from '../../../../../../../store/order/order-reducer';
import PriceViewer from '../../../../../../../components/ui/components/PriceViewer/PriceViewer';
import { pluralize } from '../../../../../../../helpers/translatioHelpers';
import AppText from '../../../../../../../components/ui/typography/AppText/AppText';
import BookButton from '../../../../../../../components/ui/components/Buttons/BookButton/BookButton';
import useSearchForm from '../../../../../common/HotelSearchForm/hooks/useSearchForm';
import { addToOrderThunk } from '../../../../../../../store/order/order-thunk';
import { AppState } from '../../../../../../../store';
import { AppDispatch } from '../../../../../../../../client';
import { createBemClassProp } from '../../../../../../../helpers/common';
import { MobileOnly, TabletAndUpper } from '../../../../../../../components/common/Media/MediaHelpers';
import AppButton from '../../../../../../../components/ui/components/Buttons/AppButton/AppButton';
import IconClose from '../../../../../../../components/ui/icons/icons/IconClose';
import { useTranslation } from "react-i18next";

type Props = {
    reservations: ReservationItem[];
    totalRoomsCount: number;
    totalPrice: number;
    onClickOnAddRoom?: () => void
};

const HotelDetailOrderSummarySelectedContent: React.FC<Props> = (props) => {
    const {
        reservations,
        totalPrice,
        totalRoomsCount,
        onClickOnAddRoom
    } = props;

    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();
    const isLoading = useSelector((state: AppState) => state.order.isLoading);
    const {nights} = useSearchForm();
    const [t] = useTranslation()

    const {guestsParams} = useSearchForm();
    const {adults, kids, rooms} = guestsParams;

    const basePlacesSum = reservations.reduce((acc, reservation,) => {
        // @ts-ignore
        return acc + reservation.basePlaces * reservation.count
    }, 0);
    let disabledBookButton = false;
    let guests = 0;

    if (reservations.length) {
        if (rooms > 1) {
            disabledBookButton = basePlacesSum < (adults + kids.length);

            guests = (adults + kids.length) - basePlacesSum;
        } else {
            disabledBookButton = false;
        }
    }

    const onRemove = (reservationId, rateId) => {
        dispatch(removeRoomFromOrder({roomId: reservationId, rateId}));
    };

    const onBook = async () => {
        const result = await dispatch(addToOrderThunk());

        if (result.error && result?.payload?.data?.status_code === 401) history.push('/auth/signin');

        history.push('/order');
    }
    const renderNotify = () => {
        if (disabledBookButton) {
            return <span {...cn('notify')}>{t('розміщення', {count: Number(guests)})}</span>
        }

        return null
    }

    const cn = createBemClassProp('hotel-detail-summary-selected-content');

    return (
        <div {...cn()}>
            <TabletAndUpper>
                <span {...cn('title')}>
                    Обрано {pluralize('номер', totalRoomsCount, true)}
                </span>
            </TabletAndUpper>

            <div {...cn('inner')}>
                {reservations.map(reservation => (
                    <div {...cn('item')} key={reservation.id}>
                        <div {...cn('item__top-row')}>
                            <AppText variant={'caption'}>{pluralize('номер', reservation.count, true)}</AppText>

                            <button
                                onClick={() => onRemove(reservation.id, reservation.price.rateId)}
                                {...cn('remove-button')}
                            >
                                <IconClose/>
                            </button>
                        </div>

                        <div>
                            <span {...cn('room-name')}>{reservation.name}</span>

                            <div className="hotel-detail-summary-selected-content__price">
                                <AppText variant={'caption'}>Ціна за {pluralize('ніч', nights, true)}</AppText>

                                <div className="hotel-detail-summary-selected-content__price-room-total">
                                    {/*{reservation.price.discountAmount > 0 && (*/}
                                    {/*    <PriceViewer price={reservation.price.fullPrice} oldPrice/>*/}
                                    {/*)}*/}

                                    <PriceViewer
                                        price={reservation.price.totalPrice}
                                        totalAfterDiscount={!!reservation.price.discountAmount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

                <MobileOnly>
                    <AppButton theme={'outline'} onClick={onClickOnAddRoom}>+ додати номер</AppButton>
                </MobileOnly>
            </div>


            <MobileOnly>
                {renderNotify()}
                <div {...cn('buttons')}>
                    <AppButton onClick={onBook} theme={'primary'} disabled={disabledBookButton}>Продовжити</AppButton>
                </div>
            </MobileOnly>

            <TabletAndUpper>
                {renderNotify()}
                <BookButton
                    disabled={disabledBookButton}
                    price={totalPrice}
                    onClick={onBook}
                    isLoading={isLoading}
                />
            </TabletAndUpper>
        </div>
    );
};

export default HotelDetailOrderSummarySelectedContent;
