import notifyAlert from 'toastr';
// import {ToastContainer, toast} from "react-toastify";



interface INotify {
   toastr: any;
   error: (message: string, title?: string, options?: any) => void;
   success: (message: string, title?: string, options?: any) => void;
   info: (message: string, title?: string, options?: any) => void;
   clear: () => void;
   remove: () => void;
}

class Notify implements INotify {
   toastr;
   options = {
      "positionClass": "toast-bottom-right",
   }

   constructor(toastr) {
      this.toastr = toastr;
   }

   success(message, title = '', options = {}) {
      this.clear()
      this.toastr.success(message, title, {...options, ...this.options});
   }

   info(message, title = '', options = {}) {
      this.clear()
      this.toastr.info(message, title, {...options, ...this.options});
   }

   error(message, title = '', options = {}) {
      this.clear()
      this.toastr.error(message, title, {...options, ...this.options});
   }

   clear() {
      this.toastr.clear();
   }

   remove() {
      this.toastr.remove();
   }
}

export default new Notify(notifyAlert);
