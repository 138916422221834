import React, {FC} from "react";

interface Props {

}

const IconSaved: FC<Props> = (props) => {

  return (
    <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.61797 2.82238V9.53243C8.61797 9.91903 8.30457 10.2324 7.91797 10.2324C7.53137 10.2324 7.21797 9.91903 7.21797 9.53243V2.82238L4.91294 5.12741C4.63958 5.40078 4.19636 5.40078 3.92299 5.12741C3.64963 4.85404 3.64963 4.41083 3.92299 4.13746L7.42299 0.637459C7.69636 0.364092 8.13958 0.364092 8.41294 0.637459L11.9129 4.13746C12.1863 4.41083 12.1863 4.85404 11.9129 5.12741C11.6396 5.40078 11.1964 5.40078 10.923 5.12741L8.61797 2.82238ZM13.518 9.53243C13.518 9.14583 13.8314 8.83243 14.218 8.83243C14.6046 8.83243 14.918 9.14583 14.918 9.53243V12.3324C14.918 13.4922 13.9778 14.4324 12.818 14.4324H3.01797C1.85817 14.4324 0.917969 13.4922 0.917969 12.3324V9.53243C0.917969 9.14583 1.23137 8.83243 1.61797 8.83243C2.00457 8.83243 2.31797 9.14583 2.31797 9.53243V12.3324C2.31797 12.719 2.63137 13.0324 3.01797 13.0324H12.818C13.2046 13.0324 13.518 12.719 13.518 12.3324V9.53243Z" fill="#D1CFD5"/>
    </svg>
  )
}

export default IconSaved