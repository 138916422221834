import React, {FC} from "react";

interface Props {

}

const IconSortButton: FC<Props> = (props) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5413 19.5067C15.2243 19.1896 14.7102 19.1896 14.3932 19.5067L11.2723 22.6275L8.15156 19.5067C7.83451 19.1896 7.32043 19.1896 7.00342 19.5067C6.68636 19.8237 6.68636 20.3377 7.00342 20.6548L10.6983 24.3497C10.8506 24.5019 11.0571 24.5875 11.2724 24.5875C11.4877 24.5875 11.6942 24.5019 11.8465 24.3497L15.5413 20.6548C15.8583 20.3378 15.8583 19.8237 15.5413 19.5067ZM11.2723 11.2878C10.8239 11.2878 10.4605 11.6513 10.4605 12.0997V23.7756C10.4605 24.224 10.824 24.5875 11.2724 24.5875C11.7208 24.5875 12.0842 24.224 12.0842 23.7756V12.0997C12.0842 11.6513 11.7207 11.2878 11.2723 11.2878Z" fill="#6D7188"/>
            <path d="M14.4606 10.0084C14.7777 10.3254 15.2917 10.3254 15.6088 10.0084L18.7296 6.88754L21.8504 10.0084C22.1674 10.3254 22.6815 10.3254 22.9985 10.0084C23.3156 9.6913 23.3156 9.17727 22.9985 8.86021L19.3037 5.16534C19.1514 5.01307 18.9449 4.92754 18.7296 4.92754C18.5142 4.92754 18.3077 5.01307 18.1555 5.16534L14.4606 8.86021C14.1436 9.17723 14.1436 9.6913 14.4606 10.0084ZM18.7296 18.2272C19.178 18.2272 19.5415 17.8637 19.5415 17.4153V5.73939C19.5415 5.29099 19.1779 4.92754 18.7296 4.92754C18.2812 4.92754 17.9178 5.29099 17.9178 5.73939V17.4153C17.9178 17.8637 18.2812 18.2272 18.7296 18.2272Z" fill="#6D7188"/>
        </svg>




    )
}
export default IconSortButton


