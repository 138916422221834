import React from "react";


const AuthBack =()=>{
  return(
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" rx="22" fill="#101010" fillOpacity="0.13"/>
      <path d="M29 22H15M15 22L22 29M15 22L22 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default AuthBack;