import React, { FC } from 'react';
import './ProfileUser.scss';
import Image from '../../../../../components/ui/components/Image/Image';
import IconEdit from '../../../../../components/ui/icons/account/IconEdit';
import { Link, Route } from 'react-router-dom';
import AccountEditModal from '../../AccountEditModal/AccountEditModal';
import NoAvatar from '../../../common/NoAvatar/NoAvatar';

interface Props {
  image: string;
  title: any;
  name: string;
  lastname: string;
  description: React.ReactNode;
}

const ProfileUser: FC<Props> = props => {
  const { title, image, description, name, lastname } = props;
  // const name = null;
  // const lastname = null;
  // const image = null;

  const renderAvatar = () => {
    if (image) {
      return <Image height={73} width={73} className="profile-user__image" aspectRatio="1:1" src={image}/>
    } else {
      if (name && lastname) {
        return <NoAvatar size={73} letters={`${name.slice(0, 1)}${lastname.slice(0, 1)}`} fontSize={32}/>
      }
    }
  }


  return (
    <div className="profile-user">
      <Link to="/profile/avatar" className="profile-user__edit">
        {renderAvatar()}
        <IconEdit/>
      </Link>

      <div className="profile-user__text-wrapper">
        <span className="profile-user__title">{title}</span>
        <span className="profile-user__description">{description}</span>
      </div>

      <Route path="/profile/avatar" component={AccountEditModal}/>
    </div>
  );
};

export default ProfileUser;
