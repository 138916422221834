import React from 'react';
import { Link } from 'react-scroll';

type Props = {};

const HotelDetailHeaderNav: React.FC<Props> = () => {
   const buttonOptions = {
      className: 'hotel-detail-header__navigation-button',
      duration: 300,
      offset: -100,
      smooth: true,
      //containerId: 'layoutContainer'
   };

   return (
      <nav className={'hotel-detail-header__navigation'}>
         <ul>
            <li>
               <Link to={'photosScrollElement'} {...buttonOptions}>
                  Фотографії
               </Link>
               {/*<button className={'hotel-detail-header__navigation-button'}>Фотографії</button>*/}
            </li>

            <li>
               <Link to={'amenitiesScrollElement'} {...buttonOptions}>
                  Зручності
               </Link>
            </li>

            <li>
               <Link to={'mapScrollElement'} {...buttonOptions}>
                  Розташування
               </Link>
            </li>

            <li>
               <Link to={'reviewsScrollElement'} {...buttonOptions}>
                  Відгуки
               </Link>
            </li>
         </ul>
      </nav>
   );
};

export default HotelDetailHeaderNav;
