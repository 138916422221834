import React, { Suspense, useEffect } from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '../common/store/configureStore';
import { CookiesProvider } from 'react-cookie';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import { MediaContextProvider } from '../common/components/common/Media/Media';
import { useSSR } from 'react-i18next';
import routes from '../common/routes';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GOOGLE_RE_CAPTCHA_KEY } from "../common/config";

const preloadedState = getSerializedData('preloaded_state');
const preloadedInitialLanguage = getSerializedData('preloaded_initial_language');
const preloadedInitialLanguageStore = getSerializedData('initial_language_store');

const store = configureStore(preloadedState);

export type AppDispatch = typeof store.dispatch;

const BaseApp = ({data, routes}) => {
    /**
     * transitionBehavior = blocked | instant
     * blocked - при ajax запросі чекає відповідь сервера тоді перехоить на сторінку яку ми запросили (by default)
     * instant - при ajax запросі не чекає відповіді з сервера. Відразу переходить на сторінку, яку ми запросили
     * */
    useSSR(preloadedInitialLanguageStore, preloadedInitialLanguage);

    return (
        <Suspense fallback={null}>
            <MediaContextProvider>
                <CookiesProvider>
                    <Provider store={store}>
                        <BrowserRouter
                            basename={
                                preloadedInitialLanguage /* щоб дефолтна мова не мала префіксу в урл добавити провірку тут */
                            }
                        >
                            <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RE_CAPTCHA_KEY}>
                                {/*@ts-ignore*/}
                                <After data={data} routes={routes} store={store} transitionBehavior="instant"/>
                            </GoogleReCaptchaProvider>
                        </BrowserRouter>
                    </Provider>
                </CookiesProvider>
            </MediaContextProvider>
        </Suspense>
    );
};

function renderApp() {
    ensureReady(routes).then(data => {
        return hydrate(<BaseApp data={data} routes={routes}/>, document.getElementById('root'));
    });
}

renderApp();

if (module.hot) {
    module.hot.accept('../common/routes', renderApp);
}
