import React, { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './OrderSuccess.scss';
import AppButton from '../../../components/ui/components/Buttons/AppButton/AppButton';
import Container from '../../../components/ui/layouts/Container/Container';
import Page from '../../../components/common/Page/Page';
import successLogoImage from '../../../assets/images/order-success/success-logo.svg';
import moonImage from '../../../assets/images/order-success/moon.svg';

import { RouteNames } from '../../../routes';
import { parseSearchParams } from '../../../helpers/url';
import { useDispatch } from 'react-redux';
import { clearPaymentServerData, clearStoringOnServerData } from '../../../store/order/order-reducer';
import { OrderPaymentResponse } from '../../../types/order';
import OrderSuccessWithPaymentData from './components/OrderSuccessWithPaymentData/OrderSuccessWithPaymentData';

const OrderSuccess = () => {
    const {search} = useLocation();
    const params = parseSearchParams(search);
    const canConfirm = params['can-confirm'] === 'true';
    const dispatch = useDispatch();

    const [paymentData, setPaymentData] = useState<OrderPaymentResponse>(null);

    useEffect(() => {
        const paymentDataFromLocalStorate = sessionStorage.getItem('paymentServerData');
        setPaymentData(paymentDataFromLocalStorate ? JSON.parse(paymentDataFromLocalStorate) : null);

        return () => {
            dispatch(clearPaymentServerData())
            dispatch(clearStoringOnServerData())
        }
    }, []);

    return (
        <Page>

            <Container>
                <div className="order-success">
                    <h1 className="order-success__title">
                        {canConfirm ? 'Запит на бронювання здійснено' : 'Бронювання успішне'}
                    </h1>

                    <div className="order-success__image">
                        <img src={successLogoImage} alt=""/>

                        {!paymentData?.paymentDetails && (
                            <div className={'moon-image-container'}>
                                <img src={moonImage} alt=""/>
                            </div>
                        )}
                    </div>

                    {paymentData?.paymentDetails
                        ? <OrderSuccessWithPaymentData paymentData={paymentData}/>
                        : (
                            <>
                                <div className="order-success__text">
                                    {canConfirm ? 'Протягом 15 хвилин Вам має надійти лист-підтвердження.' : 'Ми відправили вам на пошту підтвердження про бронювання.'}

                                    <br/>Детальну інформацію про бронювання Ви можете знайти у розділі <br
                                    className={'hide-on-mobile'}/>{' '}
                                    <NavLink to={RouteNames.PROFILE_ORDERS_LIST}>Найближчі подорожі</NavLink>
                                </div>

                                <AppButton className="order-success__button" size="middle" to={'/'}>
                                    Готово
                                </AppButton>
                            </>
                        )}

                </div>
            </Container>
        </Page>
    );
}

export default OrderSuccess;
