import React from 'react';
import { PhotoType } from '../../../../../../../types/common';
import './SelectedHotelInListGallery.scss';
import Image from '../../../../../../../components/ui/components/Image/Image';
import { generateSrcSet } from '../../../../../../../helpers/common';

type Props = {
    photos: PhotoType[]
}

const SelectedHotelInListGallery: React.FC<Props> = ({photos}) => {

    return (
        <ul className={'selected-list-hotel-item-gallery photos-length-' + photos.length}>
            {photos.map(photo => (
                <li key={`selected-list-hotel-item-gallery${photo.id}`}>
                    <Image src={generateSrcSet(photo.source, '560x560')}/>
                </li>
            ))}
        </ul>
    )
};

export default SelectedHotelInListGallery;
