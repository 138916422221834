import React, { FC, useEffect } from 'react';
import './HotelIndex.scss';
import Page from '../../../components/common/Page/Page';
import HotelIndexPlaces from './components/HotelIndexPlaces/HotelIndexPlaces';
import { scrollToTop } from '../../../helpers/common';
import Head from '../../../components/layout/components/Head/Head';

const HotelIndex: FC = () => {
    useEffect(scrollToTop, []);
    const title = `Бронюй.com - Готелі в Україні`;
    const description = "Перевірені готелі в Україні. Бронюй напряму без комісій та посередників."
    return (
        <Page isSearchFormOpen={true} className={'page--index-variant'}>
            <Head
              ogImage="/images/index/kyiv.png"
              title={title}
              metaDescription={description}
              ogDescription={description}
              ogTitle={title}>
                <meta name="theme-color" content="#213353"/>
            </Head>

            <div className={'hotel-index-background-image'}/>
            <div className="hotel-index">
                <div className="hotel-index__wrapper">
                    {/*<div className="hotel-index__carousel">*/}
                    {/*    <Container>*/}
                    {/*        <HotelsCarousel/>*/}
                    {/*    </Container>*/}
                    {/*</div>*/}
                </div>

                <HotelIndexPlaces/>
            </div>
        </Page>
    );
};

export default HotelIndex;
