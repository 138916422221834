import { createSlice } from '@reduxjs/toolkit';
import { editProfileThunk, getProfileThunk, setPhoneThunk, setResetPasswordThunk } from './profile-thunks';
import { ProfileData, ProfileDataResponse } from '../../types/profile';

interface InitialData {
   data: ProfileDataResponse;
   error: any;
   isFetching: boolean;
   isSending: boolean;
}

const initialState: InitialData = {
   data: {
      data: {
         birthday: null,
         email: null,
         gender: 0,
         id: 0,
         lastname: '',
         name: '',
         phone: '',
         photo: '',
         countryId: 0,
         city: '',
         countryName: '',
      },
      meta: {
         countries: { data: [] },
      },
   },
   error: {},
   isFetching: false,
   isSending: false,
};

const profileSlice = createSlice({
   name: 'profile',
   initialState,
   reducers: {},
   extraReducers: builder => {
      /** GET PROFILE */
      // request
      builder.addCase(getProfileThunk.pending, (state, action) => {
         state.isFetching = true;
      });

      // success response
      builder.addCase(getProfileThunk.fulfilled, (state, action) => {
         // console.log("action.payload",action.payload)
         state.isFetching = false;
         state.data = action.payload;
      });

      // response with errors
      builder.addCase(getProfileThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.payload;
      });

      /** EDIT PROFILE */
      // request
      builder.addCase(editProfileThunk.pending, (state, action) => {
         state.isSending = true;
      });

      // success response
      builder.addCase(editProfileThunk.fulfilled, (state, action) => {
         state.isSending = false;
         state.data.data = {
            ...state.data.data,
            ...action.payload,
         };
      });

      // response with errors
      builder.addCase(editProfileThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      });

      //
      /** Set phone */
      // request
      builder.addCase(setPhoneThunk.pending, (state, action) => {
         state.isSending = true;
      });

      // success response
      builder.addCase(setPhoneThunk.fulfilled, (state, action) => {
         state.isSending = false;

         state.data.data = {
            ...state.data.data,
            ...action.payload,
         };
      });

      // response with errors
      builder.addCase(setPhoneThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      });


      /** Change password */
      builder.addCase(setResetPasswordThunk.pending, (state, action) => {
         state.isSending = true;
      })
      builder.addCase(setResetPasswordThunk.fulfilled, (state, action) => {
         state.isSending = false;
         // do something after success
      })
      builder.addCase(setResetPasswordThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      })
   },
});

export default profileSlice.reducer;
