import React, { FC } from 'react';
import './ModalMobileButtons.scss';
import AppButton from '../../../../components/Buttons/AppButton/AppButton';
import { useTranslation } from 'react-i18next';

interface Props {
    submit: () => void;
    cancel: () => void;
    disabled?: boolean;
    buttonText: string;
}

const ModalMobileButtons: FC<Props> = props => {
    const {submit, cancel, disabled = false, buttonText} = props;
    const [t] = useTranslation();

    return (
        <div className="mobile-modal-buttons">
            <button className="mobile-modal-buttons__cancel" onClick={cancel}>
                {t('Очистити дати')}
            </button>

            <AppButton size="middle" onClick={submit} disabled={disabled} theme={'primary'}>
                {buttonText}
            </AppButton>
        </div>
    );
};

export default ModalMobileButtons;
