import { createSlice } from '@reduxjs/toolkit';
import { getOrdersThunk, hideAccountOrdersThunk } from './orders-thunks';
import { OrdersList } from '../../../types/orders';

interface InitialState {
   data: OrdersList;
   error: any;
   isFetching: boolean;
   isSending: boolean;
}

const initialState: InitialState = {
   data: [],
   error: {},
   isFetching: false,
   isSending: false,
};

const ordersSlice = createSlice({
   name: 'orders',
   initialState,
   reducers: {

   },
   extraReducers: builder => {
      // Fetch
      builder.addCase(getOrdersThunk.pending, (state) => {
         state.data = initialState.data
         state.isFetching = true;
      });

      builder.addCase(getOrdersThunk.fulfilled, (state, action) => {
         state.isFetching = false;
         state.data = action.payload;
      });

      builder.addCase(getOrdersThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.payload;
      });

      // Hide
      builder.addCase(hideAccountOrdersThunk.pending, (state) => {
         state.isSending = true;
      });

      builder.addCase(hideAccountOrdersThunk.fulfilled, (state, action) => {
         state.data  = state.data.filter((item) => item.id !== action.payload)
         state.isSending = false;
      });

      builder.addCase(hideAccountOrdersThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      });
   },
});


export default ordersSlice.reducer;
