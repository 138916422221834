import { createSlice } from '@reduxjs/toolkit';
import {
   createNewWishGroupThunk,
   deleteWishGroupThunk,
   getFavoriteGroupThunk,
   updateWishGroupThunk,
} from './favorite-group-list-actions';
import { FavoritesGroupListItem } from '../../../types/favorites';
import { mergeArrayItemById } from '../../../helpers/common';

interface InitialState {
   data: Array<FavoritesGroupListItem>;
   error: any;
   isFetching: boolean;
   isSending: boolean;
   isDeleting: boolean;
}

const initialState: InitialState = {
   data: [],
   error: {},
   isFetching: false,
   isSending: false,
   isDeleting: false,
};

const favoriteSlice = createSlice({
   name: 'favorite',
   initialState,
   reducers: {
      updateFavoriteGroup: (state, action) => {
         state.data = mergeArrayItemById<FavoritesGroupListItem>(state.data, action.payload)
      },
   },
   extraReducers: builder => {
      // Fetch
      builder.addCase(getFavoriteGroupThunk.pending, (state, action) => {
         state.isFetching = true;
      });

      builder.addCase(getFavoriteGroupThunk.fulfilled, (state, action) => {
         state.isFetching = false;
         state.data = action.payload;
      });

      builder.addCase(getFavoriteGroupThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.payload;
      });

      // Create
      builder.addCase(createNewWishGroupThunk.pending, (state, action) => {
         state.isSending = true;
      });

      builder.addCase(createNewWishGroupThunk.fulfilled, (state, action) => {
         state.isSending = false;
         state.data = [...state.data, action.payload];
      });

      builder.addCase(createNewWishGroupThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      });

      // Update
      builder.addCase(updateWishGroupThunk.pending, (state, action) => {
         state.isSending = true;
      });

      builder.addCase(updateWishGroupThunk.fulfilled, (state, action) => {
         state.isSending = false;

         state.data = state.data.map(item => {
            if (item.id === action.payload.id) {
               return {
                  ...item,
                  ...action.payload,
               };
            }

            return item;
         });
      });

      builder.addCase(updateWishGroupThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      });

      // DELETE
      builder.addCase(deleteWishGroupThunk.pending, (state, action) => {
         state.isDeleting = true;
      });
      builder.addCase(deleteWishGroupThunk.fulfilled, (state, action) => {
         state.data = state.data.filter(item => item.id !== action.payload);
         state.isDeleting = false;
      });
      builder.addCase(deleteWishGroupThunk.rejected, (state, action) => {
         state.isDeleting = false;
         state.error = action.payload;
      });
   },
});

export const { updateFavoriteGroup } = favoriteSlice.actions;

export default favoriteSlice.reducer;
