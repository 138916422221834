import React from 'react';
import './HotelDetailContentBlock.scss';
import classNames from 'classnames';

type Props = {
    title?: string;
    noBorder?: boolean;
    className?: string
};

const HotelDetailContentBlock: React.FC<Props> = ({title, noBorder, className = '', children}) => {
    const _className = classNames(
        'hotel-detail-content-block',
        className,
        {'hotel-detail-content-block--no-border': noBorder}
    );

    return (
        <div className={_className}>
            {title && <h4 className={'hotel-detail-content-block__title'}>{title}</h4>}

            <div>{children}</div>
        </div>
    );
};

export default HotelDetailContentBlock;
