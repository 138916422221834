import { useEffect, useRef } from 'react';
import { getHotelData } from './hotelDetailHelpers';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getRoomsThunk } from '../../../store/rooms/rooms-thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { clearOrder } from '../../../store/order/order-reducer';

export const useHotelDetailLogic = (dispatch, alias) => {
    const justLoaded = useRef(true);
    const params = useParams<any>();
    const history = useHistory();
    const {search, hash} = useLocation<any>();

    useEffect(() => {
        //if (isClient) scrollToTop();
        if (params.alias !== alias) getHotelData(dispatch, location.search, params.alias).then(() => {});
    }, []);

    useEffect(() => {
        if (!justLoaded.current) {
            dispatch(getRoomsThunk({alias: params.alias, query: search,}))
                .then(unwrapResult)
                .catch(error => {
                    /* return {statusCode: 404}*/
                })
        } else {
            justLoaded.current = false;
        }
    }, [search])

    useEffect(() => {
        dispatch(clearOrder());
    }, [history.location.search]);
}
