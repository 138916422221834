import React from 'react';
import OrderIndexHotelInfo from '../components/OrderIndexHotelInfo/OrderIndexHotelInfo';
import { OrderResponse } from '../../../../types/order';
import OrderPageSectionTitle from '../components/OrderPageSectionTitle/OrderPageSectionTitle';
import AppText from '../../../../components/ui/typography/AppText/AppText';
import OrderIndexReservations from '../components/OrderIndexReservations/OrderIndexReservations';
import useOrderLogic from '../../../../hooks/useOrderLogic';
import PriceViewer from '../../../../components/ui/components/PriceViewer/PriceViewer';
import OrderPageSeparator from '../components/OrderPageSeparator';
import OrderPageInfoClickableBlocks from '../components/OrderPageInfoClickableBlocks';
import OrderIndexPageSubmitSection from '../components/OrderIndexPageSubmitSection/OrderIndexPageSubmitSection';
import OrderIndexSummaryReservations from '../components/OrderIndexSummaryReservations/OrderIndexSummaryReservations';
import OrderPaymentTypeSelect from '../components/OrderPaymentTypeSelect/OrderPaymentTypeSelect';
import PaymentDropdown from '../components/PaymentDropdown/PaymentDropdown';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../store/auth/auth-selector';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { Log } from '@3ddie/react-utils';

type Props = {
    serverOrderData: OrderResponse,
    step: number
    onSubmit: () => void
}

const OrderIndexMobile = ({serverOrderData, step, onSubmit}: Props) => {
        const order = useAppSelector(state => state.order);
        const conditionsText = order.serverOrderData.data.reservationConditions;
        const {getReservationsTotalPrice} = useOrderLogic();
        const {isAuthorized} = useSelector(authSelector);
        const history = useHistory();
        const {pathname} = useLocation();

        return (
            <div>
                <OrderIndexHotelInfo hotel={serverOrderData.data.hotel.data}/>
                <OrderPageSeparator/>

                {step === 1 ? (
                    <>
                        <div className='order-page__order-info-top-line'>
                            <OrderPageSectionTitle>
                                Ваше бронювання № {serverOrderData.data.id}
                            </OrderPageSectionTitle>

                            <AppText variant={'gray'}>{serverOrderData.data.personDescription}</AppText>
                        </div>

                        <OrderIndexReservations
                            reservations={serverOrderData.data.reservations.data}
                            hotelAlias={serverOrderData?.data?.hotel?.data?.alias || ''}
                        />

                        {conditionsText && <OrderPageSeparator/>}

                        <OrderPageInfoClickableBlocks/>
                    </>
                ) : (
                    <>
                        <OrderIndexSummaryReservations
                            reservations={serverOrderData.data.reservations.data}
                            amount={serverOrderData.data.amount}
                            prepaymentAmount={serverOrderData.data.prepaymentAmount}
                            afterAmount={serverOrderData.data.reminderAmount}
                        />

                        {/*<OrderPageSeparator/>*/}

                        {isAuthorized ? (
                            <>
                                <OrderPaymentTypeSelect options={serverOrderData.data.paymentTypes}/>
                            </>
                        ) : null}

                    </>
                )}

                <OrderPageSeparator/>

                {isAuthorized ? (
                    <>
                        <OrderIndexPageSubmitSection mobileStep={step} onSubmit={onSubmit}/>
                    </>
                ) : (
                    <>
                        <AppButton
                            className={'order-index-page-submit-section__order-button'}
                            onClick={() => {
                                history.push(`/auth/signin?redirect-url=${pathname}`);
                            }}
                            style={{marginBottom: 20}}
                            size='big'
                        >
                            {'Увійти та продовжити'}
                        </AppButton>
                    </>
                )}

            </div>
        )
    }
;

export default OrderIndexMobile;
