import React, { FC, useRef, useState } from 'react';
import './AccountOrdersDetailLocation.scss';
import ClickableArrowItem from '../../../../../../../components/common/ClickableArrowItem/ClickableArrowItem';
import TextWithIcon from '../../TextWithIcon/TextWithIcon';
import IconCopy from '../../ui/icons/IconCopy';
import IconLocation from '../../ui/icons/IconLocation';
import appToastr from '../../../../../../../services/appToastr';
import { generateNavigationOnGoogleMap, getMyPosition } from '../../../../../../../helpers/geolocation';
import AppLoader from "../../../../../../../components/ui/loaders/AppLoader/AppLoader";

interface Props {
   address: string;
   direction: {
      latitude: number;
      longitude: number;
   };
}

const AccountOrdersDetailLocation: FC<Props> = ({ address, direction }) => {
   const [loading, setLoading] = useState(false);
   const generatePath = () => {
      setLoading(true);
      getMyPosition()
         .then((position: GeolocationPosition) => {
            const pos = {
               latitude: position.coords.latitude,
               longitude: position.coords.longitude,
            };

            const generatedPath = generateNavigationOnGoogleMap(pos, direction);
            setLoading(false);

            window.open(generatedPath, '_blank');
         })
         .catch(error => {
            appToastr.info('Щоб прокласти шлях на карті дозвольте браузеру використовувати геодані', 'Важливо');
         });
   };

   return (
      <div>
         <h4 className="account-orders-detail-subtitle">Як дістатися</h4>

         <ul className="account-orders-detail-info-list">
            <li>
               <ClickableArrowItem
                  content={<TextWithIcon text={'Скопіювати адресу'} Icon={IconCopy} />}
                  onClick={e => {
                     navigator.clipboard.writeText(address);
                     appToastr.info('Скопійовано в буфер');
                  }}
               />
            </li>
            <li>
               <ClickableArrowItem
                  content={<TextWithIcon text={'Як дістатися'} Icon={loading ? ()=> <AppLoader size={24} style={{marginRight: 16}} /> : IconLocation} />}
                  onClick={generatePath}
               />
            </li>
         </ul>
      </div>
   );
};

export default AccountOrdersDetailLocation;
