import React, { FC } from 'react';
import './FormButtons.scss';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';

interface Props {
   onCancel: (e: HTMLButtonElement) => void;
   isSending: boolean;
   onSave: () => void;
}

const FormButtons: FC<Props> = (props) => {
   const {onCancel, isSending, onSave} = props;
   return (
      <div className="form-buttons">
         <AppButton theme="light" size="middle" onClick={onCancel}>
            Скасувати
         </AppButton>
         <AppButton size="middle" showIndicator={isSending} onClick={onSave} >
            Зберегти
         </AppButton>
      </div>
   );
};

export default FormButtons;