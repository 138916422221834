import { createAsyncThunk } from '@reduxjs/toolkit';
import favoriteApi from '../../../api/favoriteApi';

export const getFavoriteGroupItemsThunk = createAsyncThunk(
   'favoriteItems/getFavoriteGroupItemsThunk',
   async (id: number, { rejectWithValue }) => {
      try {
         const response = await favoriteApi.fetchGroupById(id);
         //console.log("response",response)
         return response.data;
      } catch (error) {
         throw rejectWithValue(error);
      }
   },
);
