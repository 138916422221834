import {createAsyncThunk} from "@reduxjs/toolkit";
import profileAPI from "../../api/profileAPI";
import {authorizedIn, authorizedOut} from "../auth/auth-reducer";
import { ProfilePutData, RequestSetPasswordParams, RequestSetPhoneParams } from '../../types/profile';
import appToastr from '../../services/appToastr';
import { _formatDate, formatDate } from '../../helpers/date';

export const getProfileThunk = createAsyncThunk(
    'profile/getProfileThunk',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const res = await profileAPI.fetchProfile();

            if(res.data.birthday){
               res.data.birthday = formatDate(new Date(res.data.birthday), 'dd.MM.yyyy')
            }

            dispatch(authorizedIn())
            return res;
        } catch (error) {
            dispatch(authorizedOut())

            throw rejectWithValue(error.response); //data
        }
    }
)


export const editProfileThunk = createAsyncThunk(
  'profile/editProfileThunk',
  async (data: ProfilePutData, { rejectWithValue, dispatch }) => {
    try {

       if (data.birthday) {
          data.birthday = _formatDate(data.birthday,'YYYY-MM-DD', 'DD.MM.YYYY') ;
       }

      const res = await profileAPI.editProfile(data);

      appToastr.success('Дані користувача були збережені', "Успішно")


       if(res.data.birthday){
          res.data.birthday = formatDate(new Date(res.data.birthday), 'dd.MM.yyyy')
       }

      return res.data;
    } catch (error) {

      if (error?.response?.status === 408) {
        appToastr.error(error?.response?.data.message, "Помилка")
      } else {
       appToastr.error('Дані користувача не були збережені', "Помилка")
      }

      throw rejectWithValue(error.response); //data
    }
  }
)


export const setPhoneThunk = createAsyncThunk(
   'profile/setPhoneThunk',
   async (data: RequestSetPhoneParams, { rejectWithValue, dispatch }) => {
      try {
         const res = await profileAPI.setPhone(data);
         appToastr.success('Ваш телефон був успішно збережений', "Успішно")
         return res.data;
      } catch (error) {
         appToastr.error('Не вдалось змінити телефон', "Помилка")
         throw rejectWithValue(error.response); //data
      }
   }
)


export const setResetPasswordThunk = createAsyncThunk(
   'profile/setResetPasswordThunk',
   async (data: RequestSetPasswordParams, { rejectWithValue, dispatch }) => {
      try {
         const res = await profileAPI.setPassword(data);

         return res.data;
      } catch (error) {
         throw rejectWithValue(error.response); //data
      }
   }
)