import React from 'react';
import './ModalGalleryArrow.scss';

type Props = {
   direction: 'prev' | 'next';
};

const ModalGalleryArrow = ({ direction, ...rest }: Props) => {
   //@ts-ignore
   const { className } = rest;
   const isDisabled = className.includes('disabled');

   return (
      <button
         {...rest}
         className={`model-gallery__arrow model-gallery__arrow--${direction} ${
            isDisabled ? 'model-gallery__arrow--disabled' : ''
         }`}
      >
         <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="46" height="46" rx="23" stroke="white" strokeWidth="2" />
            <path
               d="M25.6829 24L20.2408 17.6508C19.8813 17.2315 19.9299 16.6002 20.3492 16.2408C20.7686 15.8813 21.3999 15.9299 21.7593 16.3492L27.7593 23.3492C28.0803 23.7237 28.0803 24.2763 27.7593 24.6508L21.7593 31.6508C21.3999 32.0701 20.7686 32.1187 20.3492 31.7593C19.9299 31.3999 19.8813 30.7686 20.2408 30.3492L25.6829 24Z"
               fill="white"
            />
         </svg>
      </button>
   );
};

export default ModalGalleryArrow;
