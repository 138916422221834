import React, {FC} from "react";

interface Props {
  className?: string;
}

const IconRight: FC<Props> = (props) => {

  return (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.68294 8.00002L0.240762 1.65081C-0.118661 1.23148 -0.0700991 0.600184 0.349227 0.240762C0.768553 -0.118661 1.39985 -0.0700991 1.75928 0.349227L7.75928 7.34923C8.08027 7.72372 8.08027 8.27632 7.75928 8.65081L1.75928 15.6508C1.39985 16.0701 0.768553 16.1187 0.349227 15.7593C-0.0700991 15.3999 -0.118661 14.7686 0.240762 14.3492L5.68294 8.00002Z"
        fill="#C5C6CF"/>
    </svg>

  )
}

export default IconRight