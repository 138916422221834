import React from 'react';
import './ReservationEditModalTitle.scss';

type Props = {}

const ReservationEditModalTitle: React.FC<Props> = ({children}) => {
    return (
        <span className={'reservation-edit-modal-title'}>{children}</span>
    )
};

export default ReservationEditModalTitle;
