export const LANGUAGE_UK = 'uk';

export const allowedLanguagesArray = [LANGUAGE_UK];
export const defaultLanguage = LANGUAGE_UK;
export const enabledLikes = false;

export const DATE_FORMAT_SERVER = 'YYYY-MM-DD';
export const DATE_FORMAT_CLIENT = 'D MMMM YYYY';
export const DATE_FORMAT_CLIENT_SHORT = 'D MMMM';

export const DATE_FORMAT_CLIENT_SHORT_DATEFNS = 'd MMMM';

export const DISABLE_STARS_SHOW = true;

// headers
export const HTTP_HEADER_CURRENCY = 'Currency'
export const HTTP_HEADER_AUTHORIZATION = 'Authorization'
export const HTTP_HEADER_LANGUAGE = 'Accept-Language'
export const HTTP_HEADER_CONTACT_TOKEN = 'Contact-Token';

export const EXTRANET_URL = process.env.NODE_ENV === 'development' ? 'https://extranet.dev.bronui.com' : 'https://extranet.bronui.com';
export const GOOGLE_RE_CAPTCHA_KEY = '6Le1iRclAAAAANXBWy91M1Gcd6Qw8huE52DNLzxO'
