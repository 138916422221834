import React, { FC } from 'react';

interface Props {
   onClick?: () => void;
   fill?: string;
}

const IconClose: FC<Props> = ({ onClick, fill }) => {
   return (
      <svg width="23" height="24" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
         <path
            d="M10.2668 12L5.01401 6.49372C4.68804 6.15201 4.68804 5.59799 5.01401 5.25628C5.33999 4.91457 5.86851 4.91457 6.19448 5.25628L11.4473 10.7626L16.7001 5.25628C17.0261 4.91457 17.5546 4.91457 17.8805 5.25628C18.2065 5.59799 18.2065 6.15201 17.8805 6.49372L12.6278 12L17.8805 17.5063C18.2065 17.848 18.2065 18.402 17.8805 18.7437C17.5546 19.0854 17.0261 19.0854 16.7001 18.7437L11.4473 13.2374L6.19448 18.7437C5.86851 19.0854 5.33999 19.0854 5.01401 18.7437C4.68804 18.402 4.68804 17.848 5.01401 17.5063L10.2668 12Z"
            fill={fill || '#6D7188'}
         />
      </svg>
   );
};

export default IconClose;
