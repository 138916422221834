import {sendData} from "../helpers/network";
import {
    DataTokenResponse,
    SighInRequest,
    SignUpCompleteRequest,
    SignUpConfirm,
    SignUpRequest,
    SignUpTemporaryHeader,
    SignInSocialParams
} from "../types/auth";
import { RequestSetPasswordParams } from '../types/profile';

export default {
    signInSocial: (data: SignInSocialParams) => sendData('/auth/login-social', data) as Promise<DataTokenResponse>,
    signIn: (data: SighInRequest) => sendData('/auth/login', data) as Promise<DataTokenResponse>,
    signUpInitial: (data: SignUpRequest) => sendData('/auth/register/initialize', data), // sign up (Реєстрація. Крок №1. Ініціалізація)
    signUpResendCode: (data: SignUpRequest) => sendData('/auth/register/resend-code', data),
    signUpConfirmCode: (data: SignUpConfirm) => sendData('/auth/register/confirm-code', data) as Promise<DataTokenResponse>, // sign up confirm (Реєстрація. Крок №2. Підтвердження коду)
    signUpComplete: (data: SignUpCompleteRequest, headers: SignUpTemporaryHeader) => sendData('/auth/register/complete', data, headers) as Promise<DataTokenResponse>,
    signUpRefreshToken: () => sendData('/auth/refresh') as Promise<DataTokenResponse>,

    // restore password
    restoreRequest: (data: {login: string}) => sendData('/auth/restore-password', data),
    restoreComplete: (data: RequestSetPasswordParams) => sendData('/auth/set-password', data),

}
