import React from 'react';
import classNames from 'classnames';
import Select from 'react-dropdown-select';
import './FieldStyledSelect.scss';

type Props = {
    id?: string;
    size?: string;
    isError?: boolean;
    name?: string;
    value?: string | number;
    disabled?: boolean;
    selected?: number | string;
    onChange?: (event: any) => void;
    options: Array<{ value: any; label: string }>;
    values: Array<{ value: any; label: string }>;
    placeholder?: string;
    onClick?: () => void
};

const FieldStyledSelect: React.FC<Props> = (props) => {
    const {
        size,
        isError,
        options,
        values = [],
        onChange,
        placeholder = 'Вибрати',
        onClick,
        disabled
    } = props;

    const classes = classNames('field-styled-select', {isError}, size, {disabled});

    return (
        <div className={classes} onClick={!disabled ? onClick : () => {}}>
            <Select
                name={'field-styled-select' + props.id}
                values={values}
                disabled={disabled || !!onClick}
                options={options}
                placeholder={placeholder}
                dropdownPosition="auto"
                onChange={onChange}
                contentRenderer={({props, state, methods}) => {
                    if (values.length && values[0].value !== 0) {
                        return (
                            <div className={`field-styled-select__selected-value`}>
                                <span className={'field-styled-select__selected-value-text'}>{values[0].label}</span>
                            </div>
                        );
                    } else {
                        return <div className={'field-styled-select__placeholder'}>{placeholder}</div>;
                    }
                }}
            />
        </div>
    );
};

export default FieldStyledSelect;
