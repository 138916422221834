import React, {FC} from "react";

interface Props {

}

const IconUnknownUser: FC<Props> = (props) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 9C13.6569 9 15 7.65685 15 6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6C9 7.65685 10.3431 9 12 9Z" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M19 20H5V16.259C5.89739 15.2303 6.98159 14.4103 8.1856 13.8498C9.38961 13.2892 10.6878 13 12 13C13.3122 13 14.6104 13.2892 15.8144 13.8498C17.0184 14.4103 18.1026 15.2303 19 16.259V20Z" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>

  )
}

export default IconUnknownUser