import React, { FC } from "react";
import "./SearchOption.scss";
import SearchOptionPlaceIcon from './components/SearchOptionPlaceIcon';
import SearchOptionHotelIcon from './components/SearchOptionHotelIcon';

const getIcon = (type: 'place' | 'hotel') => {
    switch (type) {
        case 'place':
            return <SearchOptionPlaceIcon/>

        case 'hotel':
            return <SearchOptionHotelIcon/>;
    }

    return <SearchOptionPlaceIcon/>;
}

const SearchOption: FC<any> = (props) => {
    const {parameters} = props;

    const getHighlightedText = (text, highlight) => {
        // Split text on highlight term, include term itself into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        const resultText = parts.map((part, index) => part.toLowerCase() === highlight.toLowerCase() ? <b key={index}>{part}</b> : part);

        return <span>{resultText}</span>;
    };

    const photo = parameters?.data?.place?.photo || parameters?.data?.photo;

    return (
        <div className="react-select-option" key={'option-' + parameters?.data?.value}>
            {
                photo
                    ? <img src={photo} width={36} height={36} alt={''}/>
                    : getIcon(parameters.data?.place?.type)
            }

            <div className="react-select-option__text">
                {getHighlightedText(parameters.data.label, parameters.selectProps.inputValue)}
            </div>
        </div>
    );
}

export default SearchOption
