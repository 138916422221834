import React, {FC, HTMLProps} from 'react';
import classNames from 'classnames';
import './FieldCard.scss';
import {uid} from "../../../../../helpers/common";

interface PropsType extends HTMLProps<HTMLInputElement> {
    isError?: boolean;
    isActive?: boolean;
    body: any;
}

const FieldCard: FC<PropsType> = (props) => {
    const {isError, isActive, id, body, disabled, ...rest} = props;
    const classes = classNames('field-radio-card', {isActive}, {isError}, {disabled});

    return (
        <label className={classes} id={id}>
            <input id={id}  {...rest} type="radio" disabled={disabled}/>
            <div>{body}</div>
        </label>
    );
};

export default FieldCard;
