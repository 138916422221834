import React, { forwardRef, useEffect, useRef, useState } from 'react';

import FieldDatepicker from '../../../../../../../components/ui/forms/fields/FieldDatepicker/FieldDatepicker';
import { _formatDate, formatDateStringToDateObject } from '../../../../../../../helpers/date';
import CustomDatepickerField
    from '../../../../../../Hotels/HotelDetail/components/HotelRoomsSearch/components/CustomDatepickerField/CustomDatepickerField';
import { DATE_FORMAT_CLIENT_SHORT } from '../../../../../../../config';
import SearchModalDates from '../../../../../../Hotels/common/mobile/SearchModalDates/SearchModalDates';
import useResizeObserver from '../../../../../../../hooks/useResizeObserver';
import useWindowSize from '../../../../../../../hooks/useWindowSize';

type Props = {
    startDateInitial: string,
    endDateInitial: string,
    onSearch: (dates: [Date, Date]) => void,
    onCalendarStateChange?: (isOpen: boolean) => void
}

const _format = date => _formatDate(date, DATE_FORMAT_CLIENT_SHORT);

const ReservationEditModalSearchForm: React.FC<Props> = (props) => {
    const {
        startDateInitial,
        endDateInitial,
        onSearch,
        onCalendarStateChange
    } = props;

    const [dates, setDates] = useState<[any, any]>([startDateInitial, endDateInitial]);
    const [showMobileCalendar, setShowMobileCalendar] = useState(false);
    const {isMobile} = useWindowSize();

    const calendarRef = useRef(null);

    useEffect(() => {}, [onCalendarStateChange]);

    // @ts-ignore
    const CustomInput = forwardRef(({value, onClick}, ref) => {
        return (
            <CustomDatepickerField
                start={_format(dates[0])}
                end={dates[1] ? _format(dates[1]) : ''}
                onClickStart={onClick}
                onClickEnd={onClick}
            />
        );
    });

    return (
        <div>
            {showMobileCalendar && <SearchModalDates
               start={dates[0] ? formatDateStringToDateObject(dates[0]) : null}
               end={dates[1] ? formatDateStringToDateObject(dates[1]) : null}
               onSubmit={() => {
                   onSearch(dates);
                   setShowMobileCalendar(false);
               }}
               title='Дати проживання'
               changeDates={(values) => {
                   if (Array.isArray(values) && values.length === 2) {
                       //@ts-ignore
                       setDates(values);
                   }
               }}
               onCloseModal={() => setShowMobileCalendar(false)}
            />}

            <FieldDatepicker
                //@ts-ignore
                ref={calendarRef}
                name="start"
                size="large"
                selectsRange
                minDate={new Date()}
                monthsShown={1}
                dateFormat="dd.MM.yyyy"
                selected={null}
                onChange={(values) => {
                    if (Array.isArray(values) && values.length === 2) {
                        setDates(values);
                        onSearch(values);
                    }
                }}
                startDate={dates[0] ? formatDateStringToDateObject(dates[0]) : null}
                endDate={dates[1] ? formatDateStringToDateObject(dates[1]) : null}
                customInput={<CustomInput/>}
                onCalendarOpen={() => {
                    if (isMobile) setShowMobileCalendar(true);
                    if (onCalendarStateChange) onCalendarStateChange(true)
                }}
                onCalendarClose={() => {
                    if (onCalendarStateChange) onCalendarStateChange(false)
                }}
            />
        </div>
    )
};

export default ReservationEditModalSearchForm;
