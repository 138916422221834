import React, { FC, forwardRef } from 'react';
import './CustomDatepickerField.scss';
import FieldInput from '../../../../../../../components/ui/forms/fields/FieldInput/FieldInput';

interface Props {
    start: string;
    end: string;
    onClickStart: (e: any) => void;
    onClickEnd: (e: any) => void;
}

const CustomDatepickerField: FC<Props> = forwardRef((props, ref) => {
    const {start, end, onClickStart, onClickEnd} = props;

    return (
        <div className="hotel-rooms-custom-date-pickers">
            <div className="hotel-rooms-custom-date-pickers__item">
                <div>
                    <label className="hotel-rooms-custom-date-pickers__label" htmlFor="checkin-date">
                        Заїзд
                    </label>

                    <div className="hotel-rooms-custom-date-pickers__input" onClick={onClickStart}>
                        {/*  @ts-ignore */}
                        <FieldInput
                            size="large"
                            value={start}
                            readOnly
                            ref={ref}
                            id="checkin-date"

                            placeholder="День заїзду"
                        />
                    </div>
                </div>
            </div>

            <div className="hotel-rooms-custom-date-pickers__item">
                <div>
                    <label className="hotel-rooms-custom-date-pickers__label" htmlFor="checkout-date">
                        Виїзд
                    </label>

                    <div className="hotel-rooms-custom-date-pickers__input" onClick={onClickEnd}>
                        {/*  @ts-ignore */}
                        <FieldInput
                            size="large"
                            value={end}
                            readOnly
                            ref={ref}
                            disabled
                            id="checkout-date"
                            placeholder="День виїзду"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default CustomDatepickerField;
