import React from 'react';
import './NoReviews.scss';
import NoReviewsIcon from './NoReviewsIcon';

type Props = {}

const NoReviews: React.FC<Props> = () => {
    return (
        <div className={'no-reviews'}>
            <NoReviewsIcon/>

            <span>Відгуки відсутні</span>
        </div>
    )
};

export default NoReviews;
