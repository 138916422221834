import React, { FC, HTMLProps } from 'react';
import classNames from 'classnames';
import './FieldCheckbox.scss';

interface IProps extends HTMLProps<HTMLInputElement> {
   isError?: boolean;
}

const FieldCheckbox: FC<IProps> = props => {
   const { id, className, isError, value, checked, onChange, ...rest } = props;
   const classes = classNames('app-checkbox', className, 'app-checkbox--secondary');

   return (
      <div className={classes}>
         <input id={id} value={value} checked={checked} onChange={onChange} {...rest} type="checkbox" />
         <label className="app-checkbox__label" htmlFor={id} />
      </div>
   );
};

export default FieldCheckbox;
