import React from 'react';

const RoomPriceMealIconRO: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3562_74228)">
            <path
                d="M23.4901 12.1597C23.4662 11.9794 23.3784 11.8136 23.2426 11.6924C23.1068 11.5713 22.932 11.503 22.7501 11.4998H19.7501V9.90973C19.7262 9.72935 19.6384 9.56356 19.5026 9.44244C19.3668 9.32133 19.192 9.25297 19.0101 9.24976H12.4201L13.9201 10.7498H18.3001V15.0897L19.8001 16.5897V12.9697H22.0501V18.2197H21.4601L22.9501 19.7097C23.1075 19.6719 23.2501 19.5884 23.3601 19.4697C23.4804 19.3307 23.5477 19.1536 23.5501 18.9697V12.1297L23.4901 12.1597ZM17.8501 20.9697C17.7455 21.1341 17.6175 21.2824 17.4701 21.4097C17.063 21.7651 16.5406 21.9606 16.0001 21.9597H14.4501C14.307 20.6809 13.8014 19.4697 12.9929 18.4688C12.1843 17.4678 11.1065 16.7187 9.88641 16.3099C8.66632 15.9011 7.35482 15.8496 6.10641 16.1614C4.85801 16.4732 3.72473 17.1353 2.84012 18.0697C1.64936 19.3199 0.989798 20.9832 1.00012 22.7097C1.00012 22.9086 1.07913 23.0994 1.21978 23.2401C1.36044 23.3807 1.55121 23.4597 1.75012 23.4597H16.1901C17.0278 23.4127 17.8261 23.0892 18.4601 22.5397C18.6313 22.3904 18.7856 22.2228 18.9201 22.0397L17.8501 20.9697ZM2.55011 21.9697V21.8297V21.6097C2.82044 20.3633 3.53038 19.2558 4.55011 18.4897L5.55011 21.9697H2.55011ZM8.30011 21.9697H7.19012L5.99011 17.7697C6.55593 17.5722 7.15082 17.4708 7.75012 17.4697H8.1101C8.58423 17.5044 9.05167 17.6019 9.50012 17.7597L8.30011 21.9697ZM9.8601 21.9697L10.8601 18.4897C11.9488 19.2985 12.6861 20.4938 12.9201 21.8297V21.9697H9.8601ZM8.50012 11.6697V15.2498H10.0001V13.1697L8.50012 11.6697ZM13.7401 0.969727C13.7402 2.15832 13.3502 3.31408 12.6301 4.2597L12.2101 4.91974C11.6163 5.76749 11.1731 6.71131 10.9001 7.70972L11.6601 8.46973H12.3001C12.4519 7.55236 12.8147 6.68284 13.3601 5.92975L13.9301 5.03973C14.7972 3.86077 15.2602 2.4332 15.2501 0.969727H13.7401ZM17.4901 0.969727C17.4902 2.15832 17.1002 3.31408 16.3801 4.2597L15.9601 4.91974C15.1853 5.95994 14.6926 7.18287 14.5301 8.46973H16.0401C16.1977 7.56634 16.5604 6.7111 17.1001 5.96973L17.6701 5.07971C18.5372 3.90075 19.0002 2.47317 18.9901 1.0097L17.4901 0.969727Z"
            />
            <path
                d="M22.9799 22.9697C22.8401 23.1054 22.6546 23.1838 22.4599 23.1896C22.2643 23.1879 22.0774 23.1088 21.9399 22.9697L1.5199 2.54968C1.39263 2.4143 1.32178 2.23549 1.32178 2.04968C1.32178 1.86388 1.39263 1.68506 1.5199 1.54968C1.65288 1.41778 1.83259 1.34375 2.0199 1.34375C2.2072 1.34375 2.38692 1.41778 2.5199 1.54968L11.7499 10.7797L18.2199 17.2397L22.9499 21.9697C23.0771 22.1034 23.1505 22.2794 23.156 22.4639C23.1615 22.6484 23.0988 22.8285 22.9799 22.9697V22.9697Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_3562_74228">
                <rect width="22.5" height="22.5" fill="white" transform="translate(1 1)"/>
            </clipPath>
        </defs>
    </svg>
);

export default RoomPriceMealIconRO;
