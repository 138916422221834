import React, { FC } from 'react';
import './StatusOrder.scss';
import classNames from 'classnames';

interface Props {
   status: 'cart' | 'confirmed' | 'new' | 'partial_paid' | 'paid' | 'cancelled';
}

const StatusOrder: FC<Props> = (props) => {
   const {status = 'cart', children} = props;
   const classes = classNames('status-order', `status-order--${status}`)

   return (
      <div className={classes}>
         {children}
      </div>
   );
};

export default StatusOrder;