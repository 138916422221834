import React, { FC } from 'react';
import IconHotelBackground from '../icons/IconHotelBackground';
import './NoFoundHotel.scss';

interface IProps{
  searchPhone: string;
}

const NoFoundHotel:FC<IProps> = ({searchPhone}) => {
   return (
      <div className="no-found-hotel">
         <IconHotelBackground />
         <h4>Зареєструвати готель </h4>
         <h6>Ми нічого не знайшли за номером: «{searchPhone}» Бажаєте додати новий готель?</h6>
      </div>
   );
};

export default NoFoundHotel;
