import React from "react";

const IconClose = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0665 2.40023L11.5999 0.933594L6.99988 5.60026L2.39986 0.933594L0.933228 2.40023L5.59989 7.00024L0.933228 11.6003L2.39986 13.0669L6.99988 8.40023L11.5999 13.0669L13.0665 11.6003L8.39986 7.00024L13.0665 2.40023Z" fill="white"/>
    </svg>
  )
}

export default IconClose;