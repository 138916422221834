import React, { FC } from 'react';
import './BreadcrumbsMobile.scss';
import IconArrowLeft from '../../icons/account/IconArrowLeft';
import { Link } from 'react-router-dom';

interface Props {
   backPath: string;
   title: string;
   onClick?: (e: any) => void
}

const BreadcrumbsMobile: FC<Props> = (props) => {
   const { backPath, title, onClick } = props;

   return (
      <Link to={backPath} className='breadcrumbs-mobile' onClick={onClick}>
         <IconArrowLeft />
         <h3>{title}</h3>
         <div></div>
      </Link>
   );
};

export default BreadcrumbsMobile;