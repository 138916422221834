import React, { ChangeEvent, ClipboardEvent, FC, HTMLProps } from 'react';
import {Field, FieldProps} from 'formik';
import ControlLayout from './ControlLayout/ControlLayout';
import MuiFieldInput from "../muiFields/MuiFieldInput";
import { removeSymbolsFromString } from '../../../../helpers/regEx';

interface Props extends HTMLProps<HTMLInputElement> {
  isError?: boolean;
  label?: string;
  mask?: string;
  maskChar?: string;
  alwaysShowMask?: boolean;
  formatChars?: any;
  unmask?: boolean;
  rightComponent?: React.ReactNode,
  onPast?: (e: any) => void;
  role?: 'input' | 'phone';
}

const AppFormInput: FC<Props> = props => {
  const {name, unmask = false, type = 'text',role = 'input', disabled, label, id, mask, alwaysShowMask, maskChar, readOnly, rightComponent, onChange, onPast} = props;

  return (
    <Field name={name}>
      {(propsField: FieldProps) => {
        const {field, meta, form} = propsField;
        const isError: boolean = meta.touched && !!meta.error;

        const handlePast = (e: ClipboardEvent<HTMLInputElement>) => {
          e.preventDefault()
          let value = e.clipboardData.getData('Text');

          if(onPast){
            onPast(e)
          }

          if(role === 'phone'){
            if(value.includes("+38")){
              value = value.replace("+38", "");
            }
            if(value[0] == "8"){
              value = value.substring(1);
            }
          }

          form.setFieldValue(name, value);
        }

        return (
          <ControlLayout isError={isError} error={meta.error}>
            <MuiFieldInput

              label={label}
              type={type}
              id={id}
              alwaysShowMask={alwaysShowMask}
              maskChar={maskChar}
              mask={mask}
              rightComponent={rightComponent}

              {...field}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {

                if(onChange) {
                  onChange(e)
                }

                if (unmask) {
                  const unmask = removeSymbolsFromString(e.target.value);
                  form.setFieldValue(name, unmask);

                } else {
                  form.setFieldValue(name, e.target.value);
                }
              }}
              onPaste={handlePast}
              readOnly={readOnly}
              isError={isError}
              disabled={disabled}
            />
          </ControlLayout>
        );
      }}
    </Field>
  );
};

export default AppFormInput;
