import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { RouteNames } from '../../../../../routes';
import ModalGallery from '../../../../../components/hotels/ModalGallery/ModalGallery';
import FeedbackModal from '../FeedbackModal/FeedbackModal';
import { PhotoType } from '../../../../../types/common';

type Props = {
    photos: PhotoType[],
    onClose: () => void,
    alias: string
}

const HotelDetailGalleryModalRoute: React.FC<Props> = ({photos, onClose, alias}) => {
    const {search, hash} = useLocation<any>();

    return (
        <Switch location={useLocation()}>
            <Route
                exact={false}
                path={RouteNames.HOTELS_GALLERY}
                render={() => (
                    <ModalGallery
                        images={photos}
                        onClose={onClose}
                        initialPathName={'/hotels/' + alias}
                    />
                )}
            />
        </Switch>
    )
};

export default HotelDetailGalleryModalRoute;
