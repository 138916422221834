import { useState, useEffect, useRef } from "react";

const breakpoints = {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
}

function useWindowSize() {
    const [windowSize, setWindowSize] = useState(0);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize(window.innerWidth);
        }
        handleResize();
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return {
        isMobile: windowSize <= breakpoints.md
    };
}

export default useWindowSize;
