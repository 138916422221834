import React, {FC} from "react";
import "./RadioButtonIcon.scss";

interface Props {
  checked: boolean;
}

const RadioButtonIcon: FC<Props> = (props) => {
  const {checked} = props;

  if (checked) {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#6D5ECB"/>
        <circle cx="8" cy="8" r="3" fill="white"/>
      </svg>

    )
  }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="8" cy="8" r="7.5" fill="white" stroke="#C5C6CF"/>
    </svg>

  )


}

export default RadioButtonIcon