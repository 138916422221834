import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRoomsThunk } from './rooms-thunks';
import { Rooms } from '../../types/rooms';

interface InitialState {
   data: Rooms;
   error: any;
   isFetching: boolean;
   focusOnHotelSearchForm: boolean;
   showRoomsMobileFlow: boolean
}

const initialState: InitialState = {
   data: {
      data: [],
      meta: {
         hotel: {
            name: '',
         },
         mealTypes: {
            data: []
         },
         properties:{
            data: []
         }
      },
   },
   error: {},
   isFetching: false,
   focusOnHotelSearchForm: false,
   showRoomsMobileFlow: false
};

const roomsSlice = createSlice({
   name: 'rooms',
   initialState,
   reducers: {
      setHotelSearchFormFocus(state, action: PayloadAction<boolean>) {
         console.log(action);
         state.focusOnHotelSearchForm = action.payload;
      },
      setShowMobileRoomsFlow(state, action: PayloadAction<boolean>) {
         state.showRoomsMobileFlow = action.payload;
      }
   },
   extraReducers: builder => {
      // request
      builder.addCase(getRoomsThunk.pending, (state, action) => {
         state.isFetching = true;
      });

      // success response
      builder.addCase(getRoomsThunk.fulfilled, (state, action) => {
         state.data = action.payload;
         state.isFetching = false;
      });

      // response with errors
      builder.addCase(getRoomsThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.error;
      });
   },
});

export const { setHotelSearchFormFocus, setShowMobileRoomsFlow } = roomsSlice.actions;

export default roomsSlice.reducer;
