import { orderRoom, removeRoomFromOrder } from '../store/order/order-reducer';
import { useAppDispatch } from './reduxHooks';
import { RoomItemPrepared, RoomItemPriceType } from '../types/rooms';
import useSearchForm from '../screens/Hotels/common/HotelSearchForm/hooks/useSearchForm';
import { pluralize } from '../helpers/translatioHelpers';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { useEffect, useState } from 'react';

const useOrderLogic = () => {
    const dispatch = useAppDispatch();
    const {reservations, serverOrderData} = useSelector((state: AppState) => state.order);
    const {guestsParams, startDateServerFormat, endDateServerFormat} = useSearchForm()
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>();

    useEffect(() => {
        const paymentCards = serverOrderData?.data?.paymentCards?.data;

        if (Array.isArray(paymentCards)) {
            paymentCards.forEach(paymentCard => {
                if (paymentCard.selected) {
                    setSelectedPaymentMethod(paymentCard);
                }
            })
        }
    }, [serverOrderData]);

    const addRoomToOrder = (roomItem: RoomItemPrepared, roomPrice: RoomItemPriceType, roomsCount) => {
        if (roomsCount > 0) {
            dispatch(
                orderRoom({
                    basePlaces: roomItem.basePlaces,
                    extraPlaces: roomItem.extraPlaces,
                    roomName: roomItem.name,
                    roomId: roomItem.id,
                    roomPrice,
                    mealTypeName: roomPrice?.mealType?.name || '',
                    roomsCount,
                    searchData: {
                        adults: guestsParams.adults,
                        kids: guestsParams.kids,
                        arrival_date: startDateServerFormat,
                        departure_date: endDateServerFormat
                    }
                }),
            );
        } else {
            removeFromOrder(roomItem.id, roomPrice.rateId);
        }
    }

    const removeFromOrder = (roomId, rateId) => {
        dispatch(removeRoomFromOrder({roomId, rateId}));
    }

    /**
     * Adds or remove reservation
     * @param roomItem
     * @param roomPrice
     * @param roomsCount
     */
    const onSelectRooms = (roomItem: RoomItemPrepared, roomPrice: RoomItemPriceType, roomsCount) => {
        addRoomToOrder(roomItem, roomPrice, roomsCount);
    }

    const getSelectedReservation = (price: RoomItemPriceType, roomId: number, forSelect = false) => {
        if (!reservations.length || !price) return null;

        const reservation = reservations
            .find(reservation => reservation.price.mealTypeId === price.mealTypeId && reservation.id === roomId);

        if (forSelect) {
            return {
                value: reservation.count,
                label: pluralize('номер', reservation.count, true),
            }
        } else {
            return reservation;
        }
    }

    /**
     * Get rooms count by room or all
     * @param roomId Room id
     * @param rateId Rate id
     */
    const getReservationsSum = (roomId?: number, rateId?: number) => {
        return reservations.reduce((acc, reservation) => {
            if (roomId && reservation.roomTypeId !== roomId) return acc;
            if (rateId && reservation.price.rateId !== rateId) return acc;

            return acc + reservation.count;
        }, 0);
    }

    const getRoomReservations = (roomId: number) => {
        return reservations.filter(reservation => reservation.roomTypeId === roomId);
    }

    const getReservationsTotalPrice = (fromServer = false) => {
        if (fromServer) {
            return serverOrderData.data.reservations.data.reduce((acc, reservation) => acc + reservation.amount, 0);
        } else {
            return reservations.reduce((acc, reservation) => acc + reservation.price.totalPrice * reservation.count, 0);
        }

    }

    const getSelectedValuesForSelect = (roomId, rateId) => {
        return reservations
            .filter(reservation => reservation.price.rateId === rateId && reservation.id === roomId)
            .map(reservation => ({
                value: reservation.count,
                label: pluralize('номер', reservation.count, true),
            }));
    }

    return {
        addRoomToOrder,
        removeFromOrder,
        onSelectRooms,
        getSelectedReservation,
        getReservationsSum,
        getReservationsTotalPrice,
        getRoomReservations,
        getSelectedValuesForSelect,
        reservations,
        selectedPaymentMethod,
        setSelectedPaymentMethod
    }
}

export default useOrderLogic;
