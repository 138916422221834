import { formatDate } from '../../../../../helpers/date';

export const getSearchButtonText = (place_name: string, arrival_date?: string, departure_date?: string) => {
    if (arrival_date && departure_date) {
        const format = place_name.length > 35 ? 'dd.MM' : 'dd MMMM';
        const arrivalDateText = formatDate(new Date(arrival_date), format);
        const departureDateText = formatDate(new Date(departure_date), format);

        return `${place_name}, ${arrivalDateText} - ${departureDateText}`;
    }

    return place_name;

};
