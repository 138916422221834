import React, { FC } from 'react';
import './ModalButtons.scss';
import { Link } from 'react-router-dom';
import AppButton from '../../../../components/Buttons/AppButton/AppButton';

interface Props {
   backPath: string;
   submit?: () => void;
   isSending?: boolean;
}

const ModalButtons: FC<Props> = props => {
   const { backPath, submit, isSending = false, ...rest } = props;

   return (
      <div className="modal-buttons">
         <Link className="modal-buttons__cancel" to={backPath}>
            Скасувати
         </Link>
         <AppButton size="middle" onClick={submit} {...rest} showIndicator={isSending}>
            Зберегти
         </AppButton>
      </div>
   );
};

export default ModalButtons;
