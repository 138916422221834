import React, {FC} from "react";

interface Props {

}

const IconFavoriteSmall: FC<Props> = (props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.726 4.24994C11.726 5.11215 11.3834 5.93904 10.7736 6.54849L6.35361 10.9685C6.15835 11.1638 5.84177 11.1638 5.64651 10.9685L1.22651 6.54849C-0.0429499 5.27903 -0.04295 3.22084 1.22651 1.95138C2.49596 0.681927 4.55416 0.681927 5.82361 1.95138L6.00006 2.12783L6.17642 1.95147C6.78596 1.34164 7.61284 0.999023 8.47506 0.999023C9.33724 0.999023 10.1641 1.34161 10.7736 1.95138C11.3834 2.56091 11.726 3.38776 11.726 4.24994ZM9.53651 6.37138L10.0666 5.8413C10.4888 5.41931 10.726 4.84686 10.726 4.24994C10.726 3.65302 10.4888 3.08056 10.0666 2.65857C9.64444 2.23622 9.07198 1.99902 8.47506 1.99902C7.87814 1.99902 7.30568 2.23622 6.88361 2.65849L6.35361 3.18849C6.15835 3.38375 5.84177 3.38375 5.64651 3.18849L5.11651 2.65849C4.23757 1.77956 2.81254 1.77956 1.93361 2.65849C1.05468 3.53742 1.05468 4.96245 1.93361 5.84138L6.00006 9.90783L9.53651 6.37138Z" fill="#C5C6CF"/>
        </svg>
    )
}
export default IconFavoriteSmall


