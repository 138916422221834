import { createSlice } from '@reduxjs/toolkit';
import { getMessagesThunk, sendMessagesThunk } from './messages-thunks';

interface Initial {
   messages: any;
   conversations: any;
   isFetching: boolean;
}

const initialState: Initial = {
   messages: {},
   isFetching: false,
   conversations: [
      {
         id: 1,
         hotelName: 'Шале "Winter Story',
         name: '•Іван',
         status: 'Бронювання скасовано 12–15 серпня 2019',
         photos: [
            'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
            'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
         ],
         lastConversation: 'Вибачте але ми такі послуги не надаємо',
      },
      {
         id: 2,
         hotelName: 'Шале "Winter Story',
         name: '•Іван',
         status: 'Бронювання скасовано 12–15 серпня 2019',
         photos: [
            'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
            'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
         ],
         lastConversation: 'Вибачте але ми такі послуги не надаємо',
      },
      {
         id: 3,
         hotelName: 'Шале "Winter Story',
         name: '•Іван',
         status: 'Бронювання скасовано 12–15 серпня 2019',
         photos: [
            'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
            'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
         ],
         lastConversation: 'Вибачте але ми такі послуги не надаємо',
      },
      {
         id: 4,
         hotelName: 'Шале "Winter Story',
         name: '•Іван',
         status: 'Бронювання скасовано 12–15 серпня 2019',
         photos: [
            'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
            'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
         ],
         lastConversation: 'Вибачте але ми такі послуги не надаємо',
      },
      {
         id: 5,
         hotelName: 'Шале "Winter Story',
         name: '•Іван',
         status: 'Бронювання скасовано 12–15 серпня 2019',
         photos: [
            'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
            'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
         ],
         lastConversation: 'Вибачте але ми такі послуги не надаємо',
      },
   ],
};

const messagesSlice = createSlice({
   name: 'messages',
   initialState,
   reducers: {},
   extraReducers: builder => {
      // get message
      builder.addCase(getMessagesThunk.pending, (state, action) => {
         state.isFetching = true;
         state.messages = {}
      });
      builder.addCase(getMessagesThunk.fulfilled, (state, action) => {
            state.messages = action.payload;
            state.isFetching = false;
      });
      builder.addCase(getMessagesThunk.rejected, (state, action) => {
         state.isFetching = false;
      });

      // send message
      builder.addCase(sendMessagesThunk.fulfilled, (state, action) => {
         if (state.messages[action.payload.date]) {
            state.messages[action.payload.date].push(action.payload);
         } else {
            state.messages[action.payload.date] = [action.payload];
         }
      });
   },
});

export default messagesSlice.reducer;
