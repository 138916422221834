import React from 'react';
import { formatCreditCardNumber } from '../../../../../../../helpers/common';
import AppText from '../../../../../../../components/ui/typography/AppText/AppText';
import './CopyableValueText.scss';
import appToastr from '../../../../../../../services/appToastr';

type Props = {
    text: string
}

const CopyableValueText: React.FC<Props> = ({text}) => {
    const onCopy = () => {
        navigator.clipboard.writeText(text.split(' ').join(''));
        appToastr.info('Скопійовано');
    }

    return (
        <div className={`copyable-value-text`} onClick={onCopy}>
            <AppText variant={'H100'}>{text}</AppText>

            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.5 1.5H12.6C14.8402 1.5 15.9603 1.5 16.816 1.93597C17.5686 2.31947 18.1805 2.93139 18.564 3.68404C19 4.53969 19 5.65979 19 7.9V15M4.2 19.5H12.3C13.4201 19.5 13.9802 19.5 14.408 19.282C14.7843 19.0903 15.0903 18.7843 15.282 18.408C15.5 17.9802 15.5 17.4201 15.5 16.3V8.2C15.5 7.07989 15.5 6.51984 15.282 6.09202C15.0903 5.71569 14.7843 5.40973 14.408 5.21799C13.9802 5 13.4201 5 12.3 5H4.2C3.0799 5 2.51984 5 2.09202 5.21799C1.71569 5.40973 1.40973 5.71569 1.21799 6.09202C1 6.51984 1 7.07989 1 8.2V16.3C1 17.4201 1 17.9802 1.21799 18.408C1.40973 18.7843 1.71569 19.0903 2.09202 19.282C2.51984 19.5 3.0799 19.5 4.2 19.5Z"
                    stroke="#C5C6CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
};

export default CopyableValueText;
