import { AppState } from '../index';
import { createSelector } from 'reselect';


export const initialSelector = (state: AppState) => {
   return state.initial;
};

export const languageSelector = createSelector(initialSelector, (initial) => {
   return initial.data.locales.data.map((item => ({ value: item.code, key: item.name })))
})

export const currencySelector = createSelector(initialSelector, (initial) => {
   return initial.data.currency.data.map((item => ({ value: item.code, key: item.name })))
})