import React, { FC } from 'react';

interface Props {

}

const IconCreatablePlus: FC<Props> = (props) => {

   return (
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect x="0.5" y="0.5" width="63" height="63" rx="7.5" stroke="#C5C6CF"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M33 19H31V31H20V33H31V44H33V33H45V31H33V19Z" fill="#3D3A45"/>
      </svg>

   );
};

export default IconCreatablePlus;