import React from "react";

const IconSocialInstagram = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#F4F2F6"/>
      <path
        d="M25.1 20.207C22.85 20.207 21.057 22.0352 21.057 24.25C21.057 26.5 22.85 28.293 25.1 28.293C27.3148 28.293 29.143 26.5 29.143 24.25C29.143 22.0352 27.3148 20.207 25.1 20.207ZM25.1 26.8867C23.6586 26.8867 22.4633 25.7266 22.4633 24.25C22.4633 22.8086 23.6234 21.6484 25.1 21.6484C26.5414 21.6484 27.7016 22.8086 27.7016 24.25C27.7016 25.7266 26.5414 26.8867 25.1 26.8867ZM30.2328 20.0664C30.2328 19.5391 29.8109 19.1172 29.2836 19.1172C28.7562 19.1172 28.3344 19.5391 28.3344 20.0664C28.3344 20.5938 28.7562 21.0156 29.2836 21.0156C29.8109 21.0156 30.2328 20.5938 30.2328 20.0664ZM32.9047 21.0156C32.8344 19.75 32.5531 18.625 31.6391 17.7109C30.725 16.7969 29.6 16.5156 28.3344 16.4453C27.0336 16.375 23.1312 16.375 21.8305 16.4453C20.5648 16.5156 19.475 16.7969 18.5258 17.7109C17.6117 18.625 17.3305 19.75 17.2602 21.0156C17.1898 22.3164 17.1898 26.2188 17.2602 27.5195C17.3305 28.7852 17.6117 29.875 18.5258 30.8242C19.475 31.7383 20.5648 32.0195 21.8305 32.0898C23.1312 32.1602 27.0336 32.1602 28.3344 32.0898C29.6 32.0195 30.725 31.7383 31.6391 30.8242C32.5531 29.875 32.8344 28.7852 32.9047 27.5195C32.975 26.2188 32.975 22.3164 32.9047 21.0156ZM31.2172 28.8906C30.9711 29.5938 30.4086 30.1211 29.7406 30.4023C28.6859 30.8242 26.225 30.7188 25.1 30.7188C23.9398 30.7188 21.4789 30.8242 20.4594 30.4023C19.7562 30.1211 19.2289 29.5938 18.9477 28.8906C18.5258 27.8711 18.6312 25.4102 18.6312 24.25C18.6312 23.125 18.5258 20.6641 18.9477 19.6094C19.2289 18.9414 19.7562 18.4141 20.4594 18.1328C21.4789 17.7109 23.9398 17.8164 25.1 17.8164C26.225 17.8164 28.6859 17.7109 29.7406 18.1328C30.4086 18.3789 30.9359 18.9414 31.2172 19.6094C31.6391 20.6641 31.5336 23.125 31.5336 24.25C31.5336 25.4102 31.6391 27.8711 31.2172 28.8906Z"
        fill="#C5C6CF"/>
    </svg>

  )
};


export default IconSocialInstagram