import React from 'react';
import './HotelRoomItem.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import CustomCarousel from '../../../../../../../components/ui/components/CustomCarousel/CustomCarousel';
import { RoomItemPrepared, RoomItemPriceType } from '../../../../../../../types/rooms';
import Image from '../../../../../../../components/ui/components/Image/Image';
import { pluralize } from '../../../../../../../helpers/translatioHelpers';
import useSearchForm from '../../../../../common/HotelSearchForm/hooks/useSearchForm';
import useOrderLogic from '../../../../../../../hooks/useOrderLogic';
import RoomItemPersons from './components/RoomItemPersons/RoomItemPersons';
import useRoomsLogic from '../../../../useRoomsLogic';
import NoRoomsMessage from './components/NoRoomsMessage/NoRoomsMessage';
import { PhotoType } from '../../../../../../../types/common';
import HotelRoomItemWhenPhoneOnBook from '../HotelRoomItemWhenPhoneOnBook/HotelRoomItemWhenPhoneOnBook';
import { getHotelSelector } from '../../../../../../../store/hotel/hotel-selector';
import RoomItemPrice from './components/RoomItemPrice/RoomItemPrice';

type Props = {
    roomItem: RoomItemPrepared;
    onShowRoomProperties: () => void;
};

export const HotelRoomGallery = ({roomId, photos}) => {
    const history = useHistory();
    const {search} = useLocation();
    const {alias} = useSelector(getHotelSelector).data;

    const getLink = (index = 1) => `/hotels/${alias}/room-gallery/${roomId}/${index}${search}`;

    return (
        <CustomCarousel
            Item={({data}: { data: PhotoType }) => (
                <Image
                    className="hotel-room-item__image"
                    aspectRatio="1:1"
                    src={data?.source?.['560x560']}
                />
            )}
            data={photos}
            settings={{dots: true}}
            theme="secondary"
            onClickOnItem={(item, index) => {
                history.push(getLink(index + 1))
            }}
        />
    );
}

const HotelRoomItem: React.FC<Props> = ({roomItem, onShowRoomProperties}) => {
    const {bookingState} = useSelector(getHotelSelector)
    const {nights} = useSearchForm();
    const {onSelectRooms} = useOrderLogic();
    const roomProperties = roomItem.properties;
    const {showDisabledPricesWithMessage, showNoPricesMessageWithButton, focusOnEditForm} = useRoomsLogic(roomItem);
    const {selectedOrderBookType} = useSelector(getHotelSelector);

    const isPhoneBookVariant = showNoPricesMessageWithButton && bookingState === 'phone';
    const isNoPricesBookVariant = showNoPricesMessageWithButton && bookingState != 'phone';
    const classes = classnames('hotel-room-item', {'hotel-room-item--phone-book': isPhoneBookVariant})
    const contentClasses = classnames('hotel-room-item__content', {'hotel-room-item--phone-book__content': isPhoneBookVariant});

    return (
        <div className={classes}>
            <div className={`hotel-room-item__image-block ${roomItem.photos.length < 1 ? 'no-image' : ''}`}>
                <HotelRoomGallery
                    photos={roomItem.photos.slice(0, 10)}
                    roomId={roomItem.id}
                />
            </div>

            <div className={contentClasses}>
                {((selectedOrderBookType === 'phone' || isPhoneBookVariant) && isNoPricesBookVariant) ? (
                    <>
                           <HotelRoomItemWhenPhoneOnBook

                              roomItem={roomItem}
                            onShowRoomProperties={onShowRoomProperties}
                        />
                    </>

                ) : (
                    <>
                        <div className={'hotel-room-item__top-row'}>
                            <span className={'hotel-room-item__name'}>{roomItem.name}</span>
                            {roomItem.square &&
                               <span className={'hotel-room-item__room-size'}>{roomItem.square} m²</span>}
                        </div>

                        <div className='hotel-room-item__info-row'>
                            <div>
                                <div>
                                    {roomItem.rooms}, {roomItem.bathrooms}
                                </div>

                                <div className={'hotel-room-item__amenities-more'}>
                                    {roomProperties.length ? (
                                        <button onClick={onShowRoomProperties}>
                                            + {pluralize('зручність', roomProperties.length, true)}
                                        </button>
                                    ) : null}
                                </div>
                            </div>

                            <div>
                                <RoomItemPersons
                                    basePlaces={roomItem.basePlaces}
                                    extraPlaces={roomItem.extraPlaces}
                                />
                            </div>
                        </div>

                        <div className="hotel-room-item__prices">
                            {showDisabledPricesWithMessage ?
                                <NoRoomsMessage text={roomItem?.message?.data?.text}/> : null}

                            {
                                showNoPricesMessageWithButton ?
                                    (
                                        <NoRoomsMessage
                                            text={roomItem?.message?.data?.text} variant='with-button'
                                            onButtonClick={() => focusOnEditForm()}/>
                                    ) : null
                            }

                            {roomItem.prices.data.map((price: RoomItemPriceType, index) => {
                                return (
                                    <RoomItemPrice
                                        key={'roomPrice' + price.rateId + index + price.mealTypeId + roomItem.id }
                                        disabled={showDisabledPricesWithMessage}
                                        roomId={roomItem.id}
                                        price={price}
                                        nights={nights}
                                        onSelectRoom={roomsCount => onSelectRooms(roomItem, price, roomsCount)}
                                        availableRoomsCount={roomItem.availableRoomsCount}
                                    />
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default HotelRoomItem;
