import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import FieldCheckbox from '../fields/FieldCheckbox/FieldCheckbox';
import ControlLayout from './ControlLayout/ControlLayout';

interface IProps {
   name?: string;
   value?: any
}

const AppFormCheckbox: FC<IProps> = props => {
   const { name, value } = props;

   return (
      <Field name={name}>
         {(propsField: FieldProps) => {
            const { field, meta } = propsField;
            const isError = meta.touched && !!meta.error;

            return (
               <ControlLayout isError={isError} error={meta.error}>
                  <FieldCheckbox
                     id={name}
                     {...field}
                     value={value || field.value}
                     checked={field.value}
                     isError={isError}
                  />
               </ControlLayout>
            );
         }}
      </Field>
   );
};

export default AppFormCheckbox;
