import React, {FC} from "react";
import "./AccountPayment.scss";
import Page from "../../../components/common/Page/Page";
import Container from "../../../components/ui/layouts/Container/Container";
import PageHeader from "../common/PageHeader/PageHeader";
import { RouteNames } from '../../../routes';

interface Props {

}

const AccountPayment: FC<Props> = (props) => {

  const breadcrumbs = [
     {
        url: RouteNames.PROFILE_INDEX,
        title: 'Акаунт',
     },
     {
        title: 'Оплата',
     },
  ];
  return (
    <Page>
      <Container>
        <PageHeader breadcrumbs={breadcrumbs} title="Оплата"/>

      </Container>
    </Page>
  )
}

export default AccountPayment