import React, { Ref, useEffect } from 'react';

const useResizeObserver = (ref: Ref<any>, callback: (height: number, width: number) => void) => {
    const [height, setHeight] = React.useState(0);

    useEffect(() => {
        let resizeObserver: ResizeObserver;
        const {current} = ref;

        const trigger = () => {
            const height = current.offsetHeight;
            const width = current.offsetWidth;

            setHeight(height);

            if (callback) callback(height, width);
        };

        if (current) {
            if ('ResizeObserver' in window) {
                resizeObserver = new ResizeObserver(trigger);
                resizeObserver.observe(current);
            }

            trigger();
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        }
    }, []);

    return height;
}

export default useResizeObserver;
