import React, {FC} from "react";
import "./HotelBookingSummary.scss";
import OrderDetail from "./OrderDetail/OrderDetail";
import Container from "../../../components/ui/layouts/Container/Container";
import Page from "../../../components/common/Page/Page";

export type RoomType = {
    nameOfRoom: string,
    adult: number
    kids: number
    arrivalDate: string
    departureDate: string
    price: number
    days: number
}

interface Props {
}

export type OrderType = {
    nameOfHotel: string
    address: string
    reviews: number
    stars: number
    distance: string
    rooms: Array<RoomType>
    countPrice: number
    image: string
}

const data: OrderType = {
    nameOfHotel: 'Готель «Nazare Belveder»',
    address: 'м.Будапешт, вул. Фредеріко Фелліні 245б',
    reviews: 55,
    stars: 4.9,
    distance: "2.5",
    countPrice: 28690,
    image: 'https://deluxe.voyage/useruploads/articles/article_1eb0a64d00.jpg',
    rooms: [
        {
            nameOfRoom: 'Premium Lux Apartment French Quarter 2 ',
            adult: 2,
            kids: 1,
            arrivalDate: '12 лютого 2022',
            departureDate: '16 лютого 2022',
            price: 14345,
            days: 4
        },
        {
            nameOfRoom: 'Premium Lux Apartment French Quarter 2 ',
            adult: 2,
            kids: 1,
            arrivalDate: '12 лютого 2022',
            departureDate: '16 лютого 2022',
            price: 14345,
            days: 4
        },
        {
            nameOfRoom: 'Premium Lux Apartment French Quarter 2 ',
            adult: 2,
            kids: 1,
            arrivalDate: '12 лютого 2022',
            departureDate: '16 лютого 2022',
            price: 14345,
            days: 4
        }],

}

//Todo: check this component
//@deprecated
const HotelBookingSummary: FC<Props> = (props) => {


    return (
      <Page>
          <Container>
              <h1 className='order__text'>Оформлення Бронювання</h1>
              <div className='order'>
                  <div className='order__payment'> </div>
                  <OrderDetail data={data}/>
              </div>
          </Container>
      </Page>

    )

}
export default HotelBookingSummary;
