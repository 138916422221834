import React, {FC} from "react";
import "./ModalRoutes.scss";
import SwitchLanguage from "../SwitchLanguage/SwitchLanguage";
import {switchCountrySchema, switchCurrencySchema, switchLanguageSchema} from "../../../../../helpers/validation";
import {Route, useHistory} from "react-router-dom";
import RadioButtonIcon
  from "../../../../../components/ui/forms/fields/FieldCard/component/RadioButtonIcon/RadioButtonIcon";
import { useDispatch, useSelector } from 'react-redux';
import { currencySelector, initialSelector, languageSelector } from '../../../../../store/initial/initial-selectors';
import classNames from "classnames";
import {Cookies, useCookies} from "react-cookie";
import {setAxiosInstanceHeader} from "../../../../../helpers/network";
import {setCurrency} from "../../../../../store/initial/initial-reducer";
import { HTTP_HEADER_CURRENCY } from '../../../../../config';

interface Props {

}

const countryOptions = [
  {value: '1', key: 'Україна', image: '/images/countries/uk.png'},
  {value: '2', key: 'Англія', image: '/images/countries/au.png'},
  {value: '3', key: 'Німеччина', image: '/images/countries/be.png'},
  {value: '4', key: 'Франція', image: '/images/countries/ca.png'},
  {value: '5', key: 'Італія', image: '/images/countries/uk.png'},
  {value: '6', key: 'Словенія', image: '/images/countries/au.png'},
  {value: '7', key: 'Бельгія', image: '/images/countries/be.png'},
  {value: '8', key: 'Іспанія', image: '/images/countries/uk.png'},
];

const RadioTemplate = props => {
  const {isActive, data} = props;
  const {value, key, disabled, image} = data;
  const classes = classNames('radio-button-item', {'radio-button-item--disabled': disabled});

  return (
    <div className={classes}>
      <RadioButtonIcon checked={isActive}/>
      <div className="radio-button-item__text">
        {
          image
            ? <img src={image}/>
            : null
        }
        {key}
      </div>

    </div>
  );
};

const ModalRoutes: FC<Props> = (props) => {
  const initial = useSelector(initialSelector);
   const dispatch = useDispatch();
   const history = useHistory();
  // const [cookiesCurrency] = useCookies(['currency']);

  const languages = useSelector(languageSelector);
  const currency = useSelector(currencySelector);

  return (
    <div>
      <Route
        path="/profile/settings/language"
        component={() => {
          return (
            <SwitchLanguage
              backPath="/profile/settings"
              initialValues={{
                language: initial.language,
              }}
              name="language"
              title={"Вибір мови"}
              options={languages}
              validationSchema={switchLanguageSchema}
              RadioOptionTemplate={RadioTemplate}
              submitData={(data) => {

                window.location.href = `/${data.language}/profile/settings`
              }}
            />
          )
        }}
      />


      <Route
        path="/profile/settings/country"
        component={() => {
          return (
            <SwitchLanguage
              backPath="/profile/settings"
              initialValues={{
                country: '',
              }}
              name="country"
              title={"Вибір країни"}
              options={countryOptions}
              validationSchema={switchCountrySchema}
              RadioOptionTemplate={RadioTemplate}
              submitData={() => {

              }}
            />
          )
        }}
      />


      <Route
        path="/profile/settings/currency"
        component={() => {
          return (
            <SwitchLanguage
              backPath="/profile/settings"
              initialValues={{
                currency:  initial.currency,
              }}
              title={"Вибір валюти"}
              name="currency"
              options={currency}
              validationSchema={switchCurrencySchema}
              RadioOptionTemplate={RadioTemplate}
              submitData={(data) => {
                const cookies = new Cookies();
                // for node server
                cookies.set('currency', data.currency, {path: '/'})

                 // do it or redirect
                 dispatch(setCurrency(data.currency))

                 // for axios
                setAxiosInstanceHeader(HTTP_HEADER_CURRENCY, data.currency);

                 history.push('/profile/settings')
              }}
            />
          )
        }}
      />
    </div>
  )
}

export default ModalRoutes