import { useDispatch } from "react-redux";
import { authorizedOut } from "../store/auth/auth-reducer";
import { removeAuthTokenAndHeader } from "../helpers/auth";
import { useAppSelector } from './reduxHooks';
import { defaultLanguage } from '../config';

const useLogout = () => {
    const dispatch = useDispatch();
    const lang = useAppSelector(state => state.initial.language);

    const setLogout = () => {
        removeAuthTokenAndHeader();
        //dispatch(authorizedOut());

        //if (window) window.location.reload();
        if (window) window.location.href = '/' + (lang || defaultLanguage);
    }

    return [setLogout];
}

export default useLogout

// import {useCookies} from 'react-cookie';
// const [cookies, setCookie, removeCookie] = useCookies(['auth']);
// removeCookie(['auth'], {path: '/'})
