import { fetchData, putData } from '../helpers/network';
import { AccountOrdersDetailResponse } from '../types/account-orders/account-orders-detail';
import axios from 'axios';
import { axiosInstance } from './axiosInstance';

class AccountOrdersDetailApi {
   public fetchOrdersDetail(id: number) {
      return fetchData<AccountOrdersDetailResponse>(`/orders/${id}`);
   }
   public cancelOrder(orderId: number) {
      return putData(`/orders/${orderId}/cancel`);
   }
   public cancelReservation(orderId: number, reservationId: number) {
      return putData(`/orders/${orderId}/reservations/${reservationId}`);
   }
   public confirmationOrderPdf(orderId: number) {

     return axiosInstance({
         url: `/orders/${orderId}/confirmation`,
         method: 'GET',
         responseType: 'blob', // important
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `confirmation-${orderId}.pdf`);
         document.body.appendChild(link);
         link.click();
      });
      // return fetchData<any>(`/orders/${orderId}/confirmation`);
   }
   public fetchOrderConfirmHtml(orderId: number) {
      return fetchData<any>(`/orders/${orderId}/confirmation?type=html`);
   }

}

export default new AccountOrdersDetailApi();
