import { createAsyncThunk } from '@reduxjs/toolkit';
import ordersApi from '../../../api/account-orders-list-api';
import appToastr from '../../../services/appToastr';

export const getOrdersThunk = createAsyncThunk(
   'orders/getOrdersThunk',
   async (_, { rejectWithValue }) => {
      try {
         const response = await ordersApi.fetchOrders();
         return response.data;
      } catch (error) {
         throw rejectWithValue(error);
      }
   },
);


export const hideAccountOrdersThunk = createAsyncThunk(
   'orders/hideAccountOrdersThunk',
   async (orderId: number, { rejectWithValue }) => {
      try {
         const response = await ordersApi.hideOrder(orderId);
         appToastr.success('Замовлення було успішно видалено')
         return  orderId //response.data;
      } catch (error) {
         appToastr.success('Не вдалось видалити замовлення')
         throw rejectWithValue(error);
      }
   },
);