import React, { FC, useCallback, useEffect, useState } from 'react';
import './ResetPassword.scss';
import AuthLayout from '../common/AuthLayout/AuthLayout';
import {Link, Redirect} from 'react-router-dom';
import FormPhone from '../forms/FormPhone/FormPhone';
import FromResetPassword from '../forms/FromResetPassword/FromResetPassword';
import {registrationStage} from '../../../store/auth/auth-reducer';
import {REGISTRATION_INITIAL, REGISTRATION_RESTORE} from '../../../store/auth/auth-types';
import {useDispatch, useSelector} from 'react-redux';
import {authSelector, authStageSelector, isAuthorizedSelector} from '../../../store/auth/auth-selector';
import {resetPasswordCompleteThunk, resetPasswordRequestThunk} from '../../../store/auth/auth-actions';
import {unwrapResult} from '@reduxjs/toolkit';
import {ResendPassword} from '../../../types/auth';
import AuthSignUpLink from '../common/AuthSignUpLink/AuthSignUpLink';
import {FormikHelpers} from 'formik/dist/types';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface Props {
}

interface PhoneData {
    login: string;
}

const ResetPassword: FC<Props> = props => {
    const dispatch = useDispatch<any>();
    const stage = useSelector(authStageSelector);
    const {isSending} = useSelector(authSelector);
    const [login, setLogin] = useState<string>('');
    const isAuthorized = useSelector(isAuthorizedSelector);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        dispatch(registrationStage(REGISTRATION_INITIAL));
    }, []);

    const phoneSubmit = useCallback(async (data: PhoneData, onSubmitProps) => {
        const {setErrors} = onSubmitProps;

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        if (token) {
            dispatch(resetPasswordRequestThunk({...data,'g-recaptcha-response': token}))
              .then(unwrapResult)
              .catch(error => {
                  // TODO Винести цей код в функцію
                  if (error.status === 404) {
                      setErrors({
                          login: 'Користувача з таким номером телефону немає в базі',
                      });
                  } else if (error.status === 408 && error.data?.message) {
                      setErrors({
                          login: error.data?.message,
                      });
                  } else {
                      if (error?.data?.errors) {
                          setErrors(error.data.errors);
                      } else {
                          setErrors({
                              login: 'Телефон не є валідним',
                          });
                      }
                  }
              });

            setLogin(data.login);
        }


    },[executeRecaptcha ]);

    const resetPassword = (values: ResendPassword, formikHelpers: FormikHelpers<ResendPassword>) => {
        const {setErrors} = formikHelpers;

        dispatch(resetPasswordCompleteThunk(values))
            .then(unwrapResult)
            .catch(error => {
                if (error.status === 406) {
                    setErrors({
                        code: 'Неправильний код підтвердження',
                    });
                } else {
                    if (error?.data?.errors) {
                        setErrors(error.data.errors);
                    } else {
                    }
                }
            });
    };

    const initialValue = {
        code: '',
        login: login,
        password: '',
        password_confirmation: '',
    };

    if (isAuthorized) {
        return <Redirect to="/profile"/>;
    }

    return (
        <AuthLayout image="/images/backgrounds/1.jpg" activeStep={1} className="reset-password" title="Відновлення доступу"
                    AuthHeader={() => <>Не зареєстровані? <Link to="/auth/signup">Зареєструйтесь</Link></>}>

            {stage === REGISTRATION_INITIAL && (
                <div className="reset-password__phone-form">
                    <FormPhone onSubmit={phoneSubmit} isSending={isSending}/>
                </div>
            )}
            {stage === REGISTRATION_RESTORE && (
                <FromResetPassword initialValues={initialValue} onSubmit={resetPassword} isSending={isSending}/>
            )}
        </AuthLayout>
    );
};

export default ResetPassword;
