import React, { FC } from 'react';

interface Props {

}

const IconCopy: FC<Props> = (props) => {

   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M18.75 3.75C19.5456 3.75 20.3087 4.06607 20.8713 4.62868C21.4339 5.19129 21.75 5.95435 21.75 6.75V19.5C21.75 20.4946 21.3549 21.4484 20.6516 22.1516C19.9484 22.8549 18.9946 23.25 18 23.25H9C8.00544 23.25 7.05161 22.8549 6.34835 22.1516C5.64509 21.4484 5.25 20.4946 5.25 19.5V7.5C5.25 6.50544 5.64509 5.55161 6.34835 4.84835C7.05161 4.14509 8.00544 3.75 9 3.75H18.75ZM18.75 5.25H9C8.40326 5.25 7.83097 5.48705 7.40901 5.90901C6.98705 6.33097 6.75 6.90326 6.75 7.5V19.5C6.75 20.0967 6.98705 20.669 7.40901 21.091C7.83097 21.5129 8.40326 21.75 9 21.75H18C18.5967 21.75 19.169 21.5129 19.591 21.091C20.0129 20.669 20.25 20.0967 20.25 19.5V6.75C20.25 6.35218 20.092 5.97064 19.8107 5.68934C19.5294 5.40804 19.1478 5.25 18.75 5.25ZM16.5 0.75V2.25H8.25C7.08571 2.24996 5.96673 2.70117 5.12815 3.50885C4.28956 4.31652 3.79666 5.41778 3.753 6.58125L3.75 6.75V16.5H2.25V6.75C2.25002 5.19118 2.8567 3.69356 3.9416 2.57422C5.0265 1.45489 6.50444 0.801713 8.0625 0.753L8.25 0.75H16.5Z" fill="#C5C6CF"/>
      </svg>

   );
};

export default IconCopy;