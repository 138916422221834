import React, { FC, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { parse } from 'qs';
import './HotelListItem.scss';

import { HotelListItemType } from '../../../../types/hotels';
import Image from '../../../../components/ui/components/Image/Image';
import CustomCarousel from '../../../../components/ui/components/CustomCarousel/CustomCarousel';
import HotelListItemTopInfo from './components/HotelListItemTopInfo/HotelListItemTopInfo';
import FavoriteFunctionality from '../components/FavoriteFunctionality/FavoriteFunctionality';
import { PhotoType } from '../../../../types/common';
import { generateSrcSet } from '../../../../helpers/common';
import { getOnePhotoLinkFromHotelData } from '../../../../helpers/hotels';
import HotelPriceWithMeal from '../../../../components/hotels/HotelPriceWithMeal/HotelPriceWithMeal';
import classnames from 'classnames';
import useWindowSize from '../../../../hooks/useWindowSize';
import IconFavorites from '../../../../components/ui/icons/hotels/IconFavorites';

// import { useInView } from 'react-intersection-observer';

interface Props {
    hotelItem: HotelListItemType;
    // meta: HotelListMeta;
    disableGallery?: boolean;
    fromMap?: boolean;
    fromList?: boolean;
    selected?: boolean
    generatedLink?: string;
    isFavorite?: boolean;
    onClickOnFavorite?: () => void;
}

const HotelListItem: FC<Props> = (props) => {
    const [showAllPhotos, setShowAllPhotos] = React.useState(false);

    const {
        disableGallery,
        hotelItem,
        fromList,
        fromMap,
        selected,
        generatedLink,
        onClickOnFavorite
    } = props;

    const {
        id,
        name,
        stars,
        alias,
        distanceToPlaceFormatted,
        fullPrice,
        minPrice,
        reviewsCount,
        photos,
        isFavorite = false,
        mealType,
        placeName
    } = hotelItem;

    const NavLink = fromMap ? ({children, to}) => (
        <a href={to} target={'_blank'} className={'map-hotel-name'}>{children}</a>
    ) : RouterLink;

    const {isMobile} = useWindowSize();
    // const {ref, inView, entry} = useInView();

    // useEffect(() => {
    //     if (inView && !showAllPhotos) {
    //         setShowAllPhotos(true)
    //     }
    // }, [inView]);

    const settings = {
        dots: true,
        infinite: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const target = process.env.RAZZLE_IS_DEV || isMobile ? '_self' : '_blank';

    const link = generatedLink || `/hotels/${alias}`;

    const HotelGalleryTemplate = (props: any) => {
        const {data}: { data: PhotoType } = props;
        const {source} = data;

        return (
            <NavLink to={link} target={target}>
                <Image
                    aspectRatio="16:9"
                    className="hotel-list-item__image"
                    src={generateSrcSet(source, '302x170')}
                    alt="photo"
                />
            </NavLink>
        );
    };

    const hotelName = (
        <h3 className="hotel-list-item__name">
            <NavLink to={link} target={target} title={name}>
                {name}
            </NavLink>
        </h3>
    );

    const photoLink = disableGallery ? getOnePhotoLinkFromHotelData(hotelItem) : generateSrcSet(photos?.[0]?.source, '302x170');

    const className = classnames(
        'hotel-list-item',
        {
            'hotel-list-item--animated': fromList,
            'hotel-list-item--selected': selected
        }
    );

    let showPhotosCount = 1;
    if (showAllPhotos) showPhotosCount = 10;

    const distanceAndRatingContent = (
        <HotelListItemTopInfo
            stars={stars}
            reviewsCount={reviewsCount}
            distance={distanceToPlaceFormatted}
            placeName={selected && placeName}
        />
    );

    return (
        <div className={className}>
            <div className="hotel-list-item__block-image">
                <div>
                    {photos && photos?.length === 1 || disableGallery || !photos?.length ? (
                        <NavLink
                            to={link}
                            target={target}
                        >
                            <Image
                                aspectRatio={'16:9'}
                                src={photoLink}
                            />
                        </NavLink>
                    ) : (
                        <CustomCarousel
                            Item={HotelGalleryTemplate}
                            data={photos.slice(0, showPhotosCount)} //Max 10 photos per item, because a lot of dots looks ugly
                            settings={settings}
                            theme="secondary"
                        />
                    )}
                </div>

                {/*{parseFloat(rating) > 0 ? <div className="hotel-list-item__top-text">{rating}</div> : null}*/}

                <div className="hotel-list-item__favorites">
                    {onClickOnFavorite ? (
                        <i onClick={onClickOnFavorite}>
                            <IconFavorites isSelected={props.isFavorite}/>
                        </i>
                    ) : (
                        <FavoriteFunctionality
                            id={id} isFavorite={isFavorite}
                        />
                    )}
                </div>
            </div>

            {hotelName}

            {selected && distanceAndRatingContent}

            {selected && (
                <div className={'hotel-list-item__properties'}>
                    {hotelItem.preparedProperties.slice(0, 10).map(p => p.name).join(', ')}
                </div>
            )}

            {!selected && distanceAndRatingContent}

            <div className="hotel-list-item__block-price">
                <div>
                    <div className={'hotel-list-item__block-price__price-container'}>
                        <HotelPriceWithMeal
                            price={minPrice}
                            oldPrice={fullPrice}
                            mealType={mealType}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(HotelListItem);
