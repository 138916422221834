import React, {FC, ClipboardEvent, HTMLProps } from 'react';
import './Mui.scss';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

interface Props extends HTMLProps<HTMLInputElement> {
   isError: boolean;
   label?: string;
   mask?: string;
   maskChar?: string;
   alwaysShowMask?: boolean;
   formatChars?: any;
   rightComponent?: React.ReactNode,
   onPast?: (e: ClipboardEvent<HTMLInputElement>) => void;
}

const MuiFieldInput: FC<Props> = props => {
   const { isError, label, rightComponent, ...rest } = props;
   const classes = classNames('mui-form-group', { isError });


   return (
      <div className={classes}>
          <span className={'mui-form-right-component'}>{rightComponent}</span>
         <InputMask autoComplete="off" required={true}  {...rest}/>
         <label htmlFor="input" className="control-label">
            {label}
         </label>
         <i className="bar" />
      </div>
   );
};

export default MuiFieldInput;
