import { createSlice } from '@reduxjs/toolkit';
import { getCommentsThunk } from './comments-thunks';
import { ReviewsResponse } from '../../types/reviews';

interface InitialState {
   data: ReviewsResponse;
   error: any;
   isFetching: boolean;
}

const initialState: InitialState = {
   data: {
      data: [],
      meta: {
         pagination: {
            count: 0,
            current_page: 0,
            links: {},
            per_page: 0,
            total: 0,
            total_pages: 0,
         },
      },
   },
   error: {},
   isFetching: false,
};

const commentsSlice = createSlice({
   name: 'comments',
   initialState,
   reducers: {},
   extraReducers: builder => {
      // request
      builder.addCase(getCommentsThunk.pending, (state, action) => {
         state.isFetching = true;
      });

      // success response
      builder.addCase(getCommentsThunk.fulfilled, (state, action) => {
         state.isFetching = false;
         state.data = action.payload;
      });

      // response with errors
      builder.addCase(getCommentsThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.payload;
      });
   },
});

export default commentsSlice.reducer;
