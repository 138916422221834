import { deleteData, fetchData, putData, sendData } from '../helpers/network';
import { OrderPaymentResponse, OrderPostData, OrderReservationEditPutData, OrderResponse } from '../types/order';

export default {
    getOrder: () => fetchData('/cart') as Promise<OrderResponse>,
    addToOrder: (orderPostData: OrderPostData | any) => sendData(`/cart`, orderPostData) as Promise<any>,
    saveContactDataForReservation: (reservationId: string | number, contactData: string) => putData(`/cart/${reservationId}/contact`, {contact_data: contactData}),
    editReservation: (reservationId: string | number, data: OrderReservationEditPutData) => putData(`/cart/${reservationId}`, data),
    removeReservation: (reservationId: string | number) => deleteData(`cart/${reservationId}`),
    goToPayment: (payment_method: string, paymentCardId?: number) => {
        //const cardIdParam = paymentCardId ? `&payment_card_id=${paymentCardId}` : '';
        const params = payment_method ? `?payment_method=${payment_method}` : '';

        return fetchData(`/cart/pay${params}`) as Promise<OrderPaymentResponse>
    }
};
