import React from "react";
import "./ContactsBlock.scss";
import ContactInfo from "../ContactInfo/ContactInfo";
import ContactsSocial from "../ContactsSocial/ContactsSocial";


const ContactsBlock = () => {


  return(
    <div className="contacts-block">
      <h3>Контакні дані</h3>
      <h5>Контактуйте з нами зручним способом</h5>
      <div className="contacts-block__info">
        <ContactInfo phone={"301-531-4254"} email={'letstalk@bluesteelcyber.com'}/>
      </div>
      <div className="contacts-block__social">
        <ContactsSocial
          facebookLink={'https://www.facebook.com/'}
          instagramLink={'https://www.instagram.com/'}
          twitterLink={'https://twitter.com/'}
          youtubeLink={'https://www.youtube.com/'}

        />
      </div>


    </div>
  )
}
export default ContactsBlock