import React, { FC, MouseEventHandler } from 'react';
import './ClickableArrowItem.scss';
import IconRight from '../../ui/icons/account/IconRight';
import classNames from 'classnames';

interface Props {
   content: any;
   onClick: MouseEventHandler<HTMLDivElement>
   arrowDirection?: 'bottom' | 'top' | 'left' | 'right'
}

const ClickableArrowItem: FC<Props> = (props) => {
   const {arrowDirection = 'right', content, onClick} = props;
   const arrowClasses = classNames('clickable-arrow-item__arrow', arrowDirection)

   return (
      <div className="clickable-arrow-item" onClick={onClick}>
         {content}
         <div className={arrowClasses}>
            <IconRight/>
         </div>
      </div>
   );
};

export default ClickableArrowItem;