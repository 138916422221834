import React, {FC, HTMLProps } from 'react';
import './Mui.scss';
import classNames from 'classnames';

interface Props extends HTMLProps<HTMLTextAreaElement> {
  isError: boolean;
  label?: string;
}

const MuiFieldFieldInput: FC<Props> = props => {
  const { isError, label, ...rest } = props;
  const classes = classNames('mui-form-group', { isError });

  return (
    <div className={classes}>
      <textarea required={true} {...rest} autoComplete="off" />
      <label htmlFor="input" className="control-label">
        {label}
      </label>
      <i className="bar" />
    </div>
  );
};

export default MuiFieldFieldInput;
