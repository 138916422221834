import React, { FC } from 'react';
import './NoAvatar.scss';

interface Props {
   letters: string;
   size: number;
   fontSize: number
}

const NoAvatar: FC<Props> = ({ letters, size,fontSize }) => {
   return (
      <div className="no-avatar" style={{ minWidth: `${size}px`, width: `${size}px`, height: `${size}px`, fontSize }}>
         {letters}
      </div>
   );
};

export default NoAvatar;
