import React, { FC } from 'react';

interface Props {

}

const IconLocation: FC<Props> = (props) => {

   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M12 22L17.5 16.5C20.5376 13.4624 20.5376 8.53757 17.5 5.5C14.4624 2.46244 9.53757 2.46244 6.5 5.5C3.46244 8.53757 3.46244 13.4624 6.5 16.5L12 22Z" stroke="#C5C6CF" strokeWidth="1.5" strokeLinejoin="round"/>
         <circle cx="12" cy="11" r="2.25" stroke="#C5C6CF" strokeWidth="1.5"/>
      </svg>

   );
};

export default IconLocation;