import React from "react";

const IconSocialFacebook = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#F4F2F6"/>
      <path
        d="M29.2836 25.375L29.7758 22.1406H26.6469V20.0312C26.6469 19.1172 27.0687 18.2734 28.475 18.2734H29.9164V15.4961C29.9164 15.4961 28.6156 15.25 27.3852 15.25C24.8187 15.25 23.1312 16.832 23.1312 19.6445V22.1406H20.2484V25.375H23.1312V33.25H26.6469V25.375H29.2836Z"
        fill="#C5C6CF"/>
    </svg>

  )
};


export default IconSocialFacebook