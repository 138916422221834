import React, { FC, useState } from 'react';
import './Test.scss';
import Page from '../../components/common/Page/Page';
import ModalOptionsSelect from '../../components/ui/modals/ModalOptionsSelect/ModalOptionsSelect';
import { NavLink, Route, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import { isClient } from '../../helpers/common';
import HotelMapListItem from '../Hotels/HotelList/HotelsMap/HotelsMapList/HotelsMapListItem/HotelsMapListItem';

interface Props extends FC {
    getInitialProps: any;
}

const options = [<div style={{padding: '10px 0'}}>1</div>, <div style={{padding: '10px 0'}}>2</div>,
    <div style={{padding: '10px 0'}}>3</div>];

const TestScreen = (props: Props) => {
    const [value, setValue] = useState('123');
    const {path, url} = useRouteMatch();
    const hotels = useAppSelector(state => state.hotels);

    const properties = [
        {
            "id": 1,
            "name": "Басейн із підігрівом",
            "icon": null
        },
        {
            "id": 2,
            "name": "Басейн із навісом",
            "icon": null
        },
        {
            "id": 3,
            "name": "Басейн із видом",
            "icon": null
        },
        {
            "id": 4,
            "name": "Басейн на даху",
            "icon": null
        },
        {
            "id": 5,
            "name": "Рушники для басейну",
            "icon": null
        },
        {
            "id": 6,
            "name": "Басейн із солоною водою",
            "icon": null
        },
        {
            "id": 7,
            "name": "Приватний басейн",
            "icon": null
        },
        {
            "id": 8,
            "name": "Гідромасажна ванна",
            "icon": null
        },
        {
            "id": 9,
            "name": "Ванна",
            "icon": null
        },
        {
            "id": 10,
            "name": "Душ",
            "icon": null
        },
        {
            "id": 11,
            "name": "Ванна",
            "icon": null
        },
        {
            "id": 12,
            "name": "Фен",
            "icon": null
        },
        {
            "id": 13,
            "name": "Спільний туалет",
            "icon": null
        },
        {
            "id": 14,
            "name": "Сауна",
            "icon": null
        },
        {
            "id": 15,
            "name": "Туалетний папір",
            "icon": null
        },
        {
            "id": 16,
            "name": "Біде",
            "icon": null
        },
        {
            "id": 17,
            "name": "Туалетно-косметичні засоби",
            "icon": null
        },
        {
            "id": 18,
            "name": "Гостьовий туалет",
            "icon": null
        },
        {
            "id": 19,
            "name": "Туалет",
            "icon": null
        },
        {
            "id": 20,
            "name": "Додаткова ванна кімната",
            "icon": null
        },
        {
            "id": 21,
            "name": "Зубна щітка",
            "icon": null
        },
        {
            "id": 22,
            "name": "Шампунь",
            "icon": null
        },
        {
            "id": 23,
            "name": "Кондиціонер для волосся",
            "icon": null
        },
        {
            "id": 24,
            "name": "Мило для тіла",
            "icon": null
        },
        {
            "id": 25,
            "name": "Шапочка для душу",
            "icon": null
        },
        {
            "id": 26,
            "name": "Білизна",
            "icon": null
        },
        {
            "id": 27,
            "name": "Рушники",
            "icon": null
        },
        {
            "id": 28,
            "name": "Дитячі ліжечка",
            "icon": null
        },
        {
            "id": 29,
            "name": "Вішалка для одягу",
            "icon": null
        },
        {
            "id": 30,
            "name": "Сушарка для одягу",
            "icon": null
        },
        {
            "id": 31,
            "name": "Розкладне ліжко",
            "icon": null
        },
        {
            "id": 32,
            "name": "Диван-ліжко",
            "icon": null
        }];

    return (
        <Page>
            <div className={'test-container'}>
                <div style={{width: 600, border: '1px solid red', padding: 5}}>

                    <HotelMapListItem
                        hotel={{
                            "id": 25,
                            "selected": false,
                            "name": "Atrium Hotel",
                            "alias": "25-atrium-hotel",
                            "stars": 4,
                            preparedProperties: properties,
                            "photo": {
                                "id": "253c6637-f9f5-425d-b44c-492d76b8975b",
                                "order": 0,
                                "active": true,
                                "source": {
                                    "original": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_original.jpg",
                                    "302x170": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_302x170.jpg",
                                    "302x170@2x": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_302x170@2x.jpg",
                                    "560x560": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_560x560.jpg",
                                    "560x560@2x": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_560x560@2x.jpg"
                                }
                            },
                            "address": " Galytska Vulitsa 31, Івано-Франківськ, 76018, Україна ",
                            "rating": "4",
                            "reviewsCount": 42,
                            "fullPrice": 2550,
                            "minPrice": 1750,
                            "mealTypeId": 2,
                            mealType: {
                                "id": 1,
                                "name": "Без харчування",
                                "description": "Без харчування",
                                "code": "RO"
                            },
                            "coordinates": {
                                "latitude": 48.9228518,
                                "longitude": 24.7096789
                            },
                            "distanceToPlaceFormatted": "в центрі",
                            "properties": [
                                91,
                                10,
                                102,
                                19,
                                27,
                                26,
                                38,
                                63,
                                101,
                                62,
                                95,
                                12,
                                60,
                                98,
                                42,
                                120,
                                29,
                                15
                            ],
                            "isFavorite": false
                        }}
                        meta={{
                            "parameters": {
                                "arrivalDate": "2023-02-16T00:00:00.000000Z",
                                "departureDate": "2023-02-16T00:00:00.000000Z",
                                "adults": "2",
                                "kids": [],
                                "orderBy": 1,
                                "rooms": "1"
                            },
                            "filters": {
                                "available": 0
                            },
                            "sortable": [
                                {
                                    "id": 4,
                                    "name": "Ціна (спочатку найвища)",
                                    "default": false
                                },
                                {
                                    "id": 5,
                                    "name": "Ціна (спочатку найнижча)",
                                    "default": false
                                },
                                {
                                    "id": 6,
                                    "name": "Відстань від центру",
                                    "default": true
                                }
                            ],
                            "filtersInitData": {
                                "price": {
                                    "title": "Ціна",
                                    "min": 0,
                                    "max": 10000,
                                    "ratio": 1.0957549999999998,
                                    "data": [
                                        {
                                            "start": 0,
                                            "end": 10,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 11,
                                            "end": 22,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 23,
                                            "end": 36,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 37,
                                            "end": 51,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 52,
                                            "end": 67,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 68,
                                            "end": 84,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 85,
                                            "end": 103,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 104,
                                            "end": 123,
                                            "quantity": 4
                                        },
                                        {
                                            "start": 124,
                                            "end": 145,
                                            "quantity": 4
                                        },
                                        {
                                            "start": 146,
                                            "end": 169,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 170,
                                            "end": 195,
                                            "quantity": 18
                                        },
                                        {
                                            "start": 196,
                                            "end": 224,
                                            "quantity": 27
                                        },
                                        {
                                            "start": 225,
                                            "end": 255,
                                            "quantity": 30
                                        },
                                        {
                                            "start": 256,
                                            "end": 289,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 290,
                                            "end": 326,
                                            "quantity": 17
                                        },
                                        {
                                            "start": 327,
                                            "end": 367,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 368,
                                            "end": 412,
                                            "quantity": 20
                                        },
                                        {
                                            "start": 413,
                                            "end": 461,
                                            "quantity": 17
                                        },
                                        {
                                            "start": 462,
                                            "end": 514,
                                            "quantity": 44
                                        },
                                        {
                                            "start": 515,
                                            "end": 572,
                                            "quantity": 45
                                        },
                                        {
                                            "start": 573,
                                            "end": 636,
                                            "quantity": 69
                                        },
                                        {
                                            "start": 637,
                                            "end": 706,
                                            "quantity": 125
                                        },
                                        {
                                            "start": 707,
                                            "end": 782,
                                            "quantity": 72
                                        },
                                        {
                                            "start": 783,
                                            "end": 865,
                                            "quantity": 132
                                        },
                                        {
                                            "start": 866,
                                            "end": 956,
                                            "quantity": 125
                                        },
                                        {
                                            "start": 957,
                                            "end": 1056,
                                            "quantity": 109
                                        },
                                        {
                                            "start": 1057,
                                            "end": 1165,
                                            "quantity": 73
                                        },
                                        {
                                            "start": 1166,
                                            "end": 1285,
                                            "quantity": 98
                                        },
                                        {
                                            "start": 1286,
                                            "end": 1416,
                                            "quantity": 93
                                        },
                                        {
                                            "start": 1417,
                                            "end": 1559,
                                            "quantity": 60
                                        },
                                        {
                                            "start": 1560,
                                            "end": 1716,
                                            "quantity": 65
                                        },
                                        {
                                            "start": 1717,
                                            "end": 1888,
                                            "quantity": 51
                                        },
                                        {
                                            "start": 1889,
                                            "end": 2076,
                                            "quantity": 56
                                        },
                                        {
                                            "start": 2077,
                                            "end": 2282,
                                            "quantity": 44
                                        },
                                        {
                                            "start": 2283,
                                            "end": 2508,
                                            "quantity": 34
                                        },
                                        {
                                            "start": 2509,
                                            "end": 2755,
                                            "quantity": 29
                                        },
                                        {
                                            "start": 2756,
                                            "end": 3025,
                                            "quantity": 38
                                        },
                                        {
                                            "start": 3026,
                                            "end": 3321,
                                            "quantity": 18
                                        },
                                        {
                                            "start": 3322,
                                            "end": 3645,
                                            "quantity": 23
                                        },
                                        {
                                            "start": 3646,
                                            "end": 4000,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 4001,
                                            "end": 4389,
                                            "quantity": 7
                                        },
                                        {
                                            "start": 4390,
                                            "end": 4815,
                                            "quantity": 10
                                        },
                                        {
                                            "start": 4816,
                                            "end": 5282,
                                            "quantity": 11
                                        },
                                        {
                                            "start": 5283,
                                            "end": 5794,
                                            "quantity": 7
                                        },
                                        {
                                            "start": 5795,
                                            "end": 6354,
                                            "quantity": 13
                                        },
                                        {
                                            "start": 6355,
                                            "end": 6968,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 6969,
                                            "end": 7641,
                                            "quantity": 8
                                        },
                                        {
                                            "start": 7642,
                                            "end": 8378,
                                            "quantity": 8
                                        },
                                        {
                                            "start": 8379,
                                            "end": 9185,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 9186,
                                            "end": 10000,
                                            "quantity": 6
                                        }
                                    ]
                                },
                                "rooms": {
                                    "title": "Номери та ліжка",
                                    "rooms": {
                                        "label": "Кімнати",
                                        "min": 1,
                                        "max": 9
                                    },
                                    "beds": {
                                        "label": "Ліжка",
                                        "min": 1,
                                        "max": 9
                                    },
                                    "bathrooms": {
                                        "label": "Санвузли",
                                        "min": 1,
                                        "max": 9
                                    }
                                },
                                "filterGroups": [
                                    {
                                        "title": "Обмеження",
                                        "alias": "restrictions",
                                        "data": [
                                            {
                                                "alias": "pets",
                                                "label": "Можна з тваринами"
                                            },
                                            {
                                                "alias": "smoke",
                                                "label": "Можна палити"
                                            }
                                        ]
                                    },
                                    {
                                        "title": "Тип помешкання",
                                        "alias": "accommodationTypes",
                                        "meta": "accommodationTypes",
                                        "data": [
                                            1,
                                            2,
                                            3,
                                            4,
                                            5,
                                            6,
                                            7,
                                            8,
                                            9,
                                            10,
                                            11,
                                            12,
                                            13,
                                            14,
                                            15,
                                            16,
                                            17,
                                            18,
                                            19,
                                            20,
                                            21,
                                            22,
                                            23,
                                            24,
                                            25,
                                            26,
                                            27,
                                            28,
                                            29,
                                            30,
                                            31,
                                            32,
                                            33,
                                            34
                                        ]
                                    },
                                    {
                                        "title": "Зручності в готелі",
                                        "alias": "hotelProperties",
                                        "meta": "properties",
                                        "data": [
                                            131,
                                            132,
                                            133,
                                            134,
                                            135,
                                            136,
                                            137,
                                            138,
                                            139,
                                            140,
                                            141,
                                            142,
                                            143,
                                            144,
                                            145,
                                            146,
                                            147,
                                            148,
                                            149,
                                            150,
                                            151,
                                            152,
                                            153,
                                            154,
                                            155,
                                            156,
                                            157,
                                            158,
                                            159,
                                            160,
                                            161,
                                            162,
                                            163,
                                            164,
                                            165,
                                            166,
                                            167,
                                            168,
                                            169,
                                            170,
                                            171,
                                            172,
                                            173,
                                            174,
                                            175,
                                            176,
                                            177,
                                            178,
                                            179,
                                            180,
                                            181,
                                            182,
                                            183,
                                            184,
                                            185,
                                            186,
                                            187,
                                            188,
                                            189,
                                            190,
                                            191,
                                            192,
                                            193,
                                            194,
                                            195,
                                            196,
                                            197,
                                            198,
                                            199,
                                            200,
                                            201,
                                            202,
                                            203,
                                            204,
                                            205,
                                            206,
                                            207,
                                            208,
                                            209,
                                            210,
                                            211,
                                            212,
                                            213,
                                            214,
                                            215,
                                            216,
                                            217,
                                            218,
                                            219,
                                            220,
                                            221,
                                            222,
                                            223,
                                            224,
                                            225,
                                            226,
                                            227,
                                            228,
                                            229,
                                            230,
                                            231,
                                            232,
                                            233,
                                            234,
                                            235,
                                            236,
                                            237,
                                            238,
                                            239,
                                            240,
                                            241,
                                            242,
                                            243,
                                            244,
                                            245,
                                            246,
                                            247,
                                            248,
                                            249,
                                            250,
                                            251,
                                            252,
                                            253,
                                            254,
                                            255,
                                            256,
                                            257,
                                            258,
                                            259,
                                            260,
                                            261,
                                            262,
                                            263,
                                            264,
                                            265,
                                            266,
                                            267,
                                            268,
                                            269,
                                            270,
                                            271,
                                            272,
                                            273,
                                            274,
                                            275
                                        ]
                                    },
                                    {
                                        "title": "Зручності в номерах",
                                        "alias": "roomProperties",
                                        "meta": "properties",
                                        "data": [
                                            1,
                                            2,
                                            3,
                                            4,
                                            5,
                                            6,
                                            7,
                                            8,
                                            9,
                                            10,
                                            11,
                                            12,
                                            13,
                                            14,
                                            15,
                                            16,
                                            17,
                                            18,
                                            19,
                                            20,
                                            21,
                                            22,
                                            23,
                                            24,
                                            25,
                                            26,
                                            27,
                                            28,
                                            29,
                                            30,
                                            31,
                                            32,
                                            33,
                                            34,
                                            35,
                                            36,
                                            37,
                                            38,
                                            39,
                                            40,
                                            41,
                                            42,
                                            43,
                                            44,
                                            45,
                                            46,
                                            47,
                                            48,
                                            49,
                                            50,
                                            51,
                                            52,
                                            53,
                                            54,
                                            55,
                                            56,
                                            57,
                                            58,
                                            59,
                                            60,
                                            61,
                                            62,
                                            63,
                                            64,
                                            65,
                                            66,
                                            67,
                                            68,
                                            69,
                                            70,
                                            71,
                                            72,
                                            73,
                                            74,
                                            75,
                                            76,
                                            77,
                                            78,
                                            79,
                                            80,
                                            81,
                                            82,
                                            83,
                                            84,
                                            85,
                                            86,
                                            87,
                                            88,
                                            89,
                                            90,
                                            91,
                                            92,
                                            93,
                                            94,
                                            95,
                                            96,
                                            97,
                                            98,
                                            99,
                                            100,
                                            101,
                                            102,
                                            103,
                                            104,
                                            105,
                                            106,
                                            107,
                                            108,
                                            109,
                                            110,
                                            111,
                                            112,
                                            113,
                                            114,
                                            115,
                                            116,
                                            117,
                                            118,
                                            119,
                                            120,
                                            121,
                                            122,
                                            123,
                                            124,
                                            125,
                                            126,
                                            127,
                                            128,
                                            129,
                                            130
                                        ]
                                    }
                                ]
                            },
                            "mealTypes": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Без харчування",
                                        "description": "Без харчування",
                                        "code": "RO"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Тільки сніданок",
                                        "description": "Сніданок включено",
                                        "code": "BB"
                                    },
                                    {
                                        "id": 3,
                                        "name": "Напівпансіон",
                                        "description": "Напівпансіон",
                                        "code": "HB"
                                    },
                                    {
                                        "id": 4,
                                        "name": "Повний пансіон",
                                        "description": "Повний пансіон",
                                        "code": "FB"
                                    },
                                    {
                                        "id": 5,
                                        "name": "Все включено",
                                        "description": "Все включено",
                                        "code": "AI"
                                    },
                                    {
                                        "id": 6,
                                        "name": "Ультра все включено",
                                        "description": "Ультра все включено",
                                        "code": "UAI"
                                    }
                                ]
                            },
                            "properties": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Басейн із підігрівом",
                                        "icon": null
                                    },
                                    {
                                        "id": 2,
                                        "name": "Басейн із навісом",
                                        "icon": null
                                    },
                                    {
                                        "id": 3,
                                        "name": "Басейн із видом",
                                        "icon": null
                                    },
                                    {
                                        "id": 4,
                                        "name": "Басейн на даху",
                                        "icon": null
                                    },
                                    {
                                        "id": 5,
                                        "name": "Рушники для басейну",
                                        "icon": null
                                    },
                                    {
                                        "id": 6,
                                        "name": "Басейн із солоною водою",
                                        "icon": null
                                    },
                                    {
                                        "id": 7,
                                        "name": "Приватний басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 8,
                                        "name": "Гідромасажна ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 9,
                                        "name": "Ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 10,
                                        "name": "Душ",
                                        "icon": null
                                    },
                                    {
                                        "id": 11,
                                        "name": "Ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 12,
                                        "name": "Фен",
                                        "icon": null
                                    },
                                    {
                                        "id": 13,
                                        "name": "Спільний туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 14,
                                        "name": "Сауна",
                                        "icon": null
                                    },
                                    {
                                        "id": 15,
                                        "name": "Туалетний папір",
                                        "icon": null
                                    },
                                    {
                                        "id": 16,
                                        "name": "Біде",
                                        "icon": null
                                    },
                                    {
                                        "id": 17,
                                        "name": "Туалетно-косметичні засоби",
                                        "icon": null
                                    },
                                    {
                                        "id": 18,
                                        "name": "Гостьовий туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 19,
                                        "name": "Туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 20,
                                        "name": "Додаткова ванна кімната",
                                        "icon": null
                                    },
                                    {
                                        "id": 21,
                                        "name": "Зубна щітка",
                                        "icon": null
                                    },
                                    {
                                        "id": 22,
                                        "name": "Шампунь",
                                        "icon": null
                                    },
                                    {
                                        "id": 23,
                                        "name": "Кондиціонер для волосся",
                                        "icon": null
                                    },
                                    {
                                        "id": 24,
                                        "name": "Мило для тіла",
                                        "icon": null
                                    },
                                    {
                                        "id": 25,
                                        "name": "Шапочка для душу",
                                        "icon": null
                                    },
                                    {
                                        "id": 26,
                                        "name": "Білизна",
                                        "icon": null
                                    },
                                    {
                                        "id": 27,
                                        "name": "Рушники",
                                        "icon": null
                                    },
                                    {
                                        "id": 28,
                                        "name": "Дитячі ліжечка",
                                        "icon": null
                                    },
                                    {
                                        "id": 29,
                                        "name": "Вішалка для одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 30,
                                        "name": "Сушарка для одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 31,
                                        "name": "Розкладне ліжко",
                                        "icon": null
                                    },
                                    {
                                        "id": 32,
                                        "name": "Диван-ліжко",
                                        "icon": null
                                    },
                                    {
                                        "id": 33,
                                        "name": "Кошики для сміття",
                                        "icon": null
                                    },
                                    {
                                        "id": 34,
                                        "name": "Гардеробна",
                                        "icon": null
                                    },
                                    {
                                        "id": 35,
                                        "name": "Подовжене ліжко (>2 метрів)",
                                        "icon": null
                                    },
                                    {
                                        "id": 36,
                                        "name": "Диван",
                                        "icon": null
                                    },
                                    {
                                        "id": 37,
                                        "name": "Вітальня/м'який куток",
                                        "icon": null
                                    },
                                    {
                                        "id": 38,
                                        "name": "Робочий стіл",
                                        "icon": null
                                    },
                                    {
                                        "id": 39,
                                        "name": "Пухова подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 40,
                                        "name": "Синтетична подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 41,
                                        "name": "Гіпоалергенна подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 42,
                                        "name": "Шафа або гардероб",
                                        "icon": null
                                    },
                                    {
                                        "id": 43,
                                        "name": "Високий стілець для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 44,
                                        "name": "Піжама",
                                        "icon": null
                                    },
                                    {
                                        "id": 45,
                                        "name": "Електроковдра",
                                        "icon": null
                                    },
                                    {
                                        "id": 46,
                                        "name": "Москітна сітка",
                                        "icon": null
                                    },
                                    {
                                        "id": 47,
                                        "name": "Окремий вхід",
                                        "icon": null
                                    },
                                    {
                                        "id": 48,
                                        "name": "Сейф",
                                        "icon": null
                                    },
                                    {
                                        "id": 49,
                                        "name": "Датчик чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 50,
                                        "name": "Датчик диму",
                                        "icon": null
                                    },
                                    {
                                        "id": 51,
                                        "name": "Очищувачі повітря",
                                        "icon": null
                                    },
                                    {
                                        "id": 52,
                                        "name": "Вогнегасник",
                                        "icon": null
                                    },
                                    {
                                        "id": 53,
                                        "name": "Захисні кришки на розетках для безпеки дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 54,
                                        "name": "Ігрова приставка PS4",
                                        "icon": null
                                    },
                                    {
                                        "id": 55,
                                        "name": "Ігрова приставка Xbox One",
                                        "icon": null
                                    },
                                    {
                                        "id": 56,
                                        "name": "Комп'ютер",
                                        "icon": null
                                    },
                                    {
                                        "id": 57,
                                        "name": "Ігрова приставка",
                                        "icon": null
                                    },
                                    {
                                        "id": 58,
                                        "name": "Ноутбук",
                                        "icon": null
                                    },
                                    {
                                        "id": 59,
                                        "name": "iPad",
                                        "icon": null
                                    },
                                    {
                                        "id": 60,
                                        "name": "Кабельні канали",
                                        "icon": null
                                    },
                                    {
                                        "id": 61,
                                        "name": "Радіо",
                                        "icon": null
                                    },
                                    {
                                        "id": 62,
                                        "name": "Телефон",
                                        "icon": null
                                    },
                                    {
                                        "id": 63,
                                        "name": "Телевізор",
                                        "icon": null
                                    },
                                    {
                                        "id": 64,
                                        "name": "Портативна точка доступу в інтернет",
                                        "icon": null
                                    },
                                    {
                                        "id": 65,
                                        "name": "Потоковий сервіс (наприклад, Netflix)",
                                        "icon": null
                                    },
                                    {
                                        "id": 66,
                                        "name": "Настільні ігри/пазли",
                                        "icon": null
                                    },
                                    {
                                        "id": 67,
                                        "name": "Дитячі книги, музика чи фільми",
                                        "icon": null
                                    },
                                    {
                                        "id": 68,
                                        "name": "Обідня зона",
                                        "icon": null
                                    },
                                    {
                                        "id": 69,
                                        "name": "Обідній стіл",
                                        "icon": null
                                    },
                                    {
                                        "id": 70,
                                        "name": "Келихи для вина",
                                        "icon": null
                                    },
                                    {
                                        "id": 71,
                                        "name": "Пляшка води",
                                        "icon": null
                                    },
                                    {
                                        "id": 72,
                                        "name": "Шоколад або печиво",
                                        "icon": null
                                    },
                                    {
                                        "id": 73,
                                        "name": "Фрукти",
                                        "icon": null
                                    },
                                    {
                                        "id": 74,
                                        "name": "Вино або шампанське",
                                        "icon": null
                                    },
                                    {
                                        "id": 75,
                                        "name": "Барбекю",
                                        "icon": null
                                    },
                                    {
                                        "id": 76,
                                        "name": "Духовка",
                                        "icon": null
                                    },
                                    {
                                        "id": 77,
                                        "name": "Плита",
                                        "icon": null
                                    },
                                    {
                                        "id": 78,
                                        "name": "Тостер",
                                        "icon": null
                                    },
                                    {
                                        "id": 79,
                                        "name": "Посудомийна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 80,
                                        "name": "Електрочайник",
                                        "icon": null
                                    },
                                    {
                                        "id": 81,
                                        "name": "Обідня зона на відкритому повітрі",
                                        "icon": null
                                    },
                                    {
                                        "id": 82,
                                        "name": "Вуличні меблі",
                                        "icon": null
                                    },
                                    {
                                        "id": 83,
                                        "name": "Міні-бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 84,
                                        "name": "Кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 85,
                                        "name": "Міні-кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 86,
                                        "name": "Кухонний посуд",
                                        "icon": null
                                    },
                                    {
                                        "id": 87,
                                        "name": "Мікрохвильова піч",
                                        "icon": null
                                    },
                                    {
                                        "id": 88,
                                        "name": "Холодильник",
                                        "icon": null
                                    },
                                    {
                                        "id": 89,
                                        "name": "Чайник/кавоварка",
                                        "icon": null
                                    },
                                    {
                                        "id": 90,
                                        "name": "Кавоварка",
                                        "icon": null
                                    },
                                    {
                                        "id": 91,
                                        "name": "Кондиціонер",
                                        "icon": null
                                    },
                                    {
                                        "id": 92,
                                        "name": "Сушильна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 93,
                                        "name": "Вентилятор",
                                        "icon": null
                                    },
                                    {
                                        "id": 94,
                                        "name": "Камін",
                                        "icon": null
                                    },
                                    {
                                        "id": 95,
                                        "name": "Опалення",
                                        "icon": null
                                    },
                                    {
                                        "id": 96,
                                        "name": "Доступні суміжні номери",
                                        "icon": null
                                    },
                                    {
                                        "id": 97,
                                        "name": "Праска",
                                        "icon": null
                                    },
                                    {
                                        "id": 98,
                                        "name": "Послуга дзвінок-\"будильник\"",
                                        "icon": null
                                    },
                                    {
                                        "id": 99,
                                        "name": "Доступ до представницького лаунжу",
                                        "icon": null
                                    },
                                    {
                                        "id": 100,
                                        "name": "Вхід з електронною карткою",
                                        "icon": null
                                    },
                                    {
                                        "id": 101,
                                        "name": "Капці",
                                        "icon": null
                                    },
                                    {
                                        "id": 102,
                                        "name": "Халат",
                                        "icon": null
                                    },
                                    {
                                        "id": 103,
                                        "name": "Пральна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 104,
                                        "name": "Засоби для чищення",
                                        "icon": null
                                    },
                                    {
                                        "id": 105,
                                        "name": "Балкон",
                                        "icon": null
                                    },
                                    {
                                        "id": 106,
                                        "name": "Тераса",
                                        "icon": null
                                    },
                                    {
                                        "id": 107,
                                        "name": "Вид на місто",
                                        "icon": null
                                    },
                                    {
                                        "id": 108,
                                        "name": "Вид на сад",
                                        "icon": null
                                    },
                                    {
                                        "id": 109,
                                        "name": "Вид на озеро",
                                        "icon": null
                                    },
                                    {
                                        "id": 110,
                                        "name": "Вид на пам'ятку",
                                        "icon": null
                                    },
                                    {
                                        "id": 111,
                                        "name": "Вид на гори",
                                        "icon": null
                                    },
                                    {
                                        "id": 112,
                                        "name": "Вид на басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 113,
                                        "name": "Вид на річку",
                                        "icon": null
                                    },
                                    {
                                        "id": 114,
                                        "name": "Вид на море",
                                        "icon": null
                                    },
                                    {
                                        "id": 115,
                                        "name": "Вид на внутрішній двір",
                                        "icon": null
                                    },
                                    {
                                        "id": 116,
                                        "name": "Вид на тиху вулицю",
                                        "icon": null
                                    },
                                    {
                                        "id": 117,
                                        "name": "Приміщення повністю знаходиться на першому поверсі",
                                        "icon": null
                                    },
                                    {
                                        "id": 118,
                                        "name": "Приміщення придатне для гостей в інвалідних візках",
                                        "icon": null
                                    },
                                    {
                                        "id": 119,
                                        "name": "Зручності для гостей із вадами слуху",
                                        "icon": null
                                    },
                                    {
                                        "id": 120,
                                        "name": "На вищі поверхи можна піднятися на ліфті",
                                        "icon": null
                                    },
                                    {
                                        "id": 121,
                                        "name": "На верхні поверхи можна піднятися тільки сходами",
                                        "icon": null
                                    },
                                    {
                                        "id": 122,
                                        "name": "Ванна з поручнями",
                                        "icon": null
                                    },
                                    {
                                        "id": 123,
                                        "name": "Аварійний шнур у ванній кімнаті",
                                        "icon": null
                                    },
                                    {
                                        "id": 124,
                                        "name": "Високий унітаз",
                                        "icon": null
                                    },
                                    {
                                        "id": 125,
                                        "name": "Низький умивальник",
                                        "icon": null
                                    },
                                    {
                                        "id": 126,
                                        "name": "Безбар'єрна душова",
                                        "icon": null
                                    },
                                    {
                                        "id": 127,
                                        "name": "Стілець для душа",
                                        "icon": null
                                    },
                                    {
                                        "id": 128,
                                        "name": "Туалет з поручнями",
                                        "icon": null
                                    },
                                    {
                                        "id": 129,
                                        "name": "Душ без піддону",
                                        "icon": null
                                    },
                                    {
                                        "id": 130,
                                        "name": "Захисні бар'єри для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 131,
                                        "name": "Дитяче меню",
                                        "icon": null
                                    },
                                    {
                                        "id": 132,
                                        "name": "Шведський стіл з вибором страв для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 133,
                                        "name": "Вино/шампанськеУ помешканні подають вино або шампанське",
                                        "icon": null
                                    },
                                    {
                                        "id": 134,
                                        "name": "ФруктиУ номерах є фрукти",
                                        "icon": null
                                    },
                                    {
                                        "id": 135,
                                        "name": "Кав'ярня (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 136,
                                        "name": "Снек-бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 137,
                                        "name": "Доставка продуктів",
                                        "icon": null
                                    },
                                    {
                                        "id": 138,
                                        "name": "Запаковані ланчі",
                                        "icon": null
                                    },
                                    {
                                        "id": 139,
                                        "name": "Барбекю",
                                        "icon": null
                                    },
                                    {
                                        "id": 140,
                                        "name": "Автомат з напоями",
                                        "icon": null
                                    },
                                    {
                                        "id": 141,
                                        "name": "Автомат із закусками",
                                        "icon": null
                                    },
                                    {
                                        "id": 142,
                                        "name": "Спеціальне дієтичне меню (за запитом)",
                                        "icon": null
                                    },
                                    {
                                        "id": 143,
                                        "name": "Сніданок у номері",
                                        "icon": null
                                    },
                                    {
                                        "id": 144,
                                        "name": "Спільна кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 145,
                                        "name": "Ресторан",
                                        "icon": null
                                    },
                                    {
                                        "id": 146,
                                        "name": "Обслуговування номерів",
                                        "icon": null
                                    },
                                    {
                                        "id": 147,
                                        "name": "Бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 148,
                                        "name": "Водна гірка",
                                        "icon": null
                                    },
                                    {
                                        "id": 149,
                                        "name": "Пляжне крісло/шезлонг",
                                        "icon": null
                                    },
                                    {
                                        "id": 150,
                                        "name": "Сонячна парасоля",
                                        "icon": null
                                    },
                                    {
                                        "id": 151,
                                        "name": "Косметичні послуги",
                                        "icon": null
                                    },
                                    {
                                        "id": 152,
                                        "name": "Спа-процедури",
                                        "icon": null
                                    },
                                    {
                                        "id": 153,
                                        "name": "Парова лазня",
                                        "icon": null
                                    },
                                    {
                                        "id": 154,
                                        "name": "Спа-лаунж/зона для релаксації",
                                        "icon": null
                                    },
                                    {
                                        "id": 155,
                                        "name": "Ванночка для ніг",
                                        "icon": null
                                    },
                                    {
                                        "id": 156,
                                        "name": "Пакети послуг спа / велнес",
                                        "icon": null
                                    },
                                    {
                                        "id": 157,
                                        "name": "Масажне крісло",
                                        "icon": null
                                    },
                                    {
                                        "id": 158,
                                        "name": "Фітнес",
                                        "icon": null
                                    },
                                    {
                                        "id": 159,
                                        "name": "Заняття йогою",
                                        "icon": null
                                    },
                                    {
                                        "id": 160,
                                        "name": "Заняття фітнесом",
                                        "icon": null
                                    },
                                    {
                                        "id": 161,
                                        "name": "Особистий тренер",
                                        "icon": null
                                    },
                                    {
                                        "id": 162,
                                        "name": "Роздягальня з шафками",
                                        "icon": null
                                    },
                                    {
                                        "id": 163,
                                        "name": "Дитячий басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 164,
                                        "name": "Хамам/турецька лазня",
                                        "icon": null
                                    },
                                    {
                                        "id": 165,
                                        "name": "Солярій",
                                        "icon": null
                                    },
                                    {
                                        "id": 166,
                                        "name": "Гарячі джерела",
                                        "icon": null
                                    },
                                    {
                                        "id": 167,
                                        "name": "Масаж",
                                        "icon": null
                                    },
                                    {
                                        "id": 168,
                                        "name": "Лазні на відкритому повітрі",
                                        "icon": null
                                    },
                                    {
                                        "id": 169,
                                        "name": "Громадські лазні",
                                        "icon": null
                                    },
                                    {
                                        "id": 170,
                                        "name": "Сауна",
                                        "icon": null
                                    },
                                    {
                                        "id": 171,
                                        "name": "Фітнес-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 172,
                                        "name": "Оздоровчий спа-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 173,
                                        "name": "Гідромасажна ванна/джакузі",
                                        "icon": null
                                    },
                                    {
                                        "id": 174,
                                        "name": "Басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 175,
                                        "name": "Аквапарк",
                                        "icon": null
                                    },
                                    {
                                        "id": 176,
                                        "name": "Квитки на громадський транспорт",
                                        "icon": null
                                    },
                                    {
                                        "id": 177,
                                        "name": "Трансфер до помешкання",
                                        "icon": null
                                    },
                                    {
                                        "id": 178,
                                        "name": "Прокат автомобілів",
                                        "icon": null
                                    },
                                    {
                                        "id": 179,
                                        "name": "Чи є парковка для гостей?",
                                        "icon": null
                                    },
                                    {
                                        "id": 180,
                                        "name": "Трансфер з/до аеропорту",
                                        "icon": null
                                    },
                                    {
                                        "id": 181,
                                        "name": "Спорядження для тенісу",
                                        "icon": null
                                    },
                                    {
                                        "id": 182,
                                        "name": "Спорядження для бадмінтону",
                                        "icon": null
                                    },
                                    {
                                        "id": 183,
                                        "name": "Пляж",
                                        "icon": null
                                    },
                                    {
                                        "id": 184,
                                        "name": "Тимчасові виставки мистецтва",
                                        "icon": null
                                    },
                                    {
                                        "id": 185,
                                        "name": "Похід барами (пабкрол)",
                                        "icon": null
                                    },
                                    {
                                        "id": 186,
                                        "name": "Стендап-комедія",
                                        "icon": null
                                    },
                                    {
                                        "id": 187,
                                        "name": "Вечори перегляду фільмів",
                                        "icon": null
                                    },
                                    {
                                        "id": 188,
                                        "name": "Піші екскурсії",
                                        "icon": null
                                    },
                                    {
                                        "id": 189,
                                        "name": "Велосипедні екскурсії",
                                        "icon": null
                                    },
                                    {
                                        "id": 190,
                                        "name": "Тематичні вечері",
                                        "icon": null
                                    },
                                    {
                                        "id": 191,
                                        "name": "Щаслива година",
                                        "icon": null
                                    },
                                    {
                                        "id": 192,
                                        "name": "Екскурсія або урок про місцеву культуру",
                                        "icon": null
                                    },
                                    {
                                        "id": 193,
                                        "name": "Урок готування",
                                        "icon": null
                                    },
                                    {
                                        "id": 194,
                                        "name": "Жива музика/виступ",
                                        "icon": null
                                    },
                                    {
                                        "id": 195,
                                        "name": "Пряма трансляція спортивних ігор",
                                        "icon": null
                                    },
                                    {
                                        "id": 196,
                                        "name": "Стрільба з лука",
                                        "icon": null
                                    },
                                    {
                                        "id": 197,
                                        "name": "Аеробіка",
                                        "icon": null
                                    },
                                    {
                                        "id": 198,
                                        "name": "Бінго",
                                        "icon": null
                                    },
                                    {
                                        "id": 199,
                                        "name": "Тенісний корт",
                                        "icon": null
                                    },
                                    {
                                        "id": 200,
                                        "name": "Більярд",
                                        "icon": null
                                    },
                                    {
                                        "id": 201,
                                        "name": "Настільний теніс",
                                        "icon": null
                                    },
                                    {
                                        "id": 202,
                                        "name": "Дартс",
                                        "icon": null
                                    },
                                    {
                                        "id": 203,
                                        "name": "Сквош",
                                        "icon": null
                                    },
                                    {
                                        "id": 204,
                                        "name": "Боулінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 205,
                                        "name": "Міні-гольф",
                                        "icon": null
                                    },
                                    {
                                        "id": 206,
                                        "name": "Поле для гольфу (в радіусі 3 км)",
                                        "icon": null
                                    },
                                    {
                                        "id": 207,
                                        "name": "Зручності для занять водними видами спорту (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 208,
                                        "name": "Віндсерфінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 209,
                                        "name": "Дайвінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 210,
                                        "name": "Плавання під водою з маскою, трубкою і ластами",
                                        "icon": null
                                    },
                                    {
                                        "id": 211,
                                        "name": "Каноє",
                                        "icon": null
                                    },
                                    {
                                        "id": 212,
                                        "name": "Риболовля",
                                        "icon": null
                                    },
                                    {
                                        "id": 213,
                                        "name": "Верхова їзда",
                                        "icon": null
                                    },
                                    {
                                        "id": 214,
                                        "name": "Велоспорт",
                                        "icon": null
                                    },
                                    {
                                        "id": 215,
                                        "name": "Піші прогулянки",
                                        "icon": null
                                    },
                                    {
                                        "id": 216,
                                        "name": "Катання на лижах",
                                        "icon": null
                                    },
                                    {
                                        "id": 217,
                                        "name": "Садові меблі",
                                        "icon": null
                                    },
                                    {
                                        "id": 218,
                                        "name": "Місце для пікніка",
                                        "icon": null
                                    },
                                    {
                                        "id": 219,
                                        "name": "Камін",
                                        "icon": null
                                    },
                                    {
                                        "id": 220,
                                        "name": "Камін вуличний",
                                        "icon": null
                                    },
                                    {
                                        "id": 221,
                                        "name": "Тераса для засмаги",
                                        "icon": null
                                    },
                                    {
                                        "id": 222,
                                        "name": "Спільна кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 223,
                                        "name": "Спільна зона відпочинку з телевізором",
                                        "icon": null
                                    },
                                    {
                                        "id": 224,
                                        "name": "Ігрова кімната",
                                        "icon": null
                                    },
                                    {
                                        "id": 225,
                                        "name": "Церква/храм",
                                        "icon": null
                                    },
                                    {
                                        "id": 226,
                                        "name": "Сад",
                                        "icon": null
                                    },
                                    {
                                        "id": 227,
                                        "name": "Тераса",
                                        "icon": null
                                    },
                                    {
                                        "id": 228,
                                        "name": "Дитячий клуб",
                                        "icon": null
                                    },
                                    {
                                        "id": 229,
                                        "name": "Настільні ігри / пазли",
                                        "icon": null
                                    },
                                    {
                                        "id": 230,
                                        "name": "Ігровий майданчик в приміщенні",
                                        "icon": null
                                    },
                                    {
                                        "id": 231,
                                        "name": "Устаткування для ігор на вулиці",
                                        "icon": null
                                    },
                                    {
                                        "id": 232,
                                        "name": "Захисні бар'єри для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 233,
                                        "name": "Дитячі візки",
                                        "icon": null
                                    },
                                    {
                                        "id": 234,
                                        "name": "Вечірня розважальна програма",
                                        "icon": null
                                    },
                                    {
                                        "id": 235,
                                        "name": "Нічний клуб/ді-джей",
                                        "icon": null
                                    },
                                    {
                                        "id": 236,
                                        "name": "Казино",
                                        "icon": null
                                    },
                                    {
                                        "id": 237,
                                        "name": "Караоке",
                                        "icon": null
                                    },
                                    {
                                        "id": 238,
                                        "name": "Аніматори",
                                        "icon": null
                                    },
                                    {
                                        "id": 239,
                                        "name": "Дитячий майданчик",
                                        "icon": null
                                    },
                                    {
                                        "id": 240,
                                        "name": "Няня/Послуги по догляду за дітьми",
                                        "icon": null
                                    },
                                    {
                                        "id": 241,
                                        "name": "Хімчистка",
                                        "icon": null
                                    },
                                    {
                                        "id": 242,
                                        "name": "Прасування одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 243,
                                        "name": "Пральня",
                                        "icon": null
                                    },
                                    {
                                        "id": 244,
                                        "name": "Щоденне прибирання",
                                        "icon": null
                                    },
                                    {
                                        "id": 245,
                                        "name": "Прес для штанів",
                                        "icon": null
                                    },
                                    {
                                        "id": 246,
                                        "name": "Конференц-зал/бенкетний зал",
                                        "icon": null
                                    },
                                    {
                                        "id": 247,
                                        "name": "Бізнес-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 248,
                                        "name": "Міні-маркет (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 249,
                                        "name": "Перукарня/Салон краси",
                                        "icon": null
                                    },
                                    {
                                        "id": 250,
                                        "name": "Кошик для домашніх тварин",
                                        "icon": null
                                    },
                                    {
                                        "id": 251,
                                        "name": "Миски для домашніх тварин",
                                        "icon": null
                                    },
                                    {
                                        "id": 252,
                                        "name": "Вхід з ключем",
                                        "icon": null
                                    },
                                    {
                                        "id": 253,
                                        "name": "Вхід з електронною карткою",
                                        "icon": null
                                    },
                                    {
                                        "id": 254,
                                        "name": "Лише для дорослих",
                                        "icon": null
                                    },
                                    {
                                        "id": 255,
                                        "name": "Спеціальні номери для алергіків",
                                        "icon": null
                                    },
                                    {
                                        "id": 256,
                                        "name": "На всій території готельного об'єкта і в номерах заборонено курити",
                                        "icon": null
                                    },
                                    {
                                        "id": 257,
                                        "name": "Місця для куріння",
                                        "icon": null
                                    },
                                    {
                                        "id": 258,
                                        "name": "Номери/зручності для осіб з обмеженими фізичними можливостями",
                                        "icon": null
                                    },
                                    {
                                        "id": 259,
                                        "name": "Ліфт",
                                        "icon": null
                                    },
                                    {
                                        "id": 260,
                                        "name": "Звуконепроникні номери",
                                        "icon": null
                                    },
                                    {
                                        "id": 261,
                                        "name": "Опалення",
                                        "icon": null
                                    },
                                    {
                                        "id": 262,
                                        "name": "Шафки, що замикаються",
                                        "icon": null
                                    },
                                    {
                                        "id": 263,
                                        "name": "Камера зберігання багажу",
                                        "icon": null
                                    },
                                    {
                                        "id": 264,
                                        "name": "Цілодобова стійка реєстрації гостей",
                                        "icon": null
                                    },
                                    {
                                        "id": 265,
                                        "name": "Номери для некурців",
                                        "icon": null
                                    },
                                    {
                                        "id": 266,
                                        "name": "Кондиціонер",
                                        "icon": null
                                    },
                                    {
                                        "id": 267,
                                        "name": "Цілодобова охорона",
                                        "icon": null
                                    },
                                    {
                                        "id": 268,
                                        "name": "Охоронна сигналізація",
                                        "icon": null
                                    },
                                    {
                                        "id": 269,
                                        "name": "Датчики диму",
                                        "icon": null
                                    },
                                    {
                                        "id": 270,
                                        "name": "Відеоспостереження в зонах загального користування",
                                        "icon": null
                                    },
                                    {
                                        "id": 271,
                                        "name": "Відеоспостереження ззовні будівлі",
                                        "icon": null
                                    },
                                    {
                                        "id": 272,
                                        "name": "Вогнегасники",
                                        "icon": null
                                    },
                                    {
                                        "id": 273,
                                        "name": "Датчик чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 274,
                                        "name": "Джерела чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 275,
                                        "name": "Сейф",
                                        "icon": null
                                    }
                                ]
                            },
                            "requestCoordinates": {
                                "longitude": "24.7111170",
                                "latitude": "48.9226330"
                            },
                            "accommodationTypes": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Готель"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Апартаменти"
                                    },
                                    {
                                        "id": 3,
                                        "name": "Хостел"
                                    },
                                    {
                                        "id": 4,
                                        "name": "Вілла"
                                    },
                                    {
                                        "id": 5,
                                        "name": "Шале"
                                    },
                                    {
                                        "id": 6,
                                        "name": "Помешкання"
                                    },
                                    {
                                        "id": 7,
                                        "name": "Мотель"
                                    },
                                    {
                                        "id": 8,
                                        "name": "Курортний готель"
                                    },
                                    {
                                        "id": 9,
                                        "name": "Особняк"
                                    },
                                    {
                                        "id": 10,
                                        "name": "Готель типу «ліжко і сніданок»"
                                    },
                                    {
                                        "id": 11,
                                        "name": "Рьокан"
                                    },
                                    {
                                        "id": 12,
                                        "name": "Фермерський будинок"
                                    },
                                    {
                                        "id": 13,
                                        "name": "Парк-готель"
                                    },
                                    {
                                        "id": 14,
                                        "name": "Кемпінг"
                                    },
                                    {
                                        "id": 15,
                                        "name": "Ботель"
                                    },
                                    {
                                        "id": 16,
                                        "name": "Гостьовий будинок"
                                    },
                                    {
                                        "id": 17,
                                        "name": "Міні-готель з рестораном"
                                    },
                                    {
                                        "id": 18,
                                        "name": "Апарт-готель"
                                    },
                                    {
                                        "id": 19,
                                        "name": "Будинок для відпочинку"
                                    },
                                    {
                                        "id": 20,
                                        "name": "Лодж"
                                    },
                                    {
                                        "id": 21,
                                        "name": "Розміщення в сім'ї"
                                    },
                                    {
                                        "id": 22,
                                        "name": "Заміський будинок"
                                    },
                                    {
                                        "id": 23,
                                        "name": "Намет-люкс"
                                    },
                                    {
                                        "id": 24,
                                        "name": "Капсульний готель"
                                    },
                                    {
                                        "id": 25,
                                        "name": "Готель для побачень"
                                    },
                                    {
                                        "id": 26,
                                        "name": "Ріад"
                                    },
                                    {
                                        "id": 27,
                                        "name": "Кондомініум"
                                    },
                                    {
                                        "id": 28,
                                        "name": "Котедж"
                                    },
                                    {
                                        "id": 29,
                                        "name": "Готель економ-класу"
                                    },
                                    {
                                        "id": 30,
                                        "name": "Французький котедж"
                                    },
                                    {
                                        "id": 31,
                                        "name": "Санаторій"
                                    },
                                    {
                                        "id": 32,
                                        "name": "Круїз"
                                    },
                                    {
                                        "id": 33,
                                        "name": "Гуртожиток"
                                    },
                                    {
                                        "id": 34,
                                        "name": "Вілли"
                                    }
                                ]
                            },
                            "pagination": {
                                "total": 0,
                                "count": 38,
                                "per_page": 500,
                                "current_page": 1,
                                "total_pages": 1,
                                "links": []
                            }
                        }}
                        link={'/'}
                        nights={2}
                    />

                    <HotelMapListItem
                        hotel={{
                            "id": 25,
                            "selected": false,
                            "name": "Atrium Hotel",
                            "alias": "25-atrium-hotel",
                            "stars": 4,
                            preparedProperties: [],
                            "photo": {
                                "id": "253c6637-f9f5-425d-b44c-492d76b8975b",
                                "order": 0,
                                "active": true,
                                "source": {
                                    "original": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_original.jpg",
                                    "302x170": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_302x170.jpg",
                                    "302x170@2x": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_302x170@2x.jpg",
                                    "560x560": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_560x560.jpg",
                                    "560x560@2x": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_560x560@2x.jpg"
                                }
                            },
                            "address": " Galytska Vulitsa 31, Івано-Франківськ, 76018, Україна ",
                            "rating": "0.00",
                            "reviewsCount": 0,
                            "fullPrice": 1550,
                            "minPrice": 1550,
                            "mealTypeId": 2,
                            "coordinates": {
                                "latitude": 48.9228518,
                                "longitude": 24.7096789
                            },
                            "distanceToPlaceFormatted": "в центрі",
                            "properties": [
                                91,
                                10,
                                102,
                                19,
                                27,
                                26,
                                38,
                                63,
                                101,
                                62,
                                95,
                                12,
                                60,
                                98,
                                42,
                                120,
                                29,
                                15
                            ],
                            "isFavorite": false
                        }}
                        meta={{
                            "parameters": {
                                "arrivalDate": "2023-02-16T00:00:00.000000Z",
                                "departureDate": "2023-02-16T00:00:00.000000Z",
                                "adults": "2",
                                "kids": [],
                                "orderBy": 1,
                                "rooms": "1"
                            },
                            "filters": {
                                "available": 0
                            },
                            "sortable": [
                                {
                                    "id": 4,
                                    "name": "Ціна (спочатку найвища)",
                                    "default": false
                                },
                                {
                                    "id": 5,
                                    "name": "Ціна (спочатку найнижча)",
                                    "default": false
                                },
                                {
                                    "id": 6,
                                    "name": "Відстань від центру",
                                    "default": true
                                }
                            ],
                            "filtersInitData": {
                                "price": {
                                    "title": "Ціна",
                                    "min": 0,
                                    "max": 10000,
                                    "ratio": 1.0957549999999998,
                                    "data": [
                                        {
                                            "start": 0,
                                            "end": 10,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 11,
                                            "end": 22,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 23,
                                            "end": 36,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 37,
                                            "end": 51,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 52,
                                            "end": 67,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 68,
                                            "end": 84,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 85,
                                            "end": 103,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 104,
                                            "end": 123,
                                            "quantity": 4
                                        },
                                        {
                                            "start": 124,
                                            "end": 145,
                                            "quantity": 4
                                        },
                                        {
                                            "start": 146,
                                            "end": 169,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 170,
                                            "end": 195,
                                            "quantity": 18
                                        },
                                        {
                                            "start": 196,
                                            "end": 224,
                                            "quantity": 27
                                        },
                                        {
                                            "start": 225,
                                            "end": 255,
                                            "quantity": 30
                                        },
                                        {
                                            "start": 256,
                                            "end": 289,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 290,
                                            "end": 326,
                                            "quantity": 17
                                        },
                                        {
                                            "start": 327,
                                            "end": 367,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 368,
                                            "end": 412,
                                            "quantity": 20
                                        },
                                        {
                                            "start": 413,
                                            "end": 461,
                                            "quantity": 17
                                        },
                                        {
                                            "start": 462,
                                            "end": 514,
                                            "quantity": 44
                                        },
                                        {
                                            "start": 515,
                                            "end": 572,
                                            "quantity": 45
                                        },
                                        {
                                            "start": 573,
                                            "end": 636,
                                            "quantity": 69
                                        },
                                        {
                                            "start": 637,
                                            "end": 706,
                                            "quantity": 125
                                        },
                                        {
                                            "start": 707,
                                            "end": 782,
                                            "quantity": 72
                                        },
                                        {
                                            "start": 783,
                                            "end": 865,
                                            "quantity": 132
                                        },
                                        {
                                            "start": 866,
                                            "end": 956,
                                            "quantity": 125
                                        },
                                        {
                                            "start": 957,
                                            "end": 1056,
                                            "quantity": 109
                                        },
                                        {
                                            "start": 1057,
                                            "end": 1165,
                                            "quantity": 73
                                        },
                                        {
                                            "start": 1166,
                                            "end": 1285,
                                            "quantity": 98
                                        },
                                        {
                                            "start": 1286,
                                            "end": 1416,
                                            "quantity": 93
                                        },
                                        {
                                            "start": 1417,
                                            "end": 1559,
                                            "quantity": 60
                                        },
                                        {
                                            "start": 1560,
                                            "end": 1716,
                                            "quantity": 65
                                        },
                                        {
                                            "start": 1717,
                                            "end": 1888,
                                            "quantity": 51
                                        },
                                        {
                                            "start": 1889,
                                            "end": 2076,
                                            "quantity": 56
                                        },
                                        {
                                            "start": 2077,
                                            "end": 2282,
                                            "quantity": 44
                                        },
                                        {
                                            "start": 2283,
                                            "end": 2508,
                                            "quantity": 34
                                        },
                                        {
                                            "start": 2509,
                                            "end": 2755,
                                            "quantity": 29
                                        },
                                        {
                                            "start": 2756,
                                            "end": 3025,
                                            "quantity": 38
                                        },
                                        {
                                            "start": 3026,
                                            "end": 3321,
                                            "quantity": 18
                                        },
                                        {
                                            "start": 3322,
                                            "end": 3645,
                                            "quantity": 23
                                        },
                                        {
                                            "start": 3646,
                                            "end": 4000,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 4001,
                                            "end": 4389,
                                            "quantity": 7
                                        },
                                        {
                                            "start": 4390,
                                            "end": 4815,
                                            "quantity": 10
                                        },
                                        {
                                            "start": 4816,
                                            "end": 5282,
                                            "quantity": 11
                                        },
                                        {
                                            "start": 5283,
                                            "end": 5794,
                                            "quantity": 7
                                        },
                                        {
                                            "start": 5795,
                                            "end": 6354,
                                            "quantity": 13
                                        },
                                        {
                                            "start": 6355,
                                            "end": 6968,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 6969,
                                            "end": 7641,
                                            "quantity": 8
                                        },
                                        {
                                            "start": 7642,
                                            "end": 8378,
                                            "quantity": 8
                                        },
                                        {
                                            "start": 8379,
                                            "end": 9185,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 9186,
                                            "end": 10000,
                                            "quantity": 6
                                        }
                                    ]
                                },
                                "rooms": {
                                    "title": "Номери та ліжка",
                                    "rooms": {
                                        "label": "Кімнати",
                                        "min": 1,
                                        "max": 9
                                    },
                                    "beds": {
                                        "label": "Ліжка",
                                        "min": 1,
                                        "max": 9
                                    },
                                    "bathrooms": {
                                        "label": "Санвузли",
                                        "min": 1,
                                        "max": 9
                                    }
                                },
                                "filterGroups": [
                                    {
                                        "title": "Обмеження",
                                        "alias": "restrictions",
                                        "data": [
                                            {
                                                "alias": "pets",
                                                "label": "Можна з тваринами"
                                            },
                                            {
                                                "alias": "smoke",
                                                "label": "Можна палити"
                                            }
                                        ]
                                    },
                                    {
                                        "title": "Тип помешкання",
                                        "alias": "accommodationTypes",
                                        "meta": "accommodationTypes",
                                        "data": [
                                            1,
                                            2,
                                            3,
                                            4,
                                            5,
                                            6,
                                            7,
                                            8,
                                            9,
                                            10,
                                            11,
                                            12,
                                            13,
                                            14,
                                            15,
                                            16,
                                            17,
                                            18,
                                            19,
                                            20,
                                            21,
                                            22,
                                            23,
                                            24,
                                            25,
                                            26,
                                            27,
                                            28,
                                            29,
                                            30,
                                            31,
                                            32,
                                            33,
                                            34
                                        ]
                                    },
                                    {
                                        "title": "Зручності в готелі",
                                        "alias": "hotelProperties",
                                        "meta": "properties",
                                        "data": [
                                            131,
                                            132,
                                            133,
                                            134,
                                            135,
                                            136,
                                            137,
                                            138,
                                            139,
                                            140,
                                            141,
                                            142,
                                            143,
                                            144,
                                            145,
                                            146,
                                            147,
                                            148,
                                            149,
                                            150,
                                            151,
                                            152,
                                            153,
                                            154,
                                            155,
                                            156,
                                            157,
                                            158,
                                            159,
                                            160,
                                            161,
                                            162,
                                            163,
                                            164,
                                            165,
                                            166,
                                            167,
                                            168,
                                            169,
                                            170,
                                            171,
                                            172,
                                            173,
                                            174,
                                            175,
                                            176,
                                            177,
                                            178,
                                            179,
                                            180,
                                            181,
                                            182,
                                            183,
                                            184,
                                            185,
                                            186,
                                            187,
                                            188,
                                            189,
                                            190,
                                            191,
                                            192,
                                            193,
                                            194,
                                            195,
                                            196,
                                            197,
                                            198,
                                            199,
                                            200,
                                            201,
                                            202,
                                            203,
                                            204,
                                            205,
                                            206,
                                            207,
                                            208,
                                            209,
                                            210,
                                            211,
                                            212,
                                            213,
                                            214,
                                            215,
                                            216,
                                            217,
                                            218,
                                            219,
                                            220,
                                            221,
                                            222,
                                            223,
                                            224,
                                            225,
                                            226,
                                            227,
                                            228,
                                            229,
                                            230,
                                            231,
                                            232,
                                            233,
                                            234,
                                            235,
                                            236,
                                            237,
                                            238,
                                            239,
                                            240,
                                            241,
                                            242,
                                            243,
                                            244,
                                            245,
                                            246,
                                            247,
                                            248,
                                            249,
                                            250,
                                            251,
                                            252,
                                            253,
                                            254,
                                            255,
                                            256,
                                            257,
                                            258,
                                            259,
                                            260,
                                            261,
                                            262,
                                            263,
                                            264,
                                            265,
                                            266,
                                            267,
                                            268,
                                            269,
                                            270,
                                            271,
                                            272,
                                            273,
                                            274,
                                            275
                                        ]
                                    },
                                    {
                                        "title": "Зручності в номерах",
                                        "alias": "roomProperties",
                                        "meta": "properties",
                                        "data": [
                                            1,
                                            2,
                                            3,
                                            4,
                                            5,
                                            6,
                                            7,
                                            8,
                                            9,
                                            10,
                                            11,
                                            12,
                                            13,
                                            14,
                                            15,
                                            16,
                                            17,
                                            18,
                                            19,
                                            20,
                                            21,
                                            22,
                                            23,
                                            24,
                                            25,
                                            26,
                                            27,
                                            28,
                                            29,
                                            30,
                                            31,
                                            32,
                                            33,
                                            34,
                                            35,
                                            36,
                                            37,
                                            38,
                                            39,
                                            40,
                                            41,
                                            42,
                                            43,
                                            44,
                                            45,
                                            46,
                                            47,
                                            48,
                                            49,
                                            50,
                                            51,
                                            52,
                                            53,
                                            54,
                                            55,
                                            56,
                                            57,
                                            58,
                                            59,
                                            60,
                                            61,
                                            62,
                                            63,
                                            64,
                                            65,
                                            66,
                                            67,
                                            68,
                                            69,
                                            70,
                                            71,
                                            72,
                                            73,
                                            74,
                                            75,
                                            76,
                                            77,
                                            78,
                                            79,
                                            80,
                                            81,
                                            82,
                                            83,
                                            84,
                                            85,
                                            86,
                                            87,
                                            88,
                                            89,
                                            90,
                                            91,
                                            92,
                                            93,
                                            94,
                                            95,
                                            96,
                                            97,
                                            98,
                                            99,
                                            100,
                                            101,
                                            102,
                                            103,
                                            104,
                                            105,
                                            106,
                                            107,
                                            108,
                                            109,
                                            110,
                                            111,
                                            112,
                                            113,
                                            114,
                                            115,
                                            116,
                                            117,
                                            118,
                                            119,
                                            120,
                                            121,
                                            122,
                                            123,
                                            124,
                                            125,
                                            126,
                                            127,
                                            128,
                                            129,
                                            130
                                        ]
                                    }
                                ]
                            },
                            "mealTypes": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Без харчування",
                                        "description": "Без харчування",
                                        "code": "RO"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Тільки сніданок",
                                        "description": "Сніданок включено",
                                        "code": "BB"
                                    },
                                    {
                                        "id": 3,
                                        "name": "Напівпансіон",
                                        "description": "Напівпансіон",
                                        "code": "HB"
                                    },
                                    {
                                        "id": 4,
                                        "name": "Повний пансіон",
                                        "description": "Повний пансіон",
                                        "code": "FB"
                                    },
                                    {
                                        "id": 5,
                                        "name": "Все включено",
                                        "description": "Все включено",
                                        "code": "AI"
                                    },
                                    {
                                        "id": 6,
                                        "name": "Ультра все включено",
                                        "description": "Ультра все включено",
                                        "code": "UAI"
                                    }
                                ]
                            },
                            "properties": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Басейн із підігрівом",
                                        "icon": null
                                    },
                                    {
                                        "id": 2,
                                        "name": "Басейн із навісом",
                                        "icon": null
                                    },
                                    {
                                        "id": 3,
                                        "name": "Басейн із видом",
                                        "icon": null
                                    },
                                    {
                                        "id": 4,
                                        "name": "Басейн на даху",
                                        "icon": null
                                    },
                                    {
                                        "id": 5,
                                        "name": "Рушники для басейну",
                                        "icon": null
                                    },
                                    {
                                        "id": 6,
                                        "name": "Басейн із солоною водою",
                                        "icon": null
                                    },
                                    {
                                        "id": 7,
                                        "name": "Приватний басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 8,
                                        "name": "Гідромасажна ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 9,
                                        "name": "Ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 10,
                                        "name": "Душ",
                                        "icon": null
                                    },
                                    {
                                        "id": 11,
                                        "name": "Ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 12,
                                        "name": "Фен",
                                        "icon": null
                                    },
                                    {
                                        "id": 13,
                                        "name": "Спільний туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 14,
                                        "name": "Сауна",
                                        "icon": null
                                    },
                                    {
                                        "id": 15,
                                        "name": "Туалетний папір",
                                        "icon": null
                                    },
                                    {
                                        "id": 16,
                                        "name": "Біде",
                                        "icon": null
                                    },
                                    {
                                        "id": 17,
                                        "name": "Туалетно-косметичні засоби",
                                        "icon": null
                                    },
                                    {
                                        "id": 18,
                                        "name": "Гостьовий туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 19,
                                        "name": "Туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 20,
                                        "name": "Додаткова ванна кімната",
                                        "icon": null
                                    },
                                    {
                                        "id": 21,
                                        "name": "Зубна щітка",
                                        "icon": null
                                    },
                                    {
                                        "id": 22,
                                        "name": "Шампунь",
                                        "icon": null
                                    },
                                    {
                                        "id": 23,
                                        "name": "Кондиціонер для волосся",
                                        "icon": null
                                    },
                                    {
                                        "id": 24,
                                        "name": "Мило для тіла",
                                        "icon": null
                                    },
                                    {
                                        "id": 25,
                                        "name": "Шапочка для душу",
                                        "icon": null
                                    },
                                    {
                                        "id": 26,
                                        "name": "Білизна",
                                        "icon": null
                                    },
                                    {
                                        "id": 27,
                                        "name": "Рушники",
                                        "icon": null
                                    },
                                    {
                                        "id": 28,
                                        "name": "Дитячі ліжечка",
                                        "icon": null
                                    },
                                    {
                                        "id": 29,
                                        "name": "Вішалка для одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 30,
                                        "name": "Сушарка для одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 31,
                                        "name": "Розкладне ліжко",
                                        "icon": null
                                    },
                                    {
                                        "id": 32,
                                        "name": "Диван-ліжко",
                                        "icon": null
                                    },
                                    {
                                        "id": 33,
                                        "name": "Кошики для сміття",
                                        "icon": null
                                    },
                                    {
                                        "id": 34,
                                        "name": "Гардеробна",
                                        "icon": null
                                    },
                                    {
                                        "id": 35,
                                        "name": "Подовжене ліжко (>2 метрів)",
                                        "icon": null
                                    },
                                    {
                                        "id": 36,
                                        "name": "Диван",
                                        "icon": null
                                    },
                                    {
                                        "id": 37,
                                        "name": "Вітальня/м'який куток",
                                        "icon": null
                                    },
                                    {
                                        "id": 38,
                                        "name": "Робочий стіл",
                                        "icon": null
                                    },
                                    {
                                        "id": 39,
                                        "name": "Пухова подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 40,
                                        "name": "Синтетична подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 41,
                                        "name": "Гіпоалергенна подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 42,
                                        "name": "Шафа або гардероб",
                                        "icon": null
                                    },
                                    {
                                        "id": 43,
                                        "name": "Високий стілець для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 44,
                                        "name": "Піжама",
                                        "icon": null
                                    },
                                    {
                                        "id": 45,
                                        "name": "Електроковдра",
                                        "icon": null
                                    },
                                    {
                                        "id": 46,
                                        "name": "Москітна сітка",
                                        "icon": null
                                    },
                                    {
                                        "id": 47,
                                        "name": "Окремий вхід",
                                        "icon": null
                                    },
                                    {
                                        "id": 48,
                                        "name": "Сейф",
                                        "icon": null
                                    },
                                    {
                                        "id": 49,
                                        "name": "Датчик чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 50,
                                        "name": "Датчик диму",
                                        "icon": null
                                    },
                                    {
                                        "id": 51,
                                        "name": "Очищувачі повітря",
                                        "icon": null
                                    },
                                    {
                                        "id": 52,
                                        "name": "Вогнегасник",
                                        "icon": null
                                    },
                                    {
                                        "id": 53,
                                        "name": "Захисні кришки на розетках для безпеки дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 54,
                                        "name": "Ігрова приставка PS4",
                                        "icon": null
                                    },
                                    {
                                        "id": 55,
                                        "name": "Ігрова приставка Xbox One",
                                        "icon": null
                                    },
                                    {
                                        "id": 56,
                                        "name": "Комп'ютер",
                                        "icon": null
                                    },
                                    {
                                        "id": 57,
                                        "name": "Ігрова приставка",
                                        "icon": null
                                    },
                                    {
                                        "id": 58,
                                        "name": "Ноутбук",
                                        "icon": null
                                    },
                                    {
                                        "id": 59,
                                        "name": "iPad",
                                        "icon": null
                                    },
                                    {
                                        "id": 60,
                                        "name": "Кабельні канали",
                                        "icon": null
                                    },
                                    {
                                        "id": 61,
                                        "name": "Радіо",
                                        "icon": null
                                    },
                                    {
                                        "id": 62,
                                        "name": "Телефон",
                                        "icon": null
                                    },
                                    {
                                        "id": 63,
                                        "name": "Телевізор",
                                        "icon": null
                                    },
                                    {
                                        "id": 64,
                                        "name": "Портативна точка доступу в інтернет",
                                        "icon": null
                                    },
                                    {
                                        "id": 65,
                                        "name": "Потоковий сервіс (наприклад, Netflix)",
                                        "icon": null
                                    },
                                    {
                                        "id": 66,
                                        "name": "Настільні ігри/пазли",
                                        "icon": null
                                    },
                                    {
                                        "id": 67,
                                        "name": "Дитячі книги, музика чи фільми",
                                        "icon": null
                                    },
                                    {
                                        "id": 68,
                                        "name": "Обідня зона",
                                        "icon": null
                                    },
                                    {
                                        "id": 69,
                                        "name": "Обідній стіл",
                                        "icon": null
                                    },
                                    {
                                        "id": 70,
                                        "name": "Келихи для вина",
                                        "icon": null
                                    },
                                    {
                                        "id": 71,
                                        "name": "Пляшка води",
                                        "icon": null
                                    },
                                    {
                                        "id": 72,
                                        "name": "Шоколад або печиво",
                                        "icon": null
                                    },
                                    {
                                        "id": 73,
                                        "name": "Фрукти",
                                        "icon": null
                                    },
                                    {
                                        "id": 74,
                                        "name": "Вино або шампанське",
                                        "icon": null
                                    },
                                    {
                                        "id": 75,
                                        "name": "Барбекю",
                                        "icon": null
                                    },
                                    {
                                        "id": 76,
                                        "name": "Духовка",
                                        "icon": null
                                    },
                                    {
                                        "id": 77,
                                        "name": "Плита",
                                        "icon": null
                                    },
                                    {
                                        "id": 78,
                                        "name": "Тостер",
                                        "icon": null
                                    },
                                    {
                                        "id": 79,
                                        "name": "Посудомийна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 80,
                                        "name": "Електрочайник",
                                        "icon": null
                                    },
                                    {
                                        "id": 81,
                                        "name": "Обідня зона на відкритому повітрі",
                                        "icon": null
                                    },
                                    {
                                        "id": 82,
                                        "name": "Вуличні меблі",
                                        "icon": null
                                    },
                                    {
                                        "id": 83,
                                        "name": "Міні-бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 84,
                                        "name": "Кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 85,
                                        "name": "Міні-кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 86,
                                        "name": "Кухонний посуд",
                                        "icon": null
                                    },
                                    {
                                        "id": 87,
                                        "name": "Мікрохвильова піч",
                                        "icon": null
                                    },
                                    {
                                        "id": 88,
                                        "name": "Холодильник",
                                        "icon": null
                                    },
                                    {
                                        "id": 89,
                                        "name": "Чайник/кавоварка",
                                        "icon": null
                                    },
                                    {
                                        "id": 90,
                                        "name": "Кавоварка",
                                        "icon": null
                                    },
                                    {
                                        "id": 91,
                                        "name": "Кондиціонер",
                                        "icon": null
                                    },
                                    {
                                        "id": 92,
                                        "name": "Сушильна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 93,
                                        "name": "Вентилятор",
                                        "icon": null
                                    },
                                    {
                                        "id": 94,
                                        "name": "Камін",
                                        "icon": null
                                    },
                                    {
                                        "id": 95,
                                        "name": "Опалення",
                                        "icon": null
                                    },
                                    {
                                        "id": 96,
                                        "name": "Доступні суміжні номери",
                                        "icon": null
                                    },
                                    {
                                        "id": 97,
                                        "name": "Праска",
                                        "icon": null
                                    },
                                    {
                                        "id": 98,
                                        "name": "Послуга дзвінок-\"будильник\"",
                                        "icon": null
                                    },
                                    {
                                        "id": 99,
                                        "name": "Доступ до представницького лаунжу",
                                        "icon": null
                                    },
                                    {
                                        "id": 100,
                                        "name": "Вхід з електронною карткою",
                                        "icon": null
                                    },
                                    {
                                        "id": 101,
                                        "name": "Капці",
                                        "icon": null
                                    },
                                    {
                                        "id": 102,
                                        "name": "Халат",
                                        "icon": null
                                    },
                                    {
                                        "id": 103,
                                        "name": "Пральна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 104,
                                        "name": "Засоби для чищення",
                                        "icon": null
                                    },
                                    {
                                        "id": 105,
                                        "name": "Балкон",
                                        "icon": null
                                    },
                                    {
                                        "id": 106,
                                        "name": "Тераса",
                                        "icon": null
                                    },
                                    {
                                        "id": 107,
                                        "name": "Вид на місто",
                                        "icon": null
                                    },
                                    {
                                        "id": 108,
                                        "name": "Вид на сад",
                                        "icon": null
                                    },
                                    {
                                        "id": 109,
                                        "name": "Вид на озеро",
                                        "icon": null
                                    },
                                    {
                                        "id": 110,
                                        "name": "Вид на пам'ятку",
                                        "icon": null
                                    },
                                    {
                                        "id": 111,
                                        "name": "Вид на гори",
                                        "icon": null
                                    },
                                    {
                                        "id": 112,
                                        "name": "Вид на басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 113,
                                        "name": "Вид на річку",
                                        "icon": null
                                    },
                                    {
                                        "id": 114,
                                        "name": "Вид на море",
                                        "icon": null
                                    },
                                    {
                                        "id": 115,
                                        "name": "Вид на внутрішній двір",
                                        "icon": null
                                    },
                                    {
                                        "id": 116,
                                        "name": "Вид на тиху вулицю",
                                        "icon": null
                                    },
                                    {
                                        "id": 117,
                                        "name": "Приміщення повністю знаходиться на першому поверсі",
                                        "icon": null
                                    },
                                    {
                                        "id": 118,
                                        "name": "Приміщення придатне для гостей в інвалідних візках",
                                        "icon": null
                                    },
                                    {
                                        "id": 119,
                                        "name": "Зручності для гостей із вадами слуху",
                                        "icon": null
                                    },
                                    {
                                        "id": 120,
                                        "name": "На вищі поверхи можна піднятися на ліфті",
                                        "icon": null
                                    },
                                    {
                                        "id": 121,
                                        "name": "На верхні поверхи можна піднятися тільки сходами",
                                        "icon": null
                                    },
                                    {
                                        "id": 122,
                                        "name": "Ванна з поручнями",
                                        "icon": null
                                    },
                                    {
                                        "id": 123,
                                        "name": "Аварійний шнур у ванній кімнаті",
                                        "icon": null
                                    },
                                    {
                                        "id": 124,
                                        "name": "Високий унітаз",
                                        "icon": null
                                    },
                                    {
                                        "id": 125,
                                        "name": "Низький умивальник",
                                        "icon": null
                                    },
                                    {
                                        "id": 126,
                                        "name": "Безбар'єрна душова",
                                        "icon": null
                                    },
                                    {
                                        "id": 127,
                                        "name": "Стілець для душа",
                                        "icon": null
                                    },
                                    {
                                        "id": 128,
                                        "name": "Туалет з поручнями",
                                        "icon": null
                                    },
                                    {
                                        "id": 129,
                                        "name": "Душ без піддону",
                                        "icon": null
                                    },
                                    {
                                        "id": 130,
                                        "name": "Захисні бар'єри для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 131,
                                        "name": "Дитяче меню",
                                        "icon": null
                                    },
                                    {
                                        "id": 132,
                                        "name": "Шведський стіл з вибором страв для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 133,
                                        "name": "Вино/шампанськеУ помешканні подають вино або шампанське",
                                        "icon": null
                                    },
                                    {
                                        "id": 134,
                                        "name": "ФруктиУ номерах є фрукти",
                                        "icon": null
                                    },
                                    {
                                        "id": 135,
                                        "name": "Кав'ярня (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 136,
                                        "name": "Снек-бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 137,
                                        "name": "Доставка продуктів",
                                        "icon": null
                                    },
                                    {
                                        "id": 138,
                                        "name": "Запаковані ланчі",
                                        "icon": null
                                    },
                                    {
                                        "id": 139,
                                        "name": "Барбекю",
                                        "icon": null
                                    },
                                    {
                                        "id": 140,
                                        "name": "Автомат з напоями",
                                        "icon": null
                                    },
                                    {
                                        "id": 141,
                                        "name": "Автомат із закусками",
                                        "icon": null
                                    },
                                    {
                                        "id": 142,
                                        "name": "Спеціальне дієтичне меню (за запитом)",
                                        "icon": null
                                    },
                                    {
                                        "id": 143,
                                        "name": "Сніданок у номері",
                                        "icon": null
                                    },
                                    {
                                        "id": 144,
                                        "name": "Спільна кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 145,
                                        "name": "Ресторан",
                                        "icon": null
                                    },
                                    {
                                        "id": 146,
                                        "name": "Обслуговування номерів",
                                        "icon": null
                                    },
                                    {
                                        "id": 147,
                                        "name": "Бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 148,
                                        "name": "Водна гірка",
                                        "icon": null
                                    },
                                    {
                                        "id": 149,
                                        "name": "Пляжне крісло/шезлонг",
                                        "icon": null
                                    },
                                    {
                                        "id": 150,
                                        "name": "Сонячна парасоля",
                                        "icon": null
                                    },
                                    {
                                        "id": 151,
                                        "name": "Косметичні послуги",
                                        "icon": null
                                    },
                                    {
                                        "id": 152,
                                        "name": "Спа-процедури",
                                        "icon": null
                                    },
                                    {
                                        "id": 153,
                                        "name": "Парова лазня",
                                        "icon": null
                                    },
                                    {
                                        "id": 154,
                                        "name": "Спа-лаунж/зона для релаксації",
                                        "icon": null
                                    },
                                    {
                                        "id": 155,
                                        "name": "Ванночка для ніг",
                                        "icon": null
                                    },
                                    {
                                        "id": 156,
                                        "name": "Пакети послуг спа / велнес",
                                        "icon": null
                                    },
                                    {
                                        "id": 157,
                                        "name": "Масажне крісло",
                                        "icon": null
                                    },
                                    {
                                        "id": 158,
                                        "name": "Фітнес",
                                        "icon": null
                                    },
                                    {
                                        "id": 159,
                                        "name": "Заняття йогою",
                                        "icon": null
                                    },
                                    {
                                        "id": 160,
                                        "name": "Заняття фітнесом",
                                        "icon": null
                                    },
                                    {
                                        "id": 161,
                                        "name": "Особистий тренер",
                                        "icon": null
                                    },
                                    {
                                        "id": 162,
                                        "name": "Роздягальня з шафками",
                                        "icon": null
                                    },
                                    {
                                        "id": 163,
                                        "name": "Дитячий басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 164,
                                        "name": "Хамам/турецька лазня",
                                        "icon": null
                                    },
                                    {
                                        "id": 165,
                                        "name": "Солярій",
                                        "icon": null
                                    },
                                    {
                                        "id": 166,
                                        "name": "Гарячі джерела",
                                        "icon": null
                                    },
                                    {
                                        "id": 167,
                                        "name": "Масаж",
                                        "icon": null
                                    },
                                    {
                                        "id": 168,
                                        "name": "Лазні на відкритому повітрі",
                                        "icon": null
                                    },
                                    {
                                        "id": 169,
                                        "name": "Громадські лазні",
                                        "icon": null
                                    },
                                    {
                                        "id": 170,
                                        "name": "Сауна",
                                        "icon": null
                                    },
                                    {
                                        "id": 171,
                                        "name": "Фітнес-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 172,
                                        "name": "Оздоровчий спа-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 173,
                                        "name": "Гідромасажна ванна/джакузі",
                                        "icon": null
                                    },
                                    {
                                        "id": 174,
                                        "name": "Басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 175,
                                        "name": "Аквапарк",
                                        "icon": null
                                    },
                                    {
                                        "id": 176,
                                        "name": "Квитки на громадський транспорт",
                                        "icon": null
                                    },
                                    {
                                        "id": 177,
                                        "name": "Трансфер до помешкання",
                                        "icon": null
                                    },
                                    {
                                        "id": 178,
                                        "name": "Прокат автомобілів",
                                        "icon": null
                                    },
                                    {
                                        "id": 179,
                                        "name": "Чи є парковка для гостей?",
                                        "icon": null
                                    },
                                    {
                                        "id": 180,
                                        "name": "Трансфер з/до аеропорту",
                                        "icon": null
                                    },
                                    {
                                        "id": 181,
                                        "name": "Спорядження для тенісу",
                                        "icon": null
                                    },
                                    {
                                        "id": 182,
                                        "name": "Спорядження для бадмінтону",
                                        "icon": null
                                    },
                                    {
                                        "id": 183,
                                        "name": "Пляж",
                                        "icon": null
                                    },
                                    {
                                        "id": 184,
                                        "name": "Тимчасові виставки мистецтва",
                                        "icon": null
                                    },
                                    {
                                        "id": 185,
                                        "name": "Похід барами (пабкрол)",
                                        "icon": null
                                    },
                                    {
                                        "id": 186,
                                        "name": "Стендап-комедія",
                                        "icon": null
                                    },
                                    {
                                        "id": 187,
                                        "name": "Вечори перегляду фільмів",
                                        "icon": null
                                    },
                                    {
                                        "id": 188,
                                        "name": "Піші екскурсії",
                                        "icon": null
                                    },
                                    {
                                        "id": 189,
                                        "name": "Велосипедні екскурсії",
                                        "icon": null
                                    },
                                    {
                                        "id": 190,
                                        "name": "Тематичні вечері",
                                        "icon": null
                                    },
                                    {
                                        "id": 191,
                                        "name": "Щаслива година",
                                        "icon": null
                                    },
                                    {
                                        "id": 192,
                                        "name": "Екскурсія або урок про місцеву культуру",
                                        "icon": null
                                    },
                                    {
                                        "id": 193,
                                        "name": "Урок готування",
                                        "icon": null
                                    },
                                    {
                                        "id": 194,
                                        "name": "Жива музика/виступ",
                                        "icon": null
                                    },
                                    {
                                        "id": 195,
                                        "name": "Пряма трансляція спортивних ігор",
                                        "icon": null
                                    },
                                    {
                                        "id": 196,
                                        "name": "Стрільба з лука",
                                        "icon": null
                                    },
                                    {
                                        "id": 197,
                                        "name": "Аеробіка",
                                        "icon": null
                                    },
                                    {
                                        "id": 198,
                                        "name": "Бінго",
                                        "icon": null
                                    },
                                    {
                                        "id": 199,
                                        "name": "Тенісний корт",
                                        "icon": null
                                    },
                                    {
                                        "id": 200,
                                        "name": "Більярд",
                                        "icon": null
                                    },
                                    {
                                        "id": 201,
                                        "name": "Настільний теніс",
                                        "icon": null
                                    },
                                    {
                                        "id": 202,
                                        "name": "Дартс",
                                        "icon": null
                                    },
                                    {
                                        "id": 203,
                                        "name": "Сквош",
                                        "icon": null
                                    },
                                    {
                                        "id": 204,
                                        "name": "Боулінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 205,
                                        "name": "Міні-гольф",
                                        "icon": null
                                    },
                                    {
                                        "id": 206,
                                        "name": "Поле для гольфу (в радіусі 3 км)",
                                        "icon": null
                                    },
                                    {
                                        "id": 207,
                                        "name": "Зручності для занять водними видами спорту (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 208,
                                        "name": "Віндсерфінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 209,
                                        "name": "Дайвінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 210,
                                        "name": "Плавання під водою з маскою, трубкою і ластами",
                                        "icon": null
                                    },
                                    {
                                        "id": 211,
                                        "name": "Каноє",
                                        "icon": null
                                    },
                                    {
                                        "id": 212,
                                        "name": "Риболовля",
                                        "icon": null
                                    },
                                    {
                                        "id": 213,
                                        "name": "Верхова їзда",
                                        "icon": null
                                    },
                                    {
                                        "id": 214,
                                        "name": "Велоспорт",
                                        "icon": null
                                    },
                                    {
                                        "id": 215,
                                        "name": "Піші прогулянки",
                                        "icon": null
                                    },
                                    {
                                        "id": 216,
                                        "name": "Катання на лижах",
                                        "icon": null
                                    },
                                    {
                                        "id": 217,
                                        "name": "Садові меблі",
                                        "icon": null
                                    },
                                    {
                                        "id": 218,
                                        "name": "Місце для пікніка",
                                        "icon": null
                                    },
                                    {
                                        "id": 219,
                                        "name": "Камін",
                                        "icon": null
                                    },
                                    {
                                        "id": 220,
                                        "name": "Камін вуличний",
                                        "icon": null
                                    },
                                    {
                                        "id": 221,
                                        "name": "Тераса для засмаги",
                                        "icon": null
                                    },
                                    {
                                        "id": 222,
                                        "name": "Спільна кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 223,
                                        "name": "Спільна зона відпочинку з телевізором",
                                        "icon": null
                                    },
                                    {
                                        "id": 224,
                                        "name": "Ігрова кімната",
                                        "icon": null
                                    },
                                    {
                                        "id": 225,
                                        "name": "Церква/храм",
                                        "icon": null
                                    },
                                    {
                                        "id": 226,
                                        "name": "Сад",
                                        "icon": null
                                    },
                                    {
                                        "id": 227,
                                        "name": "Тераса",
                                        "icon": null
                                    },
                                    {
                                        "id": 228,
                                        "name": "Дитячий клуб",
                                        "icon": null
                                    },
                                    {
                                        "id": 229,
                                        "name": "Настільні ігри / пазли",
                                        "icon": null
                                    },
                                    {
                                        "id": 230,
                                        "name": "Ігровий майданчик в приміщенні",
                                        "icon": null
                                    },
                                    {
                                        "id": 231,
                                        "name": "Устаткування для ігор на вулиці",
                                        "icon": null
                                    },
                                    {
                                        "id": 232,
                                        "name": "Захисні бар'єри для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 233,
                                        "name": "Дитячі візки",
                                        "icon": null
                                    },
                                    {
                                        "id": 234,
                                        "name": "Вечірня розважальна програма",
                                        "icon": null
                                    },
                                    {
                                        "id": 235,
                                        "name": "Нічний клуб/ді-джей",
                                        "icon": null
                                    },
                                    {
                                        "id": 236,
                                        "name": "Казино",
                                        "icon": null
                                    },
                                    {
                                        "id": 237,
                                        "name": "Караоке",
                                        "icon": null
                                    },
                                    {
                                        "id": 238,
                                        "name": "Аніматори",
                                        "icon": null
                                    },
                                    {
                                        "id": 239,
                                        "name": "Дитячий майданчик",
                                        "icon": null
                                    },
                                    {
                                        "id": 240,
                                        "name": "Няня/Послуги по догляду за дітьми",
                                        "icon": null
                                    },
                                    {
                                        "id": 241,
                                        "name": "Хімчистка",
                                        "icon": null
                                    },
                                    {
                                        "id": 242,
                                        "name": "Прасування одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 243,
                                        "name": "Пральня",
                                        "icon": null
                                    },
                                    {
                                        "id": 244,
                                        "name": "Щоденне прибирання",
                                        "icon": null
                                    },
                                    {
                                        "id": 245,
                                        "name": "Прес для штанів",
                                        "icon": null
                                    },
                                    {
                                        "id": 246,
                                        "name": "Конференц-зал/бенкетний зал",
                                        "icon": null
                                    },
                                    {
                                        "id": 247,
                                        "name": "Бізнес-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 248,
                                        "name": "Міні-маркет (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 249,
                                        "name": "Перукарня/Салон краси",
                                        "icon": null
                                    },
                                    {
                                        "id": 250,
                                        "name": "Кошик для домашніх тварин",
                                        "icon": null
                                    },
                                    {
                                        "id": 251,
                                        "name": "Миски для домашніх тварин",
                                        "icon": null
                                    },
                                    {
                                        "id": 252,
                                        "name": "Вхід з ключем",
                                        "icon": null
                                    },
                                    {
                                        "id": 253,
                                        "name": "Вхід з електронною карткою",
                                        "icon": null
                                    },
                                    {
                                        "id": 254,
                                        "name": "Лише для дорослих",
                                        "icon": null
                                    },
                                    {
                                        "id": 255,
                                        "name": "Спеціальні номери для алергіків",
                                        "icon": null
                                    },
                                    {
                                        "id": 256,
                                        "name": "На всій території готельного об'єкта і в номерах заборонено курити",
                                        "icon": null
                                    },
                                    {
                                        "id": 257,
                                        "name": "Місця для куріння",
                                        "icon": null
                                    },
                                    {
                                        "id": 258,
                                        "name": "Номери/зручності для осіб з обмеженими фізичними можливостями",
                                        "icon": null
                                    },
                                    {
                                        "id": 259,
                                        "name": "Ліфт",
                                        "icon": null
                                    },
                                    {
                                        "id": 260,
                                        "name": "Звуконепроникні номери",
                                        "icon": null
                                    },
                                    {
                                        "id": 261,
                                        "name": "Опалення",
                                        "icon": null
                                    },
                                    {
                                        "id": 262,
                                        "name": "Шафки, що замикаються",
                                        "icon": null
                                    },
                                    {
                                        "id": 263,
                                        "name": "Камера зберігання багажу",
                                        "icon": null
                                    },
                                    {
                                        "id": 264,
                                        "name": "Цілодобова стійка реєстрації гостей",
                                        "icon": null
                                    },
                                    {
                                        "id": 265,
                                        "name": "Номери для некурців",
                                        "icon": null
                                    },
                                    {
                                        "id": 266,
                                        "name": "Кондиціонер",
                                        "icon": null
                                    },
                                    {
                                        "id": 267,
                                        "name": "Цілодобова охорона",
                                        "icon": null
                                    },
                                    {
                                        "id": 268,
                                        "name": "Охоронна сигналізація",
                                        "icon": null
                                    },
                                    {
                                        "id": 269,
                                        "name": "Датчики диму",
                                        "icon": null
                                    },
                                    {
                                        "id": 270,
                                        "name": "Відеоспостереження в зонах загального користування",
                                        "icon": null
                                    },
                                    {
                                        "id": 271,
                                        "name": "Відеоспостереження ззовні будівлі",
                                        "icon": null
                                    },
                                    {
                                        "id": 272,
                                        "name": "Вогнегасники",
                                        "icon": null
                                    },
                                    {
                                        "id": 273,
                                        "name": "Датчик чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 274,
                                        "name": "Джерела чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 275,
                                        "name": "Сейф",
                                        "icon": null
                                    }
                                ]
                            },
                            "requestCoordinates": {
                                "longitude": "24.7111170",
                                "latitude": "48.9226330"
                            },
                            "accommodationTypes": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Готель"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Апартаменти"
                                    },
                                    {
                                        "id": 3,
                                        "name": "Хостел"
                                    },
                                    {
                                        "id": 4,
                                        "name": "Вілла"
                                    },
                                    {
                                        "id": 5,
                                        "name": "Шале"
                                    },
                                    {
                                        "id": 6,
                                        "name": "Помешкання"
                                    },
                                    {
                                        "id": 7,
                                        "name": "Мотель"
                                    },
                                    {
                                        "id": 8,
                                        "name": "Курортний готель"
                                    },
                                    {
                                        "id": 9,
                                        "name": "Особняк"
                                    },
                                    {
                                        "id": 10,
                                        "name": "Готель типу «ліжко і сніданок»"
                                    },
                                    {
                                        "id": 11,
                                        "name": "Рьокан"
                                    },
                                    {
                                        "id": 12,
                                        "name": "Фермерський будинок"
                                    },
                                    {
                                        "id": 13,
                                        "name": "Парк-готель"
                                    },
                                    {
                                        "id": 14,
                                        "name": "Кемпінг"
                                    },
                                    {
                                        "id": 15,
                                        "name": "Ботель"
                                    },
                                    {
                                        "id": 16,
                                        "name": "Гостьовий будинок"
                                    },
                                    {
                                        "id": 17,
                                        "name": "Міні-готель з рестораном"
                                    },
                                    {
                                        "id": 18,
                                        "name": "Апарт-готель"
                                    },
                                    {
                                        "id": 19,
                                        "name": "Будинок для відпочинку"
                                    },
                                    {
                                        "id": 20,
                                        "name": "Лодж"
                                    },
                                    {
                                        "id": 21,
                                        "name": "Розміщення в сім'ї"
                                    },
                                    {
                                        "id": 22,
                                        "name": "Заміський будинок"
                                    },
                                    {
                                        "id": 23,
                                        "name": "Намет-люкс"
                                    },
                                    {
                                        "id": 24,
                                        "name": "Капсульний готель"
                                    },
                                    {
                                        "id": 25,
                                        "name": "Готель для побачень"
                                    },
                                    {
                                        "id": 26,
                                        "name": "Ріад"
                                    },
                                    {
                                        "id": 27,
                                        "name": "Кондомініум"
                                    },
                                    {
                                        "id": 28,
                                        "name": "Котедж"
                                    },
                                    {
                                        "id": 29,
                                        "name": "Готель економ-класу"
                                    },
                                    {
                                        "id": 30,
                                        "name": "Французький котедж"
                                    },
                                    {
                                        "id": 31,
                                        "name": "Санаторій"
                                    },
                                    {
                                        "id": 32,
                                        "name": "Круїз"
                                    },
                                    {
                                        "id": 33,
                                        "name": "Гуртожиток"
                                    },
                                    {
                                        "id": 34,
                                        "name": "Вілли"
                                    }
                                ]
                            },
                            "pagination": {
                                "total": 0,
                                "count": 38,
                                "per_page": 500,
                                "current_page": 1,
                                "total_pages": 1,
                                "links": []
                            }
                        }}
                        link={'/'}
                        nights={2}
                    />

                    <HotelMapListItem
                        hotel={{
                            "id": 25,
                            "selected": false,
                            "name": "Atrium Hotel",
                            "alias": "25-atrium-hotel",
                            "stars": 0,
                            preparedProperties: [],
                            "photo": {
                                "id": "253c6637-f9f5-425d-b44c-492d76b8975b",
                                "order": 0,
                                "active": true,
                                "source": {
                                    "original": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_original.jpg",
                                    "302x170": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_302x170.jpg",
                                    "302x170@2x": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_302x170@2x.jpg",
                                    "560x560": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_560x560.jpg",
                                    "560x560@2x": "https://api.bronui.com/storage/hotels/ce/b6/ceb6dab2-685c-4e31-b64f-5801b96291e8/photos/253c6637-f9f5-425d-b44c-492d76b8975b/253c6637-f9f5-425d-b44c-492d76b8975b_560x560@2x.jpg"
                                }
                            },
                            "address": " Galytska Vulitsa 31, Івано-Франківськ, 76018, Україна ",
                            "rating": "0.00",
                            "reviewsCount": 0,
                            "fullPrice": 1550,
                            "minPrice": 1550,
                            "mealTypeId": 2,
                            "coordinates": {
                                "latitude": 48.9228518,
                                "longitude": 24.7096789
                            },
                            "distanceToPlaceFormatted": "в центрі",
                            "properties": [
                                91,
                                10,
                                102,
                                19,
                                27,
                                26,
                                38,
                                63,
                                101,
                                62,
                                95,
                                12,
                                60,
                                98,
                                42,
                                120,
                                29,
                                15
                            ],
                            "isFavorite": false
                        }}
                        meta={{
                            "parameters": {
                                "arrivalDate": "2023-02-16T00:00:00.000000Z",
                                "departureDate": "2023-02-16T00:00:00.000000Z",
                                "adults": "2",
                                "kids": [],
                                "orderBy": 1,
                                "rooms": "1"
                            },
                            "filters": {
                                "available": 0
                            },
                            "sortable": [
                                {
                                    "id": 4,
                                    "name": "Ціна (спочатку найвища)",
                                    "default": false
                                },
                                {
                                    "id": 5,
                                    "name": "Ціна (спочатку найнижча)",
                                    "default": false
                                },
                                {
                                    "id": 6,
                                    "name": "Відстань від центру",
                                    "default": true
                                }
                            ],
                            "filtersInitData": {
                                "price": {
                                    "title": "Ціна",
                                    "min": 0,
                                    "max": 10000,
                                    "ratio": 1.0957549999999998,
                                    "data": [
                                        {
                                            "start": 0,
                                            "end": 10,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 11,
                                            "end": 22,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 23,
                                            "end": 36,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 37,
                                            "end": 51,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 52,
                                            "end": 67,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 68,
                                            "end": 84,
                                            "quantity": 0
                                        },
                                        {
                                            "start": 85,
                                            "end": 103,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 104,
                                            "end": 123,
                                            "quantity": 4
                                        },
                                        {
                                            "start": 124,
                                            "end": 145,
                                            "quantity": 4
                                        },
                                        {
                                            "start": 146,
                                            "end": 169,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 170,
                                            "end": 195,
                                            "quantity": 18
                                        },
                                        {
                                            "start": 196,
                                            "end": 224,
                                            "quantity": 27
                                        },
                                        {
                                            "start": 225,
                                            "end": 255,
                                            "quantity": 30
                                        },
                                        {
                                            "start": 256,
                                            "end": 289,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 290,
                                            "end": 326,
                                            "quantity": 17
                                        },
                                        {
                                            "start": 327,
                                            "end": 367,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 368,
                                            "end": 412,
                                            "quantity": 20
                                        },
                                        {
                                            "start": 413,
                                            "end": 461,
                                            "quantity": 17
                                        },
                                        {
                                            "start": 462,
                                            "end": 514,
                                            "quantity": 44
                                        },
                                        {
                                            "start": 515,
                                            "end": 572,
                                            "quantity": 45
                                        },
                                        {
                                            "start": 573,
                                            "end": 636,
                                            "quantity": 69
                                        },
                                        {
                                            "start": 637,
                                            "end": 706,
                                            "quantity": 125
                                        },
                                        {
                                            "start": 707,
                                            "end": 782,
                                            "quantity": 72
                                        },
                                        {
                                            "start": 783,
                                            "end": 865,
                                            "quantity": 132
                                        },
                                        {
                                            "start": 866,
                                            "end": 956,
                                            "quantity": 125
                                        },
                                        {
                                            "start": 957,
                                            "end": 1056,
                                            "quantity": 109
                                        },
                                        {
                                            "start": 1057,
                                            "end": 1165,
                                            "quantity": 73
                                        },
                                        {
                                            "start": 1166,
                                            "end": 1285,
                                            "quantity": 98
                                        },
                                        {
                                            "start": 1286,
                                            "end": 1416,
                                            "quantity": 93
                                        },
                                        {
                                            "start": 1417,
                                            "end": 1559,
                                            "quantity": 60
                                        },
                                        {
                                            "start": 1560,
                                            "end": 1716,
                                            "quantity": 65
                                        },
                                        {
                                            "start": 1717,
                                            "end": 1888,
                                            "quantity": 51
                                        },
                                        {
                                            "start": 1889,
                                            "end": 2076,
                                            "quantity": 56
                                        },
                                        {
                                            "start": 2077,
                                            "end": 2282,
                                            "quantity": 44
                                        },
                                        {
                                            "start": 2283,
                                            "end": 2508,
                                            "quantity": 34
                                        },
                                        {
                                            "start": 2509,
                                            "end": 2755,
                                            "quantity": 29
                                        },
                                        {
                                            "start": 2756,
                                            "end": 3025,
                                            "quantity": 38
                                        },
                                        {
                                            "start": 3026,
                                            "end": 3321,
                                            "quantity": 18
                                        },
                                        {
                                            "start": 3322,
                                            "end": 3645,
                                            "quantity": 23
                                        },
                                        {
                                            "start": 3646,
                                            "end": 4000,
                                            "quantity": 14
                                        },
                                        {
                                            "start": 4001,
                                            "end": 4389,
                                            "quantity": 7
                                        },
                                        {
                                            "start": 4390,
                                            "end": 4815,
                                            "quantity": 10
                                        },
                                        {
                                            "start": 4816,
                                            "end": 5282,
                                            "quantity": 11
                                        },
                                        {
                                            "start": 5283,
                                            "end": 5794,
                                            "quantity": 7
                                        },
                                        {
                                            "start": 5795,
                                            "end": 6354,
                                            "quantity": 13
                                        },
                                        {
                                            "start": 6355,
                                            "end": 6968,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 6969,
                                            "end": 7641,
                                            "quantity": 8
                                        },
                                        {
                                            "start": 7642,
                                            "end": 8378,
                                            "quantity": 8
                                        },
                                        {
                                            "start": 8379,
                                            "end": 9185,
                                            "quantity": 3
                                        },
                                        {
                                            "start": 9186,
                                            "end": 10000,
                                            "quantity": 6
                                        }
                                    ]
                                },
                                "rooms": {
                                    "title": "Номери та ліжка",
                                    "rooms": {
                                        "label": "Кімнати",
                                        "min": 1,
                                        "max": 9
                                    },
                                    "beds": {
                                        "label": "Ліжка",
                                        "min": 1,
                                        "max": 9
                                    },
                                    "bathrooms": {
                                        "label": "Санвузли",
                                        "min": 1,
                                        "max": 9
                                    }
                                },
                                "filterGroups": [
                                    {
                                        "title": "Обмеження",
                                        "alias": "restrictions",
                                        "data": [
                                            {
                                                "alias": "pets",
                                                "label": "Можна з тваринами"
                                            },
                                            {
                                                "alias": "smoke",
                                                "label": "Можна палити"
                                            }
                                        ]
                                    },
                                    {
                                        "title": "Тип помешкання",
                                        "alias": "accommodationTypes",
                                        "meta": "accommodationTypes",
                                        "data": [
                                            1,
                                            2,
                                            3,
                                            4,
                                            5,
                                            6,
                                            7,
                                            8,
                                            9,
                                            10,
                                            11,
                                            12,
                                            13,
                                            14,
                                            15,
                                            16,
                                            17,
                                            18,
                                            19,
                                            20,
                                            21,
                                            22,
                                            23,
                                            24,
                                            25,
                                            26,
                                            27,
                                            28,
                                            29,
                                            30,
                                            31,
                                            32,
                                            33,
                                            34
                                        ]
                                    },
                                    {
                                        "title": "Зручності в готелі",
                                        "alias": "hotelProperties",
                                        "meta": "properties",
                                        "data": [
                                            131,
                                            132,
                                            133,
                                            134,
                                            135,
                                            136,
                                            137,
                                            138,
                                            139,
                                            140,
                                            141,
                                            142,
                                            143,
                                            144,
                                            145,
                                            146,
                                            147,
                                            148,
                                            149,
                                            150,
                                            151,
                                            152,
                                            153,
                                            154,
                                            155,
                                            156,
                                            157,
                                            158,
                                            159,
                                            160,
                                            161,
                                            162,
                                            163,
                                            164,
                                            165,
                                            166,
                                            167,
                                            168,
                                            169,
                                            170,
                                            171,
                                            172,
                                            173,
                                            174,
                                            175,
                                            176,
                                            177,
                                            178,
                                            179,
                                            180,
                                            181,
                                            182,
                                            183,
                                            184,
                                            185,
                                            186,
                                            187,
                                            188,
                                            189,
                                            190,
                                            191,
                                            192,
                                            193,
                                            194,
                                            195,
                                            196,
                                            197,
                                            198,
                                            199,
                                            200,
                                            201,
                                            202,
                                            203,
                                            204,
                                            205,
                                            206,
                                            207,
                                            208,
                                            209,
                                            210,
                                            211,
                                            212,
                                            213,
                                            214,
                                            215,
                                            216,
                                            217,
                                            218,
                                            219,
                                            220,
                                            221,
                                            222,
                                            223,
                                            224,
                                            225,
                                            226,
                                            227,
                                            228,
                                            229,
                                            230,
                                            231,
                                            232,
                                            233,
                                            234,
                                            235,
                                            236,
                                            237,
                                            238,
                                            239,
                                            240,
                                            241,
                                            242,
                                            243,
                                            244,
                                            245,
                                            246,
                                            247,
                                            248,
                                            249,
                                            250,
                                            251,
                                            252,
                                            253,
                                            254,
                                            255,
                                            256,
                                            257,
                                            258,
                                            259,
                                            260,
                                            261,
                                            262,
                                            263,
                                            264,
                                            265,
                                            266,
                                            267,
                                            268,
                                            269,
                                            270,
                                            271,
                                            272,
                                            273,
                                            274,
                                            275
                                        ]
                                    },
                                    {
                                        "title": "Зручності в номерах",
                                        "alias": "roomProperties",
                                        "meta": "properties",
                                        "data": [
                                            1,
                                            2,
                                            3,
                                            4,
                                            5,
                                            6,
                                            7,
                                            8,
                                            9,
                                            10,
                                            11,
                                            12,
                                            13,
                                            14,
                                            15,
                                            16,
                                            17,
                                            18,
                                            19,
                                            20,
                                            21,
                                            22,
                                            23,
                                            24,
                                            25,
                                            26,
                                            27,
                                            28,
                                            29,
                                            30,
                                            31,
                                            32,
                                            33,
                                            34,
                                            35,
                                            36,
                                            37,
                                            38,
                                            39,
                                            40,
                                            41,
                                            42,
                                            43,
                                            44,
                                            45,
                                            46,
                                            47,
                                            48,
                                            49,
                                            50,
                                            51,
                                            52,
                                            53,
                                            54,
                                            55,
                                            56,
                                            57,
                                            58,
                                            59,
                                            60,
                                            61,
                                            62,
                                            63,
                                            64,
                                            65,
                                            66,
                                            67,
                                            68,
                                            69,
                                            70,
                                            71,
                                            72,
                                            73,
                                            74,
                                            75,
                                            76,
                                            77,
                                            78,
                                            79,
                                            80,
                                            81,
                                            82,
                                            83,
                                            84,
                                            85,
                                            86,
                                            87,
                                            88,
                                            89,
                                            90,
                                            91,
                                            92,
                                            93,
                                            94,
                                            95,
                                            96,
                                            97,
                                            98,
                                            99,
                                            100,
                                            101,
                                            102,
                                            103,
                                            104,
                                            105,
                                            106,
                                            107,
                                            108,
                                            109,
                                            110,
                                            111,
                                            112,
                                            113,
                                            114,
                                            115,
                                            116,
                                            117,
                                            118,
                                            119,
                                            120,
                                            121,
                                            122,
                                            123,
                                            124,
                                            125,
                                            126,
                                            127,
                                            128,
                                            129,
                                            130
                                        ]
                                    }
                                ]
                            },
                            "mealTypes": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Без харчування",
                                        "description": "Без харчування",
                                        "code": "RO"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Тільки сніданок",
                                        "description": "Сніданок включено",
                                        "code": "BB"
                                    },
                                    {
                                        "id": 3,
                                        "name": "Напівпансіон",
                                        "description": "Напівпансіон",
                                        "code": "HB"
                                    },
                                    {
                                        "id": 4,
                                        "name": "Повний пансіон",
                                        "description": "Повний пансіон",
                                        "code": "FB"
                                    },
                                    {
                                        "id": 5,
                                        "name": "Все включено",
                                        "description": "Все включено",
                                        "code": "AI"
                                    },
                                    {
                                        "id": 6,
                                        "name": "Ультра все включено",
                                        "description": "Ультра все включено",
                                        "code": "UAI"
                                    }
                                ]
                            },
                            "properties": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Басейн із підігрівом",
                                        "icon": null
                                    },
                                    {
                                        "id": 2,
                                        "name": "Басейн із навісом",
                                        "icon": null
                                    },
                                    {
                                        "id": 3,
                                        "name": "Басейн із видом",
                                        "icon": null
                                    },
                                    {
                                        "id": 4,
                                        "name": "Басейн на даху",
                                        "icon": null
                                    },
                                    {
                                        "id": 5,
                                        "name": "Рушники для басейну",
                                        "icon": null
                                    },
                                    {
                                        "id": 6,
                                        "name": "Басейн із солоною водою",
                                        "icon": null
                                    },
                                    {
                                        "id": 7,
                                        "name": "Приватний басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 8,
                                        "name": "Гідромасажна ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 9,
                                        "name": "Ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 10,
                                        "name": "Душ",
                                        "icon": null
                                    },
                                    {
                                        "id": 11,
                                        "name": "Ванна",
                                        "icon": null
                                    },
                                    {
                                        "id": 12,
                                        "name": "Фен",
                                        "icon": null
                                    },
                                    {
                                        "id": 13,
                                        "name": "Спільний туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 14,
                                        "name": "Сауна",
                                        "icon": null
                                    },
                                    {
                                        "id": 15,
                                        "name": "Туалетний папір",
                                        "icon": null
                                    },
                                    {
                                        "id": 16,
                                        "name": "Біде",
                                        "icon": null
                                    },
                                    {
                                        "id": 17,
                                        "name": "Туалетно-косметичні засоби",
                                        "icon": null
                                    },
                                    {
                                        "id": 18,
                                        "name": "Гостьовий туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 19,
                                        "name": "Туалет",
                                        "icon": null
                                    },
                                    {
                                        "id": 20,
                                        "name": "Додаткова ванна кімната",
                                        "icon": null
                                    },
                                    {
                                        "id": 21,
                                        "name": "Зубна щітка",
                                        "icon": null
                                    },
                                    {
                                        "id": 22,
                                        "name": "Шампунь",
                                        "icon": null
                                    },
                                    {
                                        "id": 23,
                                        "name": "Кондиціонер для волосся",
                                        "icon": null
                                    },
                                    {
                                        "id": 24,
                                        "name": "Мило для тіла",
                                        "icon": null
                                    },
                                    {
                                        "id": 25,
                                        "name": "Шапочка для душу",
                                        "icon": null
                                    },
                                    {
                                        "id": 26,
                                        "name": "Білизна",
                                        "icon": null
                                    },
                                    {
                                        "id": 27,
                                        "name": "Рушники",
                                        "icon": null
                                    },
                                    {
                                        "id": 28,
                                        "name": "Дитячі ліжечка",
                                        "icon": null
                                    },
                                    {
                                        "id": 29,
                                        "name": "Вішалка для одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 30,
                                        "name": "Сушарка для одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 31,
                                        "name": "Розкладне ліжко",
                                        "icon": null
                                    },
                                    {
                                        "id": 32,
                                        "name": "Диван-ліжко",
                                        "icon": null
                                    },
                                    {
                                        "id": 33,
                                        "name": "Кошики для сміття",
                                        "icon": null
                                    },
                                    {
                                        "id": 34,
                                        "name": "Гардеробна",
                                        "icon": null
                                    },
                                    {
                                        "id": 35,
                                        "name": "Подовжене ліжко (>2 метрів)",
                                        "icon": null
                                    },
                                    {
                                        "id": 36,
                                        "name": "Диван",
                                        "icon": null
                                    },
                                    {
                                        "id": 37,
                                        "name": "Вітальня/м'який куток",
                                        "icon": null
                                    },
                                    {
                                        "id": 38,
                                        "name": "Робочий стіл",
                                        "icon": null
                                    },
                                    {
                                        "id": 39,
                                        "name": "Пухова подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 40,
                                        "name": "Синтетична подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 41,
                                        "name": "Гіпоалергенна подушка",
                                        "icon": null
                                    },
                                    {
                                        "id": 42,
                                        "name": "Шафа або гардероб",
                                        "icon": null
                                    },
                                    {
                                        "id": 43,
                                        "name": "Високий стілець для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 44,
                                        "name": "Піжама",
                                        "icon": null
                                    },
                                    {
                                        "id": 45,
                                        "name": "Електроковдра",
                                        "icon": null
                                    },
                                    {
                                        "id": 46,
                                        "name": "Москітна сітка",
                                        "icon": null
                                    },
                                    {
                                        "id": 47,
                                        "name": "Окремий вхід",
                                        "icon": null
                                    },
                                    {
                                        "id": 48,
                                        "name": "Сейф",
                                        "icon": null
                                    },
                                    {
                                        "id": 49,
                                        "name": "Датчик чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 50,
                                        "name": "Датчик диму",
                                        "icon": null
                                    },
                                    {
                                        "id": 51,
                                        "name": "Очищувачі повітря",
                                        "icon": null
                                    },
                                    {
                                        "id": 52,
                                        "name": "Вогнегасник",
                                        "icon": null
                                    },
                                    {
                                        "id": 53,
                                        "name": "Захисні кришки на розетках для безпеки дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 54,
                                        "name": "Ігрова приставка PS4",
                                        "icon": null
                                    },
                                    {
                                        "id": 55,
                                        "name": "Ігрова приставка Xbox One",
                                        "icon": null
                                    },
                                    {
                                        "id": 56,
                                        "name": "Комп'ютер",
                                        "icon": null
                                    },
                                    {
                                        "id": 57,
                                        "name": "Ігрова приставка",
                                        "icon": null
                                    },
                                    {
                                        "id": 58,
                                        "name": "Ноутбук",
                                        "icon": null
                                    },
                                    {
                                        "id": 59,
                                        "name": "iPad",
                                        "icon": null
                                    },
                                    {
                                        "id": 60,
                                        "name": "Кабельні канали",
                                        "icon": null
                                    },
                                    {
                                        "id": 61,
                                        "name": "Радіо",
                                        "icon": null
                                    },
                                    {
                                        "id": 62,
                                        "name": "Телефон",
                                        "icon": null
                                    },
                                    {
                                        "id": 63,
                                        "name": "Телевізор",
                                        "icon": null
                                    },
                                    {
                                        "id": 64,
                                        "name": "Портативна точка доступу в інтернет",
                                        "icon": null
                                    },
                                    {
                                        "id": 65,
                                        "name": "Потоковий сервіс (наприклад, Netflix)",
                                        "icon": null
                                    },
                                    {
                                        "id": 66,
                                        "name": "Настільні ігри/пазли",
                                        "icon": null
                                    },
                                    {
                                        "id": 67,
                                        "name": "Дитячі книги, музика чи фільми",
                                        "icon": null
                                    },
                                    {
                                        "id": 68,
                                        "name": "Обідня зона",
                                        "icon": null
                                    },
                                    {
                                        "id": 69,
                                        "name": "Обідній стіл",
                                        "icon": null
                                    },
                                    {
                                        "id": 70,
                                        "name": "Келихи для вина",
                                        "icon": null
                                    },
                                    {
                                        "id": 71,
                                        "name": "Пляшка води",
                                        "icon": null
                                    },
                                    {
                                        "id": 72,
                                        "name": "Шоколад або печиво",
                                        "icon": null
                                    },
                                    {
                                        "id": 73,
                                        "name": "Фрукти",
                                        "icon": null
                                    },
                                    {
                                        "id": 74,
                                        "name": "Вино або шампанське",
                                        "icon": null
                                    },
                                    {
                                        "id": 75,
                                        "name": "Барбекю",
                                        "icon": null
                                    },
                                    {
                                        "id": 76,
                                        "name": "Духовка",
                                        "icon": null
                                    },
                                    {
                                        "id": 77,
                                        "name": "Плита",
                                        "icon": null
                                    },
                                    {
                                        "id": 78,
                                        "name": "Тостер",
                                        "icon": null
                                    },
                                    {
                                        "id": 79,
                                        "name": "Посудомийна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 80,
                                        "name": "Електрочайник",
                                        "icon": null
                                    },
                                    {
                                        "id": 81,
                                        "name": "Обідня зона на відкритому повітрі",
                                        "icon": null
                                    },
                                    {
                                        "id": 82,
                                        "name": "Вуличні меблі",
                                        "icon": null
                                    },
                                    {
                                        "id": 83,
                                        "name": "Міні-бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 84,
                                        "name": "Кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 85,
                                        "name": "Міні-кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 86,
                                        "name": "Кухонний посуд",
                                        "icon": null
                                    },
                                    {
                                        "id": 87,
                                        "name": "Мікрохвильова піч",
                                        "icon": null
                                    },
                                    {
                                        "id": 88,
                                        "name": "Холодильник",
                                        "icon": null
                                    },
                                    {
                                        "id": 89,
                                        "name": "Чайник/кавоварка",
                                        "icon": null
                                    },
                                    {
                                        "id": 90,
                                        "name": "Кавоварка",
                                        "icon": null
                                    },
                                    {
                                        "id": 91,
                                        "name": "Кондиціонер",
                                        "icon": null
                                    },
                                    {
                                        "id": 92,
                                        "name": "Сушильна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 93,
                                        "name": "Вентилятор",
                                        "icon": null
                                    },
                                    {
                                        "id": 94,
                                        "name": "Камін",
                                        "icon": null
                                    },
                                    {
                                        "id": 95,
                                        "name": "Опалення",
                                        "icon": null
                                    },
                                    {
                                        "id": 96,
                                        "name": "Доступні суміжні номери",
                                        "icon": null
                                    },
                                    {
                                        "id": 97,
                                        "name": "Праска",
                                        "icon": null
                                    },
                                    {
                                        "id": 98,
                                        "name": "Послуга дзвінок-\"будильник\"",
                                        "icon": null
                                    },
                                    {
                                        "id": 99,
                                        "name": "Доступ до представницького лаунжу",
                                        "icon": null
                                    },
                                    {
                                        "id": 100,
                                        "name": "Вхід з електронною карткою",
                                        "icon": null
                                    },
                                    {
                                        "id": 101,
                                        "name": "Капці",
                                        "icon": null
                                    },
                                    {
                                        "id": 102,
                                        "name": "Халат",
                                        "icon": null
                                    },
                                    {
                                        "id": 103,
                                        "name": "Пральна машина",
                                        "icon": null
                                    },
                                    {
                                        "id": 104,
                                        "name": "Засоби для чищення",
                                        "icon": null
                                    },
                                    {
                                        "id": 105,
                                        "name": "Балкон",
                                        "icon": null
                                    },
                                    {
                                        "id": 106,
                                        "name": "Тераса",
                                        "icon": null
                                    },
                                    {
                                        "id": 107,
                                        "name": "Вид на місто",
                                        "icon": null
                                    },
                                    {
                                        "id": 108,
                                        "name": "Вид на сад",
                                        "icon": null
                                    },
                                    {
                                        "id": 109,
                                        "name": "Вид на озеро",
                                        "icon": null
                                    },
                                    {
                                        "id": 110,
                                        "name": "Вид на пам'ятку",
                                        "icon": null
                                    },
                                    {
                                        "id": 111,
                                        "name": "Вид на гори",
                                        "icon": null
                                    },
                                    {
                                        "id": 112,
                                        "name": "Вид на басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 113,
                                        "name": "Вид на річку",
                                        "icon": null
                                    },
                                    {
                                        "id": 114,
                                        "name": "Вид на море",
                                        "icon": null
                                    },
                                    {
                                        "id": 115,
                                        "name": "Вид на внутрішній двір",
                                        "icon": null
                                    },
                                    {
                                        "id": 116,
                                        "name": "Вид на тиху вулицю",
                                        "icon": null
                                    },
                                    {
                                        "id": 117,
                                        "name": "Приміщення повністю знаходиться на першому поверсі",
                                        "icon": null
                                    },
                                    {
                                        "id": 118,
                                        "name": "Приміщення придатне для гостей в інвалідних візках",
                                        "icon": null
                                    },
                                    {
                                        "id": 119,
                                        "name": "Зручності для гостей із вадами слуху",
                                        "icon": null
                                    },
                                    {
                                        "id": 120,
                                        "name": "На вищі поверхи можна піднятися на ліфті",
                                        "icon": null
                                    },
                                    {
                                        "id": 121,
                                        "name": "На верхні поверхи можна піднятися тільки сходами",
                                        "icon": null
                                    },
                                    {
                                        "id": 122,
                                        "name": "Ванна з поручнями",
                                        "icon": null
                                    },
                                    {
                                        "id": 123,
                                        "name": "Аварійний шнур у ванній кімнаті",
                                        "icon": null
                                    },
                                    {
                                        "id": 124,
                                        "name": "Високий унітаз",
                                        "icon": null
                                    },
                                    {
                                        "id": 125,
                                        "name": "Низький умивальник",
                                        "icon": null
                                    },
                                    {
                                        "id": 126,
                                        "name": "Безбар'єрна душова",
                                        "icon": null
                                    },
                                    {
                                        "id": 127,
                                        "name": "Стілець для душа",
                                        "icon": null
                                    },
                                    {
                                        "id": 128,
                                        "name": "Туалет з поручнями",
                                        "icon": null
                                    },
                                    {
                                        "id": 129,
                                        "name": "Душ без піддону",
                                        "icon": null
                                    },
                                    {
                                        "id": 130,
                                        "name": "Захисні бар'єри для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 131,
                                        "name": "Дитяче меню",
                                        "icon": null
                                    },
                                    {
                                        "id": 132,
                                        "name": "Шведський стіл з вибором страв для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 133,
                                        "name": "Вино/шампанськеУ помешканні подають вино або шампанське",
                                        "icon": null
                                    },
                                    {
                                        "id": 134,
                                        "name": "ФруктиУ номерах є фрукти",
                                        "icon": null
                                    },
                                    {
                                        "id": 135,
                                        "name": "Кав'ярня (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 136,
                                        "name": "Снек-бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 137,
                                        "name": "Доставка продуктів",
                                        "icon": null
                                    },
                                    {
                                        "id": 138,
                                        "name": "Запаковані ланчі",
                                        "icon": null
                                    },
                                    {
                                        "id": 139,
                                        "name": "Барбекю",
                                        "icon": null
                                    },
                                    {
                                        "id": 140,
                                        "name": "Автомат з напоями",
                                        "icon": null
                                    },
                                    {
                                        "id": 141,
                                        "name": "Автомат із закусками",
                                        "icon": null
                                    },
                                    {
                                        "id": 142,
                                        "name": "Спеціальне дієтичне меню (за запитом)",
                                        "icon": null
                                    },
                                    {
                                        "id": 143,
                                        "name": "Сніданок у номері",
                                        "icon": null
                                    },
                                    {
                                        "id": 144,
                                        "name": "Спільна кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 145,
                                        "name": "Ресторан",
                                        "icon": null
                                    },
                                    {
                                        "id": 146,
                                        "name": "Обслуговування номерів",
                                        "icon": null
                                    },
                                    {
                                        "id": 147,
                                        "name": "Бар",
                                        "icon": null
                                    },
                                    {
                                        "id": 148,
                                        "name": "Водна гірка",
                                        "icon": null
                                    },
                                    {
                                        "id": 149,
                                        "name": "Пляжне крісло/шезлонг",
                                        "icon": null
                                    },
                                    {
                                        "id": 150,
                                        "name": "Сонячна парасоля",
                                        "icon": null
                                    },
                                    {
                                        "id": 151,
                                        "name": "Косметичні послуги",
                                        "icon": null
                                    },
                                    {
                                        "id": 152,
                                        "name": "Спа-процедури",
                                        "icon": null
                                    },
                                    {
                                        "id": 153,
                                        "name": "Парова лазня",
                                        "icon": null
                                    },
                                    {
                                        "id": 154,
                                        "name": "Спа-лаунж/зона для релаксації",
                                        "icon": null
                                    },
                                    {
                                        "id": 155,
                                        "name": "Ванночка для ніг",
                                        "icon": null
                                    },
                                    {
                                        "id": 156,
                                        "name": "Пакети послуг спа / велнес",
                                        "icon": null
                                    },
                                    {
                                        "id": 157,
                                        "name": "Масажне крісло",
                                        "icon": null
                                    },
                                    {
                                        "id": 158,
                                        "name": "Фітнес",
                                        "icon": null
                                    },
                                    {
                                        "id": 159,
                                        "name": "Заняття йогою",
                                        "icon": null
                                    },
                                    {
                                        "id": 160,
                                        "name": "Заняття фітнесом",
                                        "icon": null
                                    },
                                    {
                                        "id": 161,
                                        "name": "Особистий тренер",
                                        "icon": null
                                    },
                                    {
                                        "id": 162,
                                        "name": "Роздягальня з шафками",
                                        "icon": null
                                    },
                                    {
                                        "id": 163,
                                        "name": "Дитячий басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 164,
                                        "name": "Хамам/турецька лазня",
                                        "icon": null
                                    },
                                    {
                                        "id": 165,
                                        "name": "Солярій",
                                        "icon": null
                                    },
                                    {
                                        "id": 166,
                                        "name": "Гарячі джерела",
                                        "icon": null
                                    },
                                    {
                                        "id": 167,
                                        "name": "Масаж",
                                        "icon": null
                                    },
                                    {
                                        "id": 168,
                                        "name": "Лазні на відкритому повітрі",
                                        "icon": null
                                    },
                                    {
                                        "id": 169,
                                        "name": "Громадські лазні",
                                        "icon": null
                                    },
                                    {
                                        "id": 170,
                                        "name": "Сауна",
                                        "icon": null
                                    },
                                    {
                                        "id": 171,
                                        "name": "Фітнес-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 172,
                                        "name": "Оздоровчий спа-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 173,
                                        "name": "Гідромасажна ванна/джакузі",
                                        "icon": null
                                    },
                                    {
                                        "id": 174,
                                        "name": "Басейн",
                                        "icon": null
                                    },
                                    {
                                        "id": 175,
                                        "name": "Аквапарк",
                                        "icon": null
                                    },
                                    {
                                        "id": 176,
                                        "name": "Квитки на громадський транспорт",
                                        "icon": null
                                    },
                                    {
                                        "id": 177,
                                        "name": "Трансфер до помешкання",
                                        "icon": null
                                    },
                                    {
                                        "id": 178,
                                        "name": "Прокат автомобілів",
                                        "icon": null
                                    },
                                    {
                                        "id": 179,
                                        "name": "Чи є парковка для гостей?",
                                        "icon": null
                                    },
                                    {
                                        "id": 180,
                                        "name": "Трансфер з/до аеропорту",
                                        "icon": null
                                    },
                                    {
                                        "id": 181,
                                        "name": "Спорядження для тенісу",
                                        "icon": null
                                    },
                                    {
                                        "id": 182,
                                        "name": "Спорядження для бадмінтону",
                                        "icon": null
                                    },
                                    {
                                        "id": 183,
                                        "name": "Пляж",
                                        "icon": null
                                    },
                                    {
                                        "id": 184,
                                        "name": "Тимчасові виставки мистецтва",
                                        "icon": null
                                    },
                                    {
                                        "id": 185,
                                        "name": "Похід барами (пабкрол)",
                                        "icon": null
                                    },
                                    {
                                        "id": 186,
                                        "name": "Стендап-комедія",
                                        "icon": null
                                    },
                                    {
                                        "id": 187,
                                        "name": "Вечори перегляду фільмів",
                                        "icon": null
                                    },
                                    {
                                        "id": 188,
                                        "name": "Піші екскурсії",
                                        "icon": null
                                    },
                                    {
                                        "id": 189,
                                        "name": "Велосипедні екскурсії",
                                        "icon": null
                                    },
                                    {
                                        "id": 190,
                                        "name": "Тематичні вечері",
                                        "icon": null
                                    },
                                    {
                                        "id": 191,
                                        "name": "Щаслива година",
                                        "icon": null
                                    },
                                    {
                                        "id": 192,
                                        "name": "Екскурсія або урок про місцеву культуру",
                                        "icon": null
                                    },
                                    {
                                        "id": 193,
                                        "name": "Урок готування",
                                        "icon": null
                                    },
                                    {
                                        "id": 194,
                                        "name": "Жива музика/виступ",
                                        "icon": null
                                    },
                                    {
                                        "id": 195,
                                        "name": "Пряма трансляція спортивних ігор",
                                        "icon": null
                                    },
                                    {
                                        "id": 196,
                                        "name": "Стрільба з лука",
                                        "icon": null
                                    },
                                    {
                                        "id": 197,
                                        "name": "Аеробіка",
                                        "icon": null
                                    },
                                    {
                                        "id": 198,
                                        "name": "Бінго",
                                        "icon": null
                                    },
                                    {
                                        "id": 199,
                                        "name": "Тенісний корт",
                                        "icon": null
                                    },
                                    {
                                        "id": 200,
                                        "name": "Більярд",
                                        "icon": null
                                    },
                                    {
                                        "id": 201,
                                        "name": "Настільний теніс",
                                        "icon": null
                                    },
                                    {
                                        "id": 202,
                                        "name": "Дартс",
                                        "icon": null
                                    },
                                    {
                                        "id": 203,
                                        "name": "Сквош",
                                        "icon": null
                                    },
                                    {
                                        "id": 204,
                                        "name": "Боулінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 205,
                                        "name": "Міні-гольф",
                                        "icon": null
                                    },
                                    {
                                        "id": 206,
                                        "name": "Поле для гольфу (в радіусі 3 км)",
                                        "icon": null
                                    },
                                    {
                                        "id": 207,
                                        "name": "Зручності для занять водними видами спорту (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 208,
                                        "name": "Віндсерфінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 209,
                                        "name": "Дайвінг",
                                        "icon": null
                                    },
                                    {
                                        "id": 210,
                                        "name": "Плавання під водою з маскою, трубкою і ластами",
                                        "icon": null
                                    },
                                    {
                                        "id": 211,
                                        "name": "Каноє",
                                        "icon": null
                                    },
                                    {
                                        "id": 212,
                                        "name": "Риболовля",
                                        "icon": null
                                    },
                                    {
                                        "id": 213,
                                        "name": "Верхова їзда",
                                        "icon": null
                                    },
                                    {
                                        "id": 214,
                                        "name": "Велоспорт",
                                        "icon": null
                                    },
                                    {
                                        "id": 215,
                                        "name": "Піші прогулянки",
                                        "icon": null
                                    },
                                    {
                                        "id": 216,
                                        "name": "Катання на лижах",
                                        "icon": null
                                    },
                                    {
                                        "id": 217,
                                        "name": "Садові меблі",
                                        "icon": null
                                    },
                                    {
                                        "id": 218,
                                        "name": "Місце для пікніка",
                                        "icon": null
                                    },
                                    {
                                        "id": 219,
                                        "name": "Камін",
                                        "icon": null
                                    },
                                    {
                                        "id": 220,
                                        "name": "Камін вуличний",
                                        "icon": null
                                    },
                                    {
                                        "id": 221,
                                        "name": "Тераса для засмаги",
                                        "icon": null
                                    },
                                    {
                                        "id": 222,
                                        "name": "Спільна кухня",
                                        "icon": null
                                    },
                                    {
                                        "id": 223,
                                        "name": "Спільна зона відпочинку з телевізором",
                                        "icon": null
                                    },
                                    {
                                        "id": 224,
                                        "name": "Ігрова кімната",
                                        "icon": null
                                    },
                                    {
                                        "id": 225,
                                        "name": "Церква/храм",
                                        "icon": null
                                    },
                                    {
                                        "id": 226,
                                        "name": "Сад",
                                        "icon": null
                                    },
                                    {
                                        "id": 227,
                                        "name": "Тераса",
                                        "icon": null
                                    },
                                    {
                                        "id": 228,
                                        "name": "Дитячий клуб",
                                        "icon": null
                                    },
                                    {
                                        "id": 229,
                                        "name": "Настільні ігри / пазли",
                                        "icon": null
                                    },
                                    {
                                        "id": 230,
                                        "name": "Ігровий майданчик в приміщенні",
                                        "icon": null
                                    },
                                    {
                                        "id": 231,
                                        "name": "Устаткування для ігор на вулиці",
                                        "icon": null
                                    },
                                    {
                                        "id": 232,
                                        "name": "Захисні бар'єри для дітей",
                                        "icon": null
                                    },
                                    {
                                        "id": 233,
                                        "name": "Дитячі візки",
                                        "icon": null
                                    },
                                    {
                                        "id": 234,
                                        "name": "Вечірня розважальна програма",
                                        "icon": null
                                    },
                                    {
                                        "id": 235,
                                        "name": "Нічний клуб/ді-джей",
                                        "icon": null
                                    },
                                    {
                                        "id": 236,
                                        "name": "Казино",
                                        "icon": null
                                    },
                                    {
                                        "id": 237,
                                        "name": "Караоке",
                                        "icon": null
                                    },
                                    {
                                        "id": 238,
                                        "name": "Аніматори",
                                        "icon": null
                                    },
                                    {
                                        "id": 239,
                                        "name": "Дитячий майданчик",
                                        "icon": null
                                    },
                                    {
                                        "id": 240,
                                        "name": "Няня/Послуги по догляду за дітьми",
                                        "icon": null
                                    },
                                    {
                                        "id": 241,
                                        "name": "Хімчистка",
                                        "icon": null
                                    },
                                    {
                                        "id": 242,
                                        "name": "Прасування одягу",
                                        "icon": null
                                    },
                                    {
                                        "id": 243,
                                        "name": "Пральня",
                                        "icon": null
                                    },
                                    {
                                        "id": 244,
                                        "name": "Щоденне прибирання",
                                        "icon": null
                                    },
                                    {
                                        "id": 245,
                                        "name": "Прес для штанів",
                                        "icon": null
                                    },
                                    {
                                        "id": 246,
                                        "name": "Конференц-зал/бенкетний зал",
                                        "icon": null
                                    },
                                    {
                                        "id": 247,
                                        "name": "Бізнес-центр",
                                        "icon": null
                                    },
                                    {
                                        "id": 248,
                                        "name": "Міні-маркет (на території)",
                                        "icon": null
                                    },
                                    {
                                        "id": 249,
                                        "name": "Перукарня/Салон краси",
                                        "icon": null
                                    },
                                    {
                                        "id": 250,
                                        "name": "Кошик для домашніх тварин",
                                        "icon": null
                                    },
                                    {
                                        "id": 251,
                                        "name": "Миски для домашніх тварин",
                                        "icon": null
                                    },
                                    {
                                        "id": 252,
                                        "name": "Вхід з ключем",
                                        "icon": null
                                    },
                                    {
                                        "id": 253,
                                        "name": "Вхід з електронною карткою",
                                        "icon": null
                                    },
                                    {
                                        "id": 254,
                                        "name": "Лише для дорослих",
                                        "icon": null
                                    },
                                    {
                                        "id": 255,
                                        "name": "Спеціальні номери для алергіків",
                                        "icon": null
                                    },
                                    {
                                        "id": 256,
                                        "name": "На всій території готельного об'єкта і в номерах заборонено курити",
                                        "icon": null
                                    },
                                    {
                                        "id": 257,
                                        "name": "Місця для куріння",
                                        "icon": null
                                    },
                                    {
                                        "id": 258,
                                        "name": "Номери/зручності для осіб з обмеженими фізичними можливостями",
                                        "icon": null
                                    },
                                    {
                                        "id": 259,
                                        "name": "Ліфт",
                                        "icon": null
                                    },
                                    {
                                        "id": 260,
                                        "name": "Звуконепроникні номери",
                                        "icon": null
                                    },
                                    {
                                        "id": 261,
                                        "name": "Опалення",
                                        "icon": null
                                    },
                                    {
                                        "id": 262,
                                        "name": "Шафки, що замикаються",
                                        "icon": null
                                    },
                                    {
                                        "id": 263,
                                        "name": "Камера зберігання багажу",
                                        "icon": null
                                    },
                                    {
                                        "id": 264,
                                        "name": "Цілодобова стійка реєстрації гостей",
                                        "icon": null
                                    },
                                    {
                                        "id": 265,
                                        "name": "Номери для некурців",
                                        "icon": null
                                    },
                                    {
                                        "id": 266,
                                        "name": "Кондиціонер",
                                        "icon": null
                                    },
                                    {
                                        "id": 267,
                                        "name": "Цілодобова охорона",
                                        "icon": null
                                    },
                                    {
                                        "id": 268,
                                        "name": "Охоронна сигналізація",
                                        "icon": null
                                    },
                                    {
                                        "id": 269,
                                        "name": "Датчики диму",
                                        "icon": null
                                    },
                                    {
                                        "id": 270,
                                        "name": "Відеоспостереження в зонах загального користування",
                                        "icon": null
                                    },
                                    {
                                        "id": 271,
                                        "name": "Відеоспостереження ззовні будівлі",
                                        "icon": null
                                    },
                                    {
                                        "id": 272,
                                        "name": "Вогнегасники",
                                        "icon": null
                                    },
                                    {
                                        "id": 273,
                                        "name": "Датчик чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 274,
                                        "name": "Джерела чадного газу",
                                        "icon": null
                                    },
                                    {
                                        "id": 275,
                                        "name": "Сейф",
                                        "icon": null
                                    }
                                ]
                            },
                            "requestCoordinates": {
                                "longitude": "24.7111170",
                                "latitude": "48.9226330"
                            },
                            "accommodationTypes": {
                                "data": [
                                    {
                                        "id": 1,
                                        "name": "Готель"
                                    },
                                    {
                                        "id": 2,
                                        "name": "Апартаменти"
                                    },
                                    {
                                        "id": 3,
                                        "name": "Хостел"
                                    },
                                    {
                                        "id": 4,
                                        "name": "Вілла"
                                    },
                                    {
                                        "id": 5,
                                        "name": "Шале"
                                    },
                                    {
                                        "id": 6,
                                        "name": "Помешкання"
                                    },
                                    {
                                        "id": 7,
                                        "name": "Мотель"
                                    },
                                    {
                                        "id": 8,
                                        "name": "Курортний готель"
                                    },
                                    {
                                        "id": 9,
                                        "name": "Особняк"
                                    },
                                    {
                                        "id": 10,
                                        "name": "Готель типу «ліжко і сніданок»"
                                    },
                                    {
                                        "id": 11,
                                        "name": "Рьокан"
                                    },
                                    {
                                        "id": 12,
                                        "name": "Фермерський будинок"
                                    },
                                    {
                                        "id": 13,
                                        "name": "Парк-готель"
                                    },
                                    {
                                        "id": 14,
                                        "name": "Кемпінг"
                                    },
                                    {
                                        "id": 15,
                                        "name": "Ботель"
                                    },
                                    {
                                        "id": 16,
                                        "name": "Гостьовий будинок"
                                    },
                                    {
                                        "id": 17,
                                        "name": "Міні-готель з рестораном"
                                    },
                                    {
                                        "id": 18,
                                        "name": "Апарт-готель"
                                    },
                                    {
                                        "id": 19,
                                        "name": "Будинок для відпочинку"
                                    },
                                    {
                                        "id": 20,
                                        "name": "Лодж"
                                    },
                                    {
                                        "id": 21,
                                        "name": "Розміщення в сім'ї"
                                    },
                                    {
                                        "id": 22,
                                        "name": "Заміський будинок"
                                    },
                                    {
                                        "id": 23,
                                        "name": "Намет-люкс"
                                    },
                                    {
                                        "id": 24,
                                        "name": "Капсульний готель"
                                    },
                                    {
                                        "id": 25,
                                        "name": "Готель для побачень"
                                    },
                                    {
                                        "id": 26,
                                        "name": "Ріад"
                                    },
                                    {
                                        "id": 27,
                                        "name": "Кондомініум"
                                    },
                                    {
                                        "id": 28,
                                        "name": "Котедж"
                                    },
                                    {
                                        "id": 29,
                                        "name": "Готель економ-класу"
                                    },
                                    {
                                        "id": 30,
                                        "name": "Французький котедж"
                                    },
                                    {
                                        "id": 31,
                                        "name": "Санаторій"
                                    },
                                    {
                                        "id": 32,
                                        "name": "Круїз"
                                    },
                                    {
                                        "id": 33,
                                        "name": "Гуртожиток"
                                    },
                                    {
                                        "id": 34,
                                        "name": "Вілли"
                                    }
                                ]
                            },
                            "pagination": {
                                "total": 0,
                                "count": 38,
                                "per_page": 500,
                                "current_page": 1,
                                "total_pages": 1,
                                "links": []
                            }
                        }}
                        link={'/'}
                        nights={2}
                    />
                </div>
            </div>
        </Page>
    );
};

TestScreen.getInitialProps = async ({store, req, scrollToTop, match, location}) => {
    if (!isClient)
        console.log('get initial props');
}

export default TestScreen;
