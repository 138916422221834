import React from "react";
import "./ContactsHeader.scss";
import { RouteNames } from "../../../../routes";
import Container from "../../../../components/ui/layouts/Container/Container";
import Breadcrumbs from "../../../../components/ui/components/Breadcrumbs/Breadcrumbs";


const ContactsHeader = () => {
  const breadcrumbs = [
    {
      url: RouteNames.PROFILE_INDEX,
      title: 'Акаунт',
    },
    {
      title: 'Контакти',
    },
  ];

  return (
    <div className="contacts-header" style={{ backgroundImage: `url('/images/contacts/backbround.png')` }}>
      <Container>
        <div  className="contacts-header__breadcrumbs">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>

        <h1>Контакти</h1>
        <h2>Контактуйте з нами зручним способом</h2>


      </Container>

    </div>
  )
}
export default ContactsHeader;