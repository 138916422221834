import React, { FC } from 'react';
import './AccountFavoriteDetail.scss';
import Page from '../../../components/common/Page/Page';
import Container from '../../../components/ui/layouts/Container/Container';
import PageHeader from '../common/PageHeader/PageHeader';
import { unwrapResult } from '@reduxjs/toolkit';
import { getFavoriteGroupItemsThunk } from '../../../store/account/favorite-group-items/favorite-group-items-thanks';
import { useSelector } from 'react-redux';
import { getFavoriteGroupItems } from '../../../store/account/favorite-group-items/favorite-group-items-selectors';
import HotelListItem from '../../Hotels/HotelList/HotelListItem/HotelListItem';
import AccountListIsEmpty from '../common/AccountListIsEmpty/AccountListIsEmpty';
import { RouteNames } from '../../../routes';
import HotelsListLoader from "../../Hotels/HotelList/components/HotelsListLoader/HotelsListLoader";

const AccountFavoriteDetail = () => {
   const { data, isFetching } = useSelector(getFavoriteGroupItems);
   const { hotels, id, name } = data;
   console.log("data",hotels.data)
   const breadcrumbs = [
      {
         url: RouteNames.PROFILE_INDEX,
         title: 'Акаунт',
      }, {
         url: RouteNames.PROFILE_FAVORITE,
         title: 'Обране',
      }, {
         title: name,
      },
   ];

   const renderView = () => {

      if(!isFetching && hotels.data.length){
         return (
            <div className="favorite-detail__list">
               {hotels.data.map(item => {
                  return <HotelListItem hotelItem={item} key={item.id} />;
               })}
            </div>
         )
      } else if(!isFetching && !hotels.data.length){
         return <AccountListIsEmpty title="" description="Створіть свій перший список обраного" additional="Під час пошуку натискайте значок серця, щоб зберігати улюблені готелі до обраного."/>;
      } else {
         return <HotelsListLoader/>
      }
   }

   return (
      <Page>
         <Container>
            <PageHeader breadcrumbs={breadcrumbs} title={`Збережені місця в ${name}`} />
            <div className="favorite-detail">
               {renderView()}
            </div>
         </Container>
      </Page>
   );
};

AccountFavoriteDetail.getInitialProps = ({ store, req, location, match }) => {
   const { id } = match.params;

   return store
      .dispatch(getFavoriteGroupItemsThunk(id))
      .then(unwrapResult)
      .then(data => {
         console.log('data', data);
      })
      .catch(error => {
         // this code will redirect to 404 page after any error
         return { statusCode: 404 };
      });
};

export default AccountFavoriteDetail;
