import React, {FC} from "react";

interface Props {
  onClick?: () => void
}

const IconArrowLeft: FC<Props> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M6.02745 12.875L10.0393 17.5555C10.3538 17.9225 10.3113 18.4748 9.94443 18.7893C9.57752 19.1038 9.02513 19.0613 8.71064 18.6944L3.46065 12.5694C3.17978 12.2418 3.17978 11.7582 3.46065 11.4306L8.71064 5.30557C9.02513 4.93866 9.57752 4.89617 9.94443 5.21066C10.3113 5.52515 10.3538 6.07754 10.0393 6.44445L6.02742 11.125H19.875C20.3582 11.125 20.75 11.5168 20.75 12C20.75 12.4833 20.3582 12.875 19.875 12.875H6.02745Z" fill="#6D7188"/>
        </svg>
    )
}
export default IconArrowLeft


