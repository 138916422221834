import { AppState } from '../../index';
import { createSelector } from 'reselect';
import { groupBy } from '../../../helpers/common';

export function reservationSelector(state:AppState){
   return state.reservations;
}

export const reservationCanceledSelector = createSelector(reservationSelector, (data)=> {
   return data.data.filter((item) => item.cancelled)
})

export const reservationAvailable = createSelector(reservationSelector, (data) => {
   return data.data.filter((item) => !item.cancelled)
})

export const reservationsFuture = createSelector(reservationAvailable, (data) => {
   return data.filter((item) => item.future)
})

export const reservationsPrev= createSelector(reservationAvailable, (data) => {
   return data.filter((item) => !item.future)
})

export const ordersFutureGrouped = createSelector(reservationsFuture, (data)=>{
   return groupBy(data, 'arrivalDate')
})

export const ordersPrevGrouped = createSelector(reservationsPrev, (data)=>{
   return groupBy(data, 'arrivalDate')
})