import React, { FC } from 'react';
import './SwitchLanguage.scss';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import { Form, Formik } from 'formik';
import AppFormRadio from '../../../../../components/ui/forms/controls/AppFormRadio';
import ModalLayoutPrimary from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import ModalButtons from '../../../../../components/ui/modals/Modal/components/ModalButtons/ModalButtons';

interface Props {}

const SwitchLanguage: FC<any> = props => {
   const { name, title, submitData, validationSchema, initialValues, options, RadioOptionTemplate, backPath } = props;

   return (
      <Modal backPath={backPath} className="switch-language">
         <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitData}
            enableReinitialize
         >
            <Form style={{height: '100%'}}>
               <ModalLayoutPrimary
                  title={title}
                  Footer={() => {
                     return <ModalButtons backPath={backPath} />;
                  }}
               >
                  <AppFormRadio name={name} options={options} label="" RadioOptionTemplate={RadioOptionTemplate} />
               </ModalLayoutPrimary>
            </Form>
         </Formik>
      </Modal>
   );
};

export default SwitchLanguage;
