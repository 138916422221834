import React, { FC } from "react";
import "./SearchButton.scss";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import { getUrlParams, removePlusSignsFromString } from "../../../../../helpers/url";
import { getSearchButtonText } from "../../Header/helpers/header";
import ButtonSearch from "../../../../ui/components/Buttons/ButtonSearch/ButtonSearch";

interface Props {
    onClick: () => void;
    theme: 'fillable' | 'outline' | 'gray' | 'light';
}

const SearchButton: FC<Props> = (props) => {
    const {onClick, theme} = props;
    const {search} = useLocation();
    const [t] = useTranslation();

    //@ts-ignore
    const {arrival_date = '', departure_date = '', place_name = ''} = getUrlParams(search);

    const searchButtonText =
        arrival_date && departure_date || place_name ? (
            getSearchButtonText(removePlusSignsFromString(place_name), arrival_date, departure_date)
        ) : (
            <span>{t('Місто або назва готелю')}</span>
        );

    return (
        <ButtonSearch theme={theme} onClick={onClick}>
            {searchButtonText}
        </ButtonSearch>
    )
}

export default SearchButton
