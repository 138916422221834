import React, { FC } from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {isAuthorizedSelector} from "../store/auth/auth-selector";
//push
// it's not possible call hook inside function. That's why I return component
const withPrivat = (Component: any) => {
    if(Component?.getInitialProps) {
        // @ts-ignore
        WithHook.getInitialProps = Component.getInitialProps
    }

    function WithHook() {
        const isAuthorized = useSelector(isAuthorizedSelector);
        const location = useLocation();




        return (
            isAuthorized
                ? (<Component/>)
                : <Redirect  to='/auth/signin'  from={location.pathname}/>
        )
    }

    return WithHook
}

export default withPrivat;
