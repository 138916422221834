import React, {FC} from "react";

interface Props {

}

const IconGuest: FC<Props> = (props) => {

  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3336 13V11.6667C11.3336 10.9594 11.0527 10.2811 10.5526 9.78105C10.0525 9.28095 9.37419 9 8.66695 9H3.33365C2.6264 9 1.94813 9.28095 1.44804 9.78105C0.947942 10.2811 0.666992 10.9594 0.666992 11.6667V13" stroke="#C5C6CF" strokeOpacity="0.5" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.99966 6.33331C7.47241 6.33331 8.66631 5.13941 8.66631 3.66665C8.66631 2.1939 7.47241 1 5.99966 1C4.52691 1 3.33301 2.1939 3.33301 3.66665C3.33301 5.13941 4.52691 6.33331 5.99966 6.33331Z" stroke="#C5C6CF" strokeOpacity="0.5" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


  )
}

export default IconGuest