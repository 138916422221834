import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import './Modal.scss';

import { isClient } from '../../../../helpers/common';
import useModal from './hooks/useModal';
import IconClose from '../../icons/modal/IconClose';
import { Media } from '../../../common/Media/Media';
import IconArrowLeft from '../../icons/account/IconArrowLeft';

let modalRoot: HTMLElement | null = null;

// TODO transport in to ....
if (isClient) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'modal');
    document.body.append(modalRoot);
}

interface IProps {
    backPath?: string;
    className?: string;
    wrapperClassName?: string;
    onClose?: () => void;
    noPadding?: boolean;
    closeButtonElement?: any;
    showClose?: boolean
    disableFullSizeOnMobile?: boolean
}

const Modal: FC<IProps> = props => {
    if (!isClient) return null;
    const history = useHistory();
    const {
        children,
        className = '',
        backPath,
        onClose,
        noPadding = false,
        closeButtonElement,
        wrapperClassName,
        showClose,
        disableFullSizeOnMobile
    } = props;

    const classesContent = classNames(
        'modal__content',
        {
            'modal__content--disable-full-size-on-modal': disableFullSizeOnMobile
        },
        className
    );

    const html = document.querySelector('html') as HTMLElement;
    const [scrollTop, setScrollTop] = useState(html.scrollTop);

    const classes = classNames('modal', {
        'modal--no-padding': noPadding,
        'modal--disable-full-size-on-modal': disableFullSizeOnMobile
    }, wrapperClassName);

    const revertScroll = () => {
        setTimeout(() => {
            html.scrollTop = scrollTop;
        }, 1);
    }

    // useEffect(() => {
    //     revertScroll()
    // }, []);

    useModal(goBack);

    function goBack() {
        if (onClose) {
            onClose();

            //Todo: remove page top jumping
            if (scrollTop) revertScroll()
        } else if (!onClose && backPath) {
            history.push(backPath);
        } else {
            // go back
            history.goBack();
        }

    }

    return createPortal(
        <div className={classes}>
            <div className='modal__background' onClick={goBack}/>
            <div className={classesContent}>
                {closeButtonElement || (
                    <>
                        {!disableFullSizeOnMobile && (
                            <Media at="sm">
                                <button className='modal__arrow' onClick={goBack}>
                                    {showClose ? <IconClose/> : <IconArrowLeft/>}
                                </button>
                            </Media>
                        )}

                        {typeof showClose === 'undefined' || showClose && (
                            <Media greaterThan="sm">
                                <button className='modal__close' onClick={goBack}>
                                    <IconClose/>
                                </button>
                            </Media>
                        )}
                    </>
                )}

                {children}
            </div>
        </div>,
        // @ts-ignore
        document.querySelector('#modal'),
    );

    return null;
};

export default Modal;
