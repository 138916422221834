import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './HotelDetailGallery.scss';
import CustomCarousel from '../../../../../components/ui/components/CustomCarousel/CustomCarousel';
import { MobileOnly, TabletAndUpper } from '../../../../../components/common/Media/MediaHelpers';
import { PhotoType } from '../../../../../types/common';
import { generateSrcSet } from '../../../../../helpers/common';

type Props = {
    photos: PhotoType[];
    hotelAlias: string
};

const HotelDetailGallery: React.FC<Props> = ({photos, hotelAlias}) => {
    const photosArray = photos.length ? photos.slice(0, 5) : [];
    const {search} = useLocation();

    const getLink = (index = 1) => `/hotels/${hotelAlias}/gallery/${index}${search}`;

    const Item = ({data, index, isMobile = true}: { data: PhotoType, index: number, isMobile: boolean }) => {
        const {source} = data;

        return (
            <NavLink
                to={getLink(index)}
                className={isMobile ? "hotel-detail-gallery__mobile-photo" : 'hotel-detail-gallery__photo'}
            >
                <img srcSet={generateSrcSet(source, '560x560')} alt=""/>
            </NavLink>
        )
    }

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    // const MobileCarousel = () => {
    //     return <CustomCarousel Item={Item} data={photosArray} settings={settings} theme={'secondary'}/>;
    // };

    return photosArray.length > 0 && (
        <>
            <TabletAndUpper>
                <div className={`hotel-detail-gallery hotel-detail-gallery--length-${photosArray.length}`}>
                    {photosArray.map((data: PhotoType, index: number) => (
                        <Item data={data} isMobile={false} key={index} index={index + 1}/>
                    ))}
                </div>
            </TabletAndUpper>

            <MobileOnly>
                <div className='hotel-detail-gallery'>
                    <CustomCarousel Item={Item} data={photosArray} settings={settings} theme={'secondary'}/>
                </div>
            </MobileOnly>
        </>
    )
};

//export default HotelDetailGallery;

export default React.memo(HotelDetailGallery, (prevProps, nextProps) => {
    return prevProps.hotelAlias == nextProps.hotelAlias;
});
