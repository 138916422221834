import React from 'react';

type Props = {}

const IconCalendar: React.FC<Props> = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.1452 7.4707H7.85787C6.97769 7.4707 6.26416 8.18875 6.26416 9.0745V16.1312C6.26416 17.017 6.97769 17.735 7.85787 17.735H16.1452C17.0253 17.735 17.7389 17.017 17.7389 16.1312V9.0745C17.7389 8.18875 17.0253 7.4707 16.1452 7.4707Z"
                stroke="#5ECBAA" strokeWidth="1.3" strokeMiterlimit="10"/>
            <path d="M6.26416 11.3203H17.7389" stroke="#5ECBAA" strokeWidth="1.3" strokeMiterlimit="10"/>
            <path d="M9.45166 8.67425V6.26855" stroke="#5ECBAA" strokeWidth="1.3" strokeMiterlimit="10"
                  strokeLinecap="round"/>
            <path d="M14.5674 8.67425V6.26855" stroke="#5ECBAA" strokeWidth="1.3" strokeMiterlimit="10"
                  strokeLinecap="round"/>
        </svg>
    )
};

export default IconCalendar;
