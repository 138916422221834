import React from 'react';
import './OrderPageSectionTitle.scss';

type Props = {}

const OrderPageSectionTitle: React.FC<Props> = ({children}) => {
    return (
        <span className={'order-page-section-title'}>{children}</span>
    )
}

export default OrderPageSectionTitle;
