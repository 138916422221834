import React from 'react';
import './HotelFiltersRow.scss';

type Props = {
    label: string,
    htmlFor?: string
}

const HotelFiltersRow: React.FC<Props> = ({label, htmlFor, children}) => {
    return (
        <div className={'hotel-filters-row'}>
            <label className={'hotel-filters-row__label'} htmlFor={htmlFor}>{label}</label>

            <div>
                {children}
            </div>
        </div>
    )
};

export default HotelFiltersRow;
