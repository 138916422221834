import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './FavoriteFunctionality.scss';

import { removeFromWishListThunk } from '../../../../../store/hotels/hotels-thunk';
import ModalEditGroupName from '../../../../Account/AccountFavorite/components/ModalEditGroupName/ModalEditGroupName';
import FavoriteSelectGroupModal from '../FavoriteSelectGroupModal/FavoriteSelectGroupModal';
import IconFavorites from '../../../../../components/ui/icons/hotels/IconFavorites';
import { authSelector } from '../../../../../store/auth/auth-selector';
import ShouldAuthModal from "../../../common/ShouldAuthModal/ShouldAuthModal";

interface Props {
    id: number;
    isFavorite: boolean;
    FavoriteButton?: FC<{ isSelected: boolean }>;
    fromMobileMap?: boolean;
    onClose?: () => void;
    onSetFavorite?: () => void;
    fromMap?: boolean;
}

type ModalStepType = '' | 'create' | 'select';

const FavoriteFunctionality: FC<Props> = ({id, fromMap, onSetFavorite, onClose, isFavorite, FavoriteButton, fromMobileMap}) => {
    const {isAuthorized} = useSelector(authSelector);
    const [isOpen, setIsOpen] = useState(fromMap);
    const [step, setStep] = useState<ModalStepType>(fromMap ? 'select' : '');
    const dispatch = useDispatch();

    useEffect(() => {
        if (fromMap && !isOpen && onClose) onClose();
    }, [isOpen]);

    const openFavoriteList = () => {
        if (isFavorite) {
            dispatch(
                removeFromWishListThunk({
                    hotelId: id,
                    isFavorite: false,
                }),
            );
        } else {
            setStep('select');
        }
    };

    // WORK HEREE
    if (isAuthorized) {
        return (
            <div>
                <div onClick={openFavoriteList}>
                    {FavoriteButton
                        ? <FavoriteButton isSelected={isFavorite}/>
                        : <IconFavorites isSelected={isFavorite}/>
                    }
                </div>


                {step === 'create' && (
                    <ModalEditGroupName
                        onClose={() => {
                            setStep('');
                            setIsOpen(false);
                        }}
                        hotelId={id}
                    />
                )}

                {step === 'select' && (
                    <FavoriteSelectGroupModal
                        onSetFavorite={onSetFavorite}
                        onClose={() => {
                            setStep('');
                            setIsOpen(false);
                        }}
                        onAdd={() => {
                            setStep('create');
                        }}
                        id={id}
                    />
                )}
            </div>
        );
    }

    return (
        <div>
            {isOpen && (
                <ShouldAuthModal
                    title="Для того щоб додати готель до обраного вам необхідно авторизуватись"
                    onClose={() => setIsOpen(false)}
                />
            )}

            <div onClick={() => setIsOpen(true)}>
                {
                    FavoriteButton
                        ? <FavoriteButton isSelected={isFavorite}/>
                        : <IconFavorites isSelected={false} variant={fromMobileMap ? '2' : 'default'}/>
                }
            </div>
        </div>
    );
};

export default FavoriteFunctionality;
