import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import ControlLayout from './ControlLayout/ControlLayout';
import FieldCard from '../fields/FieldCard/FieldCard';
import { FormOptionType } from '../formTypes';
import MuiRadio from "../muiFields/MuiRadio";

interface RadioOptionTemplateType {
  data: any;
  isError: boolean;
  isActive: boolean;
}

interface IProps {
  name?: string;
  options?: Array<FormOptionType> | any;
  label?: string;
  RadioOptionTemplate?: FC<RadioOptionTemplateType>;
  valueName?: string
  keyName?: string
}

const AppFormRadio: FC<IProps> = props => {
  const { name, options = [], RadioOptionTemplate, valueName, keyName } = props;

  return (
    <Field name={name}>
      {(propsField: FieldProps) => {
        const { field, form, meta } = propsField;
        const isError = meta.touched && !!meta.error;

        return (
          <ControlLayout isError={isError} error={meta.error}>
            {options.map((option: FormOptionType) => {

              const value = option[valueName || 'value'];
              const key = option[keyName || 'key'];

              const isActive = String(value) === String(field.value);

              if (RadioOptionTemplate) {
                return (
                  <FieldCard
                    key={value}
                    id={value}
                    {...field}
                    body={
                      <RadioOptionTemplate data={option} isError={isError} isActive={isActive}/>
                    }
                    value={value}
                    disabled={option.disabled}
                    // value={value}
                    isError={isError}
                    checked={isActive}
                    isActive={isActive}
                  />
                );
              }

              return (
                <div className="radio-button-block">
                  <MuiRadio
                    key={value}
                    id={value}
                    label={key}
                    {...field}
                    value={value}
                    isError={isError}
                    checked={isActive}
                  />
                </div>
              );
            })}
          </ControlLayout>
        );
      }}
    </Field>
  );
};

export default AppFormRadio;
