import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import languages from './mocks/languages.json';
import { allowedLanguagesArray, defaultLanguage } from './config';

const options = {
   supportedLngs: allowedLanguagesArray,
   fallbackLng: defaultLanguage,
   load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
   // have a common namespace used around the full app
   ns: ['common', '404'],
   defaultNS: 'common',
   detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
   },
   saveMissing: false,
   debug: false,
   interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: (value, format, lng) => {
         if (format === 'uppercase') return value.toUpperCase();
         return value;
      },
   },
   react: {
      useSuspense: false, // << ----- this line
   },
   wait: process && !process.release,
};

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
   i18n.use(Backend).use(LanguageDetector).use(initReactI18next);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
   i18n.init(options).then(() => {
      i18n.languages = allowedLanguagesArray;
      if (!i18n.language) i18n.language = defaultLanguage;
      i18n.reloadResources().then(() => {});
   });
}

//
// export const getLanguage = lng => {
//    if (allowedLanguagesArray.includes(lng)) {
//       return lng;
//    } else {
//       return defaultLanguage;
//    }
// };

if (i18n.isInitialized) {
   i18n.reloadResources().then(() => {});
}

export default i18n;
