import React, { FC, useEffect, useMemo, useState } from "react";
import Slider from 'rc-slider';
import debounce from 'lodash/debounce';
import 'rc-slider/assets/index.css';

import './PriceRange.scss'
import { FiltersInitPriceData } from "../../../../../../../types/hotel-filters";
import PriceViewer from "../../../../../../../components/ui/components/PriceViewer/PriceViewer";
import { getInitialPricePositions, getPriceForShow } from './helpers';
import { getPercentFromValue } from '../../../../../../../helpers/common';
import PriceRheostat from '../PriceRheostat/PriceRheostat';

interface Props {
    priceData: FiltersInitPriceData,
    onChangePrices: (min: number, max: number) => void,
    values: { min: number | string, max: number | string },
}

const PriceRange: FC<Props> = ({priceData, values, onChangePrices}) => {
    let {
        minPrice,
        maxPrice,
        priceFrom,
        priceTo,
        percentFrom,
        percentTo
    }: any = useMemo(() => getInitialPricePositions(priceData, values), []);

    const columnsCount = priceData.data.length;
    const {ratio, data} = priceData;

    const [selectedMinPrice, setSelectedMinPrice] = useState(values.min);
    const [selectedMaxPrice, setSelectedMaxPrice] = useState(values.max);
    const [rangePercents, setRangePercents] = useState([0, 100]);

    useEffect(() => {
        if (values) setRangePercents([percentFrom, percentTo]);
    }, []);

    const changePrices = React.useRef(debounce(onChangePrices, 200)).current;

    const onSliderChange = (value, isAfterChange = false) => {
        const priceForShowMin = getPriceForShow(minPrice, maxPrice, value[0], ratio, columnsCount, data);
        const priceForShowMax = getPriceForShow(minPrice, maxPrice, value[1], ratio, columnsCount, data);
        //
        setSelectedMinPrice(priceForShowMin);
        setSelectedMaxPrice(priceForShowMax);

        if (isAfterChange) changePrices(priceForShowMin, priceForShowMax);

        setRangePercents([
            getPercentFromValue(value[0], maxPrice),
            getPercentFromValue(value[1], maxPrice)
        ])
    }

    return (
        <div className="price-range">
            <div className='price-range__current-price-text'>
                <PriceViewer price={selectedMinPrice}/> — <PriceViewer price={selectedMaxPrice}/>
            </div>

            {/*{priceFrom} - {priceTo}*/}

            <PriceRheostat
                minPercent={rangePercents[0]}
                maxPercent={rangePercents[1]}
            />

            <Slider
                range
                allowCross={false}
                min={minPrice}
                max={maxPrice}
                onChange={onSliderChange}
                step={1}

                onAfterChange={(value) => onSliderChange(value, true)}
                defaultValue={[priceFrom, priceTo]}
                dots={false}
            />

            <div className='price-range__price-block'>
                <div className="price-range__min-price">Від <PriceViewer price={minPrice}/>/ніч</div>
                <div className="price-range__max-price">До <PriceViewer price={maxPrice}/>/ніч</div>
            </div>
        </div>
    )
}

export default PriceRange;
