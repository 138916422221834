import React, { FC } from "react";
import "./ModalMobileHeader.scss";

interface Props {
    title: string;
    description?: string;
    onClickIcon: () => void
    icon: JSX.Element
}

const ModalMobileHeader: FC<Props> = (props) => {
    const {onClickIcon, title, description, icon} = props;

    return (
        <div className="mobile-modal-header">
            <div className="mobile-modal-header__icon" onClick={onClickIcon}>
                {icon}
            </div>

            <div className="mobile-modal-header__text">
                <span className="mobile-modal-header__title">{title}</span>
                {description && <span className="mobile-modal-header__description">{description}</span>}
            </div>
            <span></span>
        </div>
    )
}

export default ModalMobileHeader
