import React from 'react';
import { NavLink } from 'react-router-dom';
import './FooterContactsSection.scss';

type Props = {}

const FooterContactsSection: React.FC<Props> = () => {
    return (
        <div className={'footer-contacts-section'}>
            {/*<div>*/}
            {/*    <a href="tel:0 800 50 40 20">*/}
            {/*        <i>*/}
            {/*            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                <path*/}
            {/*                    d="M10.0372 4C10.7698 4.14292 11.443 4.50119 11.9708 5.02895C12.4986 5.55671 12.8568 6.22995 12.9997 6.9625M10.0372 1C11.5592 1.16908 12.9784 1.85063 14.0619 2.93276C15.1454 4.01488 15.8288 5.43326 15.9997 6.955M7.16998 9.8973C6.2688 8.99612 5.55721 7.97714 5.03521 6.88992C4.99031 6.79641 4.96786 6.74965 4.95061 6.69048C4.88932 6.48021 4.93334 6.22202 5.06085 6.04395C5.09673 5.99384 5.1396 5.95097 5.22533 5.86524C5.48754 5.60303 5.61864 5.47193 5.70435 5.3401C6.0276 4.84293 6.0276 4.20199 5.70435 3.70482C5.61864 3.57299 5.48754 3.44189 5.22533 3.17968L5.07918 3.03353C4.6806 2.63495 4.48131 2.43566 4.26727 2.3274C3.8416 2.1121 3.3389 2.1121 2.91323 2.3274C2.6992 2.43566 2.49991 2.63495 2.10132 3.03353L1.9831 3.15176C1.58588 3.54897 1.38727 3.74758 1.23559 4.01761C1.06727 4.31724 0.946249 4.78261 0.947272 5.12627C0.948193 5.43598 1.00827 5.64765 1.12843 6.07098C1.77415 8.34603 2.99251 10.4928 4.78349 12.2838C6.57448 14.0748 8.72125 15.2931 10.9963 15.9389C11.4196 16.059 11.6313 16.1191 11.941 16.12C12.2847 16.121 12.75 16 13.0497 15.8317C13.3197 15.68 13.5183 15.4814 13.9155 15.0842L14.0337 14.966C14.4323 14.5674 14.6316 14.3681 14.7399 14.1541C14.9552 13.7284 14.9552 13.2257 14.7399 12.8C14.6316 12.586 14.4323 12.3867 14.0337 11.9881L13.8876 11.8419C13.6254 11.5797 13.4943 11.4486 13.3625 11.3629C12.8653 11.0397 12.2244 11.0397 11.7272 11.3629C11.5954 11.4486 11.4643 11.5797 11.202 11.8419C11.1163 11.9277 11.0734 11.9706 11.0233 12.0064C10.8453 12.1339 10.5871 12.178 10.3768 12.1167C10.3176 12.0994 10.2709 12.077 10.1774 12.0321C9.09014 11.5101 8.07116 10.7985 7.16998 9.8973Z"*/}
            {/*                    strokeWidth="1.5" strokeLinecap="round"*/}
            {/*                    strokeLinejoin="round"*/}
            {/*                />*/}
            {/*            </svg>*/}
            {/*        </i>*/}
            {/*        <span>0 800 50 40 20</span>*/}
            {/*    </a>*/}
            {/*</div>*/}

            <div>
                <NavLink to={'/'}>
                    <i>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.80699 5.11212L5.02302 7.40924C5.19036 7.52877 5.27404 7.58854 5.36161 7.60231C5.43866 7.61442 5.51758 7.60221 5.58736 7.56737C5.66668 7.52778 5.72837 7.44552 5.85176 7.281L6.53134 6.37489C6.56623 6.32836 6.58367 6.3051 6.60418 6.28503C6.62239 6.26722 6.64236 6.2513 6.6638 6.23753C6.68794 6.22203 6.71451 6.21022 6.76765 6.1866L9.6691 4.89707C9.78937 4.84361 9.84951 4.81688 9.89486 4.77514C9.93496 4.73822 9.96654 4.693 9.98739 4.64263C10.011 4.58568 10.0153 4.52002 10.0241 4.38869L10.2365 1.20205M9.625 9.625L11.587 10.4659C11.8146 10.5634 11.9285 10.6122 11.9888 10.6932C12.0417 10.7643 12.0677 10.8518 12.0622 10.9403C12.056 11.0411 11.9873 11.1441 11.8499 11.3502L10.9281 12.7328C10.863 12.8305 10.8304 12.8794 10.7874 12.9147C10.7493 12.946 10.7054 12.9695 10.6583 12.9838C10.605 13 10.5463 13 10.4289 13H8.93246C8.77695 13 8.69919 13 8.63303 12.974C8.57461 12.951 8.52289 12.9137 8.48262 12.8656C8.43701 12.811 8.41242 12.7373 8.36325 12.5897L7.82859 10.9858C7.79959 10.8988 7.78508 10.8553 7.78127 10.811C7.77788 10.7718 7.78069 10.7323 7.78958 10.694C7.79962 10.6508 7.82013 10.6097 7.86115 10.5277L8.26728 9.71544C8.3498 9.55041 8.39106 9.46789 8.45461 9.41666C8.51061 9.37151 8.57842 9.34342 8.64994 9.33575C8.73111 9.32704 8.81863 9.35621 8.99367 9.41456L9.625 9.625ZM16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
                                strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg>
                    </i>
                    <span>Info@bronui.com</span>
                </NavLink>
            </div>
        </div>
    )
};

export default FooterContactsSection;
