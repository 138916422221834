import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFavoriteGroupItemsThunk } from './favorite-group-items-thanks';
import { FavoritesItems } from '../../../types/favoritesItems';
import { SaveToWishListParams } from '../../../types/favorites';
import { removeFromWishListThunk, setToWishListThunk } from '../../hotels/hotels-thunk';

interface InitialState {
   data: FavoritesItems;
   error: any;
   isFetching: boolean;
}

const initialState: InitialState = {
   data: {
      hotels: {
         data: []
      },
      id: NaN,
      name:'',
   },
   error: {},
   isFetching: false,
};

const favoriteItemsSlice = createSlice({
   name: 'favoriteItems',
   initialState,
   reducers: {
      setToWishListFavoritePage(state, action: PayloadAction<SaveToWishListParams>) {
         const { isFavorite, hotel_id } = action.payload;

         state.data.hotels = {
            ...state.data.hotels,
            data: state.data.hotels.data.map(item => {
               if (item.id === hotel_id) {
                  item.isFavorite = isFavorite;
               }

               return item;
            }),
         };
      },
   },
   extraReducers: builder => {
      // Fetch
      builder.addCase(getFavoriteGroupItemsThunk.pending, (state, action) => {
         state.data = initialState.data
         state.isFetching = true;
      });

      builder.addCase(getFavoriteGroupItemsThunk.fulfilled, (state, action) => {
         state.isFetching = false;
         state.data = action.payload;
      });

      builder.addCase(getFavoriteGroupItemsThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.payload;
      });


      builder.addCase(setToWishListThunk.fulfilled,(state, action) => {
         favoriteItemsSlice.caseReducers.setToWishListFavoritePage(state, action)
      });

      builder.addCase(removeFromWishListThunk.fulfilled,(state, action)  =>{
         favoriteItemsSlice.caseReducers.setToWishListFavoritePage(state, action)
      })
   },
});
export const {setToWishListFavoritePage} = favoriteItemsSlice.actions

export default favoriteItemsSlice.reducer;
