import { createAsyncThunk } from '@reduxjs/toolkit';
import hotelsAPI from '../../api/hotelsAPI';

export const getRoomsThunk = createAsyncThunk(
    'rooms/getRoomsThunk', async (searchData: { alias?: string; query?: string }, {rejectWithValue}) => {
        const {alias = '', query = ''} = searchData;

        try {
           return await hotelsAPI.fetchHotelRoomsByAlias(alias, query);
        } catch (error) {
            return rejectWithValue(error.response);
        }
    },
);
