import React from 'react';
import classNames from 'classnames';
import './AppText.scss';

type Props = {
    variant: 'caption' | 'gray' | 'H100' | 'H200' | 'H300' | 'B400' | 'B500' | 'C400';
    weight?: '400' | '500' | '600' | 'normal'
    className?: string;
    style?: any;
};

const AppText: React.FC<Props> = ({variant, className, style = {}, children, weight}) => {
    const classname = classNames(
        'app-text',
        className,
        `app-text--variant-${variant}`,
        `app-text--weight-${weight}`
    );

    return (
        <span className={classname} style={style}>
         {children}
      </span>
    );
};

export default AppText;
