import React, { useState } from 'react';
import { isClient } from '../../../../../helpers/common';
import { createPortal } from 'react-dom';
import IconInfo from '../../../../../components/ui/icons/hotels/IconInfo';
import IconClose from '../../../../../components/ui/icons/hotels/IconClose';
import './HotelMessage.scss';

let modalRoot: HTMLElement | null = null;

if (isClient) {
   modalRoot = document.createElement('div');
   modalRoot.setAttribute('id', 'message');
   document.body.append(modalRoot);
}

const HotelMessage = ({ portal, showMessage, closeMessage }) => {

   if (!isClient) return null;


   const template = () => {
      return (
        <div className="hotel-detail-message" id="hotel-detail-message">
           <IconInfo />
           Ціни на проживання орієнтовні. Для уточнення актуальних цін скористайтеся безкоштовною функцією
           «Бронюйте по телефону»
           <div className="hotel-detail-message__close" onClick={closeMessage}>
              <IconClose />
           </div>
        </div>
      )
   }

   if (showMessage) {
      if (portal) {
         return createPortal(
            <div className="hotel-detail-message-wrapper">
               {template()}
            </div>,
            document.querySelector('#modal'),
         );
      } else {
         return (
            <div style={{ padding: 15 }}>
               {template()}
            </div>
         );
      }
   }

   return null;
};

export default HotelMessage;
