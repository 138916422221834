import React, {FC, useEffect} from "react";
import "./Accordion.scss";
import {Collapse} from 'react-collapse';
import useSwitcher from "../../../../hooks/useSwitcher";
import classNames from "classnames";

interface Props {
    HeaderComponent: any,
    BodyComponent: any,
}

const Accordion: FC<Props> = (props) => {
    const {HeaderComponent, BodyComponent} = props;
    const {isOpen = true, onToggle, onOpen} = useSwitcher()
    const classes = classNames('accordion', {isOpen})

    useEffect(onOpen,[])

    return (
        <div className={classes}>
            <div className="accordion__header" onClick={onToggle}>
                <HeaderComponent isOpen={isOpen}/>
            </div>

            <Collapse isOpened={isOpen}>
                <div className="accordion__content"><BodyComponent/></div>
            </Collapse>
        </div>
    )
}

export default Accordion
