import { createAsyncThunk } from '@reduxjs/toolkit';
import hotelsAPI from '../../api/hotelsAPI';

export const getCommentsThunk = createAsyncThunk(
   'comments/getCommentsThunk',
   async (id: string, { rejectWithValue }) => {
      try {
         return await hotelsAPI.fetchHotelCommentsById(id);
      } catch (error) {
         return rejectWithValue(error.response);
      }
   },
);
