import { AppState } from '../index';
import { HotelPropertyItem } from '../../types/hotel';
import { RoomItemPrepared } from '../../types/rooms';

export const getRoomsSelector = (state: AppState) => state.rooms;

export const getRoomsWithMealSelector = (state: AppState): RoomItemPrepared[] => {
    const properties = state?.rooms?.data?.meta?.properties?.data || [];

    return state.rooms.data.data.map(item => {
        const roomProperties = properties ? properties.filter((property: HotelPropertyItem) => {
            //@ts-ignore // TODO
            return item.properties.includes(property.id);
        }) : [];

        const prices = item?.prices?.data;
        const mealTypes = state?.rooms?.data?.meta?.mealTypes?.data || [];

        return {
            ...item,
            properties: roomProperties,
            prices: {
                ...item.prices,
                //data: []
                data: (Array.isArray(prices) ? prices.map(price => {
                    return {
                        ...price,
                        mealType: Array.isArray(mealTypes) && mealTypes.find(item => item.id === price.mealTypeId),
                    };
                }) : []),
            },
        };
    });
};
