import { createSlice } from '@reduxjs/toolkit';
import { cancelAccountOrdersDetailThunk, cancelAccountOrdersReservationDetailThunk, getAccountOrdersDetailThunk } from './account-orders-detail-thunks';
import { AccountOrdersDetailData } from '../../../types/account-orders/account-orders-detail';
// import { OrdersList } from '../../../types/account-orders-detail-detail';

interface InitialState {
   data: AccountOrdersDetailData;
   error: any;
   isFetching: boolean;
   isSending: boolean,
}

const initialState: InitialState = {
   data: {
      address: "",
      photos: [],
      adults: 0,
      alias: "",
      amount: 0,
      arrivalDate: new Date().toLocaleDateString("en-US"),
      canCancel: false,
      cancelled: false,
      contacts: {
         data: [],
         meta: {
            communicationTypes:{
               data: [],
            }
         }},
      departureDate: new Date().toLocaleDateString("en-US"),
      future: true,
      hotel: "",
      id: 0,
      kids: 0,
      latitude: 0,
      longitude: 0,
      photo: "",
      remainderAmount: 0,
      reservations: {data: []},
      status: {
         name: "",
         alias: "cart",
      },
      toddlers: 0,
   },
   error: {},
   isSending: false,
   isFetching: false,
};

const ordersSlice = createSlice({
   name: 'accountOrdersDetail',
   initialState,
   reducers: {},
   extraReducers: builder => {
      // Fetch
      builder.addCase(getAccountOrdersDetailThunk.pending, (state) => {
         state.data = initialState.data
         state.isFetching = true;
      });

      builder.addCase(getAccountOrdersDetailThunk.fulfilled, (state, action) => {
         state.isFetching = false;
         state.data = action.payload;
      });

      builder.addCase(getAccountOrdersDetailThunk.rejected, (state, action) => {
         state.isFetching = false;
         state.error = action.payload;
      });

      // cancel order
      builder.addCase(cancelAccountOrdersDetailThunk.pending, (state) => {
         state.isSending = true;
      });

      builder.addCase(cancelAccountOrdersDetailThunk.fulfilled, (state, action) => {
         state.isSending = false;
      });

      builder.addCase(cancelAccountOrdersDetailThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      });

      // cancel orders reservation
      builder.addCase(cancelAccountOrdersReservationDetailThunk.pending, (state) => {
         state.isSending = true;
      });

      builder.addCase(cancelAccountOrdersReservationDetailThunk.fulfilled, (state, action) => {
         state.isSending = false;
      });

      builder.addCase(cancelAccountOrdersReservationDetailThunk.rejected, (state, action) => {
         state.isSending = false;
         state.error = action.payload;
      });
   },
});

// cancelAccountOrdersDetailThunk
// cancelAccountOrdersReservationDetailThunk
export default ordersSlice.reducer;
