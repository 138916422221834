import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import OrderIndexReservations from './components/OrderIndexReservations/OrderIndexReservations';
import OrderPaymentTypeSelect from './components/OrderPaymentTypeSelect/OrderPaymentTypeSelect';
import OrderPageSectionTitle from './components/OrderPageSectionTitle/OrderPageSectionTitle';
import OrderIndexHotelInfo from './components/OrderIndexHotelInfo/OrderIndexHotelInfo';
import OrderIndexSummaryReservations from './components/OrderIndexSummaryReservations/OrderIndexSummaryReservations';
import PaymentDropdown from './components/PaymentDropdown/PaymentDropdown';
import OrderIndexPageSubmitSection from './components/OrderIndexPageSubmitSection/OrderIndexPageSubmitSection';
import AppText from '../../../components/ui/typography/AppText/AppText';
import { OrderResponse } from '../../../types/order';
import OrderPageInfoClickableBlocks from './components/OrderPageInfoClickableBlocks';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/auth/auth-selector';
import AppButton from '../../../components/ui/components/Buttons/AppButton/AppButton';
import { Log } from '@3ddie/react-utils';

type Props = {
    serverOrderData: OrderResponse
}

const OrderIndexDesktop: React.FC<Props> = ({serverOrderData}) => {
    const reservations = serverOrderData?.data?.reservations?.data || [];
    const {isAuthorized} = useSelector(authSelector);
    const history = useHistory();
    const {pathname} = useLocation();

    const logData = {...serverOrderData.data}
    delete logData.hotel;
    delete logData.reservations;

    return (
        <>
            <h1 className={'order-page__title'}>Оформлення бронювання</h1>

            <div className={'order-page__content'}>
                <div className={'order-page__order-info'}>
                    <div className={'order-page__order-info-top-line'}>
                        <OrderPageSectionTitle>
                            Ваше бронювання № {serverOrderData.data.id}
                        </OrderPageSectionTitle>

                        <AppText variant={'gray'}>{serverOrderData.data.personDescription}</AppText>
                    </div>

                    <OrderIndexReservations
                        reservations={reservations}
                        hotelAlias={serverOrderData?.data?.hotel?.data?.alias || ''}
                    />

                    <OrderPageInfoClickableBlocks/>
                </div>

                <div className={'order-page__summary'}>
                    <OrderPageSectionTitle>Підсумок замовлення</OrderPageSectionTitle>
                    <OrderIndexHotelInfo hotel={serverOrderData?.data?.hotel?.data}/>

                    <OrderIndexSummaryReservations
                        reservations={reservations}
                        amount={serverOrderData.data.amount}
                        prepaymentAmount={serverOrderData.data.prepaymentAmount}
                        afterAmount={serverOrderData.data.reminderAmount}
                    />

                    {isAuthorized ? (
                        <>
                            {/*<Log value={serverOrderData.data.paymentTypes}/>*/}
                            <OrderPaymentTypeSelect options={serverOrderData.data.paymentTypes}/>
                            <OrderIndexPageSubmitSection/>
                        </>
                    ) : (
                        <AppButton
                            className={'order-index-page-submit-section__order-button'}
                            onClick={() => {
                                history.push(`/auth/signin?redirect-url=${pathname}`);
                            }}
                            size='big'
                        >
                            {'Увійти та продовжити'}
                        </AppButton>
                    )}

                </div>
            </div>
        </>
    )
};

export default OrderIndexDesktop;
