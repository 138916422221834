import * as Yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import { parse, isDate, format } from "date-fns";

Yup.setLocale({
    mixed: {
        required: 'Заповніть це поле',
    },
    string: {
        email: 'E-mail невалідний'
    }
})

// вілідації телефону
Yup.addMethod(Yup.string, 'phone', function (message: string) {
    return this.test({
        message,
        name: 'phone',
        test(value) {
            try {
                // @ts-ignore
                const cleanString = value.replace(/[|&;$%@"<>()+,]/g, '');
                const isValid = parsePhoneNumber(`+${cleanString}`).isValid();

                return isValid;
            } catch (error) {
                return false;
            }
        },
    });
});

// вілідації дати
Yup.addMethod(Yup.string, 'date', function (message: string) {
    return this.test({
        message,
        name: 'date',
        test(value) {
            // @ts-ignore
            return /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.\d{4}$/.test(value)
        },
    });
});

// @ts-ignore
const defaultPhoneSchema = Yup.string().required("Заповніть це поле").min(8, "Телефон не є валідним").phone("Телефон не є валідним").nullable();

// @ts-ignore
export const signInSchema = Yup.object({
    // @ts-ignore
    login: defaultPhoneSchema,
    password: Yup.string().required("Заповніть це поле"),
});

export const phoneSchema = Yup.object({
    // @ts-ignore
    login: defaultPhoneSchema,
});

export const codeSchema = Yup.object({
    code: Yup.string().required("Заповніть це поле").min(4, "не менше 4 символів"),
});

export const completeSchema = Yup.object({
    name: Yup.string().required("Заповніть це поле"),
    lastname: Yup.string().required("Заповніть це поле"),
    email: Yup.string().email('E-mail невалідний').required("Заповніть це поле"),
    gender: Yup.number().oneOf([1, 2], 'Оберіть стать').required('Заповніть це поле'),
    // @ts-ignore
    birthday: Yup.date()
      .transform(function (value, originalValue) {
          if (this.isType(value)) {
              return value;
          }
          const result = parse(originalValue, "dd.MM.yyyy", new Date());

          return result;
      })
      .typeError("Дата не є валідною")
      .required()
      .min("1900-01-01", "Вкажіть справжній свій вік")
      .max(format(new Date, "yyyy-MM-dd"), 'Оберіть дату до сьогодні'),
    password: Yup.string().required("Заповніть це поле"),
    password_confirmation: Yup.string()
        .required("Заповніть це поле")
        // @ts-ignore
        .oneOf([Yup.ref('password', '')], 'паролі не збігаються')
        .min(8, 'не менше 8 символів'),
});

export const formResetSchema = Yup.object({
    // @ts-ignore
    login: defaultPhoneSchema,
    code: Yup.string().required("Заповніть це поле").min(4, "не менше 4 символів"),
    password: Yup.string().required("Заповніть це поле"),
    password_confirmation: Yup.string()
        .required("Заповніть це поле")
        // @ts-ignore
        .oneOf([Yup.ref('password', '')], 'паролі не збігаються')
        .min(8, 'не менше 8 символів'),
})

// function parseDateString(value, originalValue) {
//   const parsedDate = isDate(originalValue)
//      ? originalValue
//      : parse(originalValue, "dd.MM.yyyy", new Date());
//
//   return parsedDate;
// }
// birthday: Yup.date('Дата не є валідною').transform(parseDateString),//.date('Дата не є валідною'), // .date('Дата не є валідною'),

export const editProfilePersonalSchema = Yup.object({
    name: Yup.string().required("Заповніть це поле"),
    lastname: Yup.string().required("Заповніть це поле"),
    //@ts-ignore
    // birthday: Yup.string().date('Дата не є валідною'), // .date('Дата не є валідною'),


    birthday: Yup.date()
      .transform(function (value, originalValue) {
          if (this.isType(value)) {
              return value;
          }
          const result = parse(originalValue, "dd.MM.yyyy", new Date());

          return result;
      })
      .typeError("Дата не є валідною")
      .required()
      .min("1900-01-01", "Вкажіть справжній свій вік")
      .max(format(new Date, "yyyy-MM-dd"), 'Оберіть дату до сьогодні'),

    gender: Yup.number().oneOf([1, 2], 'Оберіть стать').required('Заповніть це поле'),
})

export const editProfilePlaceSchema = Yup.object({
    countryId: Yup.number().required('Заповніть це поле'),
    city: Yup.string().required("Заповніть це поле"),
})

export const editProfileChangePasswordSchema = Yup.object({
    code: Yup.string().required("Заповніть це поле").min(4, "не менше 4 символів"),
    password: Yup.string().required("Заповніть це поле"),
    password_confirmation: Yup.string()
        .required("Заповніть це поле")
        // @ts-ignore
        .oneOf([Yup.ref('password', '')], 'паролі не збігаються')
        .min(8, 'не менше 8 символів'),
})

export const editProfileContactsSchema = Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
    email: Yup.string().email('E-mail невалідний').required("Заповніть це поле"),
    showCode: Yup.boolean(),
    code: Yup.string().when("showCode", {
        is: true,
        then: Yup.string().required("Заповніть це поле").min(4, "не менше 4 символів")
    }),
    password: Yup.string().when("showPassword", {
        is: true,
        then: Yup.string().required("Заповніть це поле").min(8, "не менше 8 символів")
    }),
})

export const switchCountrySchema = Yup.object({
    country: Yup.string().required('Заповніть це поле'),
})

export const switchLanguageSchema = Yup.object({
    language: Yup.string().required('Заповніть це поле'),
})

export const switchCurrencySchema = Yup.object({
    currency: Yup.string().required('Заповніть це поле'),
})

export const uploadPhotoSchema = Yup.object({
    photo: Yup.mixed().required('Загрузіть фото'),
})

export const editFavoriteGroupName = Yup.object({
    name: Yup.string().required("Заповніть це поле"),
})

export const contactSchema = Yup.object({
    name: Yup.string().required(),
    // @ts-ignore
    phone: defaultPhoneSchema,
    email: Yup.string().email('E-mail невалідний').required("Заповніть це поле"),
    comment: Yup.string().required(),
})

export const feedbackFormSchema = Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
})


export const  selectRegistrationHotelForm =  Yup.object({
    hotel_id: Yup.string().nullable().required('Заповніть це поле'),
})

export const  newHotelRegistrationForm =  Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
})


export const  registrationFormCompleteSchema =  Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
    admin_name: Yup.string().required("Заповніть це поле"),
    admin_lastname: Yup.string().required("Заповніть це поле"),
    email: Yup.string().email('E-mail невалідний').required("Заповніть це поле"),
    password: Yup.string().required("Заповніть це поле"),
    password_confirmation: Yup.string()
      .required("Заповніть це поле")
      // @ts-ignore
      .oneOf([Yup.ref('password', '')], 'паролі не збігаються')
      .min(8, 'не менше 8 символів'),

})



export const phoneCallbackSchema = Yup.object({
    // @ts-ignore
    phone: defaultPhoneSchema,
});