import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomItemPriceType } from '../../types/rooms';
import { Hotel } from '../../types/hotel';
import {
    OrderPaymentResponse,
    OrderResponse,
    OrderResponsePaymentCardType,
    OrderResponsePaymentType,
    ReservationItem,
    SearchDataForOrder
} from '../../types/order';
import {
    addToOrderThunk,
    editReservationThunk,
    getOrderThunk,
    goToPaymentThunk,
    removeReservationThunk
} from './order-thunk';
import { PendingAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { compareReservation } from '../../helpers/order';
import { setContactToken } from '../../helpers/network';

interface InitialState {
    hotel?: Hotel; //Current hotel for orders
    isLoading: boolean;
    reservations: Array<ReservationItem>;
    serverOrderData?: OrderResponse,
    storingOnServerData?: {
        success: boolean,
        reason: string
    },
    selectedPaymentMethod?: OrderResponsePaymentCardType,
    selectedPaymentType?: OrderResponsePaymentType,
    paymentServerData?: OrderPaymentResponse,
    paymentServerDataError?: any,
    canGetCartAfterPost: boolean
}

const initialState: InitialState = {
    isLoading: false,
    reservations: [],
    serverOrderData: null,
    storingOnServerData: null,
    selectedPaymentMethod: null,
    selectedPaymentType: null,
    paymentServerData: null,
    paymentServerDataError: null,
    canGetCartAfterPost: true
};

function isPendingAction(action: AnyAction): action is PendingAction<any, any> {
    const commonTypes = ['order/getOrderThunk/pending', 'order/removeReservationThunk/pending', 'order/editReservationThunk/pending'];

    return commonTypes.includes(action.type) && action.type.endsWith('/pending');
}

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        orderRoom(
            state,
            action: PayloadAction<{
                roomId: number;
                roomName: string;
                roomPrice: RoomItemPriceType;
                roomsCount: number;
                searchData: SearchDataForOrder
                mealTypeName: string;
                basePlaces: number;
                extraPlaces: number;
            }>,
        ) {
            const {
                roomId,
                roomPrice,
                roomName,
                roomsCount,
                searchData,
                mealTypeName,
                basePlaces,
                extraPlaces
            } = action.payload;
            const {reservations} = state;

            const generateReservation = (): ReservationItem => {
                return {
                    id: roomId,
                    price: roomPrice,
                    name: roomName,
                    count: roomsCount,
                    roomTypeId: roomId,
                    basePlaces,
                    extraPlaces,
                    searchData,
                    mealTypeName
                }
            }

            if (reservations) {
                const roomInOrder = reservations.find(roomInOrder => compareReservation(roomInOrder, roomPrice, roomId));

                if (roomInOrder) {
                    state.reservations = reservations.map(el => {
                        if (el?.id === roomId) {
                            return {
                                ...el,
                                count: compareReservation(roomInOrder, el.price, roomId) ? roomsCount : el.count,
                            }
                        } else {
                            return el;
                        }
                    });

                    return;
                }
            }

            state.reservations.push(generateReservation());

        },
        removeRoomFromOrder(state, action: PayloadAction<{ roomId: number, rateId: number }>) {
            const {roomId, rateId} = action.payload;

            state.reservations = state.reservations.filter(room => !(room.id === roomId && room.price.rateId === rateId));
        },
        clearOrder(state) {
            state.reservations = [];
        },
        clearStoringOnServerData(state) {
            state.storingOnServerData = null;
        },
        clearPaymentServerData(state) {
            state.paymentServerData = null;
            state.paymentServerDataError = null;
        },
        setSelectedPaymentMethod(state, action) {
            state.selectedPaymentMethod = action.payload;
        },
        setSelectedPaymentType(state, action) {
            state.selectedPaymentType = action.payload;
        },
        setCatGetCartAfterPost(state, action) {
            state.canGetCartAfterPost = action.payload;
        }
    },
    extraReducers(builder) {
        const onGetCart = (state, actionPayload: OrderResponse) => {
            const storingOnServerData = actionPayload?.data?.storing;
            state.storingOnServerData = (storingOnServerData && storingOnServerData.success === false) ? storingOnServerData : null;

            const reservations = actionPayload?.data?.reservations?.data;
            const contactToken = actionPayload?.data?.contactToken;

            if (contactToken) setContactToken(contactToken, true);

            const setServerData = Array.isArray(reservations) && reservations.length;

            state.serverOrderData = setServerData ? actionPayload : null;
            state.isLoading = false;
        }

        /**
         * Add order
         */
        builder.addCase(addToOrderThunk.pending, (state) => {
            state.canGetCartAfterPost = false;
            state.storingOnServerData = null;
            state.isLoading = true;
        })

        builder.addCase(addToOrderThunk.fulfilled, (state, action: PayloadAction<OrderResponse>) => {
            onGetCart(state, action.payload);
        })

        builder.addCase(addToOrderThunk.rejected, (state, action) => {
            state.isLoading = false;
        })

        /**
         * Get order
         */
        builder.addCase(getOrderThunk.pending, (state, action) => {
            state.isLoading = true;
            state.serverOrderData = action.payload;
        })

        builder.addCase(getOrderThunk.fulfilled, (state, action) => {
            onGetCart(state, action.payload);
        })

        builder.addCase(getOrderThunk.rejected, state => {
            state.reservations = [];
            state.isLoading = false;
            state.serverOrderData = null;
        })

        /**
         * Remove reservation
         */
        builder.addCase(removeReservationThunk.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(removeReservationThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action?.payload?.data?.reservations?.data?.length)
                state.serverOrderData = action.payload;
            else
                state.serverOrderData = null;
        })

        builder.addCase(removeReservationThunk.rejected, (state) => {
            state.isLoading = false;
        })

        /**
         * Edit reservation
         */
        builder.addCase(editReservationThunk.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(editReservationThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.serverOrderData = action.payload;
            state.paymentServerDataError = null;
        })

        builder.addCase(editReservationThunk.rejected, (state, action: any) => {
            if (action?.payload?.status === 404) state.serverOrderData = null;
            state.isLoading = false;
        })

        /**
         * Go to payment
         */
        builder.addCase(goToPaymentThunk.pending, (state) => {
            state.isLoading = true;
        })

        builder.addCase(goToPaymentThunk.rejected, (state, action) => {
            state.isLoading = false;
            state.paymentServerDataError = action.payload;

            if (action?.payload?.status_code === 404) {
                state.serverOrderData = null;
            }
        })

        builder.addCase(goToPaymentThunk.fulfilled, (state, action: PayloadAction<{ data: OrderPaymentResponse }>) => {
            state.paymentServerData = action.payload.data;

            if (!action?.payload?.data?.redirect?.data?.bodyRequest) {
                state.isLoading = false;
            }
        })
    }
});

export const {
    orderRoom,
    removeRoomFromOrder,
    clearOrder,
    clearStoringOnServerData,
    setSelectedPaymentMethod,
    setSelectedPaymentType,
    setCatGetCartAfterPost,
    clearPaymentServerData
} = orderSlice.actions;

export default orderSlice.reducer;
