import React from 'react';
import './FieldMaterialInputEditButton.scss';
import IconEdit from '../../../../../icons/icons/IconEdit';
import AppLoader from '../../../../../loaders/AppLoader/AppLoader';

type Props = {
    state: 'editing' | 'loading' | 'default',
    onClick: () => void
}

const FieldMaterialInputEditButton: React.FC<Props> = ({state, onClick}) => {
    return (
        <button className={'field-material-input-edit-button'} disabled={state === 'loading'} onClick={onClick}>
            {state === 'editing' && <span className={'field-material-input-edit-button__save-label'}>Зберегти</span>}
            {state === 'default' && <IconEdit fill={'#C5C6CF'} size={18}/>}
            {state === 'loading' && <AppLoader size={24}/>}
        </button>
    )
}

export default FieldMaterialInputEditButton;
