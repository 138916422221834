import React, {FC} from "react";

interface Props {

}

const IconMore: FC<Props> = (props) => {
    return (
        <svg width="24" height="26.6" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.625 14.2102C15.6432 14.2102 14.8472 13.4143 14.8472 12.4324C14.8472 11.4506 15.6432 10.6547 16.625 10.6547C17.6068 10.6547 18.4028 11.4506 18.4028 12.4324C18.4028 13.4143 17.6068 14.2102 16.625 14.2102ZM22.8472 14.2102C21.8654 14.2102 21.0694 13.4143 21.0694 12.4324C21.0694 11.4506 21.8654 10.6547 22.8472 10.6547C23.8291 10.6547 24.625 11.4506 24.625 12.4324C24.625 13.4143 23.8291 14.2102 22.8472 14.2102ZM10.4028 14.2102C9.42094 14.2102 8.625 13.4143 8.625 12.4324C8.625 11.4506 9.42094 10.6547 10.4028 10.6547C11.3846 10.6547 12.1806 11.4506 12.1806 12.4324C12.1806 13.4143 11.3846 14.2102 10.4028 14.2102Z" fill="#6D7188"/>
        </svg>
    )
}
export default IconMore


