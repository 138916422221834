import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';

import { getFilterFormikValues } from './hotelsFilterHelpers';
import hotelsAPI from '../../../../../api/hotelsAPI';
import { getHotelsSelector } from '../../../../../store/hotels/hotels-selector';
import { serializeQueryStringParameter } from '../../../../../helpers/url';
import { getDeepDataObject, getNested } from '../../../../../helpers/common';

const useHotelsFilterLogic = (filtersInitData, getUrlSearchParamsWithoutFilters) => {
    const query = useRef(getUrlSearchParamsWithoutFilters()).current;

    const hotels = useSelector(getHotelsSelector);
    const [isLoading, setIsLoading] = useState(false);
    const [filterChanged, setFilterChanged] = useState(false);
    const [totalHotels, setTotalHotels] = useState<number | undefined>(hotels.data.meta.pagination.total);
    const [isTouched, setIsTouched] = useState(false);
    const [searchQuery, setSearchQuery] = useState('?' + query);

    let filtersFromServer = hotels?.data?.meta?.filters;

    const formikInitialValues = getFilterFormikValues(filtersInitData, filtersFromServer);
    const controller = useRef<any>(new AbortController());

    const cancelRequest = () => {
        if (controller?.current?.abort) {
            controller.current.abort();
        }
    }

    useEffect(() => {
        return cancelRequest;
    }, []);

    const searchOnServer = debounce((values: typeof formikInitialValues) => {
        cancelRequest();

        setIsLoading(true);
        setFilterChanged(true);
        setIsTouched(true);

        const baseQuery = '?' + query + '&' + serializeQueryStringParameter({filters: values}, false);
        setSearchQuery(baseQuery);

        const _controller = new AbortController();

        hotelsAPI.fetchHotels(baseQuery + '&count_only=1', _controller.signal)
            .then(data => {
                const resultCount = getNested(getDeepDataObject(data), ['count'], 0);
                setTotalHotels(resultCount);
                setIsLoading(false);
            });

        controller.current = _controller;
    }, 300);

    // const applyFiltersAndGetHotels = () => new Promise<HotelList>((resolve, reject) => {
    //     hotelsAPI
    //         .fetchHotels(searchQuery)
    //         .then(data => resolve(data))
    //         .finally(() => setIsLoading(false));
    // })

    return {
        isLoading,
        searchOnServer,
        hotels,
        totalHotels,
        filterChanged,
        formikInitialValues,
        filtersQuery: searchQuery,
        isTouched,
        //applyFiltersAndGetHotels
    }
}

export default useHotelsFilterLogic;
