import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './PriceRheostat.scss';
import { getHotelInitialFiltersSelector } from '../../../../../../../store/hotels/hotels-selector';
import { getPercentFromValue } from '../../../../../../../helpers/common';

type Props = {
    minPercent: number,
    maxPercent: number
}

const PriceRheostat: React.FC<Props> = ({minPercent, maxPercent}) => {
    const filtersInitData = useSelector(getHotelInitialFiltersSelector);
    const rheostatData = filtersInitData?.price?.data || [];
    const maxColumnHeight = rheostatData.reduce((acc, item) => Math.max(acc, item.quantity), -Infinity)

    const [inited, setInited] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setInited(true);
        }, 700);
    }, [])

    return rheostatData ? (
        <div>
            <div className='price-rheostat'>
                {rheostatData.map((column, index) => {
                    const columnPercent = (index / rheostatData.length) * 100;
                    const isColumnNotInRange = (minPercent > 1.5 && columnPercent < minPercent) || columnPercent + 1.5 >= maxPercent;

                    return (
                        <div
                            key={'price-rheostat-column' + index}
                            className={`price-rheostat__rheostat-column ${inited && isColumnNotInRange ? 'not-in-range' : ''}`}
                            style={{
                                height: getPercentFromValue(column.quantity, maxColumnHeight) + '%',
                            }}
                            title={(column.end - column.start).toString()}
                        >
                            {/*<span>{column.start}</span>*/}
                            {/*<span>{column.end}</span>*/}
                            {/*<span>{index}</span>*/}
                            {/*<span>{column.end - column.start}</span>*/}
                        </div>
                    )
                })}
            </div>
        </div>
    ) : null;
}

export default PriceRheostat;
