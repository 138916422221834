import React, {FC} from "react";

interface Props {

}

const AuthLogo: FC<Props> = (props) => {

    return (
        <svg width="363" height="69" viewBox="0 0 363 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.1489 68.6023C40.1629 68.6023 50.6814 58.5408 50.6814 43.9077C50.6814 28.1595 38.6352 20.1444 27.1611 20.1444C20.3708 20.1444 14.7313 22.9385 11.3867 28.0677V1.22168H0V43.9995C0 58.9081 10.3301 68.6023 25.1489 68.6023ZM25.2431 58.2587C16.9252 58.2587 11.3799 52.4803 11.3799 44.3733C11.3799 36.2664 16.9252 30.4879 25.2431 30.4879C33.561 30.4879 39.2005 36.2664 39.2005 44.3733C39.2005 52.4803 33.561 58.2587 25.2431 58.2587Z"
                fill="white"/>
            <path
                d="M60.0889 67.3885H71.4688V40.6475C71.4688 33.1899 75.2913 30.4876 80.8365 30.4876C86.3818 30.4876 90.2043 33.1899 90.2043 40.6475V41.1132H101.49V40.8311C101.49 26.7621 93.1721 20.144 80.8365 20.144C68.501 20.144 60.0889 26.7621 60.0889 40.6475V67.3951V67.3885Z"
                fill="white"/>
            <path
                d="M231.327 67.3887H242.707V43.4353C242.707 34.6724 247.485 30.4812 254.659 30.4812C261.833 30.4812 266.611 34.6724 266.611 43.4353V67.3887H277.991V43.4353C277.991 27.9626 268.623 20.1377 254.659 20.1377C240.695 20.1377 231.327 28.061 231.327 43.4353V67.3887Z"
                fill="white"/>
            <path
                d="M312.602 68.6021C326.56 68.6021 335.934 60.6788 335.934 45.3045V21.3511H324.554V45.3045C324.554 54.0674 319.776 58.2586 312.602 58.2586C305.429 58.2586 300.65 54.0674 300.65 45.3045V21.3511H289.271V45.3045C289.271 60.7772 298.638 68.6021 312.602 68.6021Z"
                fill="white"/>
            <path
                d="M354.568 15.389C358.969 15.389 362.119 12.3129 362.119 8.02985C362.119 3.74682 358.963 0.572266 354.568 0.572266C350.174 0.572266 347.011 3.74026 347.011 8.02985C347.011 12.3194 350.167 15.389 354.568 15.389ZM348.835 67.3888H360.214V21.3512H348.835V67.3888Z"
                fill="white"/>
            <rect x="120.646" y="25.4353" width="93.5394" height="37.8755" rx="18.9377" stroke="#EB5757"
                  strokeWidth="10.5825"/>
        </svg>
    )
}

export default AuthLogo