import React, {FC} from "react";

interface Props {
  className?: string;
}

const IconProfile: FC<Props> = (props) => {

  return (
    <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28 0C29.0544 0 29.9182 0.815878 29.9945 1.85074L30 2V20C30 21.0544 29.1841 21.9182 28.1493 21.9945L28 22H2C0.945638 22 0.0818349 21.1841 0.00548577 20.1493L0 20V2C0 0.945638 0.815878 0.0818347 1.85074 0.00548553L2 0H28ZM28 2H2V20H28V2ZM25 14V16H17V14H25ZM9 6C10.6569 6 12 7.34315 12 9C12 9.61679 11.8139 10.1901 11.4947 10.6668C12.9919 11.5293 14 13.1469 14 15H12C12 13.6941 11.1656 12.5831 10.0009 12.171L10 9C10 8.44772 9.55228 8 9 8C8.48716 8 8.06449 8.38604 8.00673 8.88338L8 9L8.00009 12.1707C6.83485 12.5825 6 13.6937 6 15H4C4 13.1469 5.00806 11.5293 6.50571 10.6656C6.18613 10.1901 6 9.61679 6 9C6 7.34315 7.34315 6 9 6ZM25 10V12H17V10H25ZM25 6V8H17V6H25Z"
        fill="#C5C6CF"/>
    </svg>

  )
}

export default IconProfile