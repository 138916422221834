import React, { FC } from 'react';

interface Props {

}

const IconMessenger: FC<Props> = (props) => {

   return (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M13 0.8125C5.95837 0.8125 0.5 5.9705 0.5 12.9375C0.5 16.5817 1.9935 19.7307 4.4257 21.9058C4.62988 22.0885 4.75312 22.3445 4.7615 22.6185L4.82958 24.842C4.85133 25.5512 5.58393 26.0128 6.23293 25.7262L8.714 24.631C8.92432 24.5382 9.16 24.521 9.38163 24.582C10.5218 24.8955 11.7352 25.0625 13 25.0625C20.0416 25.0625 25.5 19.9045 25.5 12.9375C25.5 5.9705 20.0416 0.8125 13 0.8125Z" fill="url(#paint0_radial_3801_47906)"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M5.49396 16.4838L9.16584 10.6583C9.74991 9.73159 11.0007 9.50084 11.877 10.1581L14.7975 12.3484C15.0654 12.5494 15.4341 12.5483 15.7009 12.3458L19.6451 9.35247C20.1715 8.95297 20.8587 9.58297 20.5064 10.142L16.8345 15.9676C16.2504 16.8943 14.9997 17.1251 14.1233 16.4678L11.2028 14.2774C10.9349 14.0765 10.5662 14.0775 10.2994 14.28L6.35521 17.2734C5.82879 17.6729 5.14159 17.0429 5.49396 16.4838Z" fill="white"/>
         <defs>
            <radialGradient id="paint0_radial_3801_47906" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.31186 25.6788) scale(27.2399)">
               <stop stopColor="#0099FF"/>
               <stop offset="0.609754" stopColor="#A033FF"/>
               <stop offset="0.934823" stopColor="#FF5280"/>
               <stop offset="1" stopColor="#FF7061"/>
            </radialGradient>
         </defs>
      </svg>

   );
};

export default IconMessenger;