import React from 'react';
import './HotelFilterContentBlock.scss';

type Props = {
    title: string,
    className?: string
}

const HotelFilterContentBlock: React.FC<Props> = ({title, className, children}) => {
    return (
        <div className={`hotel-filter-content-block ${className || ''}`}>
            <span className={'hotel-filter-content-block__title'}>{title}</span>
            <div>{children}</div>
        </div>
    )
};

export default HotelFilterContentBlock;
