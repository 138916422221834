import React, { FC } from 'react';
import './HotelsIcons.scss';

interface Props {
    stars: number;
    text?: string;
    color?: string;
}

const Stars: FC<Props> = props => {
    const {stars, text, color = '#6D5ECB'} = props;

    return (
        <div className={'star-block'}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.993 3.09923C8.30996 2.12373 9.69004 2.12373 10.007 3.09923L10.7829 5.48722C10.9247 5.92348 11.3312 6.21885 11.7899 6.21885H14.3008C15.3265 6.21885 15.753 7.53138 14.9231 8.13428L12.8918 9.61013C12.5207 9.87976 12.3654 10.3577 12.5072 10.7939L13.2831 13.1819C13.6 14.1574 12.4835 14.9686 11.6537 14.3657L9.62236 12.8899C9.25126 12.6202 8.74874 12.6202 8.37764 12.8899L6.3463 14.3657C5.51648 14.9686 4.39997 14.1574 4.71693 13.1819L5.49284 10.7939C5.63459 10.3577 5.4793 9.87976 5.1082 9.61013L3.07686 8.13428C2.24704 7.53138 2.67351 6.21885 3.69922 6.21885H6.21009C6.6688 6.21885 7.07535 5.92348 7.21709 5.48722L7.993 3.09923Z"
                    fill={color}
                />
                <mask
                    className="star-svg"
                    id="mask0_1685_3908"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="16"
                    height="16"
                >
                    <path
                        d="M16.9374 6.7818C16.8616 6.54829 16.66 6.378 16.417 6.34247L11.6808 5.6502L9.56287 1.35955C9.45421 1.13938 9.22996 1 8.98444 1C8.73892 1 8.5147 1.13938 8.40601 1.35955L6.28811 5.6502L1.5518 6.34247C1.3089 6.378 1.1072 6.54829 1.03147 6.7818C0.955745 7.01531 1.01915 7.27156 1.195 7.44285L4.62165 10.7804L3.81295 15.4954C3.77143 15.7375 3.87096 15.9821 4.0696 16.1264C4.26824 16.2707 4.5316 16.2897 4.74896 16.1754L8.98441 13.948L13.2199 16.1754C13.3143 16.225 13.4173 16.2495 13.52 16.2495C13.6538 16.2495 13.7868 16.208 13.8992 16.1264C14.0979 15.982 14.1974 15.7374 14.1559 15.4954L13.3472 10.7803L16.7739 7.44282C16.9497 7.27156 17.0131 7.01534 16.9374 6.7818ZM12.204 10.0929C12.0519 10.2411 11.9824 10.4547 12.0183 10.6641L12.6634 14.4251L9.28463 12.6483C9.19063 12.5988 9.08752 12.5741 8.98438 12.5741C8.88127 12.5741 8.77809 12.5988 8.68412 12.6482L5.30541 14.4251L5.95046 10.6641C5.98636 10.4547 5.91693 10.2411 5.76476 10.0929L3.03085 7.4301L6.80981 6.87777C7.01968 6.84708 7.20105 6.71518 7.29493 6.52499L8.98441 3.10242L10.6738 6.52499C10.7677 6.71521 10.9491 6.84708 11.1589 6.87777L14.9379 7.43013L12.204 10.0929Z"
                        fill={'white'}
                    />
                </mask>
                <g mask="url(#mask0_1685_3908)">
                    <path className="star" d="M21 -3.35965H-3V20.6404H21V-3.35965Z" fill={color}/>
                </g>
            </svg>

            {/*Todo: fix style, refactor star components*/}
            <div className="star-numberOfStars" style={{marginRight: 5}}>
                {stars}
            </div>
            <span className="star-text">{text}</span>
        </div>
    );
};
// style="mask-type:alpha"

export default Stars;
