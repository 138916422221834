import React, { FC } from 'react';
import useModal from '../../../ui/modals/Modal/hooks/useModal';
import IconClose from '../../../ui/icons/modal/IconClose';
import ButtonSecondary from '../../../ui/components/Buttons/ButtonsSecondary/ButtonSecondary';
import IconSearch from '../../../ui/icons/form/IconSearch';
import IconCalendar from '../../../ui/icons/form/IconCalendar';
import IconGuest from '../../../ui/icons/form/IconGuest';
import type { Step } from './HeaderMobile';

interface Props {
    onClose: () => void;
    setStep: (step: Step) => void;
    searchButtonText: string;
    datesButtonText: string;
    guestButtonText: string;
}

const SearchFormMobile: FC<Props> = props => {
    const {onClose, setStep, searchButtonText, datesButtonText, guestButtonText} = props;

    useModal(onClose);

    return (
        <div className={"search-mobile-form"}>
            <div className="search-mobile-form__close-background" onClick={onClose}/>
            <div className="search-mobile-form__inner">
                <div className="search-mobile-form__header">
                    <IconClose onClick={onClose}/> <span>Змінити параметри пошуку</span>
                </div>

                <div className="search-mobile-form__buttons">
                    <div className="search-mobile-form__button">
                        <ButtonSecondary
                            onClick={() => {
                                setStep('search');
                            }}
                            text={searchButtonText}
                            icon={<IconSearch/>}
                        />
                    </div>

                    <div className="search-mobile-form__button">
                        <ButtonSecondary
                            onClick={() => setStep('dates')}
                            text={datesButtonText}
                            icon={<IconCalendar/>}
                        />
                    </div>

                    <div className="search-mobile-form__button">
                        <ButtonSecondary
                            onClick={() => {
                                setStep('guests');
                            }}
                            text={guestButtonText}
                            icon={<IconGuest/>}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchFormMobile;
