import React, { FC } from 'react';
import './TextWithDescription.scss';

interface Props {

}

const TextWithDescription = ({ text, description }) => {
   return (
      <div className="orders-text-with-description">
         <div className="orders-text-with-description__text">{text}</div>
         <div className="orders-text-with-description__description">{description}</div>
      </div>
   );
};
export default TextWithDescription