import React from 'react';
import './HotelsSortingDropdown.scss'

import OutsideClick from '../../../../../components/common/OutsideClick/OutsideClick';
import FieldRadio from '../../../../../components/ui/forms/fields/FieldRadio/FieldRadio';
import { useSelector } from 'react-redux';
import { getSortingOptions } from '../../../../../store/hotels/hotels-selector';
import useSearchForm from '../../../common/HotelSearchForm/hooks/useSearchForm';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import { useAppSelector } from '../../../../../hooks/reduxHooks';

type Props = {
    onClose: () => void,
    isModalMode?: boolean
}

const HotelsSortingDropdown: React.FC<Props> = ({onClose, isModalMode}) => {
    const history = useHistory();
    const hotelMeta = useAppSelector(state => state.hotels.data.meta);

    const defaultFortableOptionIdFromMeta = hotelMeta?.sortable.find(option => option.default)?.id;

    const {orderBy, getQuery, changeUrlParam} = useSearchForm();
    const sortingOptions = useSelector(getSortingOptions);

    const onChange = (orderById: number) => {
        changeUrlParam('order_by', orderById.toString());
        changeUrlParam('page', '1');
        history.replace(`/hotels${getQuery()}`);
        onClose();
    }

    const content = sortingOptions.map(sortingOption => (
        <FieldRadio
            key={'sortingOption' + sortingOption.id}
            name={'sortingOption' + sortingOption.id} label={sortingOption.name}
            onChange={() => onChange(sortingOption.id)}
            checked={
                orderBy ? orderBy === String(sortingOption.id) :
                String(defaultFortableOptionIdFromMeta) === String(sortingOption.id)
            }
        />
    ))

    return isModalMode ? (
        <Modal onClose={onClose}>
            <ModalLayoutPrimary title={'Сортувати за'} noPaddingTop>
                {content}
            </ModalLayoutPrimary>
        </Modal>
    ) : (

        <div className='hotels-sorting-dropdown'>
            <OutsideClick onOutsideClick={onClose}>
                {content}
            </OutsideClick>
        </div>
    )
};

export default HotelsSortingDropdown;
