import { useEffect, useState } from 'react';
import { RatesResponse, RoomItemPriceType, RoomMeal, RoomTypeInOrderEdit } from '../../../../../types/rooms';
import { _formatDate, getDifferenceInNights } from '../../../../../helpers/date';
import { DATE_FORMAT_SERVER } from '../../../../../config';
import { fetchData } from '../../../../../helpers/network';
import { OrderReservationEditPutData, OrderResponseReservationItem } from '../../../../../types/order';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { editReservationThunk } from '../../../../../store/order/order-thunk';

const formatDates = (arrivalDate, departureDate) => {
    const arrivalDateFormatted = _formatDate(arrivalDate, DATE_FORMAT_SERVER);
    const departureDateFormatted = _formatDate(departureDate, DATE_FORMAT_SERVER);

    return {arrivalDateFormatted, departureDateFormatted};
}

export const useReservationEditModalLogic = (hotelAlias: string, reservation: OrderResponseReservationItem) => {
    const [roomTypes, setRoomTypes] = useState([]);
    const [selectedRoomType, setSelectedRoomType] = useState<RoomTypeInOrderEdit>(null);
    const [selectedPrice, setSelectedPrice] = useState<RoomItemPriceType>();
    const [isLoading, setIsLoading] = useState(true);
    const [dates, setDates] = useState<[any, any]>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        getRates(reservation.arrivalDate, reservation.departureDate);
    }, []);

    useEffect(() => setSelectedPrice(null), [selectedRoomType]);

    const nights = dates && dates.length === 2 ? getDifferenceInNights(dates[0], dates[1]) : 1;

    const getRates = (arrivalDate, departureDate) => {
        setDates([arrivalDate, departureDate]);
        const {arrivalDateFormatted, departureDateFormatted} = formatDates(arrivalDate, departureDate)

        const url = `hotels/${hotelAlias}/rates?arrival_date=${arrivalDateFormatted}&departure_date=${departureDateFormatted}`;
        setIsLoading(true);
        setSelectedPrice(null);

        fetchData(url).then((data: RatesResponse) => {
            if (data?.data?.length) {
                const mealTypes = data.meta.mealTypes.data;

                const withMealTypes: RoomTypeInOrderEdit[] = data.data.map((item: RoomTypeInOrderEdit) => ({
                    ...item,
                    prices: {
                        data: item.prices.data.map(price => ({
                            ...price,
                            mealType: mealTypes.find((mealType: RoomMeal) => price.mealTypeId === mealType.id)
                        }))
                    }
                }));

                setRoomTypes(withMealTypes);

                setSelectedRoomType(withMealTypes.find(item => item.id === (selectedRoomType ? selectedRoomType.id : reservation.roomTypeId)));
            }
        }).finally(() => setIsLoading(false));
    }

    const saveNewReservationData = () => {
        const sendData: OrderReservationEditPutData = {
            arrival_date: _formatDate(dates[0], DATE_FORMAT_SERVER),
            departure_date: _formatDate(dates[1], DATE_FORMAT_SERVER),
            rate_id: selectedPrice.rateId,
            room_type_id: selectedRoomType.id
        }

        dispatch(editReservationThunk({reservationId: reservation.id, data: sendData}));
    }

    return {
        roomTypes,
        selectedRoomType,
        setSelectedRoomType,
        isLoading,
        getRates,
        selectedPrice,
        setSelectedPrice,
        saveNewReservationData,
        nights
    }
}
