import React, { FC } from "react";
import "./ButtonSearch.scss";
import classNames from "classnames";
import IconSearch from "../../../icons/header/IconSearch";

interface Props {
    theme: 'fillable' | 'outline' | 'gray' | 'light';
    onClick: () => void
}

const ButtonSearch: FC<Props> = (props) => {
    const {theme, children, ...rest} = props;
    const classes = classNames("button-search", `button-search--${theme}`)

    return (
        <button className={classes} {...rest}>
            <div className="button-search__text">{children}</div>
            <IconSearch/>
        </button>
    )
}

export default ButtonSearch
