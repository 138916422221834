import { SearchFormPlaceType } from '../../../../../types/hotel';
import { removePlusSignsFromString } from '../../../../../helpers/url';

export const formatPlacesToOptions = (places: SearchFormPlaceType[]) => {
    return places.map((place: SearchFormPlaceType) => ({
        value: place.hotel_id || place.place_id,
        place: place,
        label: place.name
    }))
}

export const fillPlaceObject = (hotel_id, place_id, place_name, latitude, longitude) => {
    return {
        value: place_id || hotel_id,
        place: {
            hotel_id,
            place_id,
            coordinates: {
                latitude,
                longitude
            },
            name: '',
            type: hotel_id ? 'hotel' : 'place'
        } as SearchFormPlaceType,
        label: removePlusSignsFromString(place_name)
    }
}
