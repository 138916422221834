import React, { useEffect, useState } from 'react';
import styles from './Tabs.module.scss';

type Props = {
    selected?: any,
    buttons: Array<{
        label: string,
        value?: any,
        content: any
    }>,
    onChange?: (value: any) => void
}

const Tabs: React.FC<Props> = ({buttons, selected, onChange}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(
        selected ? buttons.findIndex(b => b.value === selected) : 0
    );

    useEffect(() => {
        if (onChange) {
            onChange(buttons[activeTabIndex]?.value);
        }
    }, [activeTabIndex]);

    return (
        <div>
            <ul className={styles['tabs-list']}>
                {buttons.map((button, index) => (
                    <li key={'tab-list' + index}>
                        <button
                            onClick={() => setActiveTabIndex(index)}
                            className={index === activeTabIndex ? styles.active : ''}
                        >
                            {button.label}
                        </button>
                    </li>
                ))}
            </ul>

            <div>
                {buttons.map((button, index) => index === activeTabIndex && (
                    <div key={'tab-button-' + index}>
                        {button.content}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default Tabs;
