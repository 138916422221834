import React, { FC } from 'react';
import './HotelsIcons.scss';

interface Props {
    isSelected: boolean;
    color?: string
}

const IconFavoritesHotelDetailVariant: FC<Props> = ({isSelected, color}) => (
    <div>
            <span className="favorites-button">
               <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.08 9.22787C16.9842 8.32405 17.4923 7.09779 17.4923 5.81913C17.4923 4.54052 16.9843 3.31432 16.08 2.41039C15.1761 1.5061 13.9499 0.998047 12.6713 0.998047C11.3926 0.998047 10.1663 1.50615 9.26241 2.41051L9.00086 2.67206L8.73919 2.41039C6.8566 0.527795 3.80431 0.527795 1.92171 2.41039C0.0391169 4.29298 0.039117 7.34527 1.92171 9.22787L8.47654 15.7827C8.76611 16.0723 9.2356 16.0723 9.52518 15.7827L16.08 9.22787Z"
                        strokeWidth={1.5}

                        fill={isSelected ? "#E47B79" : 'none'}
                        stroke={isSelected ? "none" : (color || '#C5C6CF')}
                    />
                </svg>
            </span>
    </div>
);

export default IconFavoritesHotelDetailVariant;
