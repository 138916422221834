import React from 'react';

type Props = {}

const FieldMaterialInputValidCheck: React.FC<Props> = () => {
    return (
        <div className={'field-material-input__valid-check'}>
            <svg width="18" height="13"
                 viewBox="0 0 18 13" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.74378 5.19521C1.37462 4.78443 0.74235 4.7507 0.331573 5.11986C-0.0792043 5.48902 -0.11294 6.12129 0.256223 6.53206L1.74378 5.19521ZM5.70588 11.1L4.96211 11.7684C5.32408 12.1712 5.94091 12.2127 6.35353 11.8619L5.70588 11.1ZM17.6476 2.26194C18.0685 1.90425 18.1196 1.27316 17.7619 0.852352C17.4043 0.431544 16.7732 0.380374 16.3524 0.738061L17.6476 2.26194ZM0.256223 6.53206L4.96211 11.7684L6.44966 10.4316L1.74378 5.19521L0.256223 6.53206ZM6.35353 11.8619L17.6476 2.26194L16.3524 0.738061L5.05823 10.3381L6.35353 11.8619Z"
                    fill="#5ECBAA"/>
            </svg>
        </div>
    )
};

export default FieldMaterialInputValidCheck;
