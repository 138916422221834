import React from 'react';

type Props = {}

const OrderPageSeparator: React.FC<Props> = () => {
    return (
        <div className={'order-page__mobile-block-separator'}/>
    )
};

export default OrderPageSeparator;
