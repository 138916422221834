import React, {FC} from "react";
import "./Mui.scss";
import classNames from "classnames";
import {uid} from "../../../../helpers/common";

interface Props {
  name?: string;
  id?: string;
  className?: string;
  isError?: boolean;
  value?: string;
  checked?: boolean;
  label?: string;
}

const MuiRadio: FC<Props> = (props) => {
  const {id, className, isError, label, ...rest} = props;
  const classes = classNames('radio', className);

  return (
    <div className="form-radio">
      <div className={classes}>
        <label>
          <input id={id || uid()} {...rest} type="radio"/><i className="helper"></i>
          {label}
        </label>
      </div>
    </div>
  );
}

export default MuiRadio