import { useEffect } from 'react';

const useIsOverflow = (ref, callback: (hasOverflow: boolean) => void) => {
    useEffect(() => {
        const {current} = ref;
        let resizeObserver: ResizeObserver;

        const trigger = () => {
            const hasOverflow = current.scrollHeight > current.clientHeight;
            callback && callback(hasOverflow);

            //if (hasOverflow !== isOverflow) setIsOverflow(hasOverflow);
        };

        if (current) {
            if ('ResizeObserver' in window) {
                resizeObserver = new ResizeObserver(trigger);
                resizeObserver.observe(current);
            }

            trigger();
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        }

    }, []);
};

export default useIsOverflow;
