import React from 'react';
import './LivingVariants.scss';

import ReservationEditModalTitle from '../ReservationEditModalTitle/ReservationEditModalTitle';
import { createBemClassProp } from '../../../../../../../helpers/common';
import { RoomItemPriceType, RoomTypeInOrderEdit } from '../../../../../../../types/rooms';
import FieldRadio from '../../../../../../../components/ui/forms/fields/FieldRadio/FieldRadio';
import RoomPriceMealIcon from '../../../../../../../components/ui/icons/hotels/rooms/RoomPriceMealIcon';
import PriceViewer from '../../../../../../../components/ui/components/PriceViewer/PriceViewer';
import { pluralize } from '../../../../../../../helpers/translatioHelpers';

type Props = {
    selectedRoomType: RoomTypeInOrderEdit,
    onSelectPrice: (selectedPrice: RoomItemPriceType) => void,
    nights?: number,
    showBlur?: boolean,
    selectedPrice?: RoomItemPriceType
}

const LivingVariants: React.FC<Props> = ({selectedRoomType, nights = 1, onSelectPrice, showBlur, selectedPrice}) => {
    const cn = createBemClassProp('reservation-edit-modal-living-variants');

    return (
        <div {...cn('', showBlur ? 'with-blur' : '')}>
            <ReservationEditModalTitle>Варіанти проживання</ReservationEditModalTitle>

            <div {...cn('list')}>
                {selectedRoomType?.message?.text ? (
                    <div {...cn('no-results')}>
                        {selectedRoomType?.message?.text || ''}
                    </div>
                ) : (
                    <>
                        {selectedRoomType && selectedRoomType?.prices?.data.length ? (
                            <>
                                {selectedRoomType.prices.data.map((price, index) => (
                                    <FieldRadio
                                        markerPosition='right' name='meal'
                                        onChange={(e) => onSelectPrice(price)}
                                        checked={price.rateId === selectedPrice?.rateId}
                                        key={'livingVariant' + price.rateId + index}

                                    >
                                        <div {...cn('list-item-content')}>
                                            <div>
                                                <RoomPriceMealIcon variant={price.mealType.code}/>
                                                <span {...cn('list-item-content__title')}>{price.mealType.name}</span>
                                            </div>

                                            <div {...cn('list-item-content__right-texts')}>
                                        <span {...cn('list-item-content__nights-text')}>
                                            Ціна за {pluralize('ніч', nights)}
                                        </span>

                                                <PriceViewer
                                                    price={price.totalPrice} {...cn('list-item-content__price')}/>
                                            </div>
                                        </div>
                                    </FieldRadio>
                                ))}
                            </>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    )
};

export default LivingVariants;
