import { unwrapResult } from '@reduxjs/toolkit';
import { isClient } from '../../../helpers/common';
import { serializeQueryStringParameter } from '../../../helpers/url';
import { getRoomsThunk } from '../../../store/rooms/rooms-thunks';
import { getHotelThunk } from '../../../store/hotel/hotel-thunk';
import { getCommentsThunk } from '../../../store/comments/comments-thunks';

export const getHotelData = (dispatch, query, alias) => {
    return Promise.all([
        dispatch(getHotelThunk(alias))
            .then(unwrapResult)
            .catch(error => {
                return {statusCode: error?.status}
            }),
        dispatch(getRoomsThunk({alias, query}))
            .then(unwrapResult)
            .catch(error => {}),
        dispatch(getCommentsThunk(alias))
            .then(unwrapResult)
            .catch(error => {
                /* return {statusCode: 404}*/
            }),
    ])
}

export const getHotelDetailInitialProps = async ({store, req, scrollToTop, match, location}) => {
    const {alias} = match.params;

    if (!isClient) {
        try {
            const data = await getHotelData(store.dispatch, serializeQueryStringParameter(req.query), alias).catch(e => {
                console.log('HOTEL DATA ERROR', e);
            });

            if (data?.[0]?.statusCode === 404)
                return {statusCode: 404}

            return data;
        } catch (error) {
            console.log('typeof error', typeof error, error);
            return {error}
        }
    }
}
