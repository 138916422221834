import React, { FC, useEffect, useRef, useState } from 'react';
// import io from "socket.io-client";
import { format } from 'date-fns';
import './AccountMessages.scss';
import Page from '../../../components/common/Page/Page';
import PageHeader from '../common/PageHeader/PageHeader';
import Container from '../../../components/ui/layouts/Container/Container';
import MessagesList from './components/MessagesList/MessagesList';
import { useDispatch, useSelector } from 'react-redux';
import { getMessagesSelector } from '../../../store/messages/messages-selector';
import { getMessagesThunk, sendMessagesThunk } from '../../../store/messages/messages-thunks';
import { profileSelector } from '../../../store/profile/profile-selector';
import Conversations from './components/Conversations/Conversations';
import MessagesEditable from './components/MessagesEditable/MessagesEditable';
import { useLocation } from 'react-router-dom';
import { Media } from '../../../components/common/Media/Media';
import Modal from '../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary from '../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import { getUrlParams } from '../../../helpers/url';
import ConversationsTabs from '../common/ConversationsTabs/ConversationsTabs';
import Image from '../../../components/ui/components/Image/Image';
import { RouteNames } from '../../../routes';

// const socket = io.connect('');
interface Props {}

const AccountMessages: FC<Props> = props => {
   const { messages, conversations } = useSelector(getMessagesSelector);
   const profile = useSelector(profileSelector);
   const { search } = useLocation();
   const dispatch = useDispatch();
   const ref = useRef<null | HTMLDivElement>();



   //socket
   // useEffect(() => {
      // socket.on("message", (data) => {
      //    const {message, lastname, name} = data;
      //    dispatch(
      //       sendMessagesThunk({
      //          fullName: lastname + ' ' + name,
      //          time: format(new Date(), 'H:mm'),
      //          message: message,
      //          photo: 'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
      //       }),
      //    );
      //
      // });
   // }, [socket])


   useEffect(() => {
      dispatch(getMessagesThunk());
   }, [search]);

   const handleSubmit = (message: string) => {
      const { lastname, name } = profile.data.data;
      // socket.emit("chat", {});
      dispatch(
         sendMessagesThunk({
            fullName: lastname + ' ' + name,
            time: format(new Date(), 'H:mm'),
            message: message,
            photo: 'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
         }),
      );
   };

   const breadcrumbs = [
      {
         url: RouteNames.PROFILE_INDEX,
         title: 'Акаунт',
      },
      {
         title: 'Повідомлення',
      },
   ];

   //@ts-ignore
   const { conversation_id } = getUrlParams(search);

   useEffect(() => {
      console.log('eee');
      // after response
      if(ref.current && ref.current.scrollIntoView){
         // ref.current.scrollTo(0, 9999999);
         ref.current.scrollIntoView({ behavior: "smooth" })

      }
   }, [messages]);

   return (
      <Page isSearchFormOpen={false}>
         <Container>
            <PageHeader breadcrumbs={breadcrumbs} title="Повідомлення" />

            {/*MOBILE*/}
            <Media at="sm">
               <Modal className="conversations-mobile">
                  <ModalLayoutPrimary title="Повідомлення">
                     <ConversationsTabs
                        onClickRight={()=>{}}
                        onClickLeft={()=>{}}
                        leftTextButton="Повідомлення"
                        rightTextButton="Сповіщення"
                     />
                     <Conversations conversations={conversations} />
                  </ModalLayoutPrimary>
               </Modal>

               {conversation_id && (
                  <Modal className="messages-mobile">
                     <ModalLayoutPrimary
                        title="Baza Smart Hotel"
                        Footer={() => <MessagesEditable onSubmit={handleSubmit} />}
                     >

                           <div className="messages-mobile__outer" >
                              <div className="messages-mobile-header">
                                 <Image className="messages-mobile-header__image"  src="https://i.pinimg.com/originals/d1/dc/97/d1dc979c4664242c74b144a9b115de9c.jpg" />
                                 <div className="messages-mobile-header__text">
                                    <div className="messages-mobile-header__hotel-name">Baza Smart Hotel<span>•Olesya</span></div>
                                    <div className="messages-mobile-header__description">Бронювання скасовано 12–15 серпня 2019</div>
                                 </div>
                              </div>
                              <MessagesList messages={messages} />
                              <div ref={ref}></div>
                           </div>
                     </ModalLayoutPrimary>
                  </Modal>
               )}
            </Media>
            {/* DESKTOP */}
            <Media greaterThan="sm">
               <div className="account-messages">
                  <div className="account-messages-header account-messages__header">
                     <ConversationsTabs
                        onClickRight={()=>{}}
                        onClickLeft={()=>{}}
                        leftTextButton="повідомлення"
                        rightTextButton="Сповіщення"
                     />

                     <div className="account-messages-header__info">
                        <h4>Baza Smart Hotel</h4>
                        <span>Швидкість відповіді: 1 година</span>
                     </div>
                  </div>

                  <div className="account-messages-body account-messages__body">
                     <div className="account-messages-body__users">
                        <Conversations conversations={conversations} />
                     </div>

                     <div className="account-messages-body__wrapper">
                        <div className="account-messages-body__outer" >
                           <div className="account-messages-body__messages">
                              <MessagesList messages={messages} />
                           </div>
                           <div ref={ref}/>
                        </div>

                        <div className="account-messages-body__editable">
                           <MessagesEditable onSubmit={handleSubmit} />
                        </div>
                     </div>
                  </div>
               </div>
            </Media>
         </Container>
      </Page>
   );
};

export default AccountMessages;
