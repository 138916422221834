import React, { useEffect, useState } from 'react';
import './HotelDetailHeader.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';

import useScroll from '../../../../../hooks/useScroll';
import Container from '../../../../../components/ui/layouts/Container/Container';
import useSearchForm from '../../../common/HotelSearchForm/hooks/useSearchForm';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import PriceViewer from '../../../../../components/ui/components/PriceViewer/PriceViewer';
import { pluralize } from '../../../../../helpers/translatioHelpers';
import HotelDetailHeaderNav from './components/HotelDetailHeaderNav/HotelDetailHeaderNav';
import { orderDataSelector } from '../../../../../store/order/order-selector';
import BookButton from '../../../../../components/ui/components/Buttons/BookButton/BookButton';
import { setHotelSearchFormFocus } from '../../../../../store/rooms/rooms-reducer';
import { AppState } from '../../../../../store';
import { formatDate } from '../../../../../helpers/date';
import { DATE_FORMAT_CLIENT_SHORT_DATEFNS } from '../../../../../config';
import { addToOrderThunk } from '../../../../../store/order/order-thunk';
import { useHistory } from 'react-router-dom';
import { getHotelSelector } from '../../../../../store/hotel/hotel-selector';

type Props = {};

const HotelDetailHeader: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {focusOnHotelSearchForm} = useSelector((state: AppState) => state.rooms);
    const [show, setShow] = useState(false);
    const [baseHeaderHeight, setBaseHeaderHeight] = useState(0);
    const {scrollY} = useScroll();
    const {startDate, endDate, nights} = useSearchForm();
    const {selectedOrderBookType, data: hotelData} = useSelector(getHotelSelector);

    const {reservations, totalRoomsCount, totalPrice} = useSelector(orderDataSelector);
    const isLoading = useSelector((state: AppState) => state.order.isLoading);

    useEffect(() => {
        const header: HTMLElement = document.querySelector('.header');

        if (header) setBaseHeaderHeight(header.offsetHeight);
    }, []);

    useEffect(() => {
        if (scrollY > baseHeaderHeight && !show) setShow(true);
        if (scrollY < baseHeaderHeight && show) setShow(false);
    }, [scrollY]);

    const className = classNames('hotel-detail-header', 'fixed-header', {'hotel-detail-header--visible': show});

    const onClickOnSelectRoomButton = () => {
        if (!focusOnHotelSearchForm) {
            scroller.scrollTo('hotelDetailSearchForm', {
                offset: -100, smooth: true, duration: 300,
                //containerId: 'layoutContainer'
            });

            dispatch(setHotelSearchFormFocus(true));
            setTimeout(() => {
                dispatch(setHotelSearchFormFocus(false));
            }, 1300);
        }
    };

    const onClickOnBook = () => {
        scroller.scrollTo('hotelPhonesSection', {
            offset: -100, smooth: true, duration: 300,
            //containerId: 'layoutContainer'
        });
    }

    const onBook = async () => {
        const result = await dispatch(addToOrderThunk());

        if (result.error && result?.payload?.data?.status_code === 401) history.push('/auth/signin');

        history.push('/order');
    }

    return (
        <div className={className}>
            <Container className={'hotel-detail-header__container'}>
                <HotelDetailHeaderNav/>

                <div className={'hotel-detail-header__price-and-data-content'}>
                    {reservations.length ? (
                        <>
                            <div>
                                <span className={'hotel-detail-header__price-and-data-content__price'}>
                                   <span className={'hotel-detail-header__price-and-data-content__price-value'}>
                                      Обрано {pluralize('номер', totalRoomsCount)}
                                   </span>
                                </span>

                                {startDate && endDate && (
                                    <span className={'hotel-detail-header__price-and-data-content__dates'}>
                                        {formatDate(startDate, DATE_FORMAT_CLIENT_SHORT_DATEFNS)} - {formatDate(endDate, DATE_FORMAT_CLIENT_SHORT_DATEFNS)}
                                    </span>
                                )}
                            </div>

                            <BookButton price={totalPrice} onClick={onBook} isLoading={isLoading}/>
                        </>
                    ) : (
                        <>
                            <div>
                                {/*<span className={'hotel-detail-header__price-and-data-content__price'}>*/}
                                {/*   <span className={'hotel-detail-header__price-and-data-content__price-value'}>*/}
                                {/*      Від <PriceViewer price={28999}/>*/}
                                {/*   </span>*/}
                                {/*    */}
                                {/*    {' '}*/}
                                {/*    / {pluralize('ніч', nights, true)}*/}
                                {/*</span>*/}

                                {startDate && endDate && (
                                    <span className={'hotel-detail-header__price-and-data-content__dates'}>
                                        {formatDate(startDate, 'dd MMMM')} - {formatDate(endDate, 'dd MMMM')}
                                    </span>
                                )}
                            </div>

                            {selectedOrderBookType === 'cart' &&
                               <AppButton onClick={onClickOnSelectRoomButton}>Вибрати номер</AppButton>}

                            {
                                hotelData?.phones?.data?.length && selectedOrderBookType !== 'cart'
                                    ? <AppButton onClick={onClickOnBook} theme={'primary'}>Бронювати</AppButton>
                                    : null
                            }
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default HotelDetailHeader;
