import React from 'react';
import './OrderIsEmpty.scss';
import AppText from '../../../../../components/ui/typography/AppText/AppText';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import image from '../../../../../assets/images/order-empty.svg';
import { useHistory } from 'react-router-dom';

type Props = {}

const OrderIsEmpty: React.FC<Props> = () => {
    const history = useHistory();

    return (
        <div className={'order-empty-page'}>
            <AppText variant={'H300'} weight={'600'} className={'order-empty-page__title'}>
                Ваше бронювання
            </AppText>

            <AppText variant={'B500'} className={'order-empty-page__description-text'}>
               Замовлення пусте
            </AppText>

            <img src={image} alt="" className={'order-empty-page__image'}/>

            <AppText variant={'B500'} className={'order-empty-page__description-text'}>
                На bronui.com більше 13 539 місць для проживання. <br/>Ми впевнені, що хоч один підійде вам.
            </AppText>

            <AppButton onClick={() => history.push('/')}>
                Пошук готелів
            </AppButton>
        </div>

    )
};

export default OrderIsEmpty;
