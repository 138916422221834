import { fetchData, putData } from '../helpers/network';
import { OrdersResponse } from '../types/orders';

class AccountOrdersListApi {
   public fetchOrders(){
      return fetchData<OrdersResponse>('/orders')
   }
   public hideOrder(orderId) {
      return putData(`/orders/${orderId}/hide`);
   }
}

export default new AccountOrdersListApi();