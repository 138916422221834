import React from 'react';
import './HotelsListLoadMoreSection.scss';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import { createBemClassProp } from '../../../../../helpers/common';

type Props = {
    onClick: () => void,
    isLoading: boolean
}

const HotelsListLoadMoreSection: React.FC<Props> = ({onClick, isLoading}) => {
    const cn = createBemClassProp('hotels-list-load-more-section');

    return (
        <div {...cn()}>
            <AppButton
                {...cn('button')}
                theme='outline'
                onClick={onClick}
                isLoading={isLoading}
            >
                Завантажити ще
            </AppButton>
        </div>
    )
};

export default HotelsListLoadMoreSection;
