import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MobileHotelDetailsFooter from '../components/MobileHotelDetailsFooter/MobileHotelDetailsFooter';
import MobileRoomsFlow from '../MobileRoomsFlow/MobileRoomsFlow';
import { getHotelSelector } from '../../../../store/hotel/hotel-selector';
import { AppState } from '../../../../store';

type Props = {}

const HotelDetailMobile: React.FC<Props> = () => {
    const {data: hotelData, bookingState, selectedOrderBookType} = useSelector(getHotelSelector);
    const roomsData = useSelector((state: AppState) => state.rooms);
    const history = useHistory();
    const {pathname, search, hash} = useLocation();
    const {phones} = hotelData;

    return (
        <div>
            <MobileHotelDetailsFooter
                showFeedbackButton={bookingState === 'phone' && !!phones?.data?.length}
                showRoomsButton={!!roomsData?.data?.data?.length}
                onSelectRooms={() => {
                    history.push(pathname + search + '#show-rooms');
                }}
            />

            {hash.startsWith('#show-rooms') && (
                <MobileRoomsFlow
                    hotel={hotelData}
                    onClose={() => {
                        history.replace(pathname + search);
                    }}
                    showFooter={selectedOrderBookType === 'phone'}
                    //showFooter
                />
            )}
        </div>
    )
};

export default HotelDetailMobile;
