import React, { FC, useState } from 'react';
import './FieldCounter.scss';
import classNames from 'classnames';
import IconSecondaryDecrement from '../../../icons/form/IconSecondaryDecrement';
import IconPrimaryDecrement from '../../../icons/form/IconPrimaryDecrement';
import IconSecondaryIncrement from '../../../icons/form/IconSecondaryIncrement';
import IconPrimaryIncrement from '../../../icons/form/IconPrimaryIncrement';

interface Props {
    onChange?: (value: number) => void;
    value?: number;
    min?: number;
    max?: number;
    theme?: 'primary' | 'secondary';
    text?: string;
}

const FieldCounter: FC<Props> = React.memo(props => {
    const {value, min = 0, max = 10, theme = 'primary', text} = props;

    const classes = classNames('field-counter', `field-counter--${theme}`, {'field-counter--is-first': value === min}, {'field-counter--is-last': value === max});

    const decrement = () => {
        if (value > min) {
            if (props.onChange) {
                props.onChange(value - 1);
            }
        }
    };

    const increment = () => {
        if (value < max) {
            if (props.onChange) {
                props.onChange(value + 1);
            }
        }
    };

    return (
        <div className={classes}>
            <button onClick={decrement} className="field-counter__minus" data-testid="field-decrement">
                {theme === 'secondary' ? <IconSecondaryDecrement/> : <IconPrimaryDecrement/>}
            </button>
            <div className="field-counter__center">
                <input value={value} readOnly data-testid="field-input"/>
                {text && <div className={'field-counter__text'}>{text}</div>}
            </div>
            <button onClick={increment} className="field-counter__plus" data-testid="field-increment">
                {theme === 'secondary' ? <IconSecondaryIncrement/> : <IconPrimaryIncrement/>}
            </button>
        </div>
    );
});

export default FieldCounter;
