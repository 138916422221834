import React, { FC } from "react";
import "./ContactInfo.scss";
import IconCall from "../icons/IconCall";
import IconMessage from "../icons/IconMessage";

interface Props {
  phone?: string;
  email?: string;
}


const ContactInfo: FC<Props> = ({ phone, email }) => {
  if (!phone && !email) {
    return null
  }

  return (
    <div className="contact-info">
      <ul className="contact-info-list">
        {phone && <li className="contact-info-list-item">
          <IconCall/>
          <div>
            <div className="contact-info-list-item__label">ТЕЛЕФОН</div>
            <div className="contact-info-list-item__value">{phone}</div>
          </div>
        </li>}
        {email && <li className="contact-info-list-item">
          <IconMessage/>
          <div>
            <div className="contact-info-list-item__label">EMAIL ADDRESS</div>
            <div className="contact-info-list-item__value">{email}</div>
          </div>
        </li>}
      </ul>
    </div>
  )
}
export default ContactInfo;
