import React, { FC, useEffect, useRef } from 'react';
import "./SearchMobilePlace.scss";
import SearchOption from "../../SearchOption/SearchOption";
import FieldReactSelect from "../../../../../components/ui/forms/fields/FieldReactSelect/FieldReactSelect";
import ModalMobile from "../../../../../components/ui/modals/ModalMobile/ModalMobile";
import useSwitcher from "../../../../../hooks/useSwitcher";
import IconClose from "../../../../../components/ui/icons/modal/IconClose";
import QueryNoFound from '../../QueryNoFound/QueryNoFound';
import { useTranslation } from 'react-i18next';

interface Option {
    label: string,
    value: string;
}

interface Props {
    onChange: (Option) => void,
    defaultOptions: Array<Option>
    loadOptions: (inputValue: string) => Promise<Array<Option>>,
    placeObject: Option
    searchHotel: (Option) => void
    onClose: () => void
    openSearchDropdown: () => void
}

const SearchMobilePlace: FC<Props> = (props) => {
    const {onChange, defaultOptions, loadOptions, placeObject, searchHotel, onClose, openSearchDropdown} = props;
    const [t] = useTranslation();

    const selectRef = useRef(null);

    useEffect(() => {
        openSearchDropdown()
    }, [])

    return (
        <ModalMobile
            className="search-mobile-modal"
            onClose={() => {}}
        >
            <div className="search-mobile-modal__header">
                <IconClose onClick={onClose}/>
                <h3>{t('Куди плануєте?')}</h3>
                <span/>
            </div>

            <div className="search-mobile-modal__select">
                <FieldReactSelect
                    // @ts-ignore
                    ref={selectRef}
                    autoFocus
                    className="search-mobile-modal-select"
                    classNamePrefix="search-mobile-modal-select"
                    OptionTemplate={SearchOption}
                    TemplateNoFound={QueryNoFound}
                    loadOptions={loadOptions}
                    onChange={(value) => {
                        searchHotel(value);
                        onChange(value);
                    }}
                    value={placeObject}
                    menuIsOpen={true}
                    async
                    placeholder={t("Місто або назва готелю")}
                    defaultOptions={defaultOptions}
                    size="large"
                />
            </div>
        </ModalMobile>

    )
}

export default SearchMobilePlace
