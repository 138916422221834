import React, {FC} from "react";
import "./CustomLink.scss";
import {Link} from "react-router-dom";
import IconRight from "../../../../../components/ui/icons/account/IconRight";

interface Props {
  blank?: boolean;
  to: string;
  title: string;
  value: string;
  onClick?: (e: any) => void;
}

const CustomLink: FC<Props> = (props) => {
  const {blank, to, title, value, onClick} = props;


  if (blank) {
    return (
      <a href={to} className="custom-link" target="_blank" onClick={onClick}>
        <span className="custom-link__title">{title}</span>
        <span className="custom-link__value">
          {value}
          <IconRight/>
        </span>
      </a>
    )
  }

  return (
    <Link to={to} className="custom-link" onClick={onClick}>
      <span className="custom-link__title">{title}</span>
      <span className="custom-link__value">
          {value}
        <IconRight/>
        </span>
    </Link>
  )
}

export default CustomLink