import React, { FC } from 'react';

interface Props {

}

const IconFacebook: FC<Props> = (props) => {

   return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
         <g clipPath="url(#clip0_3801_47925)">
            <g filter="url(#filter0_f_3801_47925)">
               <path d="M13.1085 33.3972C4.78465 31.9056 -1.52393 24.7109 -1.52393 16.0246C-1.52393 6.37319 6.36179 -1.52344 15.9999 -1.52344C25.638 -1.52344 33.5237 6.37319 33.5237 16.0246C33.5237 24.7109 27.2151 31.9056 18.8913 33.3972L17.9275 32.6075H14.0723L13.1085 33.3972Z" fill="white"/>
            </g>
            <path d="M13.36 31.84C5.76 30.48 0 23.92 0 16C0 7.2 7.2 0 16 0C24.8 0 32 7.2 32 16C32 23.92 26.24 30.48 18.64 31.84L17.76 31.12H14.24L13.36 31.84Z" fill="url(#paint0_linear_3801_47925)"/>
            <path d="M22.2399 20.4646L22.9599 15.9846H18.7199V12.8646C18.7199 11.5846 19.1999 10.6246 21.1199 10.6246H23.1999V6.54461C22.0799 6.38461 20.7999 6.22461 19.6799 6.22461C15.9999 6.22461 13.4399 8.46461 13.4399 12.4646V15.9846H9.43994V20.4646H13.4399V31.8716C14.3199 32.0316 15.1999 32.1116 16.0799 32.1116C16.9599 32.1116 17.8399 32.0316 18.7199 31.8716V20.4646H22.2399Z" fill="white"/>
         </g>
         <defs>
            <filter id="filter0_f_3801_47925" x="-17.5239" y="-17.5234" width="67.0479" height="66.9199" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
               <feFlood floodOpacity="0" result="BackgroundImageFix"/>
               <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
               <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_3801_47925"/>
            </filter>
            <linearGradient id="paint0_linear_3801_47925" x1="16.0008" y1="30.8871" x2="16.0008" y2="-0.00589422" gradientUnits="userSpaceOnUse">
               <stop stopColor="#0062E0"/>
               <stop offset="1" stopColor="#19AFFF"/>
            </linearGradient>
            <clipPath id="clip0_3801_47925">
               <rect width="32" height="32" fill="white"/>
            </clipPath>
         </defs>
      </svg>

   );
};

export default IconFacebook;