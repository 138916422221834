import React, {FC} from "react";

interface Props {

}

const IconInfo: FC<Props> = (props) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.1431 12.8571V18H12.8574V12.8571H18V11.1429H12.8574V6H11.1431V11.1429H6V12.8571H11.1431Z"
                  fill="#C5C6CF"/>
        </svg>
    )
}

export default IconInfo