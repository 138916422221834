import React, { FC } from 'react';

interface Props {

}

const IconNoPhotoOutline: FC<Props> = (props) => {

   return (
      <svg width="48" height="48" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M26.2801 8.53135V19.3993C26.2801 23.426 23.7601 26.266 19.7334 26.266H8.20008C4.17341 26.266 1.66675 23.426 1.66675 19.3993V8.53135C1.66675 4.50468 4.18675 1.66602 8.20008 1.66602H19.7334C23.7601 1.66602 26.2801 4.50468 26.2801 8.53135Z" stroke="#C5C6CF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
         <path d="M5.04175 19.9071L7.07908 17.7564C7.78708 17.0058 8.96708 16.9698 9.71908 17.6764C9.74175 17.6991 10.9684 18.9458 10.9684 18.9458C11.7084 19.6991 12.9177 19.7111 13.6711 18.9724C13.7204 18.9244 16.7831 15.2098 16.7831 15.2098C17.5724 14.2511 18.9897 14.1138 19.9497 14.9044C20.0137 14.9578 22.9071 17.9271 22.9071 17.9271" stroke="#C5C6CF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M11.7503 10.1766C11.7503 11.4686 10.7037 12.5152 9.41166 12.5152C8.11966 12.5152 7.073 11.4686 7.073 10.1766C7.073 8.88456 8.11966 7.83789 9.41166 7.83789C10.7037 7.83922 11.7503 8.88456 11.7503 10.1766Z" stroke="#C5C6CF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

   );
};

export default IconNoPhotoOutline;