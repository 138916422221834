import React, { useState } from 'react';
import './ReservationEditModal.scss';
import styles from './ReservationEditModal.module.scss';

import { OrderResponseReservationItem } from '../../../../../types/order';
import Modal from '../../../../../components/ui/modals/Modal/Modal';
import ModalLayoutPrimary
    from '../../../../../components/ui/modals/Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import { createBemClassProp } from '../../../../../helpers/common';
import RoomTypesList from './components/RoomTypesSelect/RoomTypesSelect';
import ReservationEditModalTitle from './components/ReservationEditModalTitle/ReservationEditModalTitle';
import LivingVariants from './components/LivingVariants/LivingVariants';
import { useReservationEditModalLogic } from './useReservationEditModalLogic';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import ReservationEditModalSearchForm from './components/ReservationEditModalSearchForm/ReservationEditModalSearchForm';
import AppLoader from '../../../../../components/ui/loaders/AppLoader/AppLoader';
import { Log } from '@3ddie/react-utils';

type Props = {
    reservation: OrderResponseReservationItem,
    onClose: () => void,
    hotelAlias: string
}

const ReservationEditModal: React.FC<Props> = ({hotelAlias, reservation, onClose}) => {
    const {
        roomTypes,
        selectedRoomType,
        setSelectedRoomType,
        isLoading,
        getRates,
        selectedPrice,
        setSelectedPrice,
        saveNewReservationData,
        nights
    } = useReservationEditModalLogic(hotelAlias, reservation);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    const cn = createBemClassProp('reservation-edit-modal');

    const Footer = () => (
        <div {...cn('footer')}>
            <AppButton
                onClick={() => {
                    saveNewReservationData();
                    onClose()
                }}
                disabled={isLoading || !selectedPrice}
            >
                Зберегти
            </AppButton>
        </div>
    )

    return (
        <Modal onClose={onClose} {...cn()} showClose >
            <ModalLayoutPrimary title={<span>Редагувати бронювання <br/>{reservation.room}</span>} Footer={Footer}>
                {isLoading && <AppLoader full/>}

                <RoomTypesList
                    roomTypes={roomTypes}
                    onSelect={setSelectedRoomType}
                    selectedRoomType={selectedRoomType}
                />

                <ReservationEditModalTitle>Дати</ReservationEditModalTitle>

                <div style={{padding: '20px 0'}}>
                    <ReservationEditModalSearchForm
                        startDateInitial={reservation.arrivalDate}
                        endDateInitial={reservation.departureDate}
                        onCalendarStateChange={setIsCalendarOpen}
                        onSearch={dates => {
                            if (dates[0] instanceof Date && dates[1] instanceof Date) {
                                getRates(dates[0].toString(), dates[1].toString());
                            }
                        }}
                    />
                </div>

                <LivingVariants
                    selectedRoomType={selectedRoomType}
                    onSelectPrice={setSelectedPrice}
                    selectedPrice={selectedPrice}
                    nights={nights}
                    showBlur={isCalendarOpen}
                />
            </ModalLayoutPrimary>
        </Modal>
    )
};

export default ReservationEditModal;
