import React from 'react';
import AppButton from '../AppButton/AppButton';
import PriceViewer from '../../PriceViewer/PriceViewer';
import './BookButton.scss';

type Props = {
  price: number;
  onClick: () => void;
  isLoading?: boolean
  disabled?: boolean
};

const BookButton: React.FC<Props> = ({ price, isLoading, onClick, disabled }) => {
  return (
    <AppButton onClick={onClick} className={'book-button'} isLoading={isLoading} disabled={disabled}>
      Бронювати за
      <PriceViewer price={price}/>
    </AppButton>
  );
};

export default BookButton;
