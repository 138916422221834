import { FiltersInitData } from '../types/hotel-filters';
import { HotelList, HotelListItemType, HotelsListMetaFilters } from '../types/hotels';
import { Hotel } from '../types/hotel';
import { generateSrcSet } from './common';
import { FooterRegionType } from '../types/initial';
import { formatDate } from './date';
import { serializeQueryStringParameter } from './url';
import { be } from 'date-fns/locale';

export const getActiveFiltersCount = (filterData: HotelsListMetaFilters, initialFiltersData: FiltersInitData) => {
    let count = 0;
    const {price} = filterData;

    for (const [key, value] of Object.entries(filterData)) {
        switch (key) {
            case 'rating':
                if (value > 0) count++;
                break;

            case 'price':
                const priceMin1 = Number(price?.min);
                const priceMin2 = Number(initialFiltersData?.price?.min);
                const priceMax1 = Number(price?.max);
                const priceMax2 = Number(initialFiltersData?.price?.max);

                if (priceMin1 !== priceMin2 || priceMax1 !== priceMax2) count++;
                break;

            case 'rooms':
                const {rooms, beds, bathrooms} = filterData?.rooms;

                [rooms, beds, bathrooms].forEach(value => {if (value && Number(value) > 1) count++;})
                break;
        }

        const group = initialFiltersData.filterGroups.find(group => group.alias === key);
        if (group && Array.isArray(value)) count += value.length;
    }

    return count;
}

export const getOnePhotoLinkFromHotelData = (hotel: HotelListItemType, imageSize = '302x170'): string => {
    let photoLink = '';
    const {photo, photos} = hotel;

    if (photo && photo?.source) {
        photoLink = generateSrcSet(photo?.source, imageSize);
    } else if (Array.isArray(photos) && photos.length && photos[0]?.source) {
        photoLink = generateSrcSet(photos[0]?.source, imageSize);
    }

    return photoLink;
}

// export const getMapPopupPosition = (e): [string, string] => {
//     const popupHeight = 312;
//     const popupWidth = 320;
//
//     const mapContainer = document.querySelector('.hotels-map');
//     const containerBounds = mapContainer.getBoundingClientRect();
//     console.log(containerBounds);
//     const headerHeight = containerBounds.top;
//
//     let vPosition = 'center';
//     let hPosition = 'center';
//
//     if (e.target.getBoundingClientRect().y - headerHeight <= popupHeight)
//         vPosition = 'bottom';
//     else
//         vPosition = 'top';
//
//     if (e.target.getBoundingClientRect().x <= popupWidth) {
//         hPosition = 'left';
//     } else if (containerBounds.width - e.target.getBoundingClientRect().x <= popupWidth)
//         hPosition = 'right';
//
//     return [vPosition, hPosition];
// }

export const getVerticalPositionForMapPopup = () => {

}

export const generateStaticLinkToHotelsSearch = (region: FooterRegionType): string => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const searchData = {
        place_id: region.id,
        place_name: region.name,
        show_all: true
        // adults: 2,
        // arrival_date: formatDate(today, 'yyyy-MM-dd'),
        // departure_date: formatDate(tomorrow, 'yyyy-MM-dd'),
        // rooms: 1,
        // latitude: region.coordinates.latitude,
        // longitude: region.coordinates.longitude
    };

    const search = serializeQueryStringParameter(searchData);

    return '/hotels/' + search;
}

