import { HotelsListMetaFilters } from '../../../../../types/hotels';
import { FiltersInitDataPrepared } from '../../../../../types/hotel-filters';

export const getFilterFormikValues = (initFiltersData: FiltersInitDataPrepared, appliedFilters: HotelsListMetaFilters): HotelsListMetaFilters => {

    const priceData = appliedFilters.price || initFiltersData.price;

    const data: HotelsListMetaFilters = {
        price: {
            min: Number(priceData.min),
            max: Number(priceData.max),
        },
        //rating: Number(appliedFilters.rating || 0),

        rooms: appliedFilters.rooms || {
            rooms: 1,
            beds: 1,
            bathrooms: 1
        },
    }

    initFiltersData.filterGroups.forEach(filterGroup => {
        data[filterGroup.alias] = appliedFilters[filterGroup?.alias] || [];
    })

    return data;
}

export const setFiltersQueryHelper = (filtersQuery, filterGroups, values) => {
    const {price, rooms, rating} = values;

    //Todo: maybe rewrite to qs library
    filtersQuery.set('filters[price][min]', price.min.toString());
    filtersQuery.set('filters[price][max]', price.max.toString());
    filtersQuery.set('filters[rating]', rating.toString());
    filtersQuery.set('filters[rooms][rooms]', rooms.rooms.toString());
    filtersQuery.set('filters[rooms][beds]', rooms.beds.toString());
    filtersQuery.set('filters[rooms][bathrooms]', rooms.bathrooms.toString());

    if (Array.isArray(filterGroups)) {
        filterGroups.forEach(filterGroup => {

            if (Array.isArray(values[filterGroup?.alias])) {
                values[filterGroup.alias].forEach(value => {
                    filtersQuery.append(`filters[${filterGroup.alias}][]`, value.toString());
                })
            }
        })
    }
}
