import {createSlice} from "@reduxjs/toolkit";
import { getInitialThunk } from './initial-thunks';
import { InitialData } from '../../types/initial';
import { defaultLanguage } from '../../config';

interface Initial {
  language: string;
  currency: string;
  data: InitialData;
  error?: any;

}

const initialState: Initial = {
  language: defaultLanguage,
  currency: 'UAH',
  data: {
    cartLifetime: null,
    currency: {
      data: []
    },
    locales: {
      data: []
    },
    regions: {
      data: []
    }
  }
};


const initialSlice = createSlice({
  name: 'initial',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    }
  },
  extraReducers: builder => {
    // request
    builder.addCase(getInitialThunk.pending, (state, action) => {
    });

    // success response
    builder.addCase(getInitialThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    // response with errors
    builder.addCase(getInitialThunk.rejected, (state, action) => {
      state.error = action.error;
    });
  },
})

export const {setLanguage, setCurrency} = initialSlice.actions;
export default initialSlice.reducer;





