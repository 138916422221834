import React from 'react';
import './ModalCloseButton.scss';

type Props = {
    onClick: () => void
}

const ModalCloseButton: React.FC<Props> = ({onClick}) => {
    return (
        <button className={'modal-close-button'} onClick={onClick}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.76256 7L0.256282 1.49372C-0.0854272 1.15201 -0.0854272 0.59799 0.256282 0.256282C0.59799 -0.0854272 1.15201 -0.0854272 1.49372 0.256282L7 5.76256L12.5063 0.256282C12.848 -0.0854272 13.402 -0.0854272 13.7437 0.256282C14.0854 0.59799 14.0854 1.15201 13.7437 1.49372L8.23744 7L13.7437 12.5063C14.0854 12.848 14.0854 13.402 13.7437 13.7437C13.402 14.0854 12.848 14.0854 12.5063 13.7437L7 8.23744L1.49372 13.7437C1.15201 14.0854 0.59799 14.0854 0.256282 13.7437C-0.0854272 13.402 -0.0854272 12.848 0.256282 12.5063L5.76256 7Z"
                    fill="#6D7188"/>
            </svg>

        </button>
    )
};

export default ModalCloseButton;
