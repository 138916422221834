import React, { FC } from 'react';
import './ModalConfirm.scss';
import Modal from '../Modal/Modal';
import ModalLayoutPrimary from '../Modal/components/ModalLayoutPrimary/ModalLayoutPrimary';
import ModalFooterWithButtons from '../components/ModalFooterWithButtons';

interface Props {
   title: string;
   onCancel: () => void;
   onConfirm: () => void;
   confirmButtonLabel: string;
   cancelButtonLabel?: string;
   className?: string;
}

const ModalConfirm: FC<Props> = props => {
   const {
      children,
      title,
      onCancel,
      onConfirm,
      confirmButtonLabel,
      cancelButtonLabel = 'Скасувати',
      className = '',
   } = props;

   const footer = (
      <ModalFooterWithButtons
         cancelButtonLabel={cancelButtonLabel}
         actionButtonLabel={confirmButtonLabel}
         onActionButtonClick={onConfirm}
         onCancel={onCancel}
      />
   );

   return (
      <Modal onClose={onCancel} className={`modal-confirm ${className}`} disableFullSizeOnMobile>
         <ModalLayoutPrimary FooterRendered={footer} title={title} disableFullSizeOnMobile>
            {children}
         </ModalLayoutPrimary>
      </Modal>
   );
};

export default ModalConfirm;
