import React from 'react';

const RoomPriceMealIconHB: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.75 0.75V2.25C16.365 2.25 12 6.615 12 12C12 17.3078 16.2413 21.6248 21.5198 21.747L21.75 21.75V23.25C15.537 23.25 10.5 18.213 10.5 12C10.5 5.865 15.4103 0.8775 21.5145 0.75225L21.75 0.75ZM2.25 0.75H3.75V5.25H5.25V0.75H6.75V5.25H8.25V0.75H9.75V7.5C9.74993 8.36431 9.45142 9.20208 8.90493 9.8717C8.35844 10.5413 7.59751 11.0017 6.75075 11.175L6.75 23.25H5.25V11.175C4.46116 11.0125 3.7454 10.6009 3.20823 10.0008C2.67107 9.40075 2.34093 8.64394 2.2665 7.842L2.25375 7.668L2.25 7.5V0.75ZM21.75 5.25V6.75C20.3728 6.74933 19.0504 7.28987 18.068 8.2551C17.0856 9.22033 16.5218 10.5329 16.4982 11.91C16.4746 13.287 16.993 14.6182 17.9418 15.6165C18.8905 16.6149 20.1935 17.2005 21.57 17.247L21.75 17.25V18.75C19.9774 18.7494 18.2762 18.0515 17.0139 16.8071C15.7515 15.5628 15.0293 13.8718 15.0032 12.0994C14.9771 10.327 15.6492 8.61549 16.8744 7.33449C18.0996 6.0535 19.7795 5.30584 21.5513 5.253L21.75 5.25ZM8.24925 6.75H3.74925V7.48125L3.753 7.61325C3.78366 8.18675 4.03247 8.72681 4.44845 9.12279C4.86443 9.51878 5.41609 9.7407 5.9904 9.7431C6.56471 9.74549 7.1182 9.52818 7.53747 9.13569C7.95674 8.74319 8.21005 8.20522 8.2455 7.632L8.25 7.5L8.24925 6.75Z"
        />
    </svg>
);

export default RoomPriceMealIconHB;
