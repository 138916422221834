import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getHotelThunk } from './hotel-thunk';
import { BookingSectionState, Hotel } from '../../types/hotel';
import { removeFromWishListThunk, setToWishListThunk } from '../hotels/hotels-thunk';
import { SaveToWishListParams } from '../../types/favorites';

interface InitialState {
    data: Hotel;
    bookingState: BookingSectionState;
    error: any;
    isFetching: boolean;
    selectedOrderBookType?: 'cart' | 'phone',
    mapLink: string,
}

const initialState: InitialState = {
    data: {
        address: '',
        alias: '',
        coordinates: {latitude: 0, longitude: 0},
        description: '',
        distanceToPlaceFormatted: '',
        id: 0,
        name: '',
        photo: {},
        photos: [],
        phones: {
            data: []
        },
        propertyGroups: {
            data: [],
        },
        rating: '',
        stars: 0,
        reviewsCount: 0,
    },
    bookingState: null,
    mapLink: '',
    error: {},
    selectedOrderBookType: null,
    isFetching: false,
};

const hotelSlice = createSlice({
    name: 'hotel',
    initialState,
    reducers: {
        setToWishListHotelPage: (state, action: PayloadAction<SaveToWishListParams>) => {
            const {isFavorite, hotel_id} = action.payload;
            state.data.isFavorite = isFavorite;
        },
        setMapLink: (state, action: PayloadAction<string>) => {
            console.log('save map link to store', action.payload);
            state.mapLink = action.payload;
        },
        changeOrderBookType: (state, action: PayloadAction<'cart' | 'phone'>) => {
            state.selectedOrderBookType = action.payload;
        },
    },
    extraReducers: builder => {
        // request
        builder.addCase(getHotelThunk.pending, (state, action) => {
            state.data = initialState.data;
            state.isFetching = true;
        });

        // success response
        builder.addCase(getHotelThunk.fulfilled, (state, action) => {
            state.isFetching = false;
            state.data = action.payload;
            const {phones = null, useReservations = null} = action.payload;

            if (useReservations) {
                state.bookingState = phones?.data?.length ? 'both' : 'cart';
                state.selectedOrderBookType = 'cart';
            } else if (phones?.data?.length) {
                state.bookingState = 'phone';
                state.selectedOrderBookType = 'phone';
            }

            //For DEBUG
            // state.bookingState = 'cart';
            // state.selectedOrderBookType = 'cart';
        });

        // response with errors
        builder.addCase(getHotelThunk.rejected, (state, action) => {
            state.isFetching = false;
            state.error = action.error;
        });

        builder.addCase(setToWishListThunk.fulfilled, (state, action) => {
            hotelSlice.caseReducers.setToWishListHotelPage(state, action)
        });
        builder.addCase(removeFromWishListThunk.fulfilled, (state, action) => {
            hotelSlice.caseReducers.setToWishListHotelPage(state, action)
        })
    },
});

export const {changeOrderBookType, setMapLink} = hotelSlice.actions;

export default hotelSlice.reducer;
