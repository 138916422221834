import React, { FC } from 'react';
import './MessagesList.scss';
import MessageItem from '../MessageItem/MessageItem';

interface Props {
   messages: any;
}

const MessagesList: FC<Props> = ({ messages }) => {
   return (
      <div className="messages-list">
         {Object.keys(messages).map(item => {
            return (
               <>
                  <div className="messages-list__date">{item}</div>
                  <ul>
                     {messages[item].map(item => {
                        return (
                           <li key={item.id}>
                              <MessageItem
                                 message={item.message}
                                 time={item.time}
                                 fullName={item.fullName}
                                 photo={item.photo}
                              />
                           </li>
                        );
                     })}
                  </ul>
               </>
            );
         })}
      </div>
   );
};

export default MessagesList;
