import React, {FC} from "react";

interface Props {

}

const IconInfo: FC<Props> = (props) => {

    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.499969" width="27" height="27" rx="13.5" stroke="#C5C6CF"/>
            <path
                d="M14.6667 13.6666H19.3333C19.5174 13.6666 19.6667 13.8159 19.6667 14C19.6667 14.1841 19.5174 14.3333 19.3333 14.3333H14.6667H13.3333H8.66667C8.48257 14.3333 8.33333 14.1841 8.33333 14C8.33333 13.8159 8.48257 13.6666 8.66667 13.6666H13.3333H14.6667Z"
                fill="#C5C6CF" stroke="#C5C6CF" strokeWidth="0.666667"/>
        </svg>
    )
}

export default IconInfo
