export function removeSymbolsFromString(string: string): string {
   if (!string) return '';

   const pattern = /[^\d]/g;

   return string.replace(pattern, '');
}

export const extractBody = (inputHTML: string): string =>{
   const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
   const array_matches = pattern.exec(inputHTML);

   return array_matches[1]
}
