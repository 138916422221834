import React from 'react';
import { AsYouType } from 'libphonenumber-js';

const FormatPhone = (props) => {
  const asYouType = new AsYouType();
  let phone =  '';

  if(!props.children) return null

  if(props.children === '+') {
    phone = asYouType.input(props.children);
  } else {
    phone = asYouType.input('+' + props.children);
  }

  return <span>{props.children ? phone : null}</span>;
};

export default FormatPhone;
