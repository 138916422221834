import { createAsyncThunk } from '@reduxjs/toolkit';
import { formatDate } from '../../helpers/date';
// import initialApi from '../../api/initialApi';

export const getMessagesThunk = createAsyncThunk(
   'messages/getMessagesThunk',
   async (data: void, { rejectWithValue }) => {

      return {
         '18.07.2022': [
            {
               id: 1,
               fullName: 'Claire Mckinney',
               time: '22:20',
               photo: 'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
               message:
                  'Very good value apartment. Walls are thin and you can hear loud neighbours easily. But I still recommend this place because.',
            },
            {
               id: 2,
               fullName: 'Claire Mckinney',
               time: '22:20',
               photo: 'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
               message:
                  'A bit far away from center but on taxi is okay. Smaller in real-life but has an essentials things. Walls are thin and you can hear loud neighbours easily. But I still recommend this place because.',
            },
            {
               id: 3,
               fullName: 'Ben Len',
               time: '22:20',
               photo: 'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
               message:
                  ' Smaller in real-life but has an essentials things. Walls are thin and you can hear loud neighbours easily. But I still recommend this place because.',
            },
         ],
         '19.07.2022': [
            {
               id: 4,
               fullName: 'Claire Mckinney',
               photo: 'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
               time: '22:20',
               message:
                  'Very good value apartment. A bit far away from center but on taxi is okay. Smaller in real-life but has an essentials things. Walls are thin and you can hear loud neighbours easily. But I still recommend this place because.',
            },
            {
               id: 5,
               fullName: 'Claire Mckinney',
               photo: 'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
               time: '22:20',
               message:
                  'Very good value apartment. Walls are thin and you can hear loud neighbours easily. But I still recommend this place because.',
            },
         ],
         '20.07.2022': [
            {
               id: 6,
               fullName: 'Claire Mckinney',
               photo: 'https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F14%2F2021%2F10%2F04%2F100421-Glowy-skin-lead-2000.jpg&w=412&h=412&c=sc&poi=face&q=60&rect=57%2C123%2C1128%2C1193',
               time: '22:20',
               message:
                  'Very good value apartment.  Smaller in real-life but has an essentials things. Walls are thin and you can hear loud neighbours easily. But I still recommend this place because.',
            },
            {
               id: 7,
               fullName: 'Claire Mckinney',
               time: '22:20',
               photo: 'https://i.pinimg.com/564x/1f/3c/96/1f3c9646fcc40c845cad8f9e4204ae58.jpg',
               message:
                  ' Smaller in real-life but has an essentials things. Walls are thin and you can hear loud neighbours easily. But I still recommend this place because.',
            },
         ],
      };
   },
);

export const sendMessagesThunk = createAsyncThunk(
   'messages/sendMessagesThunk',
   async (data: any, { rejectWithValue }) => {
      return {
         date: formatDate(new Date(), 'dd.MM.yyyy'),
         time: data.time,
         fullName: data.fullName,
         message: data.message,
         photo: data.photo,
      };
   },
);
