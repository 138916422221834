import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import './FieldMaterialInput.scss';
import classNames from 'classnames';

import FieldMaterialInputValidCheck from './components/FieldMaterialInputValidCheck';
import FieldMaterialInputEditButton from './components/FieldMaterialInputEditButton/FieldMaterialInputEditButton';

interface Props extends HTMLProps<HTMLInputElement> {
    id?: any
    isError?: boolean;
    label?: string
    onChangeValue: (value: string) => void,
    isValid?: boolean,
    errorMessage?: string,
    isLoading?: boolean,
    withEdit?: boolean;
}

const renderErrorMessage = (msg) => (
    <div className={'field-material-input__error-message'}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z"
                stroke="#E05233" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 3.75V7.75" stroke="#E05233" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.99805 9.7998H7.00805" stroke="#E05233" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

        {msg}
    </div>
)

const FieldMaterialInput = React.forwardRef<any, Props>((props, ref) => {
    const {
        id,
        isError,
        value,
        label,
        onChangeValue,
        isValid,
        errorMessage,
        isLoading,
        withEdit,
        ...rest
    } = props;
    const [internalValue, setInternalValue] = useState<any>(value);
    const [state, setState] = useState<'editing' | 'default'>('default');

    const classes = classNames('field-material-input', {isError}, {isLoading});
    const inputClasses = classNames({'withValue': withEdit ? internalValue : value})
    const internalRef = useRef();

    useEffect(() => {
        if (state === 'editing' && internalRef?.current) {
            //@ts-ignore
            internalRef?.current?.focus();

            if (!internalValue) {
                //@ts-ignore
                internalRef?.current?.setSelectionRange(0, 0);
            }
        }
    }, [state]);

    return (
        <div className={classes}>

            <input
                id={id}
                value={withEdit ? internalValue : value}
                ref={internalRef}
                className={inputClasses}
                onChange={(e) => {
                    const value = e.target.value;
                    if (withEdit) setInternalValue(value)
                    else onChangeValue(value);
                }}

                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setState('default');
                        onChangeValue(internalValue);
                    }
                }}
                {...rest}
                disabled={withEdit && state === 'default' || isLoading || props.disabled}
                autoComplete="off"
            />

            <label htmlFor={id}>{label}</label>

            {withEdit ? (
                <>
                    <FieldMaterialInputEditButton
                        state={isLoading ? 'loading' : state}
                        onClick={() => {
                            if (state === 'default') setState('editing');
                            if (state === 'editing') {
                                setState('default');
                                onChangeValue(internalValue);
                            }
                        }}
                    />
                </>
            ) : (
                <>
                    {isValid && !isError && <FieldMaterialInputValidCheck/>}
                </>
            )}

            {errorMessage && renderErrorMessage(errorMessage)}
        </div>
    );
});

export default FieldMaterialInput;
