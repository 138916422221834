import React, { useState } from 'react';
import './HotelDetailUserActions.scss';
import IconUpload from '../../../../../components/ui/icons/icons/IconUpload';
import AppButton from '../../../../../components/ui/components/Buttons/AppButton/AppButton';
import FavoriteFunctionality from '../../../HotelList/components/FavoriteFunctionality/FavoriteFunctionality';
import IconFavorites from '../../../../../components/ui/icons/hotels/IconFavorites';
import ModalShareRoute from '../../../../Account/AccountFavorite/components/ModalShareRoute/ModalShareRoute';
import { isClient } from '../../../../../helpers/common';
import useWindowSize from '../../../../../hooks/useWindowSize';
import IconFavoritesHotelDetailVariant from '../../../../../components/ui/icons/hotels/IconFavoritesHotelDetailVariant';
import useScroll from '../../../../../hooks/useScroll';
import { MobileOnly } from '../../../../../components/common/Media/MediaHelpers';
import { useHistory } from 'react-router-dom';
import IconBack from '../../../../../components/ui/icons/icons/IconBack';

type Props = {
    hotelId: number;
    isFavorite: boolean;
    name: string;
    hotelPhoto: string;
};

const HotelDetailUserActions: React.FC<Props> = ({hotelId, isFavorite, name, hotelPhoto}) => {
    const [modal, setModal] = useState<'' | 'share'>('');
    const shareLink = isClient ? window.location.href : '';
    const {isMobile} = useWindowSize();
    const history = useHistory();
    const [isScrolled, setIsScrolled] = useState(false);

    const {scrollY} = useScroll();

    return (
        <div className={`hotel-detail-user-actions ${scrollY > 0 ? 'hotel-detail-user-actions--scrolled' : ''}`}>
            <div>
                <MobileOnly>
                    <AppButton
                        icon={<IconBack/>}
                        theme={'link'}
                        onClick={history.goBack}
                    />
                </MobileOnly>
            </div>

            <div className={'hotel-detail-user-actions__right-section'}>
                <AppButton icon={<IconUpload color={''}/>} theme={'link'} onClick={() => setModal('share')}>
                    Поділитися
                </AppButton>

                <FavoriteFunctionality
                    id={hotelId}
                    isFavorite={isFavorite}
                    FavoriteButton={({isSelected}) => {
                        return (
                            <AppButton
                                icon={
                                    <IconFavoritesHotelDetailVariant
                                        isSelected={isSelected}
                                        color={isMobile ? '#6D7188' : null}
                                    />
                                }
                                theme={'link'}
                            >
                                Зберегти
                            </AppButton>
                        );
                    }}
                />
            </div>

            {modal === 'share' && (
                <ModalShareRoute
                    title="Поділитись готелем"
                    shareLink={shareLink}
                    onClose={() => setModal('')}
                    image={hotelPhoto} name={name}
                />
            )}
        </div>
    );
};

export default HotelDetailUserActions;
