import React, {FC} from "react";
import "./AuthStepper.scss";
import classNames from "classnames";

interface Props {
  steps: Array<number>;
  active: number;
  className?: string;
}

const AuthStepper: FC<Props> = (props) => {
  const {steps, active, className, ...rest} = props;

  return (
    <ul {...rest} className={`auth-stepper ${className}`}>
      {steps.map((item, index) => {
        const classes = classNames('', {active: item === active, past: item < active})
        return (
          <li className={classes} key={index}></li>
        )
      })}
    </ul>
  )
}

export default AuthStepper