import React from 'react';
import './CarouselSecondaryArrow.scss';

type Props = {
    direction: 'prev' | 'next';
    onClick?: () => void;
    className?: string;
};

const CarouselSecondaryArrow: React.FC<Props> = props => {
    const {onClick, direction, className} = props;

    const isDisabled = className.includes('slick-disabled');

    //if (className.includes('slick-disabled')) return null;

    return (
        <div
            className={`slick-arrow carousel-secondary-arrow carousel-secondary-arrow--${direction} ${isDisabled ? 'disabled' : ''}`}
            onClick={onClick}
        >
            {direction === 'prev' ? (
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.46967 9.53033C4.76256 9.82322 5.23744 9.82322 5.53033 9.53033C5.82322 9.23744 5.82322 8.76256 5.53033 8.46967L4.46967 9.53033ZM1 5L0.469669 4.46967C0.176777 4.76256 0.176777 5.23744 0.46967 5.53033L1 5ZM5.53033 1.53033C5.82322 1.23744 5.82322 0.762563 5.53033 0.46967C5.23744 0.176777 4.76256 0.176777 4.46967 0.46967L5.53033 1.53033ZM5.53033 8.46967L1.53033 4.46967L0.46967 5.53033L4.46967 9.53033L5.53033 8.46967ZM1.53033 5.53033L5.53033 1.53033L4.46967 0.46967L0.469669 4.46967L1.53033 5.53033Z"/>
                </svg>
            ) : (
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM5 5L5.53033 5.53033C5.82322 5.23744 5.82322 4.76256 5.53033 4.46967L5 5ZM0.46967 8.46967C0.176777 8.76256 0.176777 9.23744 0.46967 9.53033C0.762563 9.82322 1.23744 9.82322 1.53033 9.53033L0.46967 8.46967ZM0.46967 1.53033L4.46967 5.53033L5.53033 4.46967L1.53033 0.46967L0.46967 1.53033ZM4.46967 4.46967L0.46967 8.46967L1.53033 9.53033L5.53033 5.53033L4.46967 4.46967Z"/>
                </svg>
            )}
        </div>
    );
};

export default CarouselSecondaryArrow;
