import React, {FC} from "react";

interface Props {

}

const IconCalendar: FC<Props> = (props) => {

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 2.4V3C9 3.33137 8.73137 3.6 8.4 3.6C8.06863 3.6 7.8 3.33137 7.8 3V2.4H4.2V3C4.2 3.33137 3.93137 3.6 3.6 3.6C3.26863 3.6 3 3.33137 3 3V2.4H1.8C1.46863 2.4 1.2 2.63505 1.2 2.925V4.8H10.8V2.925C10.8 2.63505 10.5314 2.4 10.2 2.4H9ZM9 1.2H10.2C11.1941 1.2 12 1.9253 12 2.82V10.38C12 11.2747 11.1941 12 10.2 12H1.8C0.805887 12 0 11.2747 0 10.38V2.82C0 1.9253 0.805887 1.2 1.8 1.2H3V0.6C3 0.268629 3.26863 0 3.6 0C3.93137 0 4.2 0.268629 4.2 0.6V1.2H7.8V0.6C7.8 0.268629 8.06863 0 8.4 0C8.73137 0 9 0.268629 9 0.6V1.2ZM1.2 6V10.275C1.2 10.5649 1.46863 10.8 1.8 10.8H10.2C10.5314 10.8 10.8 10.5649 10.8 10.275V6H1.2Z" fill="#C5C6CF" fillOpacity="0.5"/>
    </svg>


  )
}

export default IconCalendar