import React from 'react';
import { RoomItemPriceType } from '../../../../../../../../../types/rooms';
import './RoomItemPrice.scss';
import { useSelector } from 'react-redux';

import PriceViewer from '../../../../../../../../../components/ui/components/PriceViewer/PriceViewer';
import FieldStyledSelect
    from '../../../../../../../../../components/ui/forms/fields/FieldStyledSelect/FieldStyledSelect';
import { AppState } from '../../../../../../../../../store';
import { pluralize } from '../../../../../../../../../helpers/translatioHelpers';
import RoomPriceMealIcon from '../../../../../../../../../components/ui/icons/hotels/rooms/RoomPriceMealIcon';
import { getHotelSelector } from '../../../../../../../../../store/hotel/hotel-selector';
import { getOptionsForRoomCountSelect } from '../../hotelRoomHelpers';

type Props = {
    roomId: number;
    price: RoomItemPriceType;
    onSelectRoom: (roomsCount: number) => void;
    availableRoomsCount: number,
    nights: number
    disabled?: boolean
};

const RoomItemPrice: React.FC<Props> = (props) => {
    const {
        roomId,
        price,
        onSelectRoom,
        nights,
        availableRoomsCount,
        disabled
    } = props;

    const reservations = useSelector((state: AppState) => state.order.reservations);
    const {selectedOrderBookType} = useSelector(getHotelSelector);

    const selectValues = reservations
        .filter(reservation => reservation.price.rateId === price.rateId && reservation.id === roomId)
        .map(reservation => ({
            value: reservation.count,
            label: pluralize('номер', reservation.count, true),
        }));

    return (
        <div className={`room-item-price ${disabled ? 'room-item-price--disabled' : ''} `}>
            <div className='room-item-price__meal-type'>
                <RoomPriceMealIcon variant={price.mealType.code}/>
                <div>{price.mealType && price.mealType.name}</div>
            </div>

            <div className={'room-item-price__nights-persons'}>
                <span>Ціна за {pluralize('ніч', nights, true)}</span>
            </div>

            <div className={'room-item-price__right-section'}>
                <div
                    className={`room-item-price__prices ${selectedOrderBookType === 'cart' ? 'room-item-price__prices--with-select' : ''}`}
                >
                    {price.discountAmount > 0 && <PriceViewer price={price.fullPrice} oldPrice/>}
                    <PriceViewer price={price.totalPrice} totalAfterDiscount={price.discountAmount > 0}/>
                </div>

                {selectedOrderBookType === 'cart' && (
                    <div className={'room-item-price__actions'}>
                        <FieldStyledSelect
                            id={roomId}
                            options={getOptionsForRoomCountSelect(availableRoomsCount, null, !!selectValues.length)}
                            onChange={(values: Array<{ value: number; label: string }>) => {
                                values.length && onSelectRoom(values[0]?.value);
                            }}
                            placeholder={'Вибрати'}
                            disabled={disabled}
                            values={selectValues}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RoomItemPrice;
