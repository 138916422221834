import React, { FC, useEffect, useState } from 'react';
import './PriceViewer.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { initialSelector } from '../../../../store/initial/initial-selectors';
import { pluralize } from '../../../../helpers/translatioHelpers';

interface Props {
    price: number | string;
    oldPrice?: boolean;
    totalAfterDiscount?: boolean;
    day?: boolean;
    className?: string;
    nights?: number
    currency?: string,
    language?: string
}

interface IState {
    price: number | string;
    currency: string;
}

const PriceViewer: FC<Props> = props => {
    const {className, oldPrice, totalAfterDiscount, nights, ...rest} = props;
    let initial: any;

    if(!props.currency) {
        initial = useSelector(initialSelector);
    }

    const priceClassName = classNames(
        'price-viewer',
        {
            'price-viewer--old-price': oldPrice,
            'price-viewer--total-after-discount': totalAfterDiscount,
        },
        className,
    );

    const [price, setPrice] = useState<string>(String(props.price));

    const reCalculate = () => {
        const formattedPrice = Number(props.price).toLocaleString(props?.language || initial?.language, {
            style: 'currency',
            currency: props.currency || initial?.currency,
            currencyDisplay: 'narrowSymbol',
            currencySign: 'accounting',
            minimumFractionDigits: 0,
        });

        setPrice(formattedPrice);
    };

    useEffect(() => {
        reCalculate();
    }, [props.price]);

    // @ts-ignore
    //const isUAH = price.includes('UAH') || price.includes('грн') || initial.currency === 'UAH';
    let priceText = price;//isUAH ? price.replace('UAH', '') + '₴' : price;

    // if (isUAH) {
    //     priceText = priceText
    //         .replace(/[ ]?грн[.]?/gm, '')
    //         .replace('₴', '')
    //         .trim() + '₴';
    // }

    return (
        <span className={priceClassName} {...rest}>
            <span className="price-viewer__price">{priceText}</span>

            {nights && <span className="price-viewer__additional-text">
                {` / ${pluralize('ніч', nights)}`}
            </span>}
        </span>
    );
};

export default PriceViewer;
