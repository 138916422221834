import React, { FC } from 'react';

interface Props {

}

const IconHome: FC<Props> = (props) => {

   return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M10.3158 20.421C10.3158 20.421 14.6578 20.4025 15.4552 20.421H17.0754C18.6906 20.421 20 19.1247 20 17.5257V10.1791C19.9914 9.55008 19.693 8.95936 19.1899 8.5751L13.6486 4.15595C12.6778 3.38649 11.2979 3.38649 10.3271 4.15595L4.81013 8.58312C4.30506 8.96582 4.00622 9.55752 4 10.1872V17.5257C4 19.1247 5.30937 20.421 6.92456 20.421H8.54481C9.12198 20.421 10.3158 20.421 10.3158 20.421Z" stroke="#C5C6CF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

   );
};

export default IconHome;