import React from 'react';

type Props = {
    basePlaces: number;
    extraPlaces: number;
};

const IconPerson = () => (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.5 0C4.03021 0 2.83871 1.22259 2.83871 2.73074C2.83871 4.23888 4.03021 5.46148 5.5 5.46148C6.96979 5.46148 8.16129 4.23888 8.16129 2.73074C8.16129 1.22259 6.96979 0 5.5 0Z"
            fill="#C5C6CF"
        />
        <path
            d="M2.66129 6.91787C1.1915 6.91787 0 8.14047 0 9.64861V10.5139C0 11.0624 6.40535e-05 12 6.40535e-05 12C6.40535e-05 12 11 11.9383 11 12C11 12 11 11.0624 11 10.5139V9.64861C11 8.14047 9.8085 6.91787 8.33871 6.91787H8.0968C7.96587 6.91787 7.5 6.91787 7.5 6.91787H7H5H3.3177C3.3177 6.91787 3.03413 6.91787 2.9032 6.91787H2.66129Z"
            fill="#C5C6CF"
        />
    </svg>
);

const IconPersonExtra = () => (
    <svg className="extra-place" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.5 1C5.03021 1 3.83871 2.22259 3.83871 3.73074C3.83871 5.23888 5.03021 6.46148 6.5 6.46148C7.96979 6.46148 9.16129 5.23888 9.16129 3.73074C9.16129 2.22259 7.96979 1 6.5 1Z"
            stroke="#C5C6CF"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.66129 7.91787C2.1915 7.91787 1 9.14047 1 10.6486V11.5139C1 12.0624 1.00006 13 1.00006 13C1.00006 13 12 12.9383 12 13C12 13 12 12.0624 12 11.5139V10.6486C12 9.14047 10.8085 7.91787 9.33871 7.91787H9.0968C8.96587 7.91787 8.5 7.91787 8.5 7.91787H8H6H4.3177C4.3177 7.91787 4.03413 7.91787 3.9032 7.91787H3.66129Z"
            stroke="#C5C6CF"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const RoomItemPersons: React.FC<Props> = ({basePlaces, extraPlaces}) => {
    const basePlacesArray = basePlaces && basePlaces > 0 ? [...Array(basePlaces)] : null;
    const extraPlacesArray = basePlaces && extraPlaces && extraPlaces > 0 ? [...Array(extraPlaces)] : null;

    const maxBasePlacesForShow = extraPlacesArray?.length >= 3 ? 5 : 8;

    return (
        <div className={'room-item-price__nights-persons-icons'}>
            {basePlacesArray
                ? (
                    <>
                        {basePlacesArray.length >= maxBasePlacesForShow ? (
                            <span>{basePlacesArray.length} <IconPerson/></span>
                        ) : basePlacesArray.map((_, index) => (
                            <IconPerson key={'roomPerson' + index}/>
                        ))}
                    </>
                )
                : null
            }

            {extraPlacesArray ? (
                <>
                    <span>+</span>
                    {extraPlacesArray.length >= 5 ? (
                        <span>{extraPlacesArray.length} <IconPersonExtra/></span>
                    ) : extraPlacesArray.map((_, index) => (
                        <IconPersonExtra key={'roomExtraPerson' + index}/>
                    ))}
                </>
            ) : null}
        </div>
    );
};

export default RoomItemPersons;
