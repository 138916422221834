import React, { FC } from 'react';
import './AccountListIsEmpty.scss';
import IconHotelBackground from "../../../HotelRegistration/HotelRegistrationInit/components/icons/IconHotelBackground";

interface Props {
   title: string;
   description: string;
   additional: string;
}

const AccountListIsEmpty: FC<Props> = props => {
   const { title, description, additional } = props;
   return (
      <div className="account-list-is-empty">
        <IconHotelBackground/>
         {/*<h1>{title}</h1>*/}
         <h5>{description}</h5>
         <p>{additional}</p>
      </div>
   );
};

export default AccountListIsEmpty;
