import React, {FC} from "react";
import "./OrderRoomItem.scss";
import {RoomType} from "../../HotelBookingSummary";
import PriceViewer from "../../../../../components/ui/components/PriceViewer/PriceViewer";


interface Props {
  data: RoomType
}

const OrderRoomItem: FC<Props> = (props) => {
  const {nameOfRoom, adult, kids, arrivalDate, departureDate, price, days} = props.data;

  return <div className='room-item'>
    <div className='room-item__block-name'>
      <div className='room-item__name'>{nameOfRoom}</div>
      <button className='room-item__change-button'>Змінити</button>
    </div>
    <div className='room-item__block-detail'>
      <div className='room-item__detail-text'>{adult} дорослих, {kids} дитина</div>
      <div className='room-item__detail-text'>заїзд {arrivalDate} виїзд {departureDate} {days} ночі</div>
    </div>
    <div className='room-item__block-price'>
      <div className='room-item__price-text'>Сума</div>
      <div className='room-item__price'><PriceViewer price={price}/></div>
    </div>
  </div>

}

export default OrderRoomItem