import React, { FC } from 'react';
import './FromResetPassword.scss';
import { Form, Formik } from 'formik';
import { formResetSchema } from '../../../../helpers/validation';
import AppFormInput from '../../../../components/ui/forms/controls/AppFormInput';
import AppButton from '../../../../components/ui/components/Buttons/AppButton/AppButton';
import AppFormCode from '../../../../components/ui/forms/controls/AppFormCode';
import { FormikHelpers } from 'formik/dist/types';

interface InitialProps {
   password: string;
   login: string;
   password_confirmation: string;
}

interface Props {
   initialValues: InitialProps;
   onSubmit: (values: InitialProps, formikHelpers: FormikHelpers<InitialProps>) => any;
   isSending: boolean;
}

const FromResetPassword: FC<Props> = props => {
   const { initialValues, onSubmit, isSending } = props;

   return (
      <Formik initialValues={initialValues} validationSchema={formResetSchema} onSubmit={onSubmit}>
         <Form>
            <div>
               <AppFormInput
                  type="text"
                  name="login"
                  label={'Номер вашого телефону'}
                  disabled
                  alwaysShowMask={true}
                  mask="+38 \(999) 999 99 99"
               />
               <AppFormCode name="code" label={'Введіть код, який ми надіслали через SMS'} />
               <AppFormInput type="password" name="password" label="Пароль" />
               <AppFormInput type="password" name="password_confirmation" label="Пароль" />
            </div>

            <AppButton theme="primary" size="middle" className="sign-up__submit" showIndicator={isSending}>
               Підтвердити
            </AppButton>
         </Form>
      </Formik>
   );
};

export default FromResetPassword;
