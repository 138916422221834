import React, { FC } from 'react';
import './ProfileNavigationItem.scss';
import { Link } from 'react-router-dom';
import IconProfile from '../../../../../components/ui/icons/account/IconProfile';
import IconRight from '../../../../../components/ui/icons/account/IconRight';
import classNames from 'classnames';

interface Props {
   link?: string;
   title: string;
   icon: React.ReactNode;
   onClick?: (e) => void;
   style?: any;
   className?: string;
}

const ProfileNavigationItem: FC<Props> = props => {
   const { link, onClick, title, icon, style, className } = props;
   const classes = classNames('profile-navigation-item', className);

   const inner = () => (
      <>
         <div>
            <div className='profile-navigation-item__icon'>{icon}</div>
            <div className='profile-navigation-item__title'>{title}</div>
         </div>
         <IconRight className='profile-navigation-item__icon-right' />
      </>
   );


   if (link) {
      return (
         <Link to={link} onClick={onClick} className={classes} style={style}>
            {inner()}
         </Link>
      );
   }

   return (
      <div onClick={onClick} className={classes} style={style}>
         {inner()}
      </div>
   );
};

export default ProfileNavigationItem;
